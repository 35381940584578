import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { deleteCatalog, setCatalog } from '../../../redux/actions/catalog';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import AutoCompleteInput from '../project-components/AutoCompleteInput';

const ModalConfirmation = ({ toggle, modal, row, deleteCatalog }) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Confirm Delete</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! This catalogue will be deleted and you cannot restore it.
            If any entry of the catalogue are referenced within any project, the
            respective reference(s) will be replaced by a copy of the catalogue
            entry. These copies will no longer be associated with any reference
            catalogues. Are you sure you want to proceed?
          </Label>
          <div>
            <ButtonGroup>
              <Button
                className='btn-confirm-delete mr-15'
                onClick={() => {
                  toggle();
                  deleteCatalog(row);
                }}
              >
                Ok
              </Button>
              <Button className='btn-cancel-delete ml-15' onClick={toggle}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const ViewProjectSpecificCatalog = ({
  modal,
  toggle,
  data,
  controlCatalogs,
  reftreeCatalogs,
  setCatalog,
  deleteCatalog,
}) => {
  const [catalogArray, setCatalogArray] = useState(
    controlCatalogs?.concat(reftreeCatalogs)
  );
  const history = useHistory();
  useEffect(() => {
    setCatalogArray(controlCatalogs?.concat(reftreeCatalogs));
  }, [controlCatalogs, reftreeCatalogs]);

  const viewCatalog = (catalog) => {
    setCatalog(catalog);
    history.push(`/catalogues/${catalog._id}/details`);
  };

  const [confiramtionModal, setConfirmationModal] = useState(false);
  const toggleConfirmation = () => setConfirmationModal(!confiramtionModal);
  const [deleteRow, setDeleteRow] = useState(null);

  const column = useMemo(() => [
    {
      name: 'Catalog',
      selector: 'name',
      sortable: true,
      maxWidth: '300px',
      cell: (row) => <>{row.name}</>,
    },
    {
      name: 'Content Type',
      selector: 'content_type',
      sortable: true,
      maxWidth: '300px',
      cell: (row) => (
        <>
          {row.content_type === 'control' ? (
            <Label>Control Catalogue</Label>
          ) : (
            <Label>Reference Tree Catalogue</Label>
          )}
        </>
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Button
            id={`detailsButton${row?._id}`}
            onClick={() => viewCatalog(row)}
          >
            <i className='fa fa-info' />
          </Button>
          <UncontrolledTooltip target={`detailsButton${row?._id}`}>
            Details
          </UncontrolledTooltip>
          <Button
            id={`deleteButton${row?._id}`}
            className='btn-danger'
            onClick={() => {
              toggleConfirmation();
              setDeleteRow(row);
            }}
          >
            <i className='fa fa-trash' />
          </Button>
          <UncontrolledTooltip target={`deleteButton${row?._id}`}>
            Delete
          </UncontrolledTooltip>
          <ModalConfirmation
            toggle={toggleConfirmation}
            modal={confiramtionModal}
            row={deleteRow}
            deleteCatalog={deleteCatalog}
          />
        </>
      ),
    },
  ]);

  var options = catalogArray?.map((catalog) => catalog.name);
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);

  const searchItems = catalogArray?.filter(
    (catalog) =>
      catalog &&
      catalog.name?.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search Catalogs'
      />
    );
  }, [searchText, resetPaginationToggle]);

  return (
    <DraggableModal
      isOpen={modal}
      toggle={toggle}
      unmountOnClose={false}
      size='lg'
    >
      <ModalHeader>
        <div className='modal-header'>View Project Specific Catalogues</div>
      </ModalHeader>
      <div className='modal-line' />
      <ModalBody className='modal-body'>
        <Fragment>
          <DataTable
            defaultSortAsc='id'
            columns={column}
            data={searchItems}
            pagination
            persistTableHead
            subHeader
            paginationResetDefaultPage={resetPaginationToggle}
            subHeaderComponent={subHeaderComponentMemo}
            customStyles={CustomDataTableStyles}
          />
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button className='btn-danger' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </DraggableModal>
  );
};

const mapStateToProps = (state) => ({
  controlCatalogs: state.project.control_catalogs,
  reftreeCatalogs: state.project.reftree_catalogs,
});

export default connect(mapStateToProps, { setCatalog, deleteCatalog })(
  ViewProjectSpecificCatalog
);
