import React from 'react';
import { Input } from 'reactstrap';

// this files create input options for the filtering of items in entity table
export const InputOptions = ({ type, name, inputChange, value, options }) => {
  var returnInput = null;
  switch (type) {
    case 'number':
      returnInput = (
        <span>
          <Input
            type='number'
            name={name}
            id='range1'
            onChange={inputChange}
            className='col-3 float-left'
          />
          <span className='col-1  float-left'> {' - '}</span>
          <Input
            type='number'
            name={name}
            id='range2'
            onChange={inputChange}
            className='col-3 float-left'
          />
        </span>
      );
      break;
    case 'select':
      returnInput = (
        <Input
          type='select'
          name={name}
          onChange={inputChange}
          className='col-8 float-left'
        >
          <option value=''>Select One</option>
          {options}
        </Input>
      );
      break;
    case 'text':
    default:
      returnInput = (
        <Input
          type='text'
          name={name}
          onChange={inputChange}
          className='col-8 float-left'
        />
      );
      break;
  }
  return returnInput;
};
