// styling for faEdit. This works differently in Apps.css
export const btnEdit = {
  padding: '0px',
  height: '18px',
  background: 'none',
  color: '#7AC142',
};
export const btnArrow = {
  padding: '0px',
  height: '18px',
  background: 'none',
  border: 'none',
  color: 'var(--font-color)',
};
export const btnTrash = {
  padding: '0px',
  height: '18px',
  background: 'none',
  color: 'tomato',
};
export const btnReply = {
  padding: '0px',
  height: '18px',
  background: 'none',
  color: '#003558',
};
