import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { ControlState, EntityType } from '../../../constants/Enums';
import {
  addReferenceTree,
  addReferenceTreeToNode,
  pasteSubtree,
} from '../../../redux/actions/analysistree';
import { addEntity, checkName } from '../../../redux/actions/entities';
import DraggableModal from './DraggableModal';
import { InputTemplate } from './InputTemplate';
import SimilarControlModalBody from './SimilarControlModalBody';

/**
 * Node Context Menu
 *
 * A modal for updating/creating controls
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param addEntity redux action mapped to state, creation of a new entity
 *  @param parent parent supplied by the parent component for update/creation
 *
 */

const ControlModal = ({ addEntity, toggle, modal, parent, checkName }) => {
  const [formData, setFormData] = useState({
    _id: undefined,
    name: undefined,
    description: '',
    likelihood: 0.1,
    likelihood_comment: undefined,
    proposed_state: undefined,
    implemented_state: undefined,
    owner: undefined, //TODO:set owner
    shared: undefined, //TODO: shared value
  });

  const changeFormData = (newFormData) => setFormData(newFormData);
  const [sameNameEntities, setSameNameEntities] = useState(undefined);
  const [selectedSimilarEntity, setSelectedSimilarEntity] = useState(undefined);
  const [sameNameCatalog, setSameNameCatalog] = useState(undefined);

  const onChange = (e) => {
    //checkbox to boolean value
    if (
      e.target.name === ControlState.proposed ||
      e.target.name === ControlState.implemented
    ) {
      changeFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      //Sets form data as undefined when user picks None
      changeFormData({
        ...formData,
        [e.target.name]: e.target.value === '' ? undefined : e.target.value,
      });
    }
  };

  const onSubmit = async () => {
    const similarEntities = await checkName(formData.name, EntityType.control);
    if (similarEntities) {
      setSameNameEntities(similarEntities);
    } else {
      addEntity(formData, EntityType.control, parent);
      toggle();
    }
  };

  const newEntity = () => {
    addEntity(formData, EntityType.control, parent);
  };
  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);
  return (
    <Fragment>
      <DraggableModal
        size={sameNameEntities ? 'lg' : ''}
        isOpen={modal}
        toggle={toggle}
        backdrop='static'
      >
        <ModalHeader>
          <div className='modal-header'>New Control</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          {sameNameEntities ? (
            <SimilarControlModalBody
              sameNameEntities={sameNameEntities}
              selectedSimilarEntity={selectedSimilarEntity}
              selectSimilarEntity={setSelectedSimilarEntity}
              parent={parent}
              toggle={toggle}
              newEntity={newEntity}
              entityName={formData.name}
              sameNameCatalog={sameNameCatalog}
              setSameNameCatalog={setSameNameCatalog}
            />
          ) : (
            <Form>
              <small>* required field</small>

              <FormGroup>
                <InputTemplate
                  label='* Name'
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={onChange}
                  innerRef={focusRef}
                />
                <InputTemplate
                  label='* Description'
                  type='textarea'
                  name='description'
                  value={formData.description}
                  onChange={onChange}
                />
                <InputTemplate
                  min={0}
                  max={1}
                  label='* Likelihood of compromising control'
                  type='number'
                  step={0.1}
                  name='likelihood'
                  onChange={onChange}
                  defaultValue={0.1}
                  invalid={
                    formData?.likelihood === undefined ||
                    !(formData.likelihood <= 1 && formData.likelihood >= 0)
                  }
                  formFeedback='The value of the likelihood has to be a number between 0 and
                1.'
                />
                <InputTemplate
                  label='Comment'
                  type='textarea'
                  name='likelihood_comment'
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  disabled={formData.name === undefined || formData.description === undefined}
                  className='float-right btn-add'
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Save
                </Button>
                <Button className='float-right btn-danger' onClick={toggle}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          )}
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  refTreeModificationParams: state.analysistree.refTreeModificationParams,
  userControlCatalogs: state.profile.userControlCatalogs,
  userReftrees: state.profile.userReftrees,
  defaultControlCatalogs: state.project.control_catalogs,
});

export default connect(mapStateToProps, {
  addEntity,
  checkName,
  pasteSubtree,
  addReferenceTree,
  addReferenceTreeToNode,
})(ControlModal);
