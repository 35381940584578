import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, UncontrolledTooltip } from 'reactstrap';
import {
  ImpactCategory,
  ProjectType,
  RiskType,
  SecurityGoal,
} from '../../../constants/Enums';
import {
  GenerateNodeId,
  maxFraction,
} from '../../../constants/StringConstants';
import RiskDisplay from './nodeObjects/RiskDisplay';
/**
 * Control node.
 *
 * A type of node for the attack tree, represents threats.
 *
 *  @param nodeData the node of the threat
 *  @param project redux store value of project, used to check project type
 *  @param likelihoodAndRiskFiltering view selection filtering for likelihood and risk
 *  @param toggleReview redux action to toggle review
 *
 */
export const ThreatNode = ({
  nodeData,
  project,
  likelihoodAndRiskFiltering,
  readOnly,
  impactViews,
  selectedProjectType,
}) => {
  const projectType = project?.project_type ?? selectedProjectType;
  const nodeName = nodeData?.name;

  //If any of these values are missing, display an exclamation mark at node
  const hasMissingValues =
    nodeData?.impact === undefined || nodeData?.security_goal === undefined;

  //Using a likelihood value and project type, gets the matching feasibility text
  const AttackFeasibility = (likelihood) => {
    var feasibility = ProjectType[projectType]?.AttackFeasibility.find(
      (feas) => likelihood >= feas.lower && likelihood <= feas.upper
    );
    return (
      <font
        style={{ color: feasibility?.color, fontWeight: 'bold', fontSize: 12 }}
      >
        {feasibility?.value}
      </font>
    );
  };

  const noControlLikelihood = maxFraction(nodeData?.no_control_likelihood);
  const allControlLikelihood = maxFraction(nodeData?.all_control_likelihood);
  const implementedControlLikelihood = maxFraction(
    nodeData?.implemented_control_likelihood
  );
  const proposedControlLikelihood = maxFraction(
    nodeData?.proposed_control_likelihood
  );

  const noControlRisk =
    impactViews &&
    Object.entries(nodeData?.risk)?.map(([category, categoryValues]) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.noControls);
    });

  const allControlRisk =
    impactViews &&
    Object.entries(nodeData?.risk)?.map(([category, categoryValues]) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.allControls);
    });

  const proposedControlRisk =
    impactViews &&
    Object.entries(nodeData?.risk)?.map(([category, categoryValues]) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.proposed);
    });

  const implementedControlRisk =
    impactViews &&
    Object.entries(nodeData?.risk)?.map(([category, categoryValues]) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.implemented);
    });

  return (
    <Fragment>
      <Row>
        {hasMissingValues && (
          <div>
            <div
              style={{ marginRight: 3 }}
              className='exclamation'
              id={`MissingRiskTooltip${nodeData?._id}`}
            >
              <FontAwesomeIcon icon={faExclamation} />
            </div>

            <UncontrolledTooltip
              placement='right'
              target={`MissingRiskTooltip${nodeData?._id}`}
            >
              Not all information needed to calculate the risk of this node has
              been specified yet.
            </UncontrolledTooltip>
          </div>
        )}
        <div className='node-id'>{nodeData?._id}</div>
        <div style={{ width: '5px' }}></div>
        <div
          className='node-name'
          id={`threatDescription${GenerateNodeId(nodeData?._id)}`}
        >
          {nodeName}
        </div>
      </Row>
      <Row>
        <div pill>
          <div className='node-type'>
            {ProjectType[projectType]?.ThreatType}
          </div>
          {nodeData?.description && (
            <UncontrolledTooltip
              placement='right'
              target={`threatDescription${GenerateNodeId(nodeData?._id)}`}
              disabled={nodeData?.description === undefined}
              className='display-linebreak'
            >
              {nodeData?.description}
            </UncontrolledTooltip>
          )}
        </div>
      </Row>

      <div id={`ThreatTooltip${GenerateNodeId(nodeData?._id)}`}>
        {(likelihoodAndRiskFiltering?.noControls || readOnly) && (
          <Fragment>
            <Row>
              <div className='node-table-data'>
                No control{' '}
                {ProjectType[projectType]?.RiskColumnText?.toLowerCase()} :{' '}
                {AttackFeasibility(nodeData?.no_control_likelihood)}
              </div>
              <div className='node-table-data'>
                No control likelihood: {noControlLikelihood}
              </div>
            </Row>
            {noControlRisk}
          </Fragment>
        )}
        {(likelihoodAndRiskFiltering?.implementedControls || readOnly) && (
          <Fragment>
            <Row>
              <div className='node-table-data'>
                Implemented control{' '}
                {ProjectType[projectType]?.RiskColumnText?.toLowerCase()} :{' '}
                {AttackFeasibility(nodeData?.implemented_control_likelihood)}
              </div>
              <div className='node-table-data'>
                Implemented likelihood: {implementedControlLikelihood}
              </div>
            </Row>
            {implementedControlRisk}
          </Fragment>
        )}
        {(likelihoodAndRiskFiltering?.proposedControls || readOnly) && (
          <Fragment>
            <Row>
              <div className='node-table-data'>
                Proposed control{' '}
                {ProjectType[projectType]?.RiskColumnText?.toLowerCase()} :{' '}
                {AttackFeasibility(nodeData?.proposed_control_likelihood)}
              </div>
              <div className='node-table-data'>
                Proposed likelihood: {proposedControlLikelihood}
              </div>
            </Row>
            {proposedControlRisk}
          </Fragment>
        )}
        {(likelihoodAndRiskFiltering?.allControls || readOnly) && (
          <Fragment>
            <Row>
              <div className='node-table-data'>
                All control{' '}
                {ProjectType[projectType]?.RiskColumnText?.toLowerCase()} :{' '}
                {AttackFeasibility(nodeData?.all_control_likelihood)}
              </div>
            </Row>
            <Row>
              <div className='node-table-data'>
                All control likelihood: {allControlLikelihood}
              </div>
            </Row>
            {allControlRisk}
          </Fragment>
        )}
      </div>

      <Row>
        <div className='node-table-data'>
          {nodeData?.impact_category !== undefined
            ? `${ImpactCategory[nodeData?.impact_category]?.name}: `
            : nodeData?.impact_level !== undefined
            ? 'Impact Level: '
            : undefined}
          {nodeData?.impact_level && (
            <Fragment>
              {
                ProjectType[projectType]?.ImpactLevelTypes[
                  nodeData?.impact_level
                ]
              }
            </Fragment>
          )}
        </div>
        <div className='node-table-data'>
          {ProjectType[projectType]?.ThreatSecurityType} :{' '}
          {nodeData?.security_goal !== undefined
            ? SecurityGoal[nodeData?.security_goal]?.name
            : 'N/A'}
        </div>
        <UncontrolledTooltip
          placement='right'
          target={`ThreatTooltip${GenerateNodeId(nodeData?._id)}`}
        >
          No control Likelihood: <br></br>
          {nodeData?.no_control_likelihood?.toExponential(4)}
          <br></br>
          Implemented likelihood: <br></br>
          {nodeData?.implemented_control_likelihood?.toExponential(4)}
          <br></br>
          Proposed likelihood: <br></br>
          {nodeData?.proposed_control_likelihood?.toExponential(4)}
          <br></br>
          All control likelihood: <br></br>
          {nodeData?.all_control_likelihood?.toExponential(4)}
        </UncontrolledTooltip>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  likelihoodAndRiskFiltering: state.analysistree.viewSelection,
  searchString: state.analysistree.searchCriteria,
  impactViews: state.analysistree.impactViews,
  project: state.project.project,
  selectedProjectType: state.analysistree.selectedProjectType,
  projectRole: state.profile.projectRole,
});

export default connect(mapStateToProps, {})(ThreatNode);
