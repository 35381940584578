import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import {
  OwnerType,
  RefTreeActionType,
  RefTreeModType,
} from '../../constants/Enums';
import {
  copySubtree,
  cutSubtree,
  pasteSubtree,
  SetRefTreeParams,
} from '../../redux/actions/analysistree';
import DraggableModal from '../entities/EntityModals/DraggableModal';
/**
 * Forgot Password Modal.
 *
 * Modal component for restoring forgotten passwords
 *
 *  @param modal stateful value from parent component, checks if modal should be shown
 *  @param toggle stateful value from parent component, triggers modal opening/closing
 *
 */
const RefTreeModificationModal = ({
  nodeData,
  modal,
  toggle,
  type,
  nextToggle = undefined,
  fromTree = false,
  fromEntities = false,
  SetRefTreeParams,
  cutSubtree,
  pasteSubtree,
  confirmEdit,
  analysistree,
}) => {
  return (
    <DraggableModal isOpen={modal} toggle={toggle}>
      <ModalHeader>
        <div className='confirm-modal-header'>
          Reference Tree Modification Warning
        </div>
      </ModalHeader>
      <div className='confirm-modal-line' />
      <ModalBody className='confirm-modal-body'>
        Warning! You are about to modify a reference tree. Every instance of the
        reference tree within the project will be modified. Are you sure you
        want to proceed?
        <br></br>
        {fromTree && (
          <Fragment>
            Alternatively, you can apply the change only to this instance of the
            reference tree. In this case, the subtree will no longer be
            associated with the respective reference tree. All other instances
            of the reference tree will not be affected.
          </Fragment>
        )}
        {fromEntities && (
          <Fragment>
            Alternatively, you can create a new reference tree and apply the
            change only to the new reference tree. In this case, the new
            reference tree will no longer be associated with the original
            reference tree. All instances of the original reference tree will
            not be affected.
          </Fragment>
        )}
        <FormGroup>
          <br />
          {fromEntities && (
            <Button
              type='submit'
              className='float-right btn-add'
              onClick={() => {
                SetRefTreeParams(type, RefTreeActionType.NewReftree, nodeData);
                if (nextToggle !== undefined) {
                  nextToggle();
                }
                toggle();
              }}
            >
              Create new reference tree
            </Button>
          )}
          {/* disables editing of only an instance if the root node of the analysis tree is a reference tree */}
          {fromTree && analysistree?.owner?.owner_type !== OwnerType.RefTree && (
            <Button
              disabled={type === RefTreeModType.Transfer}
              type='submit'
              className='float-right btn-add'
              onClick={() => {
                switch (type) {
                  case RefTreeModType.Pasting:
                    const params = {
                      type: type,
                      action: RefTreeActionType.NewSubtree,
                      params: {
                        node: Object.assign({}, nodeData),
                      },
                    };
                    pasteSubtree(nodeData, params);
                    toggle();
                    break;
                  case RefTreeModType.Cut:
                    SetRefTreeParams(
                      type,
                      RefTreeActionType.NewSubtree,
                      nodeData
                    );
                    cutSubtree(nodeData);
                    toggle();
                    break;
                  case RefTreeModType.Editing:
                    SetRefTreeParams(
                      type,
                      RefTreeActionType.NewSubtree,
                      nodeData
                    );
                    confirmEdit();
                    toggle();
                    break;
                  default:
                    SetRefTreeParams(
                      type,
                      RefTreeActionType.NewSubtree,
                      nodeData
                    );
                    toggle();
                    nextToggle();
                    break;
                }
              }}
            >
              Edit only this instance
            </Button>
          )}
          <Button
            type='submit'
            className='float-right btn-confirm-delete mr-15'
            onClick={() => {
              switch (type) {
                case RefTreeModType.Pasting:
                  const params = {
                    type: type,
                    action: RefTreeActionType.Edit,
                    params: {
                      node: nodeData,
                    },
                  };
                  pasteSubtree(nodeData, params);
                  toggle();
                  break;
                case RefTreeModType.Transfer:
                case RefTreeModType.Cut:
                  SetRefTreeParams(type, RefTreeActionType.Edit, nodeData);
                  cutSubtree(nodeData);
                  toggle();
                  break;
                case RefTreeModType.Editing:
                  SetRefTreeParams(type, RefTreeActionType.Edit, nodeData);
                  confirmEdit();
                  toggle();
                  break;
                default:
                  SetRefTreeParams(type, RefTreeActionType.Edit, nodeData);
                  nextToggle();
                  toggle();
                  break;
              }
            }}
          >
            Ok
          </Button>
          <Button
            className='float-right btn-cancel-delete mr-15'
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </FormGroup>
      </ModalBody>
    </DraggableModal>
  );
};

const mapStateToProps = (state) => ({
  analysistree: state.analysistree.analysistree,
});

export default connect(mapStateToProps, {
  SetRefTreeParams,
  cutSubtree,
  copySubtree,
  pasteSubtree,
})(RefTreeModificationModal);
