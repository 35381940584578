import { default as React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  Container,
} from 'reactstrap';
import SearchComponent from '../SearchComponent';
import BugReportModal from './BugReportModal';
import IntendedWorkflow from './IntendedWorkflow';
import { ToolInformation } from './ToolInformation';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */
const Tool = ({ projects, loading }) => {
  //Declares and displays the project list
  useEffect(() => {}, []);

  const [bugReport, setBugReport] = useState(false);
  const toggleBugReport = () => setBugReport(!bugReport);

  const [openWorkflow, setWorkflow] = useState(true);
  const toggleWorkflow = () => setWorkflow(!openWorkflow);

  const [openFAQ, setFAQ] = useState(true);
  const toggleFAQ = () => setFAQ(!openFAQ);

  return (
    <Container fluid='md'>
      <CardTitle>
        <h1>Application</h1>
        {/* <div className='card-actions float-right'>
          <Button color='primary' onClick={toggleBugReport}>
            Bug Report
          </Button>
        </div> */}
      </CardTitle>
      <Card>
        <CardBody>
          <h3>
            Manual:{' '}
            <a href={require('./INV_CoE_CS_Manual_ISAT40.pdf')} download>
              Download
            </a>
          </h3>
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          onClick={toggleWorkflow}
          style={{ cursor: 'pointer' }}
          className='bold'
        >
          {' '}
          {openWorkflow ? (
            <i className='fa fa-caret-down' />
          ) : (
            <i className='fa fa-caret-right' />
          )}{' '}
          {'  '}Intended Workflow
        </CardHeader>
        <Collapse isOpen={openWorkflow}>
          <CardBody>
            <IntendedWorkflow />
          </CardBody>
        </Collapse>
      </Card>

      <Card>
        <CardHeader
          style={{ cursor: 'pointer' }}
          onClick={toggleFAQ}
          className='bold'
        >
          {' '}
          {openFAQ ? (
            <i className='fa fa-caret-down' />
          ) : (
            <i className='fa fa-caret-right' />
          )}{' '}
          {'  '}Frequently Asked Questions
        </CardHeader>
        <Collapse isOpen={openFAQ}>
          <CardBody>
            <SearchComponent data={ToolInformation.FAQ} />
          </CardBody>
        </Collapse>
      </Card>
      <BugReportModal modal={bugReport} toggle={toggleBugReport} />
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(Tool));
