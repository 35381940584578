import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Input, Label, Table } from 'reactstrap';
import axiosMethods from '../../../../Axios/axios';
import DeleteModal from '../../../projects/project-components/DeleteModal';
import { setAlert } from '../../../../redux/actions/alert';
import store from '../../../../redux/store';
import { GoalModal } from './GoalModal';
import { connect } from 'react-redux';
import { EntityType, RiskDecision } from '../../../../constants/Enums';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { setEntity } from '../../../../redux/actions/entities';

const RiskDecisionControl = ({
  type,
  entityId,
  riskDecisionControls,
  riskDecision,
  entities,
  project,
  setEntity,
}) => {
  const history = useHistory();
  const { dispatch } = store;
  const [riskControls, setRiskControls] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(undefined);
  const [updateIndex, setUpdateIndex] = useState(undefined);
  const [updatedRiskControl, setUpdatedRiskControl] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [controlList, setControlList] = useState([]);
  const addToggle = () => setAddModal(!addModal);

  const update = async (item) => {
    const newRiskControls = [...riskControls];
        const control = riskControls[updateIndex];
        await axiosMethods.entity
          .patch(`${entityId}/risk_decision/${control.id}`, item)
          .then(() => {
            control.text = item?.text;
            control.link = item?.link;
            control.type = item?.type;
            newRiskControls[updateIndex] = control;
            setRiskControls(newRiskControls);
            setUpdateIndex(undefined);
            setUpdatedRiskControl(undefined)
          })
          .catch((error) => {
            dispatch(
              setAlert(
                `Error updating Cybersecurity ${type} encountered. Error message: ${
                  error?.response?.data?.msg ?? error?.response?.data?.message
                }`,
                'danger'
              )
            );
          });
  }

  const save = async (item) => {
    await axiosMethods.entity
      .put(`${entityId}/risk_decision/`, item)
      .then((response) => {
        setRiskControls([...riskControls, response.data]);
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error adding Cybersecurity ${type} encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteControl = async () => {
    const newRiskControls = [...riskControls];
    const control = riskControls[deleteIndex];
    await axiosMethods.entity
      .delete(`${entityId}/risk_decision/${control.id}`)
      .then(() => {
        newRiskControls.splice(deleteIndex, 1);
        setRiskControls(newRiskControls);
        setDeleteIndex(undefined);
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error deleting Cybersecurity ${type} encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

  const deleteItem = (index) => {
    setDeleteIndex(index);
    deleteToggle();
  };

  const updateItem = (index, riskControl) => {
    setUpdateIndex(index);
    setUpdatedRiskControl(riskControl);
    addToggle()
  };

  const getControlItem = (id) =>
    controlList.find((control) => control._id === parseInt(id));
    

  useEffect(() => {
    if (riskDecisionControls?.length > 0) {
      setRiskControls(riskDecisionControls);
    }
  }, riskDecisionControls);

  useEffect(() => {
    const threatScenario = entities.find((entity) => entity._id === entityId);
    let controls = [];
    threatScenario?.children?.forEach(getControls);
    function getControls(item) {
      const entityItem = entities.find((entity) => entity._id === item);
      if (entityItem.entity_type === EntityType.control) {
        if (controls.find((control) => control._id === item) === undefined) {
          controls.push(entityItem);
        }
      } else {
        entityItem?.children?.forEach(getControls);
      }
    }
    setControlList(controls);
  }, [entities]);

  return (
    <>
      <div>
        <div style={{marginBottom: '15px'}} className='float-right'>
          <Button className='btn-add' onClick={addToggle}>
            Add New {type}
          </Button>
        </div>
      </div>
      <div>
        <Table>
          <thead>
            <th>Cybersecurity {type}</th>
            <th> Type</th>
            {riskDecision === RiskDecision.reducing.toLowerCase() && (
              <>
                <th>Control Name</th>
                <th>Control Link</th>
              </>
            )}
            <th>Actions</th>
          </thead>
          <tbody>
            {riskControls?.map((riskControl, index) => (
              <tr>
                <td width={'40%'}>{riskControl?.text}</td>
                <td>
                  {riskControl?.type}
                </td>
                {riskDecision === RiskDecision.reducing.toLowerCase() && (
                  <>
                    <td>{getControlItem(riskControl?.link)?.name}</td>
                    <td>
                      <Link
                        to = '#'
                        onClick={() => {
                          const control = getControlItem(riskControl?.link);
                          setEntity(control);
                          history.push(
                            `/${project._id}/analysisTree/node-details/${riskControl?.link}`
                          );
                        }}
                      >
                        {getControlItem(riskControl?.link)?._id}{' '}
                      </Link>
                    </td>
                  </>
                )}
                <td>
                  <ButtonGroup>
                    <Button onClick={() => updateItem(index, riskControl)}>
                      <i className='fa fa-edit' />
                    </Button>
                    <Button
                      className='btn-danger'
                      onClick={() => deleteItem(index)}
                    >
                      <i className='fa fa-trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteModal
        isOpen={deleteModal && deleteIndex !== undefined}
        toggle={deleteToggle}
        onClick={() => {
          deleteControl();
          deleteToggle();
        }}
        message={`Delete Cybersecurity ${type}?`}
        header='Delete'
      />
      <GoalModal
        modal={addModal}
        toggle={addToggle}
        type={type}
        riskDecision={riskDecision}
        controls={controlList}
        save={(item) => save(item)}
        update= {(item) => update(item)}
        updateIndex = {updateIndex}
        riskControl = {updatedRiskControl}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  entities: state.entities.entities,
  project: state.project.project,
});
export default withRouter(
  connect(mapStateToProps, { setEntity })(RiskDecisionControl)
);
