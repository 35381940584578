import React, { Fragment } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';

/**
 * Delete Modal
 *
 * Modal used for delete feature on projects
 *
 * @param isOpen triggers opening of modal
 * @param toggle sets the toggle of the modal
 * @param onClick sets the method being triggered when the Ok Button is clicked
 * @param message message in body of the modal
 * @param header specified depends on what page or what data is to be deleted
 */
const DeleteModal = ({ isOpen, toggle, onClick, message, header }) => {
  return (
    <Fragment>
      <DraggableModal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          <div className='confirm-modal-header'>{header}</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>{message}</ModalBody>
        <ModalFooter
          style={{ width: '70%', margin: 'auto', borderTop: 'none' }}
        >
          <Button className='btn-cancel-delete' onClick={toggle}>
            Cancel
          </Button>
          <Button className='btn-confirm-delete' onClick={onClick}>
            Confirm
          </Button>
        </ModalFooter>
      </DraggableModal>
    </Fragment>
  );
};

export default DeleteModal;
