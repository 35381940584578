// SessionStorage.js
const SessionStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setAccessToken(access) {
    localStorage.setItem('access_token', access);
  }
  function _setRefreshToken(refresh) {
    localStorage.setItem('refresh_token', refresh);
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _setUserId(user_id) {
    localStorage.setItem('user_id', user_id);
  }
  function _getUserId() {
    return localStorage.getItem('user_id');
  }
  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');

  }
  return {
    getService: _getService,
    setAccessToken: _setAccessToken,
    setRefreshToken: _setRefreshToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    setUserId: _setUserId,
    getUserId: _getUserId,
    clearToken: _clearToken,
  };
})();

export default SessionStorageService;
