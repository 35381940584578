import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, UncontrolledTooltip } from 'reactstrap';
import { GenerateNodeId } from '../../../constants/StringConstants';
import { addSearchId } from '../../../redux/actions/analysistree';

/**
 * Asset node.
 *
 * A type of node for the attack tree, represents assets.
 *
 *  @param nodeData the node of the asset
 *  @param toggleReview redux action to toggle review
 *
 */
const AssetNode = ({ nodeData, projectRole }) => {
  const nodeName = nodeData?.name;

  return (
    <Fragment>
      <Row>
        <div className='node-id'>{nodeData?._id}</div>
        <div style={{ width: '5px' }}></div>
        <div
          className='node-name'
          id={`assetDescription${GenerateNodeId(nodeData?._id)}`}
        >
          {nodeName}
        </div>
      </Row>
      <Row>
        <div className='node-type'>Asset</div>
        {nodeData?.description && (
          <UncontrolledTooltip
            placement='right'
            target={`assetDescription${GenerateNodeId(nodeData?._id)}`}
            disabled={nodeData?.description === undefined}
            className='display-linebreak'
          >
            {nodeData?.description}
          </UncontrolledTooltip>
        )}
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  projectRole: state.profile.projectRole,
});

export default connect(mapStateToProps, { addSearchId })(AssetNode);
