import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setProject } from '../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from 'reactstrap';
import { GuidanceInformation, EvaluationCriteria } from './GuidancePageItems';
import ExportReviewModal from './ExportReviewModal';

const GuidancePage = ({ loading, project, setProject, profile }) => {
  useEffect(() => {
    if (profile !== null) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);

  const [activeTab, setActiveTab] = useState(0);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const handleExportButtonClick = () => {
    setExportModalOpen(true);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const currentTabInfo = GuidanceInformation[activeTab];

  return (
    <Container fluid='md'>
      <CardTitle>
        <h1>Guidance</h1>
      </CardTitle>
      <CardBody>
        {' '}
        <div
          className='card-actions float-right'
          style={{ marginBottom: '10px' }}
        >
          <Button color='add' onClick={handleExportButtonClick}>
            Export
          </Button>
        </div>
        <Row>
          <Col xl='2'>
            <Nav vertical pills>
              {GuidanceInformation.map((info, index) => (
                <NavItem key={index}>
                  <NavLink
                    href='#'
                    className={classnames({ active: activeTab === index })}
                    onClick={() => toggle(index)}
                  >
                    {info.header}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col xl='10'>
            <p>{currentTabInfo.information}</p>
            <ul>
              {currentTabInfo.FAQ.map((faq) => (
                <li key={faq.id}>
                  <strong>{faq.question}</strong>
                  {Array.isArray(faq.answer) ? (
                    <ul>
                      {faq.answer.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <h2> Evaluation Criteria </h2>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {EvaluationCriteria.map((criteria, index) => (
              <tr key={index}>
                <td>{criteria.header}</td>
                <td>{criteria.information}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {project && (
          <ExportReviewModal
            modal={isExportModalOpen}
            toggle={() => setExportModalOpen(!isExportModalOpen)}
            currentProject={project}
          />
        )}
      </CardFooter>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { setProject })(GuidancePage)
);
