import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { CatalogLocation } from '../../../constants/Enums';
import { addReferenceTreeToNode } from '../../../redux/actions/analysistree';
import useDebounce from '../../helpers/Debouncer';
import { nameFiltering } from '../../helpers/genericHelper';
import CatalogTableComponent from './CatalogTableComponent';
import DraggableModal from './DraggableModal';
import { InputTemplate } from './InputTemplate';

/**
 * Reference Tree Catalog Modal
 *
 * A modal component used to add reference trees from project specific or global catalogs
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *
 */
const RefTreeCatalogModal = ({
  refTreeCatalogs,
  parent,
  toggle,
  modal,
  addReferenceTreeToNode,
  changeParentEstimation,
  project,
  userReftrees,
  defaultReftreeCatalogs,
}) => {
  const data = refTreeCatalogs.filter(
    (reftree) =>
      reftree.catalog_type === 'global' ||
      parseInt(reftree.project_id) === parseInt(project._id)
  );
  const [filteredCatalogs, setFilteredCatalogs] = useState(data);
  const [filteredReftrees, setFilteredReftrees] = useState(undefined);

  const changeFilteredCatalogs = (catalogData) =>
    setFilteredCatalogs(catalogData);

  const [searchString, setSearchString] = useState('');

  const deboucedSearchString = useDebounce(searchString, 500);

  const [formData, setFormData] = useState({
    refTree: undefined,
    catalog: undefined,
  });

  const selectProjectCatalog = (e) => {
    const catalogId = parseInt(e.target.value);

    const projectCatalog = defaultReftreeCatalogs.find(
      (cat) => cat._id === catalogId
    );
    setFormData({
      ...formData,
      catalog: projectCatalog,
    });
  };

  const onRefTreeSelect = (reftree) => {
    //Sets form data as undefined when user picks None
    setFormData({
      ...formData,
      refTree: formData.refTree !== reftree ? reftree : undefined,
    });
  };

  useEffect(() => {
    let catalogs = Object.assign([], filteredCatalogs);
    if (deboucedSearchString === '' || deboucedSearchString === undefined) {
      changeFilteredCatalogs(data);
    } else {
      const reftrees = nameFiltering(userReftrees, deboucedSearchString);
      catalogs = data.filter(
        (cat) =>
          cat.name.toLowerCase().includes(deboucedSearchString.toLowerCase()) ||
          reftrees.some(
            (reftree) => parseInt(reftree.catalog_id) === parseInt(cat._id)
          )
      );
      setFilteredReftrees(reftrees);
      changeFilteredCatalogs(catalogs);
      if (formData.refTree !== undefined) {
        //check if reftree is in one of the filtered
        //remove if not
      }
    }
  }, [deboucedSearchString]);

  const onSubmit = async () => {
    if (
      filteredCatalogs.find((cat) => cat._id === formData?.refTree?.catalog_id)
        ?.catalog_type === CatalogLocation.Global
    ) {
      addReferenceTreeToNode(
        parent,
        formData.refTree,
        changeParentEstimation,
        formData.catalog
      );
    } else {
      addReferenceTreeToNode(parent, formData.refTree, changeParentEstimation);
    }
  };

  const columns = React.useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
  ]);

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal
        size='lg'
        isOpen={modal}
        toggle={toggle}
        backdrop='static'
      >
        <ModalHeader>
          <div className='modal-header'>Add Reference Tree</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <br />
          <FormGroup>
            <Input
              type='text'
              name='search'
              value={searchString}
              placeholder='Search'
              onChange={(e) => setSearchString(e.target.value)}
              innerRef={focusRef}
            ></Input>
          </FormGroup>
          <CatalogTableComponent
            data={filteredCatalogs}
            onRefTreeSelect={onRefTreeSelect}
            columns={columns}
            selectedRefTree={formData.refTree}
            filteredReftrees={filteredReftrees}
          />
          <Form>
            {filteredCatalogs.find(
              (cat) => cat._id === formData?.refTree?.catalog_id
            )?.catalog_type === CatalogLocation.Global && (
              <FormGroup>
                <InputTemplate
                  label='Project Catalog Assignment'
                  type='select'
                  name='catalog'
                  value={formData.catalog}
                  onChange={selectProjectCatalog}
                  selectDefault
                >
                  {defaultReftreeCatalogs?.map((catalogItem) => {
                    return (
                      <option value={catalogItem._id}>
                        {catalogItem.name}
                      </option>
                    );
                  })}
                </InputTemplate>
              </FormGroup>
            )}
            <FormGroup>
              <Button
                disabled={
                  formData.refTree === undefined ||
                  (filteredCatalogs.find(
                    (cat) => cat._id === formData?.refTree?.catalog_id
                  )?.catalog_type === CatalogLocation.Global &&
                    formData.catalog === undefined)
                }
                type='submit'
                className='float-right btn-add'
                onClick={() => {
                  onSubmit();
                  toggle();
                }}
              >
                Save
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  controls: state.controls.controls,
  refTreeCatalogs: state.profile.userRefTreeCatalogs,
  defaultReftreeCatalogs: state.project.reftree_catalogs,
  project: state.project.project,
  userReftrees: state.profile.userReftrees,
});
export default connect(mapStateToProps, { addReferenceTreeToNode })(
  RefTreeCatalogModal
);
