import React, { Fragment, useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  Button,
  Col,
  Input,
  Label,
  NavbarBrand,
  NavbarToggler,
  PopoverBody,
  Row,
  UncontrolledCollapse,
  UncontrolledPopover,
} from 'reactstrap';
import { isWithinBounds } from '../../components/helpers/AnalysisTreeHelper';
const _ = require('lodash');
//This the Expanded area when clicking the entities in the change log
const RecycleBinSearchComponent = ({
  id,
  data,
  filteredData,
  setFilteredData,
  adminBin = false,
}) => {
  const baseSearchCriteria = {
    Id: { lower: undefined, upper: undefined },
    type: undefined,
    parentId: { lower: undefined, upper: undefined },
    deletedBy: undefined,
    deletionDate: {
      fromDate: undefined,
      toDate: undefined,
    },
  };

  const [searchCriteria, setSearchCriteria] = useState(baseSearchCriteria);
  const [enteredToDate, setEnteredToDate] = useState(undefined);
  const { Id, parentId, type, deletedBy, deletionDate } = searchCriteria;
  const { fromDate, toDate } = deletionDate;
  const { lower: lowerId, upper: upperId } = Id;
  const { lower: lowerParentId, upper: upperParentId } = parentId;

  const setIdLower = (value) => {
    setSearchCriteria({
      ...searchCriteria,
      Id: { lower: value === '' ? undefined : parseInt(value), upper: upperId },
    });
  };
  const setIdUpper = (value) => {
    setSearchCriteria({
      ...searchCriteria,
      Id: { lower: lowerId, upper: value === '' ? undefined : parseInt(value) },
    });
  };
  const setParentIdLower = (value) => {
    setSearchCriteria({
      ...searchCriteria,
      parentId: {
        lower: value === '' ? undefined : parseInt(value),
        upper: upperParentId,
      },
    });
  };
  const setParentIdUpper = (value) => {
    setSearchCriteria({
      ...searchCriteria,
      parentId: {
        lower: lowerParentId,
        upper: value === '' ? undefined : parseInt(value),
      },
    });
  };

  const setType = (type) => {
    setSearchCriteria({
      ...searchCriteria,
      type: type === '' ? undefined : type,
    });
  };

  const setDeletedBy = (user) => {
    setSearchCriteria({
      ...searchCriteria,
      deletedBy: user === '' ? undefined : user,
    });
  };

  const setToDates = (to, enteredTo) => {
    setSearchCriteria({
      ...searchCriteria,
      deletionDate: {
        fromDate: fromDate,
        toDate: to,
      },
    });
    setEnteredToDate(enteredTo);
  };

  const setDeletionDates = (from, to, enteredTo) => {
    setSearchCriteria({
      ...searchCriteria,
      deletionDate: {
        fromDate: from,
        toDate: to,
      },
    });
    setEnteredToDate(enteredTo);
  };

  const handleResetClick = () => {
    setSearchCriteria(baseSearchCriteria);
  };

  const handleDayClick = (day) => {
    if (fromDate && toDate && day >= fromDate && day <= toDate) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(fromDate, toDate, day)) {
      setDeletionDates(day, undefined, undefined);
    } else {
      setToDates(day, day);
    }
  };

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(fromDate, toDate, day)) {
      setEnteredToDate(day);
    }
  };

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  useEffect(() => {
    //if data changes filter and set filtered data
    const filteredData = filterItems();
    setFilteredData(filteredData);
  }, [data, searchCriteria]);

  const filterItems = () => {
    if (data === undefined) {
      return data;
    }
    if (_.isEqual(searchCriteria, baseSearchCriteria)) {
      return data;
    }
    return data.filter((item) => {
      //Adds 1 to upper bound, and reduces 1 for lower bound
      if (
        isWithinBounds(
          {
            ...searchCriteria?.Id,
            upper: searchCriteria?.Id?.upper + 1,
            lower: searchCriteria?.Id?.lower - 1,
          },
          item?.root_object?.id
        )
      ) {
        return true;
      }
      if (
        isWithinBounds(
          {
            ...searchCriteria?.parentId,
            upper: searchCriteria?.parentId?.upper + 1,
            lower: searchCriteria?.parentId?.lower - 1,
          },
          item?.object_links?.[0]?.parent_id
        )
      ) {
        return true;
      }
      if (
        item?.root_object?.object_type
          ?.toLowerCase()
          .includes(searchCriteria?.type?.toLowerCase())
      ) {
        return true;
      }
      if (
        item?.user_name
          ?.toLowerCase()
          .includes(searchCriteria?.deletedBy?.toLowerCase())
      ) {
        return true;
      }
      if (dateWithinBounds(item.creation_date)) {
        return true;
      }
      return false;
    });
  };

  const dateWithinBounds = (date) => {
    if (
      deletionDate.toDate === undefined ||
      deletionDate.fromDate === undefined
    ) {
      return false;
    }
    const dateObject = new Date(date);
    return (
      new Date(deletionDate.toDate).setHours(23, 59, 59) >= dateObject &&
      new Date(deletionDate.fromDate).setHours(0, 0, 0) <= dateObject
    );
  };

  return (
    <Fragment>
      <NavbarBrand className='mr-auto'>
        <NavbarToggler id={`SearchDateFilterSubcomponent${id}`}>
          <i className='fas fa-filter' />
        </NavbarToggler>
        Filters
      </NavbarBrand>
      <UncontrolledCollapse toggler={`#SearchDateFilterSubcomponent${id}`}>
        <Row>
          <Col>
            <Row>
              <Label className=''>ID</Label>
              <Input
                type='number'
                onChange={(event) => {
                  setIdLower(event.target.value);
                }}
                className='col-3 '
              />
              -
              <Input
                type='number'
                onChange={(event) => {
                  setIdUpper(event.target.value);
                }}
                className='col-3 '
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <Label className=''>Type</Label>
              <Input
                onChange={(event) => {
                  setType(event.target.value);
                }}
                value={type}
                className='col-8 '
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <Label className=''>Deleted By</Label>
              <Input
                onChange={(event) => {
                  setDeletedBy(event.target.value);
                }}
                value={deletedBy}
                className='col-8 '
              />
            </Row>
          </Col>
          {!adminBin && (
            <Col>
              <Row>
                <Label className=''>Parent ID</Label>
                <Input
                  type='number'
                  onChange={(event) => {
                    setParentIdLower(event.target.value);
                  }}
                  className='col-3 '
                />
                -
                <Input
                  type='number'
                  onChange={(event) => {
                    setParentIdUpper(event.target.value);
                  }}
                  className='col-3 '
                />
              </Row>
            </Col>
          )}
          <Col>
            <Button id='UncontrolledPopover' type='button'>
              Date Filter
            </Button>
            <UncontrolledPopover
              placement='bottom'
              target='UncontrolledPopover'
            >
              <PopoverBody>
                <div className='RangeDatePicker'>
                  <DayPicker
                    className='Range'
                    numberOfMonths={1}
                    fromMonth={fromDate}
                    selectedDays={[fromDate, { fromDate, to: enteredToDate }]}
                    disabledDays={{ before: fromDate }}
                    modifiers={{ start: fromDate, end: enteredToDate }}
                    onDayClick={handleDayClick}
                    onDayMouseEnter={handleDayMouseEnter}
                  />
                </div>
                {!fromDate && !toDate && 'Please select the first day.'}
                {fromDate && !toDate && 'Please select the last day.'}
                {fromDate &&
                  toDate &&
                  `Selected from ${fromDate.toLocaleDateString()} to
                ${toDate.toLocaleDateString()}`}{' '}
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        </Row>
      </UncontrolledCollapse>
    </Fragment>
  );
};

export default RecycleBinSearchComponent;
