export const GeneralItems = [
  {
    questionId: 'General-1',
    checkpoint:
      'Does the document have a Cover Sheet which includes the following information? (Project name, Document name, Document owner, Version / Document ID, Template version, Change history, Document status)',
  },
  {
    questionId: 'General-2',
    checkpoint:
      'Is the document info on the coversheet consistent with the information in the configuration item list?',
  },
  {
    questionId: 'General-3',
    checkpoint: 'Has the currently released template been used?',
  },
  {
    questionId: 'General-4',
    checkpoint:
      'If the template has been tailored or adjusted, are the reasons described in the document?',
  },
  {
    questionId: 'General-5',
    checkpoint:
      'Are the versions of the document controlled and is each version clearly identifiable?',
  },
  {
    questionId: 'General-6',
    checkpoint: 'Does the document name fulfill the naming convention? ',
  },
  {
    questionId: 'General-7',
    checkpoint:
      'Does the document has a change history and are all the changes described? ',
  },
  {
    questionId: 'General-8',
    checkpoint:
      'Is the document updated regularly according to the update cycle defined in the configuration item list?',
  },
  {
    questionId: 'General-9',
    checkpoint:
      'Are the relevant stakeholders (i.e., representatives from all domains) for this document part of the review?',
  },
  {
    questionId: 'General-10',
    checkpoint:
      'Does every relevant stakeholder has at least reading access to the document? ',
  },
  {
    questionId: 'General-11',
    checkpoint:
      'Do only allowed stakeholders have editorial access to the document? ',
  },
];

export const AssetIdentification = [
  {
    questionId: 'Technical-1',
    checkpoint:
      'Are the assets including the cybersecurity properties identified?',
  },
  {
    questionId: 'Technical-2',
    checkpoint:
      'Are damage scenarios related to each cybersecurity property and their compromise defined? (The compromise of the cybersecurity shall lead to a damage scenario)',
  },
  {
    questionId: 'Technical-3',
    checkpoint:
      'Are the identified assets cover the whole item including functions? ',
  },
  {
    questionId: 'Technical-4',
    checkpoint:
      'Are the identified assets cover the whole item including the interfaces (internal and external)?',
  },
];

export const ThreatScenarioIdentification = [
  {
    questionId: 'Technical-6',
    checkpoint:
      'Are all threat scenarios which can realise damage scenarios identified?',
  },
  {
    questionId: 'Technical-7',
    checkpoint:
      'Are threat scenarios include the targeted asset, the compromised cybersecurity property and the cause of the compromise?  ',
  },
];

export const ImpactRating = [
  {
    questionId: 'Technical-9',
    checkpoint:
      'Are all damage scenarios evaluated in terms of potential adverse consequences encompassing Safety, Financial, Operational, and Privacy?',
  },
  {
    questionId: 'Technical-10',
    checkpoint: 'Are the impact rating of the damage scenarios correct? ',
  },
  {
    questionId: 'Technical-11',
    checkpoint:
      'Are the impact rating of the damage scenarios consistent between each other? ',
  },
];
export const AttackPathAnalysis = [
  {
    questionId: 'Technical-12',
    checkpoint: 'Are attack paths correctly defined? ',
  },
  {
    questionId: 'Technical-13',
    checkpoint:
      'Are all threat scenarios analysed for attack path identification?  ',
  },
  {
    questionId: 'Technical-14',
    checkpoint:
      'Are the identified attack paths associated with at least one threat scenario?',
  },
  {
    questionId: 'Technical-15',
    checkpoint:
      'Are all vulnerabilities which lead to realisation of a threat scenario identified and included in an attack path? ',
  },
];
export const AttackFeasibilityRating = [
  {
    questionId: 'Technical-16',
    checkpoint:
      'Is the attack feasibility rating determined based on one of the following approaches? ( Attack potential, CVSS, Attack Vector)',
  },
  {
    questionId: 'Technical-17',
    checkpoint:
      'Is the attack feasibility rating determined for each attack path? ',
  },
  {
    questionId: 'Technical-18',
    checkpoint:
      'Are the attack feasibilities of different attack paths consistent between each other? (Are the feasibilities based on a same guidance evaluated)',
  },
  {
    questionId: 'Technical-19',
    checkpoint:
      'Is the attack feasibility rating determined according to ISO/SAE 21434? (High, Medium, Low, Very low) ',
  },
];
export const RiskValue = [
  {
    questionId: 'Technical-20',
    checkpoint: 'Are the risk values of all threat scenarios determined? ',
  },
  {
    questionId: 'Technical-21',
    checkpoint:
      'Are the risk values determined correctly? (a risk value should be a number between 1(lowest) and 5(highest)).',
  },
  {
    questionId: 'Technical-22',
    checkpoint:
      'Are the risk values based on a predefined matrix? (e.g.; Table H.8 — Risk matrix example: ISO/SAE 21434:2021)',
  },
];
export const RiskTreatment = [
  {
    questionId: 'Technical-23',
    checkpoint:
      'Are risk treatment decisions for all threat scenarios determined? ',
  },
  {
    questionId: 'Technical-24',
    checkpoint:
      'Can all treatment decisions according to ISO/SAE 21434 be classified? (Avoid, Reduce, Share, Retain)',
  },
  {
    questionId: 'Technical-25',
    checkpoint:
      'Are the risk treatment decisions correct and consistent? (The risk treatment decisions shall not be contradictory) ',
  },
];
export const CybersecurityGoals = [
  {
    questionId: 'Technical-26',
    checkpoint:
      'Are cybersecurity goals defined regarding the all risk treatment decisions identified as "reduce"? ',
  },
  {
    questionId: 'Technical-27',
    checkpoint:
      'Are cybersecurity goals formulated for each security property of the associated asset? ',
  },
  {
    questionId: 'Technical-28',
    checkpoint:
      'Are cybersecurity goals correct and consistent between eachother? ',
  },
  {
    questionId: 'Technical-29',
    checkpoint:
      'Are cybersecurity claims defined regarding the all risk treatment decisions identified as "share or retain"? ',
  },
  {
    questionId: 'Technical-30',
    checkpoint: 'OEM(customer) (is there any?) and if yes are all considered? ',
  },
  {
    questionId: 'Technical-31',
    checkpoint: 'Are all with OEM reviewed. ',
  },
  {
    questionId: 'Technical-32',
    checkpoint: 'Are cybersecurity claims are correct and consistent? ',
  },
];
export const CybersecurityControls = [
  {
    questionId: 'Technical-33',
    checkpoint:
      'Are all CS controls defined to react/prevent/detect the risk? ',
  },
];
