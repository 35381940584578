import Auth from '../../Axios/Auth';
import axios from '../../Axios/axios';
import SessionStorageService from '../../Axios/SessionStorage';
import { history } from '../../components/routing/Routes';
import { loginUrl, passwordResetUrl } from '../../constants/URLConstants';
import storedUser from '../../user';
import { setAlert } from './alert';
import { setLicenseAlert, setLicenseExpiry } from './licenseAlert';
import {
  AUTH_ERROR,
  AUTH_LOADING,
  DISMISS_FORGOT_PASSWORD_ERROR,
  DISMISS_LOGIN_ERROR,
  DISMISS_REGISTER_ERROR,
  DISMISS_UPDATE_PASSWORD_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  NO_SIDEBAR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_REGISTER,
  SET_BACKEND_VERSION,
  SET_DATABASE_ID,
  SET_PROFILE,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_SUCCESS,
  WITH_SIDEBAR,
} from './types';
const sessionStorageService = SessionStorageService.getService();

// Register User

export const register =
  ({ name, mail, password, password2 }) =>
  async (dispatch) => {
    dispatch({
      type: AUTH_LOADING,
    });

    if (password !== password2) {
      dispatch({
        type: REGISTER_FAIL,
        payload: 'Passwords do not match',
      });
      return;
    }

    const passwordCheckPayload = { password: password };
    const passwordCheckResponse = await axios.authentication.checkPassword(
      passwordCheckPayload
    );

    if (!passwordCheckResponse.data || password === name) {
      dispatch({
        type: REGISTER_FAIL,
        payload: 'Password is not secure enough.',
      });
      return;
    }

    const data = {
      name: name,
      mail: mail,
      password: password,
    };
    axios.authentication
      .createUser(data)
      .then(() => {
        dispatch({
          type: AUTH_LOADING,
        });
        dispatch({
          type: REGISTER_SUCCESS,
        });
        dispatch(setAlert('Registration Successful', 'success'));
      })
      .catch((err) => {
        let errorMessage = 'Error: ';
        for (const error of Object.entries(err.response.data.msg)) {
          errorMessage = `${errorMessage}${error[1]}. `;
        }
        dispatch({
          type: REGISTER_FAIL,
          payload: errorMessage,
        });
        dispatch({
          type: AUTH_LOADING,
        });
      });
  };

// Login User

export const login = (user, password) => async (dispatch) => {
  const data = {
    user: user,
    password: password,
  };
  const userId = await Auth.authenticate(data);
  if (userId !== null) {
    //Gets user permissions
    const userDetails = await Auth.getPermissions(userId).catch((error) => {
      dispatch(
        setAlert(
          `Error getting user permissions. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

    const licenseDetails = await axios.license
      .get()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting license data. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
    dispatch(setDatabaseId(licenseDetails?.database_identification));

    const expiryDate = Date.parse(licenseDetails?.valid_until);
    dispatch(setLicenseExpiry(licenseDetails?.valid_until));
    //If expiry is less than or equal to 31 days left
    const warning = Date.now - expiryDate <= 31 * 86400000;
    warning && dispatch(setLicenseAlert());

    //check time between
    if (userDetails) {
      const userData = userDetails;
      userData.id = userId;
      delete userData.password;
      dispatch({
        type: SET_PROFILE,
        payload: userData,
      });
      storedUser.permissions = userData.permissions;
      storedUser.userId = userId;
    }

    dispatch(dismissLoginError());
    dispatch(dismissRegisterError());
    dispatch(dismissPasswordResetError());
    dispatch(dismissUpdatePasswordError());
    await dispatch({
      type: LOGIN_SUCCESS,
    });
    dispatch({
      type: WITH_SIDEBAR,
    });
    if (window.location.pathname === loginUrl) {
      window.location.pathname = '/projects';
    }
  }
};

export const refresh = () => async (dispatch) => {
  const userId = sessionStorageService.getUserId();
  if (userId !== null) {
    await dispatch({
      type: LOGIN_SUCCESS,
    });
    dispatch({
      type: WITH_SIDEBAR,
    });
    //Gets user permissions
    const userDetails = await Auth.getPermissions(userId).catch((error) => {
      dispatch(
        setAlert(
          `Error getting user data. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

    const licenseDetails = await axios.license
      .get()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting license data. Error message:  ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
    if (licenseDetails !== undefined) {
      dispatch(setDatabaseId(licenseDetails.database_identification));
      dispatch(setLicenseExpiry(licenseDetails.valid_until));
    }

    if (userDetails) {
      const userData = userDetails;
      userData.id = parseInt(userId);
      delete userData.password;
      dispatch({
        type: SET_PROFILE,
        payload: userData,
      });
      storedUser.permissions = userData.permissions;
      storedUser.userId = userId;
    }

    if (window.location.pathname === loginUrl) {
      window.location.pathname = '/projects';
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
    dispatch({
      type: NO_SIDEBAR,
    });

    if (
      `/${window.location.pathname.split('/')[1]}` === passwordResetUrl &&
      window.location.pathname.split('/')[2].length === 25
    ) {
      return;
    }
    if (window.location.pathname !== loginUrl) {
      window.location.pathname = loginUrl;
    }
  }
};

//LOGOUT /Clear profile

export const logout =
  (tokenExpired = false) =>
  async (dispatch) => {
    if (!tokenExpired) {
      await axios.authentication.logout().catch(() => {})
    }
    sessionStorageService.clearToken();
    dispatch({ type: LOGOUT });
    dispatch({
      type: NO_SIDEBAR,
    });

    window.location.href = loginUrl;
  };

export const dismissLoginError = (errorMessage) => (dispatch) => {
  dispatch({
    type: DISMISS_LOGIN_ERROR,
  });
};

export const dismissRegisterError = () => (dispatch) => {
  dispatch({
    type: DISMISS_REGISTER_ERROR,
  });
};

export const resetRegister = () => (dispatch) => {
  dispatch({
    type: RESET_REGISTER,
  });
};

export const confirmPassword = async (user, password) => {
  const data = {
    user: user,
    password: password,
  };
  const userId = await Auth.authenticate(data);
  return userId !== null ? true : false;
};

export const resetPassword = (mail) => async (dispatch) => {
  const payload = { mail: mail };
  axios.passwordReset
    .put('', payload)
    .then((response) => {
      dispatch(setAlert('Password reset request success.', 'success'));
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    })
    .catch((err) => {
      let errorMessage = 'Error: ';
      if (Array.isArray(err?.response?.data?.msg)) {
        for (const error of Object.entries(err.response.data.msg)) {
          if (Array.isArray(error) && error.length > 1) {
            errorMessage = `${errorMessage}${error[1]}. `;
          }
        }
      }
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: errorMessage,
      });
    });
};

export const dismissPasswordResetError = () => (dispatch) => {
  dispatch({
    type: DISMISS_FORGOT_PASSWORD_ERROR,
  });
};

export const resetPasswordReset = () => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_RESET,
  });
};

export const updatePassword = (formData, key) => async (dispatch) => {
  if (formData.password1 !== formData.password2) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: 'Passwords do not match',
    });
    return;
  }

  const payload = { password: formData.password1 };

  const passwordCheckResponse = await axios.authentication.checkPassword(
    payload
  );

  if (!passwordCheckResponse.data) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: 'Password is not secure enough.',
    });
    return;
  }

  axios.passwordReset
    .put(key, payload)
    .then((response) => {
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
      });
    })
    .catch((err) => {
      const errorMessage = `Error: ${
        err?.response.data?.msg ?? err?.response.data?.message
      } Request password through the Forgot Password button to request a new key.`;
      if (err.response.status === 401) {
        dispatch(setAlert(errorMessage, 'danger', 20000));
        history.push('/');
        return;
      }
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: errorMessage,
      });
    });
};

export const adminUpdatePassword = (formData, user_id) => async (dispatch) => {
  if (formData.password1 !== formData.password2) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: 'Passwords do not match',
    });
    return;
  }

  const payload = { password: formData.password1 };

  const passwordCheckResponse = await axios.authentication.checkPassword(
    payload
  );

  if (!passwordCheckResponse.data) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: 'Password is not secure enough.',
    });
    return;
  }

  axios.passwordReset
    .put(`admin/${user_id}`, payload)
    .then((response) => {
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
      });
    })
    .catch((err) => {
      const errorMessage = `Error: ${
        err?.response.data?.msg ?? err?.response.data?.message
      } Request password through the Forgot Password button to request a new key.`;
      if (err.response.status === 401) {
        dispatch(setAlert(errorMessage, 'danger', 20000));
        history.push('/');
        return;
      }
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: errorMessage,
      });
    });
};


export const setUpdatePasswordError = (errorMessage) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_ERROR,
    payload: errorMessage,
  });
};

export const dismissUpdatePasswordError = () => (dispatch) => {
  dispatch({
    type: DISMISS_UPDATE_PASSWORD_ERROR,
  });
};

export const resetUpdatePassword = () => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_RESET,
  });
};

export const insertLicense = (payload) => (dispatch) => {
  axios.license
    .post(payload)
    .then(() => {
      dispatch(setAlert('License successfully updated.', 'success'));
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error updating license. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const getLicenseData = (payload) => (dispatch) => {
  axios.license
    .get()
    .then(() => {})
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting license data. Error message:   ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const getBackendVersion = () => (dispatch) => {
  axios.backendVersion
    .get()
    .then((response) => {
      dispatch({
        type: SET_BACKEND_VERSION,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting backend version. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const getCreatorSlots = () => (dispatch) => {
  axios.user
    .get('creator/')
    .then((response) => {})
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting creator slots. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const setDatabaseId = (id) => (dispatch) => {
  dispatch({ type: SET_DATABASE_ID, payload: id });
};
