import {
  AUTH_ERROR,
  AUTH_LOADING,
  DISMISS_FORGOT_PASSWORD_ERROR,
  DISMISS_LOGIN_ERROR as DISMISS_LOGIN_FAIL,
  DISMISS_REGISTER_ERROR,
  DISMISS_UPDATE_PASSWORD_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_REGISTER,
  SET_BACKEND_VERSION,
  SET_DATABASE_ID,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_SUCCESS,
  USER_LOADED,
} from '../actions/types';

const initialState = {
  isAuthenticated: null,
  loading: false,
  registered: false,
  loginError: false,
  registerError: false,
  registerErrorMessage: undefined,
  passwordReset: false,
  passwordResetError: false,
  passwordResetErrorMessage: undefined,
  updatePasswordSuccess: false,
  updatePasswordError: false,
  updatePasswordErrorMessage: undefined,
  backendVersion: undefined,
  databaseId: undefined,
  expiryDate: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: true,
        // user: null
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registered: true,
      };
    case RESET_REGISTER:
      return {
        ...state,
        registered: false,
        registerError: false,
        registerErrorMessage: undefined,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registerError: true,
        registerErrorMessage: payload,
      };
    case DISMISS_REGISTER_ERROR:
      return {
        ...state,
        registerError: false,
        registerErrorMessage: undefined,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
      };
    case RESET_PASSWORD_RESET:
      return {
        ...state,
        passwordReset: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        passwordResetError: true,
        passwordResetErrorMessage: payload,
      };
    case DISMISS_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        passwordResetError: false,
        passwordResetErrorMessage: undefined,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordSuccess: true,
      };
    case UPDATE_PASSWORD_RESET:
      return {
        ...state,
        updatePassword: false,
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordError: true,
        updatePasswordErrorMessage: payload,
      };
    case DISMISS_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordError: false,
        updatePasswordErrorMessage: undefined,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginError: true,
      };
    case DISMISS_LOGIN_FAIL:
      return {
        ...state,
        loginError: false,
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case SET_BACKEND_VERSION:
      return {
        ...state,
        backendVersion: payload,
      };
    case SET_DATABASE_ID:
      return {
        ...state,
        databaseId: payload,
      };
    default:
      return state;
  }
}
