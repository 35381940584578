import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import DraggableModal from '../../components/entities/EntityModals/DraggableModal';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { revertCatalogBaseline } from '../../redux/actions/catalog';
import {
  getProjectBaselines,
  revertProjectBaseline,
} from '../../redux/actions/projects';
import CustomDataTableStyles from '../layout/DataTableStyles';
import SearchComponent from '../projects/project-components/SearchComponent';
import AddBaseline from './AddBaseline';

/**
 * Revert Baseline
 *
 * Modal for reverting Baselines
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */

const RevertBaseline = ({
  projectId = undefined,
  modal,
  toggle,
  projectBaselines,
  catalogBaselines,
  revertProjectBaseline,
  revertCatalogBaseline,
  catalogBaseline = false,
  getProjectBaselines,
}) => {
  const baselines = catalogBaseline ? catalogBaselines : projectBaselines;

  useEffect(() => {
    if (projectId) {
      getProjectBaselines(projectId);
    }
  }, [projectId]);

  const [baseline, setBaseline] = useState({
    baseline_id: undefined,
  });

  const selection = (row) => {
    setBaseline({
      ...baseline,
      baseline_id: row,
    });
  };

  const onSubmit = () => {
    catalogBaseline
      ? revertCatalogBaseline(baseline.baseline_id)
      : revertProjectBaseline(baseline.baseline_id, projectId, projectId === undefined);
  };

  const [newBaseline, setNewBaseline] = useState({
    baseline_name: '',
    baseline_comment: '',
  });

  const create = (row) => {
    setNewBaseline({
      ...newBaseline,
      baseline_name: row?.name,
      baseline_comment: row?.baseline_comment,
    });
  };

  const [addModal, setAddToggle] = useState(false);
  const toggleAddModal = () => setAddToggle(!addModal);

  const columns = React.useMemo(() => [
    {
      name: 'Baseline ID',
      selector: (row) => row.baseline_id,
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Name',
      selector: 'baseline_name',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <div id={`name_${row.baseline_id}`}>
          {row.baseline_name}
          <UncontrolledTooltip target={`name_${row.baseline_id}`}>
            {row.baseline_name}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      name: 'Creation Date',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '150px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      name: 'Comment',
      selector: 'baseline_comment',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <div id={`comment_${row.baseline_id}`}>
          {row.baseline_comment}
          <UncontrolledTooltip target={`comment_${row.baseline_id}`}>
            {row.baseline_comment}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      //Actions for every project
      cell: (row) => (
        <Fragment>
          <Button
            id={`selectButton${row._id}`}
            onClick={() => {
              selection(row.baseline_id);
              create(row);
            }}
            size='sm'
          >
            <FontAwesomeIcon
              icon={
                row.baseline_id === baseline?.baseline_id
                  ? faCheckSquare
                  : faSquare
              }
            />
          </Button>
          <UncontrolledTooltip target={`selectButton${row._id}`}>
            Select
          </UncontrolledTooltip>
          <DraggableModal
            size='lg'
            isOpen={revertModal}
            toggle={toggleRevertModal}
          >
            <ModalHeader>
              <div className='confirm-modal-header'>
                Revert to previous Baseline
              </div>
            </ModalHeader>
            <div className='confirm-modal-line' />
            <ModalBody className='confirm-modal-body'>
              Warning! If you revert to an existing baseline the current state
              of the project will be lost and cannot be restored. It is
              recommended to create a baseline of the current state before
              reverting to an existing baseline.
              <FormGroup>
                <Button
                  className='float-right btn-cancel-delete'
                  onClick={() => {
                    toggleRevertModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  className='float-right btn-confirm-delete mr-15'
                  onClick={() => {
                    selection(row.baseline_id);
                    onSubmit();
                    toggleRevertModal();
                    toggle();
                  }}
                >
                  Revert
                </Button>
                <Button
                  type='submit'
                  className='float-right btn-add mr-15'
                  onClick={() => {
                    toggleAddModal();
                  }}
                >
                  Create baseline of current state and revert
                </Button>
                <AddBaseline
                  modal={addModal}
                  toggle={toggleAddModal}
                  recreate={true}
                  catalogBaseline={catalogBaseline}
                  version={baseline.baseline_id}
                />
              </FormGroup>
            </ModalBody>
          </DraggableModal>
        </Fragment>
      ),
    },
  ]);

  const conditionalRowStyles = [
    {
      when: (row) => row.baseline_id === baseline?.baseline_id,
      style: {
        backgroundColor: 'var(--highlight-color)',
      },
    },
  ];

  const [revertModal, setRevertModal] = useState(false);
  const toggleRevertModal = () => setRevertModal(!revertModal);

  //Method for search input
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const searchItems =
    baselines &&
    baselines?.filter((baseline) =>
      baseline?.baseline_name?.toString()?.toLowerCase()?.includes(searchText)
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (searchText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setSearchText('');
      }
    };
    return (
      <SearchComponent
        onSearch={(e) => setSearchText(e.target.value)}
        onClear={handleClear}
        searchText={searchText}
      />
    );
  }, [searchText, resetPaginationToggle]);
  return (
    <Fragment>
      <DraggableModal
        size='lg'
        isOpen={modal}
        toggle={toggle}
        unmountOnClose={false}
      >
        <ModalHeader>
          <div className='modal-header'>Revert Baseline</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          Select an existing baseline:
          <DataTable
            defaultSortAsc='_id'
            pagination
            columns={columns}
            persistTableHead
            data={searchItems}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeaderComponent={subHeaderComponentMemo}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={CustomDataTableStyles}
          />
          <Form>
            <FormGroup>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
              <Button
                disabled={baseline?.baseline_id === undefined}
                className='float-right btn-add'
                onClick={() => {
                  toggleRevertModal();
                }}
              >
                Revert
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  projectBaselines: state.project.baselines,
  catalogBaselines: state.catalog.baselines,
});

export default connect(mapStateToProps, {
  revertProjectBaseline,
  revertCatalogBaseline,
  getProjectBaselines,
})(RevertBaseline);
