import { setAlert } from '../redux/actions/alert';
import { LOGIN_FAIL } from '../redux/actions/types';
import store from '../redux/store';
import user from '../user';
import axios from './axios';
import SessionStorageService from './SessionStorage';

const SessionService = SessionStorageService.getService();
const { dispatch } = store;
const Auth = (function () {
  function _isAdmin() {
    return user.permissions.includes('user_admin');
  }

  async function _authenticate(loginData) {
    let tokenData = await axios.authentication
      .login(loginData)
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
        });
      });
    if (tokenData === null || tokenData === undefined) {
      return null;
    }
    SessionService.setAccessToken(tokenData.data['access_csrf']);
    SessionService.setRefreshToken(tokenData.data['refresh_csrf']);
    SessionService.setUserId(tokenData.data.user_id);

    return tokenData.data.user_id;
  }

  async function _getPermissions(userId) {
    let user = await axios.user
      .get(userId + '?name=true&permissions=true&mail=true')
      .catch((error) => {
        dispatch(
          setAlert(
            'Error : ' +
              (error.response.data.msg ?? error?.response?.data?.message),
            'danger'
          )
        );
      });

    return user?.data;
  }

  return {
    isAdmin: _isAdmin,
    authenticate: _authenticate,
    getPermissions: _getPermissions,
  };
})();

export default Auth;
