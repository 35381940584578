import {
  ADD_ASSET,
  LOAD_ASSETS,
  LOGOUT,
  PROJECT_LOADING_ERROR,
  UPDATE_ASSET,
} from '../actions/types';

const initialState = {
  assets: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newAssets = Object.assign([], state.assets);
  switch (type) {
    case LOAD_ASSETS:
      return {
        ...state,
        assets: payload,
      };
    case ADD_ASSET:
      newAssets.push(payload);
      return {
        ...state,
        assets: newAssets,
      };
    case UPDATE_ASSET:
      newAssets = newAssets.map((asset) => {
        return asset._id === payload._id ? { ...asset, ...payload } : asset;
      });
      return {
        ...state,
        assets: newAssets,
      };
    case PROJECT_LOADING_ERROR:
    case LOGOUT:
      return {
        assets: [],
      };
    default:
      return state;
  }
}
