import {
  ADD_CATALOG_BASELINE,
  ADD_CATALOG_ENTRY,
  DELETE_CATALOG_BASELINE,
  LOAD_CATALOG_BASELINE,
  SET_CATALOG,
  SET_CATALOG_BASELINE_MODE,
  SET_CATALOG_ROLE,
  SET_ENTRY,
  UPDATE_CATALOG,
  UPDATE_ENTRY,
} from '../actions/types';

const initialState = {
  catalog: undefined,
  baselines: undefined,
  entry: undefined,
  catalogRole: undefined,
  baselineMode: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let catalog = {};
  switch (type) {
    case SET_CATALOG:
    case UPDATE_CATALOG:
      return {
        ...state,
        catalog: payload,
      };
    case SET_ENTRY:
      return {
        ...state,
        entry: payload,
      };
    case UPDATE_ENTRY:
      catalog = { ...state.catalog };
      catalog.children = catalog?.children?.filter(
        (child) => parseInt(child) !== payload
      );
      return {
        ...state,
        catalog: catalog,
      };
    case ADD_CATALOG_ENTRY:
      catalog = { ...state.catalog };
      catalog.children.push(payload);
      return {
        ...state,
        catalog: catalog,
      };
    case SET_CATALOG_ROLE:
      return {
        ...state,
        catalogRole: payload,
      };
    case LOAD_CATALOG_BASELINE:
      return {
        ...state,
        baselines: payload,
      };
    case SET_CATALOG_BASELINE_MODE:
      return {
        ...state,
        baselineMode: payload,
      };
    case ADD_CATALOG_BASELINE:
      const newBaselines = [...state.baselines, payload];
      return {
        ...state,
        baselines: newBaselines,
      };
    case DELETE_CATALOG_BASELINE:
      const baselines = Object.assign([], state.baselines);
      const baselineIndex = baselines.findIndex(
        (baseline) => baseline._id === payload
      );
      baselines.splice(baselineIndex, 1, payload);
      return {
        ...state,
        baselines: baselines,
      };
    default:
      return state;
  }
}
