import React, { Fragment } from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { LocalTimeWithoutSeconds } from '../../../constants/StringConstants';

const RecycleBinColumns = (restore, remove) => [
  {
    name: 'ID',
    sortable: true,
    maxWidth: '100px',
    cell: (row) => <Fragment>{row.root_object.id}</Fragment>,
    selector: (row) => row.root_object.id,
  },
  {
    name: 'Type',
    sortable: true,
    maxWidth: '100px',
    cell: (row) => <Fragment>{row.root_object.object_type}</Fragment>,
    selector: (row) => row.root_object.object_type,
  },
  {
    name: 'Deleted By',
    sortable: true,
    selector: 'user_name',
    maxWidth: '150px',
  },
  {
    name: 'Parent ID',
    sortable: true,
    maxWidth: '150px',
    cell: (row) => <Fragment>{row.parent?.resource?._id}</Fragment>,
    selector: (row) => row.parent?.resource?._id,
  },
  {
    name: 'Deleted',
    sortable: true,
    maxWidth: '200px',
    selector: 'date',
    format: (date) => LocalTimeWithoutSeconds(date.creation_date),
  },
  {
    //Actions for every project
    cell: (row) => (
      <ButtonGroup>
        {/* Button that redirects to the details page of the project */}
        <Button
          id={`restoreButton${row._id}`}
          className='btn-add'
          onClick={() => restore(row)}
        >
          <i className='fa fa-trash-restore' />
        </Button>
        <UncontrolledTooltip target={`restoreButton${row._id}`}>
          Restore
        </UncontrolledTooltip>

        {/* Button for selecting the project on attack tree */}
        <Button
          id={`removeButton${row._id}`}
          className='btn-danger'
          onClick={() => remove(row)}
        >
          <i className='fa fa-trash' />
        </Button>
        <UncontrolledTooltip target={`removeButton${row._id}`}>
          Remove
        </UncontrolledTooltip>
      </ButtonGroup>
    ),
  },
];

export default RecycleBinColumns;
