import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import DraggableModal from '../../components/entities/EntityModals/DraggableModal';
import {
  addCatalogBaseline,
  revertCatalogBaseline,
} from '../../redux/actions/catalog';
import {
  addProjectBaseline,
  revertProjectBaseline,
} from '../../redux/actions/projects';

/**
 * Add New Baseline
 *
 * Modal for Creating new Baseline
 * TODO: Implement adding feature
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
const AddBaseline = ({
  modal,
  toggle,
  addProjectBaseline,
  recreate = false,
  revertProjectBaseline,
  addCatalogBaseline,
  revertCatalogBaseline,
  version,
  catalogBaseline = false,
}) => {
  const history = useHistory();

  const [baseline, setBaseline] = useState({
    baseline_name: '',
    baseline_comment: '',
  });

  //Event handling for inputs
  const onChange = (event) => {
    setBaseline({ ...baseline, [event.target.name]: event.target.value });
  };

  const onSubmit = () => {
    catalogBaseline
      ? addCatalogBaseline(baseline)
      : addProjectBaseline(baseline);
  };

  const onRevert = () => {
    catalogBaseline
      ? revertCatalogBaseline(version)
      : revertProjectBaseline(version);
  };

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>New Baseline</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Name:</Label>
              <Input
                type='text'
                name='baseline_name'
                onChange={(event) => onChange(event)}
                value={baseline.baseline_name}
                innerRef={focusRef}
              />
              <Label>Comment: </Label>
              <Input
                type='textarea'
                name='baseline_comment'
                onChange={(event) => onChange(event)}
                value={baseline.baseline_comment}
              />
            </FormGroup>
            <FormGroup>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
              {!recreate ? (
                <Button
                  disabled={
                    baseline.baseline_name === undefined ||
                    baseline?.baseline_name?.trim().length < 1
                  }
                  type='submit'
                  className='float-right btn-add'
                  onClick={(event) => {
                    event.preventDefault();
                    onSubmit();
                    toggle();
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={
                    baseline.baseline_name === undefined ||
                    baseline?.baseline_name?.trim().length < 1
                  }
                  type='submit'
                  className='float-right btn-add'
                  onClick={(event) => {
                    event.preventDefault();
                    onSubmit();
                    onRevert();
                    toggle();
                    history.push('/projects');
                  }}
                >
                  Recreate and revert
                </Button>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
});

export default connect(mapStateToProps, {
  addProjectBaseline,
  addCatalogBaseline,
  revertProjectBaseline,
  revertCatalogBaseline,
})(AddBaseline);
