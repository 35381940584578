import {
  NO_SIDEBAR,
  SET_SELECTED_SIDEBAR_PATH,
  SIDEBAR_VISIBILITY_HIDE,
  SIDEBAR_VISIBILITY_SHOW,
  SIDEBAR_VISIBILITY_TOGGLE,
  WITH_SIDEBAR,
} from '../actions/types';

const initialState = {
  isOpen: false,
  isVisible: false,
  selectedPath: undefined,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIDEBAR_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case SIDEBAR_VISIBILITY_SHOW:
      return {
        ...state,
        isOpen: true,
      };
    case SIDEBAR_VISIBILITY_HIDE:
      return {
        ...state,
        isOpen: false,
      };
    case NO_SIDEBAR:
      return {
        ...state,
        isVisible: false,
      };
    case WITH_SIDEBAR:
      return {
        ...state,
        isVisible: true,
      };
    case SET_SELECTED_SIDEBAR_PATH:
      return {
        ...state,
        selectedPath: payload,
      };

    default:
      return state;
  }
}
