import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, UncontrolledTooltip } from 'reactstrap';
import {
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  ProjectType,
  RiskType,
} from '../../../constants/Enums';
import {
  GenerateNodeId,
  maxFraction,
} from '../../../constants/StringConstants';
import { capitalizeStart } from '../../helpers/genericHelper';
import RiskDisplay from './nodeObjects/RiskDisplay';

/**
 * Control node.
 *
 * A type of node for the attack tree, represents vulnerabilities.
 *
 *  @param nodeData the node of the vulnerability
 *  @param project redux store value of project, used to check project type
 *  @param likelihoodAndRiskFiltering view selection filtering for likelihood and risk
 *  @param toggleReview redux action to toggle review
 *
 */
const VulnerabilityNode = ({
  nodeData,
  project,
  likelihoodAndRiskFiltering,
  readOnly,
  reftreeFeasibilitySelection,
  impactViews,
  selectedProjectType,
}) => {
  const projectType = project?.project_type ?? selectedProjectType;
  const nodeName = nodeData?.name;

  //Checks if node has control children and if it is calculated automatically
  const controlChildrenCheck =
    nodeData?.likelihood_estimation_method ===
      LikelihoodEstimation[LikelihoodMethod.Automatically] &&
    nodeData?.children?.find(
      (child) => child?.entity_type === EntityType.control
    ) !== undefined;

  const noControlLikelihood = maxFraction(nodeData?.no_control_likelihood);
  const AllControlLikelihood = maxFraction(nodeData?.all_control_likelihood);
  const proposedControlLikelihood = maxFraction(
    nodeData?.proposed_control_likelihood
  );
  const implementedControlLikelihood = maxFraction(
    nodeData?.implemented_control_likelihood
  );

  const getRisk = (riskType, giveCategory = false) => {
    if (nodeData?.impact === undefined) {
      return undefined;
    }
    let category = undefined;
    let highest = 0;
    Object.entries(nodeData.impact).map((impact) => {
      if (impact[1] > highest) {
        category = impact[0];
        highest = impact[1];
      }
      return impact;
    });
    const value = nodeData.risk?.[category]?.[riskType];

    return giveCategory ? capitalizeStart(category) : value;
  };

  const noControlRisk =
    getRisk(RiskType.noControls) &&
    impactViews &&
    Object.entries(nodeData?.risk)?.map((category) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.noControls);
    });

  const allControlRisk =
    getRisk(RiskType.allControls) &&
    impactViews &&
    Object.entries(nodeData?.risk)?.map((category) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.allControls);
    });

  const proposedControlRisk =
    getRisk(RiskType.proposed) &&
    impactViews &&
    Object.entries(nodeData?.risk)?.map((category) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.proposed);
    });

  const implementedControlRisk =
    getRisk(RiskType.implemented) &&
    impactViews &&
    Object.entries(nodeData?.risk)?.map((category) => {
      return RiskDisplay(nodeData, impactViews, category, RiskType.implemented);
    });

  const AttackFeasibility = (likelihood) => {
    const feasibility =
      project !== undefined
        ? ProjectType[projectType]?.AttackFeasibility.find(
            (feas) => likelihood >= feas.lower && likelihood <= feas.upper
          )
        : ProjectType[reftreeFeasibilitySelection]?.AttackFeasibility.find(
            (feas) => likelihood >= feas.lower && likelihood <= feas.upper
          );

    const feasibilityText =
      project !== undefined
        ? ProjectType[projectType]?.RiskColumnText?.toLowerCase()
        : ProjectType[
            reftreeFeasibilitySelection
          ]?.RiskColumnText?.toLowerCase();
    return (
      <Fragment>
        {feasibilityText}:{' '}
        <font
          style={{
            color: feasibility?.color,
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          {feasibility?.value}
        </font>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Row>
        {controlChildrenCheck && (
          <Fragment>
            <div
              className='exclamation'
              id={`EstimationTooltip${GenerateNodeId(
                nodeData?._id,
                nodeData?.index
              )}`}
            >
              <FontAwesomeIcon icon={faExclamation} />
            </div>
            <div style={{ width: '5px' }}></div>
            <UncontrolledTooltip
              placement='right'
              target={`EstimationTooltip${GenerateNodeId(
                nodeData?._id,
                nodeData?.index
              )}`}
            >
              The likelihood estimation method of a node to which a control is
              attached should not be "calculated automatically".
            </UncontrolledTooltip>
          </Fragment>
        )}
        <div className='node-id'>{nodeData?._id}</div>
        <div style={{ width: '5px' }}></div>
        <div
          className='node-name'
          id={`vulnerabilityDescription${nodeData?._id}_${nodeData?.index}`}
        >
          {nodeName}
        </div>
      </Row>

      <Row>
        <div pill>
          <div className='node-type'>
            {nodeData?.parent?.entity_type === EntityType.threat
              ? ProjectType[projectType]?.VulnerabilityType
              : 'Vulnerability'}
          </div>
          {nodeData?.description && (
            <UncontrolledTooltip
              placement='right'
              target={`vulnerabilityDescription${nodeData?._id}_${nodeData?.index}`}
              disabled={nodeData?.description === undefined}
              className='display-linebreak'
            >
              {nodeData?.description}
            </UncontrolledTooltip>
          )}
        </div>
      </Row>

      <div
        id={`VulnerabilityTooltip${GenerateNodeId(
          nodeData?._id,
          nodeData?.index
        )}`}
      >
        {(likelihoodAndRiskFiltering?.noControls || readOnly) && (
          <Row>
            <div className='node-table-data'>
              No control {AttackFeasibility(nodeData?.no_control_likelihood)}
            </div>
            <div className='node-table-data'>
              No control likelihood: {noControlLikelihood}
            </div>
            {ProjectType[projectType]?.Value === ProjectType.ISO_21434.Value &&
              nodeData?.parent?.entity_type === EntityType.threat &&
              noControlRisk}
          </Row>
        )}
        {(likelihoodAndRiskFiltering?.implementedControls || readOnly) && (
          <Row>
            <div className='node-table-data'>
              Implemented control{' '}
              {AttackFeasibility(nodeData?.implemented_control_likelihood)}
            </div>
            <div className='node-table-data'>
              Implemented likelihood: {implementedControlLikelihood}
            </div>
            {ProjectType[projectType]?.Value === ProjectType.ISO_21434.Value &&
              nodeData?.parent?.entity_type === EntityType.threat &&
              implementedControlRisk}
          </Row>
        )}
        {(likelihoodAndRiskFiltering?.proposedControls || readOnly) && (
          <Row>
            <div className='node-table-data'>
              Proposed control{' '}
              {AttackFeasibility(nodeData?.proposed_control_likelihood)}
            </div>
            <div className='node-table-data'>
              Proposed likelihood: {proposedControlLikelihood}
            </div>
            {ProjectType[projectType]?.Value === ProjectType.ISO_21434.Value &&
              nodeData?.parent?.entity_type === EntityType.threat &&
              proposedControlRisk}
          </Row>
        )}
        {(likelihoodAndRiskFiltering?.allControls || readOnly) && (
          <Fragment>
            <Row>
              <div className='node-table-data'>
                All control{' '}
                {AttackFeasibility(nodeData?.all_control_likelihood)}
              </div>
            </Row>
            <Row>
              <div className='node-table-data'>
                All control likelihood: {AllControlLikelihood}
              </div>
              {ProjectType[projectType]?.Value ===
                ProjectType.ISO_21434.Value &&
                nodeData?.parent?.entity_type === EntityType.threat &&
                allControlRisk}
            </Row>
            <Row>
              {nodeData?.parent?.entity_type === EntityType.threat && (
                <div className='node-table-data'>Risk Decision: {nodeData?.risk_decision}</div>
              )}
            </Row>
          </Fragment>
        )}
      </div>
      <UncontrolledTooltip
        placement='right'
        target={`VulnerabilityTooltip${GenerateNodeId(
          nodeData?._id,
          nodeData?.index
        )}`}
      >
        No control Likelihood:
        <br></br>
        {nodeData?.no_control_likelihood?.toExponential(4)}
        <br></br>
        Implemented likelihood: <br></br>
        {nodeData?.implemented_control_likelihood?.toExponential(4)}
        <br></br>
        Proposed likelihood: <br></br>
        {nodeData?.proposed_control_likelihood?.toExponential(4)}
        <br></br>
        All control likelihood: <br></br>
        {nodeData?.all_control_likelihood?.toExponential(4)}
      </UncontrolledTooltip>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  likelihoodAndRiskFiltering: state.analysistree.viewSelection,
  searchString: state.analysistree.searchCriteria,
  reftreeFeasibilitySelection: state.analysistree.reftreeFeasibilitySelection,
  project: state.project.project,
  projectRole: state.profile.projectRole,
  impactViews: state.analysistree.impactViews,
  selectedProjectType: state.analysistree.selectedProjectType,
});

export default connect(mapStateToProps, {})(VulnerabilityNode);
