import { ProjectType } from '../../constants/Enums';
import {
  CLEAR_REFTREE_MOD_PARAMS,
  CLEAR_SEARCH_IDS,
  DISPLAY_REFERENCE_TREES,
  LOAD_ATTACKTREE,
  LOGOUT,
  PASTE_SUBTREE,
  SELECT_SUBTREE,
  SET_IMPACT_LEVEL_VIEWS,
  SET_REFTREE_FEASIBILITY_VIEW,
  SET_REFTREE_MOD_PARAMS,
  SET_TREE_LAYOUT,
  TOGGLE_PLACEMENT_REVIEWS,
  TOGGLE_POPOVER,
  TOGGLE_REFTREE_MOD_WARNING,
  TOGGLE_TREE_ANIMATIONS,
  UNSELECT_SUBTREE,
  UPDATE_SEARCH_IDS,
  VIEW_SELECTION_CHANGE,
} from '../actions/types';

const initialState = {
  analysistree: undefined,
  SelectedSubtree: undefined,
  SelectedType: undefined,
  popoverEnabled: false,
  viewSelection: {
    noControls: false,
    implementedControls: false,
    implementedAndProposedControls: false,
    allControls: true,
  },
  searchIds: [],
  showReferenceTrees: false,
  refTreeModificationParams: undefined,
  disableRefTreeWarnings: false,
  reftreeFeasibilitySelection: ProjectType.ISO_27000_series.Value,
  collapsedReviews: true,
  impactViews: ['3', '4'],
  selectedProjectType: ProjectType.ISO_27000_series.Value,
  treeAnimations: false,
  treeLayout: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ATTACKTREE:
      return {
        ...state,
        analysistree: payload,
      };
    case TOGGLE_POPOVER:
      return {
        ...state,
        popoverEnabled: !state.popoverEnabled,
      };
    case VIEW_SELECTION_CHANGE:
      return { ...state, viewSelection: payload };
    case CLEAR_SEARCH_IDS:
      return { ...state, searchIds: [] };
    case UPDATE_SEARCH_IDS:
      return { ...state, searchIds: payload };
    case SELECT_SUBTREE:
      return {
        ...state,
        SelectedSubtree: payload.subtree,
        SelectedType: payload.type,
      };
    case PASTE_SUBTREE:
      return {
        ...state,
        analysistree: payload,
      };
    case UNSELECT_SUBTREE:
      return {
        ...state,
        SelectedSubtree: undefined,
        SelectedType: {},
      };
    case DISPLAY_REFERENCE_TREES:
      return {
        ...state,
        showReferenceTrees: payload,
      };
    case LOGOUT:
      return {
        analysistree: undefined,
        Subtrees: [],
        SelectedSubtree: undefined,
        SelectedType: undefined,
        viewSelection: {
          noControls: false,
          implementedControls: false,
          implementedAndProposedControls: false,
          allControls: true,
        },
        searchIds: [],
        showReferenceTrees: false,
      };
    case SET_REFTREE_MOD_PARAMS:
      return {
        ...state,
        refTreeModificationParams: payload,
      };
    case CLEAR_REFTREE_MOD_PARAMS:
      return {
        ...state,
        refTreeModificationParams: [],
      };
    case TOGGLE_REFTREE_MOD_WARNING:
      return {
        ...state,
        disableRefTreeWarnings: !state.disableRefTreeWarnings,
      };
    case TOGGLE_PLACEMENT_REVIEWS:
      return {
        ...state,
        collapsedReviews: !state.collapsedReviews,
      };
    case SET_REFTREE_FEASIBILITY_VIEW:
      return {
        ...state,
        reftreeFeasibilitySelection: payload,
      };
    case SET_IMPACT_LEVEL_VIEWS:
      return {
        ...state,
        impactViews: payload,
      };
    case TOGGLE_TREE_ANIMATIONS:
      return {
        ...state,
        treeAnimations: !state.treeAnimations,
      };
    case SET_TREE_LAYOUT:
      return {
        ...state,
        treeLayout: payload,
      };
    default:
      return state;
  }
}
