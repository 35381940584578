import React from 'react';
import { Col, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { InputTemplate } from '../../../entities/EntityModals/InputTemplate';

export const control = (entity, fieldChange, editEntity, editMode) => {
  return editMode ? (
    <>
      <Row>
        <Col md={5}>
          <Label>Proposed : </Label>
        </Col>
        <Col md={7}>
          {' '}
          <Input
            type='checkbox'
            checked={editEntity?.proposed_state ?? entity?.proposed_state}
            name='proposed_state'
            onChange={fieldChange}
            disabled={!editMode}
            style={{ marginLeft: '-0.25rem' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Label>Implemented : </Label>
        </Col>
        <Col md={5}>
          {' '}
          <Input
            type='checkbox'
            checked={editEntity?.implemented_state ?? entity?.implemented_state}
            name='implemented_state'
            onChange={fieldChange}
            disabled={!editMode}
            style={{ marginLeft: '-0.25rem' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Label>Likelihood : </Label>
        </Col>
        <Col md={5}>
          {' '}
          <InputTemplate
            min={0}
            max={1}
            step={0.1}
            type='number'
            name='likelihood'
            onChange={fieldChange}
            value={editEntity?.likelihood ?? entity?.likelihood}
            invalid={
              editEntity?.no_control_likelihood > 1 ||
              editEntity?.no_control_likelihood < 0 ||
              editEntity?.no_control_likelihood === ''
            }
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={5}>
          <Label>Likelihood Comments : </Label>
        </Col>
        <Col md={5}>
          {' '}
          <InputTemplate
            type='textarea'
            name='likelihood_comment'
            id='likelihood_comment'
            onChange={fieldChange}
            value={
              editEntity?.likelihood_comment ??
              entity?.likelihood_description ??
              entity?.likelihood_comment
            }
            invalid={
              editEntity?.likelihood_comment?.length < 8 ||
              ((entity?.likelihood_comment === undefined ||
                entity?.likelihood_description) &&
                editEntity?.likelihood_comment === undefined) ||
              editEntity?.likelihood_comment === ''
            }
          />
          <UncontrolledTooltip placement='right' target='likelihood_comment'>
            The likelihood comment must be at least 8 characterss
          </UncontrolledTooltip>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row>
        <Col md={5}>
          <Label>Proposed : </Label>
        </Col>
        <Col md={7}>
          {' '}
          <Input
            type='checkbox'
            checked={entity?.proposed_state}
            name='proposed_state'
            onChange={fieldChange}
            disabled={!editMode}
            style={{ marginLeft: '-0.25rem' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Label>Implemented : </Label>
        </Col>
        <Col md={5}>
          {' '}
          <Input
            type='checkbox'
            checked={entity?.implemented_state}
            name='implemented_state'
            onChange={fieldChange}
            disabled={!editMode}
            style={{ marginLeft: '-0.25rem' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Label>Likelihood : </Label>
        </Col>
        <Col md={5}> {entity?.likelihood}</Col>
      </Row>
      <br />
      <Row>
        <Col md={5}>
          <Label>Likelihood Comments : </Label>
        </Col>
        <Col md={5} className='display-linebreak'>
          {entity?.likelihood_comment ?? entity?.likelihood_description}
        </Col>
      </Row>
    </>
  );
};
