import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { EntityType, ProjectType } from '../../../constants/Enums';
import DraggableModal from './DraggableModal';

/**
 * Duplicate Entity Modal
 *
 * A modal for checking for duplicate entities in tree
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *
 */
const DuplicateEntityModal = ({
  toggle,
  modal,
  parentModalToggle,
  parentModalAddEntity,
  entityToCreate,
  entityType,
  projectType,
  entityLabelOverride = undefined,
}) => {
  const entityLabel =
    entityType === EntityType.vulnerability
      ? ProjectType[projectType].VulnerabilityType
      : entityType === EntityType.threat
      ? ProjectType[projectType].ThreatType
      : entityType;

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <div className='confirm-modal-header'>
            Duplicate {entityLabelOverride ?? entityLabel}
          </div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          A {entityLabelOverride?.toLowerCase() ?? entityLabel?.toLowerCase()}{' '}
          with the name of {entityToCreate.name} is already present in the
          project.
          <br />
          Creation will result in a duplicate{' '}
          {entityLabelOverride?.toLowerCase() ??
            entityLabel?.toLowerCase()}{' '}
          from within the project. Are you sure you want to continue?
          <Form>
            <br />
            <FormGroup>
              <Button
                className='btn-confirm-delete mr-15'
                type='submit'
                onClick={() => {
                  parentModalAddEntity();
                  parentModalToggle();
                  toggle();
                }}
              >
                Confirm
              </Button>
              <Button className='btn-cancel-delete ml-15' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};
export default connect(null, {})(DuplicateEntityModal);
