import React from 'react';
import {
  Button,
  Col,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  LikelihoodEstimation,
  LikelihoodMethod,
  ProjectType,
  SecurityGoal,
} from '../../../../constants/Enums';
import DraggableModal from '../../../entities/EntityModals/DraggableModal';
import { InputTemplate } from '../../../entities/EntityModals/InputTemplate';

export const vulnerability = (
  entity,
  fieldChange,
  editEntity,
  editMode,
  project,
  likelihoodModal,
  toggleLikelihoodModal,
  setLikelihoodEstimationMethod,
  placeholderEstimationMethod,
  setPlaceholderEstimationMethod,
  selectedProjectType,
  threat,
  asset
) => {
  return editMode ? (
    <>
      <DraggableModal isOpen={likelihoodModal} toggle={toggleLikelihoodModal}>
        <ModalHeader>
          <div className='confirm-modal-header'>
            Likelihood estimation change
          </div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          "Warning! The current likelihood estimation of this node will be lost
          and you cannot restore it again. Are you sure you want to proceed?
          <FormGroup>
            <Button
              type='submit'
              className='float-right btn-confirm-delete'
              onClick={() => {
                setLikelihoodEstimationMethod(placeholderEstimationMethod);
                toggleLikelihoodModal();
              }}
            >
              Ok
            </Button>
            <Button
              className='float-right btn-cancel-delete mr-15'
              onClick={() => {
                toggleLikelihoodModal();
              }}
            >
              Cancel
            </Button>
          </FormGroup>
        </ModalBody>
      </DraggableModal>
      <Row>
        <Col md={3}>
          <Label>Likelihood estimation method: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='likelihood_estimation_method'
            value={
              editEntity?.likelihood_estimation_method ??
              entity?.likelihood_estimation_method
            }
            onChange={(e) => {
              setPlaceholderEstimationMethod(e.target.value);
              if (
                e.target.value ===
                LikelihoodEstimation[LikelihoodMethod.Automatically]
              ) {
                toggleLikelihoodModal();
              } else {
                fieldChange(e);
              }
            }}
            selectDefault
          >
            {Object.entries(LikelihoodEstimation).map((method) => {
              return <option value={method[1]}>{method[0]}</option>;
            })}
          </InputTemplate>
        </Col>
      </Row>
      {(editEntity?.likelihood_estimation_method ??
        entity?.likelihood_estimation_method) ===
      LikelihoodEstimation[LikelihoodMethod.Category] ? (
        <>
          <Row>
            <Col md={3}>
              <Label>Elapsed Time: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='select'
                name='elapsed_time'
                onChange={fieldChange}
                value={editEntity?.elapsed_time ?? entity?.elapsed_time}
                selectDefault
              >
                {ProjectType[project?.project_type ?? selectedProjectType]
                  ?.ElapsedTime &&
                  Object.entries(
                    ProjectType[project?.project_type ?? selectedProjectType]
                      ?.ElapsedTime
                  ).map((time) => {
                    return <option value={time[0]}>{time[1]}</option>;
                  })}
              </InputTemplate>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Specialist Expertise: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='select'
                name='specialist_knowledge'
                onChange={fieldChange}
                value={
                  editEntity?.specialist_knowledge ??
                  entity?.specialist_knowledge
                }
                selectDefault
              >
                {ProjectType[project?.project_type ?? selectedProjectType]
                  ?.SpecialistExpertise &&
                  Object.entries(
                    ProjectType[project?.project_type ?? selectedProjectType]
                      ?.SpecialistExpertise
                  ).map((expertise) => {
                    return <option value={expertise[0]}>{expertise[1]}</option>;
                  })}
              </InputTemplate>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Knowledge of Item: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='select'
                name='item_knowledge'
                onChange={fieldChange}
                value={editEntity?.item_knowledge ?? entity?.item_knowledge}
                selectDefault
              >
                {ProjectType[project?.project_type ?? selectedProjectType]
                  ?.ComponentKnowledge &&
                  Object.entries(
                    ProjectType[project?.project_type ?? selectedProjectType]
                      ?.ComponentKnowledge
                  ).map((knowledge) => {
                    return <option value={knowledge[0]}>{knowledge[1]}</option>;
                  })}
              </InputTemplate>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Window of Oppurtunity: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='select'
                name='window_of_opportunity'
                onChange={fieldChange}
                value={
                  editEntity?.window_of_opportunity ??
                  entity?.window_of_opportunity
                }
                selectDefault
              >
                {ProjectType[project?.project_type ?? selectedProjectType]
                  ?.OpportunityWindow &&
                  Object.entries(
                    ProjectType[project?.project_type ?? selectedProjectType]
                      ?.OpportunityWindow
                  ).map((window) => {
                    return <option value={window[0]}>{window[1]}</option>;
                  })}
              </InputTemplate>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Equipment: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='select'
                name='equipment'
                onChange={fieldChange}
                value={editEntity?.equipment ?? entity?.equipment}
                selectDefault
              >
                {ProjectType[project?.project_type ?? selectedProjectType]
                  ?.Equipment &&
                  Object.entries(
                    ProjectType[project?.project_type ?? selectedProjectType]
                      ?.Equipment
                  ).map((window) => {
                    return <option value={window[0]}>{window[1]}</option>;
                  })}
              </InputTemplate>
            </Col>
          </Row>
        </>
      ) : (editEntity?.likelihood_estimation_method ??
          entity?.likelihood_estimation_method) ===
        LikelihoodEstimation.Manually ? (
        <>
          <Row>
            <Col md={3}>
              <Label> Likelihood: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                min={0}
                max={1}
                step={0.01}
                id='likelihood'
                type='number'
                name='no_control_likelihood'
                onChange={fieldChange}
                value={
                  editEntity?.no_control_likelihood ??
                  entity?.no_control_likelihood
                }
                invalid={
                  editEntity?.no_control_likelihood > 1 ||
                  editEntity?.no_control_likelihood < 0 ||
                  editEntity?.no_control_likelihood === ''
                }
                formFeedback='The value of the likelihood has to be a number between 0 and
                    1.'
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Likelihood Comment: </Label>
            </Col>
            <Col md={5}>
              <InputTemplate
                type='textarea'
                name='likelihood_comment'
                id='likelihood_comment'
                onChange={fieldChange}
                value={
                  editEntity?.likelihood_comment ?? entity?.likelihood_comment
                }
                invalid={
                  editEntity?.likelihood_comment?.length < 8 ||
                  (entity?.likelihood_comment === undefined &&
                    editEntity?.likelihood_comment === undefined) ||
                  editEntity?.likelihood_comment === ''
                }
              />
              <UncontrolledTooltip
                placement='right'
                target='likelihood_comment'
              >
                The likelihood comment must be at least 8 characters
              </UncontrolledTooltip>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col md={3}>
          <Label>Operator: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='operator'
            onChange={fieldChange}
            value={editEntity?.operator ?? entity?.operator}
          >
            <option selected>OR</option>
            <option>AND</option>
          </InputTemplate>
        </Col>
      </Row>
      {asset && (
        <Row>
          <Col md={3}>
            <Label>Asset: </Label>
          </Col>
          <Col md={5}>{asset.name}</Col>
        </Row>
      )}
      {threat && (
        <Row>
          <Col md={3}>
            <Label>Damange Scenario: </Label>
          </Col>
          <Col md={5}>{threat.name}</Col>
        </Row>
      )}
    </>
  ) : (
    <>
      <Row>
        <Col md={3}>
          <Label>Likelihood Estimation Method: </Label>
        </Col>
        <Col md={5}>
          {
            Object.entries(LikelihoodEstimation).find(
              (likelihood) =>
                likelihood[1] === entity?.likelihood_estimation_method
            )[1]
          }
        </Col>
      </Row>

      {entity?.likelihood_estimation_method ===
      LikelihoodEstimation[LikelihoodMethod.Category] ? (
        <>
          <Row>
            <Col md={3}>
              <Label>Elapsed Time: </Label>
            </Col>

            <Col md={5}>
              {
                ProjectType[project?.project_type ?? selectedProjectType]
                  ?.ElapsedTime[entity?.elapsed_time]
              }
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Specialist Expertise: </Label>
            </Col>
            <Col md={5}>
              {
                ProjectType[project?.project_type ?? selectedProjectType]
                  ?.SpecialistExpertise[entity?.specialist_knowledge]
              }
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Knowledge of Item: </Label>
            </Col>
            <Col md={5}>
              {
                ProjectType[project?.project_type ?? selectedProjectType]
                  ?.ComponentKnowledge[entity?.item_knowledge]
              }
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Window of Oppurtunity: </Label>
            </Col>
            <Col md={5}>
              {
                ProjectType[project?.project_type ?? selectedProjectType]
                  ?.OpportunityWindow[entity?.window_of_opportunity]
              }
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Equipment: </Label>
            </Col>
            <Col md={5}>
              {
                ProjectType[project?.project_type ?? selectedProjectType]
                  ?.Equipment[entity?.equipment]
              }
            </Col>
          </Row>
        </>
      ) : entity.likelihood_estimation_method ===
        LikelihoodEstimation.Manually ? (
        <>
          <Row>
            <Col md={3}>
              <Label> Likelihood: </Label>
            </Col>
            <Col md={5}>{entity?.no_control_likelihood}</Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label> Likelihood Comment: </Label>
            </Col>
            <Col md={5} className='display-linebreak'>
              {entity?.likelihood_comment}
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col md={3}>
          <Label>Operator: </Label>
        </Col>
        <Col md={5}>{entity?.operator}</Col>
      </Row>

      {asset && (
        <Row>
          <Col md={3}>
            <Label>Asset: </Label>
          </Col>
          <Col md={5}>{asset.name}</Col>
        </Row>
      )}
      {threat && (
        <Row>
          <Col md={3}>
            <Label>Damange Scenario: </Label>
          </Col>
          <Col md={5}>{threat.name}</Col>
        </Row>
      )}
    </>
  );
};
