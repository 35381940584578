import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, CardHeader, Label, UncontrolledTooltip } from 'reactstrap';
import { ProjectRole } from '../../constants/Enums';
import { updateCatalog, updateEntry } from '../../redux/actions/catalog';
import { cancelUnsavedChanges, unsavedChanges } from '../../redux/actions/save';
import store from '../../redux/store';

const DescriptionPanel = ({
  toggle,
  openPanel,
  data,
  type,
  save,
  unsavedChanges,
  catalogRole,
}) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    description: '',
  });
  const { entryId } = useParams();

  useEffect(() => {
    setFormData({
      ...formData,
      description: data?.description,
    });
  }, [data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('edit')) {
      const editParam = urlParams.get('edit');

      editParam === 'true' &&
        catalogRole !== ProjectRole.Reader.value &&
        catalogRole !== ProjectRole.Reviewer.value &&
        setEditMode(true);
    }
  }, []);

  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      const id = unsavedChanges();
      setFieldId(id);
    }
  };
  const [fieldId, setFieldId] = useState(null);

  const { dispatch } = store;
  const onSubmit = async () => {
    setEditMode(false);

    if (type === 'catalog') {
      dispatch(updateCatalog(data._id, formData));
    } else {
      dispatch(updateEntry(entryId, formData));
    }
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };

  useEffect(() => {
    if (save && editMode) {
      dispatch(updateCatalog(data._id, formData));
      changeMode();
      setFieldId(null);
    }
  }, [save]);

  return (
    <div>
      <CardHeader>
        <div>
          <font style={{ marginRight: 20 }} className='bold'>
            Description
          </font>
          {editMode ? (
            <>
              <Button
                id='saveButtton'
                className='btn-add btn-small'
                onClick={() => {
                  onSubmit();
                  changeMode();
                }}
              >
                <i className='fa fa-save' />
              </Button>
              <UncontrolledTooltip target='saveButtton'>
                Save
              </UncontrolledTooltip>
              <Button
                id='cancelButtton'
                className='btn-danger btn-small'
                onClick={() => {
                  changeMode();
                  dispatch(cancelUnsavedChanges(fieldId));
                  setFieldId(null);
                }}
              >
                <i className='fa fa-times' />
              </Button>
              <UncontrolledTooltip target='cancelButtton'>
                Cancel
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              <Button
                disabled={
                  catalogRole === ProjectRole.Reader.value ||
                  catalogRole === ProjectRole.Reviewer.value
                }
                className='btn-small'
                style={{
                  color: '#7ac142',
                  background: 'none',
                  marginLeft: -10,
                  marginBottom: -5,
                }}
                onClick={() => {
                  changeMode();
                  dispatch(cancelUnsavedChanges(fieldId));
                }}
              >
                <i className='fa fa-edit' />
              </Button>
            </>
          )}
        </div>
      </CardHeader>

      {/* if the user has the role of owner or editor in the said project,
      he/she has the permission to edit the project  */}
      {editMode ? (
        <div>
          <textarea
            type='textarea'
            name='description'
            onChange={onChange}
            value={formData.description}
            style={{ width: '100%' }}
            className='description-edit'
          />
          <br />
        </div>
      ) : (
        <Label className='display-linebreak'>{data?.description}</Label>
      )}
    </div>
  );
};

export default connect(null, { unsavedChanges })(DescriptionPanel);
