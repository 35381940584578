import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import {
  CatalogType,
  LikelihoodEstimation,
  LikelihoodMethod,
  ProjectType,
} from '../../constants/Enums';
import { addNewCatalogEntry } from '../../redux/actions/catalog';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { InputTemplate } from '../entities/EntityModals/InputTemplate';

// to do insert fields
const AddEntry = ({
  toggle,
  modal,
  catalog,
  addNewCatalogEntry,
  userReftrees,
}) => {
  const [entry, setEntry] = useState({
    name: undefined,
    description: '',
    catalog_id: catalog?._id,
  });
  const [formData, setFormData] = useState({
    _id: undefined,
    name: undefined,
    description: '',
    likelihood: 1,
    likelihood_comment: undefined,
    proposed_state: undefined,
    implemented_state: undefined,
    owner: undefined, //TODO:set owner
    shared: undefined, //TODO: shared value
  });

  const [catalogReftrees, setCatalogReftrees] = useState([]);

  useEffect(() => {
    setCatalogReftrees(
      userReftrees?.filter((reftree) => reftree?.catalog_id === catalog?._id)
    );
  }, [userReftrees]);

  const onChange = (e) => {
    //checkbox to boolean value

    //Sets form data as undefined when user picks None
    changeFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const changeFormData = (newFormData) => setFormData(newFormData);

  const changeField = (event) => {
    setEntry({
      ...entry,
      [event.target.name]:
        event.target.value === '' ? undefined : event.target.value,
    });
  };

  const submit = () => {
    entry.catalog_id = catalog?._id;
    if (catalog?.content_type === CatalogType.ReferenceTree) {
      let newreftree = {};
      if (
        reftree.likelihood_estimation_method ===
        LikelihoodEstimation[LikelihoodMethod.Category]
      ) {
        newreftree = Object.assign(newreftree, {
          ...reftree,
          ...categoryCalculationData,
        });
      } else {
        newreftree = Object.assign(newreftree, reftree);
      }

      addNewCatalogEntry(entry, newreftree, CatalogType.ReferenceTree);
    } else {
      //Entity description will be the same as entity description
      addNewCatalogEntry(
        { ...entry, description: formData.description, name: formData.name },
        formData,
        CatalogType.Control
      );
    }
    toggle();
  };
  const [likelihoodModal, setLikelihoodModal] = useState(false);
  const toggleLikelihoodModal = () => setLikelihoodModal(!likelihoodModal);

  const [reftree, setreftree] = useState({
    id: undefined,
    name: undefined,
    description: '',
    operator: 'OR',
    no_control_likelihood: undefined,
    likelihood_comment: undefined,
    likelihood_estimation_method:
      LikelihoodEstimation[LikelihoodMethod.Automatically],
  });

  const onChangeField = (e) => {
    //Sets form data as undefined when user picks None
    setreftree({
      ...reftree,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  //Used to set likelihood estimation from placeholder
  const setLikelihoodEstimationMethod = (method) => {
    setreftree({ ...reftree, likelihood_estimation_method: method });
  };

  //Stores estimation method when confirming estimation method change
  const [placeholderEstimationMethod, setPlaceholderEstimationMethod] =
    useState(LikelihoodEstimation[LikelihoodMethod.Automatically]);

  const changePlaceholderEstimationMethod = (e) => {
    setPlaceholderEstimationMethod(e.target.value);
  };

  //Sets default values as the the first integer value of the same type
  const [categoryCalculationData, setCategoryCalculationDate] = useState({
    elapsed_time: undefined,
    specialist_knowledge: undefined,
    item_knowledge: undefined,
    window_of_opportunity: undefined,
    equipment: undefined,
  });

  const onCategoryDataChange = (e) => {
    setCategoryCalculationDate({
      ...categoryCalculationData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const resetCategoryData = (e) => {
    setreftree({ ...reftree, likelihood_comment: undefined });
    setCategoryCalculationDate({
      elapsed_time: undefined,
      specialist_knowledge: undefined,
      item_knowledge: undefined,
      window_of_opportunity: undefined,
      equipment: undefined,
    });
  };

  const reftreeFocusRef = useRef(null);
  const controlFocusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      catalog?.content_type === CatalogType.ReferenceTree
        ? setTimeout(() => {
            reftreeFocusRef.current && reftreeFocusRef.current.focus();
          }, 1)
        : catalog?.content_type === CatalogType.Control &&
          setTimeout(() => {
            controlFocusRef.current && controlFocusRef.current.focus();
          }, 1);
    }
  }, [modal]);

  //If the likelihood estimation is category and there are any undefined values, disables button
  const categoryMethodCheck =
    reftree.likelihood_estimation_method ===
    LikelihoodEstimation[LikelihoodMethod.Category]
      ? categoryCalculationData.elapsed_time === undefined ||
        categoryCalculationData.equipment === undefined ||
        categoryCalculationData.item_knowledge === undefined ||
        categoryCalculationData.specialist_knowledge === undefined ||
        categoryCalculationData.window_of_opportunity === undefined
      : false;

  //If the likelihood estimation is manual and the likelihood does not fit between 0 and 1, disables button
  const manualMethodCheck =
    reftree.likelihood_estimation_method === LikelihoodEstimation.Manually &&
    (reftree?.no_control_likelihood <= 1 && reftree?.no_control_likelihood > 0
      ? false
      : true);

  const entryNameCheck = (name) =>
    catalogReftrees.find((reftree) => reftree.name === name);

  return (
    <DraggableModal
      isOpen={modal}
      toggle={toggle}
      unmountOnClose={false}
      size='lg'
    >
      <ModalHeader>
        <div className='modal-header'>Add Entry</div>
      </ModalHeader>
      <div className='modal-line' />
      <ModalBody className='modal-body'>
        <Label>Create New Entry</Label>
        {catalog?.content_type === CatalogType.ReferenceTree ? (
          <>
            <FormGroup>
              <Label>Entry Name</Label>
              <InputTemplate
                type='text'
                name='name'
                onChange={changeField}
                innerRef={reftreeFocusRef}
                invalid={
                  entry.name === '' ||
                  entry.name === undefined ||
                  entryNameCheck(entry.name)
                }
                formFeedback='Name cannot be blank or be equal to other entry names'
              />
            </FormGroup>
            <FormGroup>
              <Label>Entry Description</Label>
              <Input
                type='textarea'
                name='description'
                onChange={changeField}
              />
            </FormGroup>
            <Label>Node Details</Label>
            <br />
            <small>* required field</small>
            <FormGroup>
              <InputTemplate
                label='* Name of root node'
                type='text'
                name='name'
                value={reftree.name}
                invalid={reftree.name === '' || reftree.name === undefined}
                onChange={onChangeField}
                formFeedback='Name cannot be blank'
              />
              <InputTemplate
                label='Description of root node'
                type='textarea'
                name='description'
                value={reftree.description}
                onChange={onChangeField}
              />
              <InputTemplate
                label='* Operator'
                type='select'
                name='operator'
                defaultValue={reftree.operator}
                onChange={onChangeField}
              >
                <option selected>OR</option>
                <option>AND</option>
              </InputTemplate>
              <Label>Likelihood estimation</Label>
              <Input
                type='select'
                name='impact_category'
                defaultValue={reftree.likelihood_estimation_method}
                onChange={(e) => {
                  changePlaceholderEstimationMethod(e);
                  if (
                    reftree.likelihood_estimation_method !==
                    LikelihoodEstimation[LikelihoodMethod.Automatically]
                  ) {
                    toggleLikelihoodModal();
                  } else {
                    setLikelihoodEstimationMethod(e.target.value);
                  }
                }}
              >
                {Object.entries(LikelihoodEstimation).map((method) => {
                  return <option value={method[1]}>{method[0]}</option>;
                })}
              </Input>
              <DraggableModal
                isOpen={likelihoodModal}
                toggle={toggleLikelihoodModal}
              >
                <ModalHeader>
                  <div className='modal-header'>
                    Likelihood estimation change
                  </div>
                </ModalHeader>
                <div className='modal-line' />
                <ModalBody className='modal-body'>
                  Are you sure you want to overwrite the previous likelihood
                  estimation?
                  <FormGroup>
                    <Button
                      type='submit'
                      className='float-right btn-add'
                      onClick={() => {
                        resetCategoryData();
                        setLikelihoodEstimationMethod(
                          placeholderEstimationMethod
                        );
                        toggleLikelihoodModal();
                      }}
                    >
                      Ok
                    </Button>
                    <Button
                      className='float-right btn-danger'
                      onClick={() => {
                        toggleLikelihoodModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                </ModalBody>
              </DraggableModal>
            </FormGroup>
            {reftree.likelihood_estimation_method ===
            LikelihoodEstimation.Manually ? (
              <div>
                <FormGroup>
                  <InputTemplate
                    min={0}
                    max={1}
                    label='* Likelihood'
                    type='number'
                    step={0.01}
                    name='no_control_likelihood'
                    onChange={onChangeField}
                    invalid={
                      reftree?.no_control_likelihood === undefined ||
                      !(
                        reftree?.no_control_likelihood <= 1 &&
                        reftree?.no_control_likelihood >= 0
                      )
                    }
                    formFeedback='The value of the likelihood has to be a number between 0 and
                    1.'
                  />
                  <InputTemplate
                    invalid={
                      reftree?.likelihood_comment?.length < 8 ||
                      reftree?.likelihood_comment === undefined
                    }
                    label='Comment'
                    type='textarea'
                    name='likelihood_comment'
                    onChange={onChangeField}
                    formFeedback='The likelihood comment must be at least 8 characters'
                  />
                </FormGroup>
              </div>
            ) : (
              reftree.likelihood_estimation_method ===
                LikelihoodEstimation[LikelihoodMethod.Category] && (
                <div>
                  <FormGroup>
                    <InputTemplate
                      label='Elapsed time'
                      type='select'
                      name='elapsed_time'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.elapsed_time === undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(ProjectType.ISO_21434.ElapsedTime).map(
                        (time) => {
                          return <option value={time[0]}>{time[1]}</option>;
                        }
                      )}
                    </InputTemplate>
                    <InputTemplate
                      label='Specialist expertise'
                      type='select'
                      name='specialist_knowledge'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.specialist_knowledge ===
                        undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType.ISO_21434.SpecialistExpertise
                      ).map((expertise) => {
                        return (
                          <option value={expertise[0]}>{expertise[1]}</option>
                        );
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Knowledge of the item'
                      type='select'
                      name='item_knowledge'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.item_knowledge === undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType.ISO_21434.ComponentKnowledge
                      ).map((knowledge) => {
                        return (
                          <option value={knowledge[0]}>{knowledge[1]}</option>
                        );
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Window of opportunity'
                      type='select'
                      name='window_of_opportunity'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.window_of_opportunity ===
                        undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType.ISO_21434.OpportunityWindow
                      ).map((window) => {
                        return <option value={window[0]}>{window[1]}</option>;
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Equipment'
                      type='select'
                      name='equipment'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={categoryCalculationData.equipment === undefined}
                      formFeedback='Please select a value'
                    >
                      {Object.entries(ProjectType.ISO_21434.Equipment).map(
                        (window) => {
                          return <option value={window[0]}>{window[1]}</option>;
                        }
                      )}
                    </InputTemplate>
                    <InputTemplate
                      label='Comment'
                      type='textarea'
                      name='likelihood_comment'
                      onChange={onChange}
                    />
                  </FormGroup>
                </div>
              )
            )}
          </>
        ) : null}

        {catalog?.content_type === CatalogType.Control ? (
          <>
            {' '}
            <small>* required field</small>
            <FormGroup>
              <InputTemplate
                label='* Name'
                type='text'
                name='name'
                value={formData.name}
                onChange={onChange}
                invalid={formData.name === '' || formData.name === undefined}
                formFeedback='Name cannot be blank'
              />
              <InputTemplate
                label='Description'
                type='textarea'
                name='description'
                value={formData.description}
                onChange={onChange}
              />
              <InputTemplate
                label='* Likelihood of compromising control'
                type='number'
                step={0.1}
                name='likelihood'
                onChange={onChange}
                defaultValue={1}
                invalid={
                  formData?.likelihood === undefined ||
                  !(formData.likelihood <= 1 && formData.likelihood > 0)
                }
                formFeedback='The value of the likelihood has to be a number between 0 and
    1.'
              />
            </FormGroup>
          </>
        ) : null}
        <div align={'right'}>
          {' '}
          <ButtonGroup>
            <Button
              disabled={
                catalog?.content_type === CatalogType.Control
                  ? formData?.name === undefined ||
                    formData?.likelihood === undefined ||
                    !(formData.likelihood <= 1 && formData.likelihood > 0)
                  : entry?.name === undefined ||
                    entry?.name === '' ||
                    reftree?.name === undefined ||
                    (reftree.likelihood_estimation_method ===
                      LikelihoodEstimation[LikelihoodMethod.Category] &&
                      categoryMethodCheck) ||
                    manualMethodCheck
              }
              className='btn-add'
              onClick={submit}
            >
              Ok
            </Button>
            <Button className='btn-danger' onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </ModalBody>
    </DraggableModal>
  );
};

const mapStateToProps = (state) => ({
  userReftrees: state.profile.userReftrees,
});

export default connect(mapStateToProps, { addNewCatalogEntry })(AddEntry);
// userReftrees: undefined,
