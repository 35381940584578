import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Isat4 from '../../img/isat4.jpg';
import logo2 from '../../img/logo2.jpg';
import { setAlert } from '../../redux/actions/alert';
import {
  dismissUpdatePasswordError,
  resetUpdatePassword,
  updatePassword,
} from '../../redux/actions/auth';
import { history } from '../routing/Routes';

const PasswordReset = ({
  updatePassword,
  dismissUpdatePasswordError,
  resetUpdatePassword,
  updatePasswordError,
  updatePasswordErrorMessage,
  updatePasswordSuccess,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    password1: '',
    password2: '',
  });

  const { password1, password2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (resetKey.length !== 25) {
      history.push('/');
      setAlert('Password reset has expired or is invalid', 'danger');
    } else {
      updatePassword(formData, resetKey);
    }
  };

  if (updatePasswordSuccess) {
    resetUpdatePassword();
    history.push('/');
    setAlert('Password reset is successful. Please log in again.', 'success');
  }

  var loginCredential = {
    width: '70%',
    height: '40px',
    margin: 'auto',
    border: '2px solid #7AC142',
    borderRadius: '6px',
  };

  const splitUrl = window.location.pathname.split('/');
  const resetKey = splitUrl[splitUrl.length - 1];

  return (
    <Fragment>
      <Row>
        <div style={{ width: '70%' }}>
          <Col style={{ right: '-70px' }}>
            <img src={Isat4} style={{ width: '90%', left: '20%' }} />
          </Col>
        </div>
        <Col style={{ left: '-40px' }}>
          <img src={logo2} style={{ width: '100%' }} />
          <h2>INVENSITY Security Assessment Tool</h2>
          <Card style={{ border: 'none' }}>
            <CardBody>
              <Form className='form' onSubmit={(e) => onSubmit(e)}>
                <div className='form-group'>
                  <div style={{ textAlign: 'center' }}>
                    <Col>
                      <Label>Reset Password</Label>
                    </Col>
                    <Col>
                      <Label>Password must be at least 8 characters long</Label>
                    </Col>
                  </div>
                  <br />
                  {updatePasswordError && (
                    <Alert
                      color='danger'
                      isOpen={updatePasswordError}
                      toggle={dismissUpdatePasswordError}
                      style={{ margin: 'auto', width: '80%' }}
                    >
                      {updatePasswordErrorMessage}
                    </Alert>
                  )}
                  <br />
                  <Input
                    type='password'
                    placeholder='New Password'
                    name='password1'
                    value={password1}
                    onChange={(e) => onChange(e)}
                    required
                    style={loginCredential}
                    minLength={8}
                  />
                </div>
                <div className='form-group'>
                  <Input
                    type='password'
                    placeholder='Confirm Password'
                    name='password2'
                    value={password2}
                    onChange={(e) => onChange(e)}
                    required
                    style={loginCredential}
                    minLength={8}
                  />
                </div>

                <Input
                  type='submit'
                  className='btn-add'
                  value='CONFIRM'
                  style={{
                    width: '30%',
                    margin: 'auto',
                    borderRadius: '13px',
                  }}
                />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  updatePasswordSuccess: state.auth.updatePasswordSuccess,
  updatePasswordError: state.auth.updatePasswordError,
  updatePasswordErrorMessage: state.auth.updatePasswordErrorMessage,
});

export default connect(mapStateToProps, {
  updatePassword,
  dismissUpdatePasswordError,
  resetUpdatePassword,
  setAlert,
})(PasswordReset);
