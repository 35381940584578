import React from 'react';
import JSONTree from 'react-json-tree';
import { JSONtheme } from '../../constants/Enums';
import { renameKeys } from '../helpers/genericHelper';

//This the Expanded area when clicking the entities in the change log
const AdminLogExpandedComponent = ({ data }) => {
  var logComponent = null;
  switch (data.alteration_type) {
    case 'creation':
      if (data?.new_object !== undefined) {
        logComponent = (
          <JSONTree data={renameKeys(data.new_object)} theme={JSONtheme} />
        );
      }
      break;
    case 'deletion':
      if (data?.deleted_object !== undefined) {
        logComponent = (
          <JSONTree data={renameKeys(data.deleted_object)} theme={JSONtheme} />
        );
      }
      break;
    case 'alteration':
      if (data?.differences !== undefined) {
        logComponent = (
          <JSONTree data={renameKeys(data.differences)} theme={JSONtheme} />
        );
      }
      break;
    default:
  }
  return (
    <pre display='inline-block' className='json-text'>
      {logComponent}
    </pre>
  );
};

export default AdminLogExpandedComponent;
