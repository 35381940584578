import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { btnEdit } from '../../../constants/Style';
import { updateProject } from '../../../redux/actions/projects';
import {
  cancelUnsavedChanges,
  unsavedChanges,
} from '../../../redux/actions/save';
import store from '../../../redux/store';

/**
 * Project Description Panel
 *
 * Panel for displaying and editing the project description
 *
 * @param toggle: triggers/toggles the modal to close/open
 * @param openPanel: checks if panel is open or closed to change caret
 * @param data: parameter for the specific project in the project details page
 * @param updateProject: redux action to update a project
 * @param profile: fetches profile of the current user in the system
 */
const DescriptionPanel = ({
  toggle,
  openPanel,
  data,
  updateProject,
  profile,
  save,
  unsavedChanges,
}) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    description: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      description: data?.description,
    });
  }, [data]);

  const [fieldId, setFieldId] = useState(null);

  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      const id = unsavedChanges();
      setFieldId(id);
    }
  };

  const { dispatch } = store;
  const onSubmit = async () => {
    updateProject(data._id, formData);
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };
  useEffect(() => {
    if (save && editMode) {
      updateProject(data._id, formData);
      changeMode();
      setFieldId(null);
    }
  }, [save]);

  var descriptionLabel = <div className='bold'>Description</div>;
  return (
    <div>
      {/* if the user has the role of owner or editor in the said project,
      he/she has the permission to edit the project  */}
      {/* <Collapse isOpen={openPanel}> */}
      {editMode ? (
        <div>
          {descriptionLabel}
          <textarea
            name='description'
            onChange={onChange}
            value={formData.description}
            className='description-edit'
          />
          <br />
          <div style={{ marginLeft: '520px' }}>
            <td className='no-border'>
              <Button
                className='btn-add'
                onClick={() => {
                  onSubmit();
                  changeMode();
                }}
              >
                Save
              </Button>
            </td>
            <td className='no-border'>
              <Button
                className='btn-danger'
                onClick={() => {
                  changeMode();
                  dispatch(cancelUnsavedChanges(fieldId));
                  setFieldId(null);
                }}
              >
                Cancel
              </Button>
            </td>
          </div>
        </div>
      ) : (
        <div style={{ width: '132px' }}>
          {data?.baseline === undefined ? (
            data?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('owner') ||
            data?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor') ? (
              <Button
                className='float-right'
                onClick={() => changeMode()}
                style={btnEdit}
              >
                <i className='fa fa-edit' />
              </Button>
            ) : null
          ) : null}
          {descriptionLabel}
          <div className='description-view'>
            {data?.description}
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

export default connect(mapStateToProps, { updateProject, unsavedChanges })(
  DescriptionPanel
);
