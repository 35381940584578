import React, { useEffect, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import { setProject, updateProject } from '../../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const AddAssumptions = ({ modal, toggle, updateProject, project }) => {
  const [assumption_data, setAssumptionData] = useState([]);
  const [newAssumption, setNewAssumption] = useState([]);

  const handleChange = (event) => {
    setNewAssumption(event.target.value);
  };

  const handleSave = () => {
    const newAssumptions = {
      assumptions: [...assumption_data, newAssumption],
    };
    updateProject(project._id, newAssumptions);
    toggle();
  };

  useEffect(() => {
    if (project?.assumptions) {
      setAssumptionData([...project.assumptions]);
    }
  }, [project]);

  return (
    modal && (
      <Fragment>
        <DraggableModal isOpen={modal} toggle={toggle}>
          <ModalHeader className='modal-header' toggle={toggle}>
            Add Assumption
          </ModalHeader>
          <div className='modal-line' style={{ zIndex: 1 }} />
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='assumptions'>Assumptions</Label>
                <Input
                  type='textarea'
                  name='assumptions'
                  id='assumptions'
                  placeholder='Enter assumptions'
                  value={newAssumption}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='btn-add' onClick={handleSave}>
              Save
            </Button>
            <Button color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </DraggableModal>
      </Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { setProject, updateProject })(AddAssumptions)
);
