import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment } from 'react';

/**
 * Auto Complete Input Component
 *
 * Component that allows to hav a autocomplete feature on filters/search
 * @param  options options being previewed
 * @param label label of respective input form
 * @param onInputChange checks the value being entered
 * @param onChange checks if there had been changes or triggers an event
 * @param id unique id of the input form
 */

const AutoCompleteInput = ({
  options,
  label,
  onInputChange,
  inputValue,
  id,
  onChange,
}) => {
  var searchFieldStyle = {
    width: '50%',
    height: '32px',
    borderRadius: '8px',
  };
  var textFieldStyle =
    window.location.pathname === '/projects'
      ? {
          border: '1px #7ac142 solid',
          height: 32,
          borderRadius: 10,
          paddingLeft: 10,
          paddingTop: 4,
        }
      : {};
  var faSearchStyle = { marginTop: 10, marginLeft: 5 };
  var variant = window.location.pathname === '/projects' ? 'standard' : 'outlined';
  return (
    <Fragment>
      <Autocomplete
        id={id}
        freeSolo
        size='small'
        style={searchFieldStyle}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            style={textFieldStyle}
            label={label}
            variant={variant}
            placeholder='Search'
          />
        )}
      />
      <FontAwesomeIcon icon={faSearch} style={faSearchStyle} />
    </Fragment>
  );
};

export default AutoCompleteInput;
