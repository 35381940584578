import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Row } from 'reactstrap';
import App from './App';
import store from './redux/store';
import { SpinnerLoader } from './spinnerLoader.';

ReactDOM.render(
  <Provider store={store}>
    <App />
    <Row className='justify-content-center align-self-center w-100 text-center'>
      <SpinnerLoader />
    </Row>
  </Provider>,
  document.getElementById('root')
);
