import uuid from 'uuid';
import {
  CANCEL_REROUTE,
  CANCEL_UNSAVED_CHANGES,
  REROUTE,
  UNSAVED_CHANGES,
} from './types';

export const unsavedChanges = () => (dispatch) => {
  const id = uuid.v4();
  dispatch({
    type: UNSAVED_CHANGES,
    payload: id,
  });
  return id;
};

export const reRoute = (link) => (dispatch) => {
  dispatch({
    type: REROUTE,
    payload: {
      link: link,
      reRoute: true,
    },
  });
};

export const cancelReroute = () => (dispatch) => {
  dispatch({
    type: CANCEL_REROUTE,
  });
};

export const cancelUnsavedChanges = (id = undefined) => (dispatch) => {
  dispatch({
    type: CANCEL_UNSAVED_CHANGES,
    payload: id,
  });
};
