import { default as React, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ProjectType } from '../../../constants/Enums';
import { addProject } from '../../../redux/actions/projects';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import TypeSelect from '../project-components/TypeSelect';
/**
 * Add Project.
 *
 * Modal for adding projects from scratch
 *
 * @param addProject: an action from redux that enables the adding of projects
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
export const AddProjects = ({ addProject, modal, toggle }) => {
  const [project, setProject] = useState({
    name: '',
    description: '',
    project_type: Object.entries(ProjectType)[0][1].Value,
    scope: '',
  });

  //event handler for inputs
  const onChange = (event) => {
    setProject({ ...project, [event.target.name]: event.target.value });
  };

  //method for adding new project
  const onSubmit = async () => {
    addProject(project);
  };

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Create New Project</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Name:</Label>
              <Input
                type='text'
                name='name'
                value={project.name}
                onChange={(event) => onChange(event)}
                innerRef={focusRef}
              />
              <Label style={{ fontSize: 10, color: 'red' }}>
                <i>*Project Name is required</i>
              </Label>
              <br />
              <Label>Project ID:</Label>
              <Input
                type='text'
                name='project_id'
                value={project.project_id}
                onChange={(event) => onChange(event)}
                innerRef={focusRef}
              />
              <br />
              <Label>Description: </Label>
              <Input
                type='textarea'
                name='description'
                value={project.description}
                onChange={(event) => onChange(event)}
              />

              <TypeSelect
                data={project.project_type}
                onChange={(event) => onChange(event)}
              />
              <Label>Project Scope: </Label>
              <Input
                type='textarea'
                name='scope'
                value={project.scope}
                onChange={(event) => onChange(event)}
              />
            </FormGroup>
            <FormGroup>
              {project?.name ? (
                <Button
                  className='float-right btn-add'
                  onClick={() => {
                    onSubmit();
                    toggle();
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button className='float-right btn-add' disabled>
                  Submit
                </Button>
              )}
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

export default connect(null, { addProject })(AddProjects);
