import React from 'react';

//This the Expanded area when clicking the projects in the Project list page
const ExpandedComponent = ({ data }) => {
  return (
    <div display='inline-block'>
      {'       '}
      <br></br>
      {'       '}{' '}
      <span>
        <p className='display-linebreak'>{data?.description}</p>
      </span>
    </div>
  );
};

export default ExpandedComponent;
