import { default as React, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { insertLicense } from '../../redux/actions/auth';
import store from '../../redux/store';
import { stringToDate } from '../helpers/genericHelper';
/**
 * License Upload Modal
 *
 * Modal for uploading new license
 *
 * TODO: Input from the backend and redux functionality
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */

const LicenseUploadModal = ({ modal, toggle, databaseId, expiryDate }) => {
  const { dispatch } = store;
  const [certificateData, setCertificateData] = useState(undefined);
  const validity = new Date(expiryDate);
  const currentDate = new Date();
  const onChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (event) => {
      isJsonString(event.target.result) &&
        setCertificateData(event.target.result);
    };
  };

  const onSubmit = async () => {
    dispatch(insertLicense(certificateData));
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>License Management</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <CardBody>
            <Form inline>
              <FormGroup>
                <Label>Database ID:</Label>{' '}
                <Input
                  style={{ border: 'none' }}
                  readOnly
                  value={databaseId}
                  type='text'
                  name='text'
                  id='databaseId'
                />
              </FormGroup>
            </Form>
            {currentDate < validity ? (
              <Fragment>
                <FormGroup>
                  <Label>Valid until: {stringToDate(expiryDate)}</Label>
                </FormGroup>
              </Fragment>
            ) : (
              <Fragment>Current license is invalid.</Fragment>
            )}
          </CardBody>
          <CardBody>
            <FormGroup>
              <Label>License update:</Label>
              <Input
                type='file'
                name='file'
                id='exampleFile'
                onChange={onChange}
                accept='.json'
              />
            </FormGroup>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button
              disabled={certificateData === undefined}
              className='btn-add'
              onClick={() => {
                onSubmit();
                toggle();
              }}
            >
              Update License
            </Button>
            <Button className='btn-danger' onClick={toggle}>
              Close
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  databaseId: state.auth.databaseId,
  expiryDate: state.licenseAlert.expiryDate,
});

export default withRouter(connect(mapStateToProps, {})(LicenseUploadModal));
