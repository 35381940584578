import { default as React, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
} from 'reactstrap';
import { INVENSITYContact } from '../../../constants/URLConstants';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */
const CybersecuritySupportPanel = ({ toggle, openPanel }) => {
  //Declares and displays the project list
  useEffect(() => {}, []);

  return (
    <Container fluid='md'>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        {'  '}Support for all kinds of Cybersecurity topics
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            <Button
              onClick={() => {
                window.open(INVENSITYContact);
              }}
              style={{ width: '200px' }}
            >
              Contact
            </Button>
          </CardBody>
        </Card>
      </Collapse>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, {})(CybersecuritySupportPanel)
);
