import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, UncontrolledTooltip } from 'reactstrap';
import { GenerateNodeId } from '../../../constants/StringConstants';

/**
 * Control node.
 *
 * A type of node for the attack tree, represents projects.
 *
 *  @param nodeData the node of the project
 *
 */
const ProjectNode = ({ nodeData }) => {
  const nodeName = nodeData.name;

  return (
    <Fragment>
      <Row>
        <div className='node-name' id={'ISATProjectNode'}>
          {nodeName}
        </div>
      </Row>
      <Row>
        <div className='node-type'>Project</div>
      </Row>
      <Row>
        <div
          id={`projectDescription${GenerateNodeId(-1, nodeData?.index)}`}
          className='node-table-data display-linebreak'
        ></div>
      </Row>
      {nodeData?.description && (
        <UncontrolledTooltip
          placement='right'
          target={'ISATProjectNode'}
          disabled={nodeData?.description === undefined}
          className='display-linebreak'
        >
          {nodeData?.description}
        </UncontrolledTooltip>
      )}
    </Fragment>
  );
};

export default connect(null, {})(ProjectNode);
