import PropTypes from 'prop-types';
import {
  default as React,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  NavbarBrand,
  NavbarToggler,
  Row,
  Spinner,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProjectType, UserPermissions } from '../../constants/Enums';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { setAlert } from '../../redux/actions/alert';
import {
  deleteProject,
  getProjects,
  setProject,
} from '../../redux/actions/projects';
import RevertBaseline from '../baseline/RevertBaseline';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission, isPermitted } from '../helpers/genericHelper';
import CustomDataTableStyles from '../layout/DataTableStyles';
import LicenseUploadModal from '../layout/LicenseUploadModal';
import AutoCompleteInput from './project-components/AutoCompleteInput';
import DeleteModal from './project-components/DeleteModal';
import AddProjects from './project-forms/AddProjects';
import { ExportModal } from './project-forms/ExportModal';
import { JSONExportModal } from './project-forms/JSONExportModal';
import DuplicateProject from './project-misc/DuplicateProject';
import ExpandedComponent from './project-misc/ExpandedComponent';
import ImportProject from './project-forms/ImportProject';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */

const FilterComponent = ({ onFilter, onClear, options, formData }) => {
  var searchFieldStyle = {
    width: '150px',
    height: '32px',
    borderRadius: '8px',
  };
  const [projects, setProjects] = useState({
    options: Array.isArray(options) && options.length > 0 ? options : [],
  });

  useEffect(() => {
    setProjects({
      ...projects,
      options: Array.isArray(options) && options.length > 0 ? options : [],
    });
  }, [options]);

  const [props, setProps] = useState({
    name: null,
    description: null,
    scope: null,
    project_type: null,
  });

  useEffect(() => {
    setProps({
      ...props,
      name: formData.name,
      description: formData.description,
      scope: formData.scope,
      project_type: formData.project_type,
    });
  }, [formData]);

  const nameChange = (value) => {
    const property = props;
    property.name = value;
    setProps({
      ...props,
      name: property.name,
    });
    onFilter(property);
  };

  const descriptionChange = (value) => {
    const property = props;
    property.description = value;
    setProps({
      ...props,
      description: property.description,
    });
    onFilter(property);
  };

  const scopeChange = (value) => {
    const property = props;
    property.scope = value;
    setProps({
      ...props,
      scope: property.scope,
    });
    onFilter(property);
  };

  const typeChange = (event) => {
    const property = props;
    property.project_type = event.target.value;
    setProps({
      ...props,
      type: property.project_type,
    });
    onFilter(property);
  };

  return (
    <Fragment>
      <Row style={{ width: '100%' }}>
        <Col>
          <Row style={{ width: '100%' }}>
            <Col className='float-left'>
              <NavbarBrand className='mr-auto float-left'>
                <NavbarToggler id='SearchFilterSubcomponent'>
                  <i className='fas fa-filter' />
                </NavbarToggler>
                Filters
              </NavbarBrand>
            </Col>
          </Row>
          <Row>
            <Col>
              <UncontrolledCollapse toggler='#SearchFilterSubcomponent'>
                <br />
                <Row>
                  <div style={{ width: '10%' }} />
                  <Col className='col-project-filter'>
                    <Label size='sm' style={{ marginRight: '5px' }}>
                      Project Name
                    </Label>
                    <AutoCompleteInput
                      id='name'
                      options={projects?.options?.map(
                        (project) => project?.name
                      )}
                      inputValue={props.name ? props.name : ''}
                      onInputChange={(event, newValue) => {
                        nameChange(newValue);
                      }}
                    />
                  </Col>
                  <Col className='col-project-filter'>
                    <Label size='sm' style={{ marginRight: '5px' }}>
                      Description
                    </Label>
                    <AutoCompleteInput
                      id='description'
                      options={projects?.options?.map(
                        (project) => project?.description
                      )}
                      inputValue={props.description ? props.description : ''}
                      onInputChange={(event, newValue) => {
                        descriptionChange(newValue);
                      }}
                    />
                  </Col>
                  <Col className='col-project-filter'>
                    <Label size='sm' style={{ marginRight: '5px' }}>
                      Scope
                    </Label>
                    <AutoCompleteInput
                      id='scope'
                      options={projects?.options?.map(
                        (project) => project?.scope
                      )}
                      inputValue={props.scope ? props.scope : ''}
                      onInputChange={(event, newValue) => {
                        scopeChange(newValue);
                      }}
                    />
                  </Col>
                  <Col className='col-project-filter'>
                    <Label size='sm' style={{ marginRight: '5px' }}>
                      Type
                    </Label>
                    <Input
                      type='select'
                      name='project_type'
                      id='project_type'
                      onChange={typeChange}
                      placeholder='Select Project Type'
                      className='mb-3'
                      bsSize='sm'
                      style={searchFieldStyle}
                    >
                      <option value=''>All</option>
                      {Object.entries(ProjectType).map((type) => {
                        return (
                          <option key={type[1].Value} value={type[1].Value}>
                            {type[1].Name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <div style={{ width: '10%' }} />
                </Row>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <Button className='mb-3 btn-add' onClick={onClear}>
                      Clear Filters
                    </Button>
                  </Col>
                </Row>
              </UncontrolledCollapse>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

const Projects = ({
  getProjects,
  setProject,
  deleteProject,
  projects,
  project,
  loading,
  profile,
  setAlert,
  assets,
}) => {
  //Declares and displays the project list
  const [selectedRows] = useState([]);
  useEffect(() => {
    getProjects();
  }, [selectedRows]);

  const history = useHistory();

  const scale = 'scale(1.25)';

  //Columns for the Project List
  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      maxWidth: '40%',
      cell: (row) => (
        <Link
          to={`/${row._id}/project`}
          onClick={() => {
            setProject(row);
          }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: 'Type',
      selector: 'project_type',
      sortable: true,
      maxWidth: '160px',
    },
    {
      name: 'Project ID',
      selector: 'project_id',
      sortable: true,
      maxWidth: '160px',
    },
    {
      name: 'Scope',
      selector: 'scope',
      sortable: true,
      maxWidth: '20%',
      format: (data) => {
        if (data.scope.length > 60) {
          return `${data.scope.substring(0, 60)}...`;
        } else {
          return data.scope;
        }
      },
    },
    {
      name: 'Last Modified',
      selector: 'last_modified',
      sortable: true,
      maxWidth: '180px',
      format: (date) => LocalTimeWithoutSeconds(date.last_modified),
    },
    {
      maxWidth: '350px',
      //Actions for every project
      cell: (row) => (
        <ButtonGroup>
          {/* Button for selecting the project on attack tree */}
          <Button
            onClick={() => {
              setProject(row);
              history.push(`/${row._id}/analysisTree`);
            }}
            size='sm'
            id={`analysisTree${row._id}`}
          >
            <i
              className='fa fa-sitemap'
              style={{
                transform: scale,
              }}
            />
          </Button>
          <UncontrolledTooltip target={`analysisTree${row._id}`}>
            Show Analysis Tree
          </UncontrolledTooltip>
          {hasPermission(profile?.permissions, UserPermissions.Creator) && (
          <Fragment>  
            <Button
              className='btn-add'
              size='sm'
              onClick={() => {
                exportToggle(row);
              }}
              id={`export${row._id}`}
            >
              <i
                className='fa fa-file-excel'
                style={{
                  color: 'white',
                  transform: scale,
                }}
              />
            </Button>
            <UncontrolledTooltip target={`export${row._id}`}>
              Export Project
            </UncontrolledTooltip>
            </Fragment>
          )}
          {hasPermission(profile?.permissions, UserPermissions.Admin) && (
            <Fragment>
              <Button
                className='btn-add'
                size='sm'
                onClick={() => {
                  exportJSONToggle(row);
                }}
                id={`exportJSON${row._id}`}
              >
                <i
                  className='fa fa-file-code'
                  style={{
                    color: 'white',
                    transform: scale,
                  }}
                />
              </Button>
              <UncontrolledTooltip target={`exportJSON${row._id}`}>
                Export Project JSON
              </UncontrolledTooltip>

              <Button
                className='btn-add'
                size='sm'
                onClick={() => {
                  revertBaselineToggle(row);
                }}
                id={`revertBaseline${row._id}`}
              >
                <i
                  className='fa fa-undo-alt'
                  style={{
                    color: 'white',
                    transform: scale,
                  }}
                />
              </Button>
              <UncontrolledTooltip target={`revertBaseline${row._id}`}>
                Revert Project Baseline
              </UncontrolledTooltip>
            </Fragment>
          )}

          <DeleteModal
            isOpen={deleteModal && deleteId === row._id}
            toggle={deletetoggle}
            onClick={() => {
              deleteProjectMethod(row._id);
              deletetoggle();
            }}
            message='All information regarding this project will be deleted. Are you sure you want to proceed?'
            header='Delete Project'
          />
          <UncontrolledTooltip target={`delete${row._id}`}>
            Delete
          </UncontrolledTooltip>
          {/* Button for deleting a specific project*/}
          <Button
            className='btn-danger'
            onClick={() => {
              isPermitted(profile, row, 'owner')
                ? deletetoggle(row)
                : setAlert(
                    'You are not allowed to delete this project',
                    'danger'
                  );
            }}
            id={`delete${row._id}`}
          >
            <i
              className='fa fa-trash'
              style={{
                color: 'white',
                transform: scale,
              }}
            />
          </Button>
        </ButtonGroup>
      ),
    },
  ]);

  //Sets Modal for deleting projects
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const deletetoggle = (row) => {
    if (!row) {
      setDeleteId(-1);
    } else {
      setDeleteId(row._id);
    }
    setDeleteModal(!deleteModal);
  };

  const [licenseUploadModal, setLicenseUploadModal] = useState(false);
  const toggleLicenseUploadModal = () =>
    setLicenseUploadModal(!licenseUploadModal);

  const [exportModal, setExportModal] = useState(false);
  const [exportRow, setExportRow] = useState(null);
  const exportToggle = (row) => {
    if (row._id === undefined) {
      setExportRow(null);
    } else {
      setExportRow(row);
    }
    setExportModal(!exportModal);
  };

  const [revertBaselineModal, setRevertBaselineModal] = useState(false);
  const [revertBaselineRow, setRevertBaselineRow] = useState(null);
  const revertBaselineToggle = (row) => {
    if (row?._id === undefined) {
      setRevertBaselineRow(null);
    } else {
      setRevertBaselineRow(row._id);
    }
    setRevertBaselineModal(!revertBaselineModal);
  };

  const [exportJSONModal, setExportJSONModal] = useState(false);
  const [exportJSONRow, setExportJSONRow] = useState(null);
  const exportJSONToggle = (row) => {
    if (row._id === undefined) {
      setExportJSONRow(null);
    } else {
      setExportJSONRow(row._id);
    }
    setExportJSONModal(!exportJSONModal);
  };

  //Method for deleting Projects
  const deleteProjectMethod = (row) => {
    deleteProject(row);
  };

  //Sets Modal for adding new projects
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  //Sets Dropdown during adding of projects
  const [dropdownOpen, setOpen] = useState(false);
  const toggleDropDown = () => setOpen(!dropdownOpen);

  //Sets modal for copying and adding an existing project
  const [copyModal, setCopyModal] = useState(false);
  const selectionToggle = () => setCopyModal(!copyModal);

  //Sets modal for importing project
  const [importModal, setImportModal] = useState(false);
  const toggleImportModal = () => setImportModal(!importModal);

  //Method for search input
  const [filterText, setFilterText] = React.useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [formData, setFormData] = useState({
    name: null,
    description: null,
    scope: null,
    project_type: null,
  });

  const onFilterChange = (props) => {
    setFormData({
      ...formData,
      name: props.name,
      description: props.description,
      scope: props.scope,
      project_type: props.project_type,
    });
  };

  const filteredItems = useMemo(() => {
    let items = [...projects];

    if (formData.name !== null && formData.name !== undefined) {
      items = items.filter((item) =>
        item?.name?.toLowerCase().includes(formData?.name?.toLowerCase())
      );
    }
    if (formData.description !== null && formData.description !== undefined) {
      items = items.filter((item) =>
        item?.description
          ?.toLowerCase()
          .includes(formData?.description?.toLowerCase())
      );
    }
    if (formData.scope !== null && formData.scope !== undefined) {
      items = items.filter((item) =>
        item?.scope?.toLowerCase().includes(formData?.scope?.toLowerCase())
      );
    }
    if (formData.project_type !== null && formData.project_type !== undefined) {
      items = items.filter((item) =>
        item?.project_type
          ?.toLowerCase()
          .includes(formData?.project_type?.toLowerCase())
      );
    }
    return items;
  }, [formData, projects]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        for (const filter of filterText) {
          const input = document.getElementById(filter.property);
          input.value = '';
        }
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText([]);
        setFormData({
          ...formData,
          name: null,
          description: null,
          scope: null,
          project_type: null,
        });
      }
    };
    return (
      <FilterComponent
        onFilter={onFilterChange}
        formData={formData}
        onClear={handleClear}
        options={projects}
      />
    );
  }, [filterText, resetPaginationToggle, projects]);

  return loading || projects === undefined ? (
    <Container>
      <Spinner className='spinner' />
    </Container>
  ) : (
    <Container fluid='md'>
      {(hasPermission(profile?.permissions, UserPermissions.Admin) ||
        hasPermission(profile?.permissions, UserPermissions.Creator)) && (
        <div className='card-actions float-right'>
          {profile?.id === 0 && (
            <Button onClick={toggleLicenseUploadModal}>
              License Management
            </Button>
          )}
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropDown}
            color='primary'
          >
            <DropdownToggle className='btn-add' caret>
              Add Project
            </DropdownToggle>
            <DropdownMenu style={{ marginLeft: '-90px' }}>
              <DropdownItem onClick={toggle}>New Project</DropdownItem>
              <AddProjects modal={modal} toggle={toggle} />
              <DropdownItem onClick={selectionToggle}>
                Copy an Existing Project
              </DropdownItem>
              <DraggableModal isOpen={copyModal} toggle={selectionToggle}>
                <ModalHeader>
                  <div className='modal-header'>Copy Existing Project</div>
                </ModalHeader>
                <div className='modal-line' />
                <ModalBody className='modal-body'>
                  <br />
                  <DuplicateProject data={projects} toggle={selectionToggle} />
                </ModalBody>
              </DraggableModal>
              <DropdownItem onClick={toggleImportModal}>
                Import Project
              </DropdownItem>
              <ImportProject modal={importModal} toggle={toggleImportModal} />
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      )}
      <CardTitle>
        <h1>Project List</h1>
      </CardTitle>
      {/* Project Table  */}
      <div className='project_table'>
        <DataTable
          defaultSortAsc={true}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          columns={columns}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={<ExpandedComponent data={filteredItems} />}
          data={filteredItems}
          subHeaderAlign='center'
          customStyles={CustomDataTableStyles}
        />
      </div>
      <ExportModal
        modal={exportModal}
        toggle={exportToggle}
        assets={assets}
        type={project?.project_type}
        project={project}
        projectRow={exportRow}
      />
      <JSONExportModal
        modal={exportJSONModal}
        toggle={exportJSONToggle}
        projectId={exportJSONRow}
      />
      <RevertBaseline
        modal={revertBaselineModal}
        toggle={revertBaselineToggle}
        projectId={revertBaselineRow}
      />
      <LicenseUploadModal
        modal={licenseUploadModal}
        toggle={toggleLicenseUploadModal}
      />
    </Container>
  );
};
Projects.propTypes = {
  getProjects: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  id: PropTypes.object,
};
const mapStateToProps = (state) => ({
  id: state.id,
  projects: state.project.projects,
  project: state.project.project,
  loading: state.project.loading,
  profile: state.profile.profile,
  profiles: state.profile.profiles,
  projectRole: state.profile.projectRole,
  assets: state.assets.assets,
});

export default withRouter(
  connect(mapStateToProps, {
    getProjects,
    setProject,
    deleteProject,
    setAlert,
  })(Projects)
);
