import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as React, Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { UserPermissions } from '../../../constants/Enums';
import { selectedColor } from '../../../constants/StringConstants';
import { adminAddMember } from '../../../redux/actions/catalog';
import { hasPermission } from '../../helpers/genericHelper';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import AutoCompleteInput from '../../projects/project-components/AutoCompleteInput';
import RoleSelect from '../../projects/project-components/RoleSelect';

/**
 * Catalog Role Asignment modal
 *
 * Displays available global catalogs to be assigned on the selected user
 *
 * @param addMember  redux action for adding new member in a catalog
 * @param userControlCatalogs state for the list of control catalogs
 * @param userRefTreeCatalogs state for the list of ref trees
 * @param data parameter for the selected user
 * @param toggle for toggling modals in this table
 */

const CatalogRoleAssignment = ({
  adminAddMember,
  userControlCatalogs,
  userRefTreeCatalogs,
  data,
  toggle,
  reftree = false,
}) => {
  const isCreator = hasPermission(data?.permissions, UserPermissions.Creator);

  const catalogArray = reftree ? userRefTreeCatalogs : userControlCatalogs;

  const [member, setMember] = useState({
    user_id: '',
    role: '',
  });

  const [catalog, setCatalog] = useState({
    _id: '',
  });

  const onChangeRole = (event) => {
    setMember({
      ...member,
      [event.target.name]: event.target.value,
      user_id: parseInt(data?._id),
    });
  };

  const onSubmit = async () => {
    adminAddMember(parseInt(catalog._id), member);
  };

  const selection = (row) => {
    setCatalog({
      ...catalog,
      _id: row,
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row._id === catalog?._id,
      style: {
        backgroundColor: selectedColor,
      },
    },
  ];

  const columns = React.useMemo(() => [
    {
      name: 'Catalogues',
      selector: 'name',
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Button
            id={`checkBox${row._id}`}
            onClick={() => {
              selection(row._id);
            }}
          >
            <FontAwesomeIcon
              icon={catalog._id === row._id ? faCheckSquare : faSquare}
            />
          </Button>
          <UncontrolledTooltip target={`checkBox${row._id}`}>
            Select
          </UncontrolledTooltip>
        </>
      ),
    },
  ]);

  //Method for search input
  var options = catalogArray.map((catalog) => catalog.name);
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);
  const searchItems =
    catalogArray &&
    catalogArray.filter(
      (catalog) =>
        catalog?.catalog_type === 'global' &&
        catalog.name &&
        catalog.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search Catalogue'
      />
    );
  }, [searchText, resetPaginationToggle]);
  return (
    <Fragment>
      <FormGroup>
        <DataTable
          columns={columns}
          data={searchItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          conditionalRowStyles={conditionalRowStyles}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          customStyles={CustomDataTableStyles}
        />
        <br />
        <Label>Role:</Label>
        <RoleSelect
          isCreator={isCreator}
          onChange={onChangeRole}
          data={member.role}
        />
      </FormGroup>
      <FormGroup>
        <div className='float-right'>
          <Button
            className='btn-add'
            disabled={member?.role === undefined || catalog._id === undefined}
            onClick={() => {
              onSubmit();
              toggle();
              setSearchText('');
            }}
          >
            Save
          </Button>
          <Button
            className='btn-danger'
            onClick={() => {
              toggle();
              setSearchText('');
            }}
          >
            Cancel
          </Button>
        </div>
      </FormGroup>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userRefTreeCatalogs: state.profile.adminRefTreeCatalogs,
  userControlCatalogs: state.profile.adminControlCatalogs,
});

export default connect(mapStateToProps, {
  adminAddMember,
})(CatalogRoleAssignment);
