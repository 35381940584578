import { default as React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardTitle, Container } from 'reactstrap';
import CybersecuritySupportPanel from './CybersecuritySupportPanel';
import RelatedSupportPanel from './RelatedSupportPanel';
import TARASupportModal from './TARASupportModal';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */
const Advisory = ({ projects, loading }) => {
  //Declares and displays the project list
  useEffect(() => {}, []);

  const [cybersecurityPanel, setCybersecurityPanel] = useState(false);
  const toggleCybersecurityPanel = () =>
    setCybersecurityPanel(!cybersecurityPanel);

  const [relatedSupportPanel, setRelatedSupportPanel] = useState(false);
  const toggleRelatedSupportPanel = () =>
    setRelatedSupportPanel(!relatedSupportPanel);

  const [TARASupport, setTARASupport] = useState(false);
  const toggleTARASupport = () => setTARASupport(!TARASupport);

  return (
    <Container fluid='md'>
      <CardTitle>
        <h1>Advisory</h1>
      </CardTitle>

      {/* <div className='card-actions float-right'>
        <Button color='primary' onClick={toggleTARASupport}>
          Support for conducting a TARA
        </Button>
      </div> */}
      <TARASupportModal modal={TARASupport} toggle={toggleTARASupport} />
      <CybersecuritySupportPanel
        toggle={toggleCybersecurityPanel}
        openPanel={cybersecurityPanel}
      />
      <RelatedSupportPanel
        toggle={toggleRelatedSupportPanel}
        openPanel={relatedSupportPanel}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(Advisory));
