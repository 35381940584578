import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { setAlert } from '../../redux/actions/alert';
import {
  dismissUpdatePasswordError,
  resetUpdatePassword,
  adminUpdatePassword,
} from '../../redux/actions/auth';

const ResetUserPasswordModal = ({
  modal,
  toggleModal, // Rename this to toggleModal to avoid naming conflicts
  data,
  adminUpdatePassword,
}) => {
  const [formData, setFormData] = useState({
    password1: '',
    password2: '',
  });

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const { password1, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const closeModal = () => {
    setFormData({ password1: '', password2: '' });
    toggleModal();
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    openConfirmationModal();
  };

  const handleConfirmation = () => {
    adminUpdatePassword(formData, data._id);
    closeConfirmationModal();
    closeModal(); 
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggleModal} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Reset Password</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form onSubmit={(e) => onSubmit(e)}>
            <FormGroup>
              <Label>New Password:</Label>
              <Input
                type='password'
                placeholder='New Password'
                name='password1'
                value={password1}
                onChange={(e) => onChange(e)}
                minLength={8}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirm Password:</Label>
              <Input
                type='password'
                placeholder='Confirm Password'
                name='password2'
                value={password2}
                onChange={(e) => onChange(e)}
                minLength={8}
                required
              />
            </FormGroup>
            <div className='modal-footer' style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type='submit'
                className='btn-add'
                style={{
                  width: '30%',
                  borderRadius: '13px',
                }}
              >
                Okay
              </Button>
              <Button
                color='danger'
                onClick={closeModal}
                style={{
                  width: '30%',
                  borderRadius: '13px',
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={confirmationModalOpen} toggle={closeConfirmationModal}>
      <ModalHeader className='modal-header'>Confirmation</ModalHeader>
      <div className='modal-line' />

      <ModalBody className='modal-body'>
        You are about to change user credentials. After changing credentials, the respective user will be logged out from ISAT and has to log in again with the new credentials. Please be aware of that situation.
      </ModalBody>
      
      <div className='modal-footer' style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          className='btn-add'
          onClick={handleConfirmation}
          style={{
            borderRadius: '13px',
            width: '30%',
          }}
        >
          Okay
        </Button>
        
        <Button
          color='danger'
          onClick={closeConfirmationModal}
          style={{
            borderRadius: '13px',
            width: '30%',

          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  updatePasswordSuccess: state.auth.updatePasswordSuccess,
  updatePasswordError: state.auth.updatePasswordError,
  updatePasswordErrorMessage: state.auth.updatePasswordErrorMessage,
});

export default connect(mapStateToProps, {
  adminUpdatePassword,
  dismissUpdatePasswordError,
  resetUpdatePassword,
  setAlert,
})(ResetUserPasswordModal);
