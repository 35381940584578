import React, { Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import {
  getCatalogChangeLogs,
  getProjectChangeLogs,
} from '../../redux/actions/logging';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AdminLogExpandedComponent from './AdminLogExpandedComponent';

/**
 * Admin ChangeLog page
 *
 * Page used to show changelog of a project
 *
 *  @param isVisible redux state mapped to props, value to show sidebar or not
 *  @param noSidebar redux action mapped to props, disables sidebar
 */
const ChangeLogPanel = ({
  toggle,
  openPanel,
  projectLogRetriever,
  catalogLogRetriever,
  catalogChangeLogs,
  projectChangeLogs,
  project,
  catalog,
}) => {
  const columns = React.useMemo(() => [
    {
      name: 'Username',
      selector: 'user_name',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <Fragment>
          {row?.user_name !== undefined ? row.user_name : 'System'}
        </Fragment>
      ),
    },
    {
      name: 'Timestamp',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '200px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      name: 'Alteration Type',
      selector: 'alteration_type',
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'Object ID',
      selector: 'object_id',
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Object Type',
      selector: 'object_type',
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Reference Tree Id',
      selector: 'reference_tree_id',
      sortable: true,
      maxWidth: '50px',
      cell: (row) => (
        <Fragment>
          {row?.reference_tree_id !== undefined ? row.reference_tree_id : 'N/A'}
        </Fragment>
      ),
    },
  ]);

  useEffect(() => {
    project && projectLogRetriever(project);
    catalog && catalogLogRetriever(catalog);
  }, [project, catalog]);
  return (
    <div>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        {'  '}Change log
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            {project && (
              <DataTable
                defaultSortField='creation_date'
                defaultSortAsc={false}
                pagination
                columns={columns}
                persistTableHead
                data={projectChangeLogs}
                expandableRowsComponent={
                  <AdminLogExpandedComponent data={projectChangeLogs} />
                }
                expandOnRowClicked
                expandableRows
                customStyles={CustomDataTableStyles}
              />
            )}
            {catalog !== undefined && (
              <DataTable
                defaultSortField='creation_date'
                defaultSortAsc={false}
                pagination
                columns={columns}
                persistTableHead
                data={catalogChangeLogs}
                expandableRowsComponent={
                  <AdminLogExpandedComponent data={catalogChangeLogs} />
                }
                expandOnRowClicked
                expandableRows
                customStyles={CustomDataTableStyles}
              />
            )}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};
const mapStateToProps = (state) => ({
  sideBarVisible: state.sidebar.isVisible,
  projectChangeLogs: state.logging.projectChangeLogs,
  catalogChangeLogs: state.logging.catalogChangeLogs,
});

export default connect(mapStateToProps, {
  projectLogRetriever: getProjectChangeLogs,
  catalogLogRetriever: getCatalogChangeLogs,
})(ChangeLogPanel);
