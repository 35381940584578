import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { UserPermissions } from '../../../constants/Enums';
import { setAlert } from '../../../redux/actions/alert';
import {
  adminDeleteMember,
  adminUpdateMember,
} from '../../../redux/actions/projects';
import { hasPermission, ownerDeletionCheck } from '../../helpers/genericHelper';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import DeleteModal from '../../projects/project-components/DeleteModal';
import ModalError from '../../projects/project-components/ModalError';
import RoleSelect from '../../projects/project-components/RoleSelect';

/**
 * Project Table
 *
 * Displays project that are assigned to the selected user
 *
 * @param adminDeleteMember redux action for removing the member in a project
 * @param adminUpdateMember redux action for updating the role of the user
 * @param setAlert redux state for triggering alerts on the page
 * @param data parameter for the selected user
 * @param getProfiles redux action for retrieving available users
 * @param profile redux state for retrieving details of the current user
 * @param projects state for the list of projects
 * @param  getProjects redux action to retrieve all available projects
 */

const userOwnership =
  'You are about to remove users ownership from the project. Are you sure you want to proceed?';
const ownOwnership =
  'You are about to remove your onwnership from the project. Are you sure you want to proceed?';

const ProjectTable = ({
  data,
  projects,
  setAlert,
  adminDeleteMember,
  adminUpdateMember,
  profile,
}) => {
  const isCreator = hasPermission(data?.permissions, UserPermissions.Creator);

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    let projectData = [];
    projectData = projects
      ?.filter((project) =>
        project?.participants.some(
          (participant) => parseInt(participant?.user_id) === parseInt(data._id)
        )
      )
      .map((project) => project);

    setProjectData(projectData);

    setMember({
      ...member,
      user_id: parseInt(data?._id),
    });
  }, [data, projects]);

  const [editUserMode, setEditUserMode] = useState(false);
  const [editId, setEditId] = useState(false);

  const editInputTrigger = (row) => {
    setEditId(row ? row._id : -1);
    setEditUserMode(true);
  };

  const [member, setMember] = useState({
    user_id: undefined,
    role: undefined,
  });

  //Event handling for inputs
  const onChange = (event) => {
    setMember({
      ...member,
      [event.target.name]:
        event.target.value === '' ? undefined : event.target.value,
    });
  };
  const onDelete = async (row) => {
    adminDeleteMember(row, data._id);
  };
  const onUpdate = async (row) => {
    if (
      projects
        .find((project) => project._id === row)
        .participants.find(
          (participant) =>
            participant?.role === member?.role &&
            participant?.user_id === member?.user_id
        ) !== undefined
    ) {
      //same project with same role
      return;
    } else if (member?.role === undefined) {
      setAlert('Please select a role', 'danger');
      return;
    } else {
      adminUpdateMember(row, member);
    }
  };

  const cancel = () => {
    setEditUserMode(false);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const deleteToggle = (row) => {
    setDeleteId(row ? row._id : -1);
    setDeleteModal(!deleteModal);
  };

  const [errorModal, setErrorModal] = useState(false);
  const errorToggle = () => setErrorModal(!errorModal);

  function updateOwnershipCheck(row, message) {
    return (
      <>
        <Button
          id={`saveButton${row._id}`}
          className='btn-add'
          disabled={member?.role === undefined}
          onClick={() => {
            !ownerDeletionCheck(data, row) ? deleteToggle(row) : errorToggle();
          }}
        >
          <i className='fa fa-save btn-icon' />
        </Button>
        <UncontrolledTooltip target={`saveButton${row._id}`}>
          Save
        </UncontrolledTooltip>
        <DeleteModal
          isOpen={deleteModal && deleteId === row._id}
          toggle={deleteToggle}
          onClick={() => {
            onUpdate(row._id);
            deleteToggle();
            cancel();
          }}
          header='Update User from Project'
          message={message}
        />
        <Button
          id={'cancelButton' + row?._id}
          className='btn-danger'
          name='cancel'
          onClick={cancel}
        >
          <i className='fa fa-times btn-icon' />
        </Button>
        <UncontrolledTooltip target={'cancelButton' + row?._id}>
          Cancel
        </UncontrolledTooltip>
      </>
    );
  }

  function deleteOwnershipCheck(row, message) {
    return (
      <>
        <Button
          id={`deleteButton${row._id}`}
          className='btn-danger'
          onClick={() => {
            !ownerDeletionCheck(data, row) ? deleteToggle(row) : errorToggle();
          }}
        >
          <i className='fa fa-trash' />
        </Button>
        <UncontrolledTooltip target={`deleteButton${row._id}`}>
          Delete
        </UncontrolledTooltip>
        <DeleteModal
          isOpen={deleteModal && deleteId === row._id}
          toggle={deleteToggle}
          onClick={() => {
            onDelete(row._id);
            deleteToggle();
          }}
          header='Delete User from Project'
          message={message}
        />
      </>
    );
  }

  const projectColumn = React.useMemo(() => [
    {
      name: 'Project',
      selector: 'name',
      sortable: true,
      maxWidth: '300px',
      cell: (row) => <>{row.name}</>,
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
      maxWidth: '150px',
      //Actions for every project
      cell: (row) => (
        <Fragment>
          {editUserMode && editId === row._id ? (
            <Fragment>
              <RoleSelect
                onChange={(event) => onChange(event)}
                data={member.role}
                isCreator={isCreator}
              />
            </Fragment>
          ) : (
            row.participants.find(
              (participant) =>
                parseInt(participant.user_id) === parseInt(data?._id)
            )?.role
          )}
        </Fragment>
      ),
    },
    {
      cell: (row) => (
        <ButtonGroup>
          {editUserMode && editId === row?._id ? (
            row.participants.find(
              (participant) =>
                parseInt(participant.user_id) === parseInt(data?._id)
            )?.role !== 'owner' ? (
              <Fragment>
                <Button
                  id={`saveButton${row._id}`}
                  className='btn-add btn-icon'
                  disabled={member?.role === undefined}
                  onClick={() => {
                    onUpdate(row._id);
                    cancel();
                  }}
                >
                  <i className='fa fa-save' />
                </Button>
                <UncontrolledTooltip target={`saveButton${row._id}`}>
                  Save
                </UncontrolledTooltip>
                <Button
                  id={`cancelButton${row._id}`}
                  className='btn-danger'
                  name='cancel'
                  onClick={cancel}
                >
                  <i className='fa fa-times' />
                </Button>
                <UncontrolledTooltip target={`saveButton${row._id}`}>
                  Cancel
                </UncontrolledTooltip>
              </Fragment>
            ) : parseInt(profile.id) !== data?._id ? (
              updateOwnershipCheck(row, userOwnership)
            ) : (
              updateOwnershipCheck(row, ownOwnership)
            )
          ) : (
            <Fragment>
              <Button
                id={`editButton${row._id}`}
                onClick={() => {
                  setMember({ ...member, role: undefined });
                  editInputTrigger(row);
                }}
              >
                <i className='fa fa-edit' />
              </Button>
              <UncontrolledTooltip target={`editButton${row._id}`}>
                Edit
              </UncontrolledTooltip>
              {row.participants.find(
                (participant) =>
                  parseInt(participant.user_id) === parseInt(data?._id)
              )?.role === 'owner' ? (
                parseInt(profile.id) === data._id ? (
                  deleteOwnershipCheck(row, ownOwnership)
                ) : (
                  deleteOwnershipCheck(row, userOwnership)
                )
              ) : (
                <Fragment>
                  <Button
                    id={`deleteButton${row._id}`}
                    className='btn-danger'
                    onClick={() => {
                      deleteToggle(row);
                    }}
                  >
                    <i className='fa fa-trash' />
                  </Button>
                  <UncontrolledTooltip target={`deleteButton${row._id}`}>
                    Delete
                  </UncontrolledTooltip>
                  <DeleteModal
                    isOpen={deleteModal && deleteId === row._id}
                    toggle={deleteToggle}
                    onClick={() => {
                      onDelete(row._id);
                      deleteToggle();
                    }}
                    header='Delete User from Project'
                    message='Are you sure you want to remove the user from the team?'
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </ButtonGroup>
      ),
    },
  ]);

  return (
    <Fragment>
      <DataTable
        title='Projects'
        defaultSortAsc='id'
        columns={projectColumn}
        data={projectData}
        pagination
        persistTableHead
        customStyles={CustomDataTableStyles}
      />
      <ModalError
        isOpen={errorModal}
        toggle={errorToggle}
        onClick={() => {
          errorToggle();
          cancel();
        }}
        header={'Ownership Check'}
        message={
          'There has to be at least one team member with the role owner at all times!'
        }
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  projects: state.profile.adminProjects,
  profiles: state.profile.profiles,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  setAlert,
  adminDeleteMember,
  adminUpdateMember,
})(ProjectTable);
