import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonDropdown,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProjectRole } from '../../../constants/Enums';
import { logout } from '../../../redux/actions/auth';
import {
  applyProjectBaseline,
  getProjectBaselines,
  setProject,
} from '../../../redux/actions/projects';
import {
  cancelReroute,
  cancelUnsavedChanges,
  reRoute,
} from '../../../redux/actions/save';
import ChangeLogPanel from '../../admin/ChangeLogPanel';
import RecycleBinPanel from '../../admin/RecycleBinPanel';
import AddBaseline from '../../baseline/AddBaseline';
import RevertBaseline from '../../baseline/RevertBaseline';
import ViewBaseline from '../../baseline/ViewBaseline';
import { ExportModal } from '../project-forms/ExportModal';
import CommentsPanel from '../project-misc/CommentsPanel';
//importing panels for project details
import DescriptionPanel from '../project-misc/DescriptionPanel';
import MembersPanel from '../project-misc/MembersPanel';
import NewsPanel from '../project-misc/NewsPanel';
import ProjectNamePanel from '../project-misc/ProjectNamePanel';
import ProjectTypePanel from '../project-misc/ProjectTypePanel';
import { SaveAll } from '../project-misc/SaveAll';
import ScopePanel from '../project-misc/ScopePanel';
import AddProjectSpecificCatalog from './AddProjectSpecificCatalog';
import ViewProjectSpecificCatalog from './ViewProjectSpecificCatalog';
import UploadImage from './UploadImage';
import ImageGallery from './ImageGallery';
import axios from '../../../Axios/axios';
import AssumptionsPanel from './AssumptionsPanel';
import ProjectIdPanel from '../project-misc/ProjectIdPanel';
/**
 * Project Detail Page
 *
 * Page that shows the details of each project
 * TODO: Editing of project should be done in this page
 *
 * @param loading: loads the spinner if project cannot be retrieved
 * @param project: currently selected project
 *
 */
const ProjectDetail = ({
  loading,
  project,
  setProject,
  profile,
  routeParams,
  unsavedChanges,
  projectRole,
  reRoute,
  cancelReroute,
  cancelUnsavedChanges,
  logout,
  getProjectBaselines,
  applyProjectBaseline,
  assets,
}) => {
  useEffect(() => {
    if (project !== undefined) {
      getProjectBaselines();
    }
  }, [project, getProjectBaselines]);
  const editableComments = projectRole !== ProjectRole.Reader.value;

  // For Uploading Image
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState('');

  //For Description Panel
  const [openDescription, setDescription] = useState(true);
  const toggleDescription = () => setDescription(!openDescription);
  //For Project Scope Panel
  const [openScope, setScope] = useState(true);
  const toggleScope = () => setScope(!openScope);
  //For Project Members Panel
  const [openMembers, setMembers] = useState(false);
  const toggleMembers = () => setMembers(!openMembers);
  //For Review Comments Panel
  const [openReview, setReview] = useState(false);
  const toggleReview = () => setReview(!openReview);
  //For Review Comments Panel
  const [openNews, setNews] = useState(false);
  const toggleNews = () => setNews(!openNews);
  //For Changelog Panel
  const [openChangelog, setChangelog] = useState(false);
  const toggleChangelog = () => setChangelog(!openChangelog);
  //For Recyclebin Panel
  const [openRecyclebin, setRecyclebin] = useState(false);
  const toggleRecyclebin = () => setRecyclebin(!openRecyclebin);

  const [catalogDropdown, setCatalogDropdown] = useState(false);
  const toggleCatalogDropdown = () => setCatalogDropdown(!catalogDropdown);

  //For Creation of Baseline
  const [baselineModal, setBaselineModal] = useState(false);
  const baselineToggle = () => setBaselineModal(!baselineModal);

  //For Baseline View
  const [baselineViewModal, setBaselineViewModal] = useState(false);
  const baselineViewToggle = () => setBaselineViewModal(!baselineViewModal);

  //For Baseline Revert
  const [baselineRevertModal, setBaselineRevertModal] = useState(false);
  const baselineRevertToggle = () =>
    setBaselineRevertModal(!baselineRevertModal);

  //For Export Project
  const [exportProjectModal, setExportProjectModal] = useState(false);
  const exportProjectToggle = () => setExportProjectModal(!exportProjectModal);

  const query = new URLSearchParams(window.location.search);
  const baselineId = query.get('baselineId');

  const [save, setSave] = useState(false);
  const history = useHistory();
  const decision = (type) => {
    if (type === 'Cancel') {
      cancelReroute();
    }
    if (type === 'OK') {
      if (routeParams.link === 'logout') {
        logout();
        history.push('/');
      } else {
        history.push(routeParams.link);
      }
      cancelReroute();
      cancelUnsavedChanges();
    }
    if (type === 'Save All') {
      setSave(true);
      cancelReroute();
      cancelUnsavedChanges();
    }
  };

  const [catalogModal, setCatalogModal] = useState(false);
  const toggleCatalog = () => setCatalogModal(!catalogModal);

  const [viewCatalogModal, setViewCatalogModal] = useState(false);
  const toggleViewCatalog = () => setViewCatalogModal(!viewCatalogModal);

  window.onbeforeunload = function (event) {
    if (unsavedChanges?.length > 0) {
      event.preventDefault();
      return 'Leaving this page will reset the wizard';
    } else {
      return;
    }
  };
  const btnWidth = '180px';
  const divSpace = (
    <div className='float-right' style={{ width: btnWidth, height: '10px' }} />
  );

  //If page is loaded via url or new tab, loads needed data and attaches to store
  if (
    (project === undefined || project === null) &&
    loading === false &&
    profile !== null
  ) {
    var url = window.location.pathname;
    var splitUrl = url.split('/');
    var projectId = splitUrl[1];

    if (projectId !== undefined) {
      if (baselineId) {
        applyProjectBaseline(projectId, baselineId);
      } else {
        const projectTemplate = { _id: projectId };
        setProject(projectTemplate);
      }
    }
  }

  return loading || project === undefined ? (
    <Container>
      <Spinner className='spinner' />
    </Container>
  ) : (
    <Container fluid='md' className='project_details_container'>
      <CardTitle>
        <font style={{ fontSize: '40px' }}>Project Details</font>
        <div
          className='card-actions float-right'
          style={{ width: btnWidth, marginTop: '20px' }}
        >
          <div className='card-actions float-right'>
            <ButtonDropdown
              isOpen={catalogDropdown}
              toggle={toggleCatalogDropdown}
            >
              <DropdownToggle
                caret
                className='btn-add'
                style={{ width: btnWidth, paddingLeft: '10px' }}
              >
                Project Actions
              </DropdownToggle>
              <DropdownMenu style={{ marginLeft: '-104px' }}>
                <DropdownItem header>Catalogs</DropdownItem>
                <DropdownItem onClick={toggleCatalog}>Create New</DropdownItem>
                <AddProjectSpecificCatalog
                  modal={catalogModal}
                  toggle={toggleCatalog}
                  data={project}
                />
                <DropdownItem onClick={toggleViewCatalog}>
                  View Project Specific Catalogues
                </DropdownItem>
                <ViewProjectSpecificCatalog
                  data={project}
                  modal={viewCatalogModal}
                  toggle={toggleViewCatalog}
                />
                <DropdownItem divider />
                <DropdownItem header>Baselines</DropdownItem>
                <DropdownItem
                  id='NewBaselineTooltip'
                  disabled={
                    projectRole !== ProjectRole.Owner.value &&
                    projectRole !== ProjectRole.Editor.value &&
                    projectRole !== ProjectRole.Demo.value &&
                    projectRole !== ProjectRole.Reviewer.value
                  }
                  onClick={baselineToggle}
                >
                  Create New
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    projectRole === ProjectRole.Owner.value ||
                    projectRole === ProjectRole.Editor.value ||
                    projectRole === ProjectRole.Demo.value ||
                    projectRole === ProjectRole.Reviewer.value
                  }
                  target={'NewBaselineTooltip'}
                >
                  The user has to be reviewer or higher for this action.
                </UncontrolledTooltip>

                <DropdownItem
                  id='ViewTooltip'
                  disabled={
                    projectRole !== ProjectRole.Owner.value &&
                    projectRole !== ProjectRole.Editor.value &&
                    projectRole !== ProjectRole.Demo.value &&
                    projectRole !== ProjectRole.Reviewer.value
                  }
                  onClick={baselineViewToggle}
                >
                  View/Delete Baseline
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    projectRole === ProjectRole.Owner.value ||
                    projectRole === ProjectRole.Editor.value ||
                    projectRole === ProjectRole.Demo.value ||
                    projectRole === ProjectRole.Reviewer.value
                  }
                  target={'ViewTooltip'}
                >
                  The user has to be reviewer or higher for this action.
                </UncontrolledTooltip>

                <DropdownItem
                  id='RevertTooltip'
                  disabled={
                    projectRole !== ProjectRole.Owner.value &&
                    projectRole !== ProjectRole.Editor.value &&
                    projectRole !== ProjectRole.Demo.value
                  }
                  onClick={baselineRevertToggle}
                >
                  Revert To Baseline
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    projectRole === ProjectRole.Owner.value ||
                    projectRole === ProjectRole.Editor.value ||
                    projectRole === ProjectRole.Demo.value
                  }
                  target={'RevertTooltip'}
                >
                  The user has to be editor or higher for this action.
                </UncontrolledTooltip>
                <DropdownItem divider />
                {projectRole !== ProjectRole.Demo.value && (
                  <DropdownItem onClick={exportProjectToggle}>
                    Export Project
                  </DropdownItem>
                )}
              </DropdownMenu>

              <RevertBaseline
                modal={baselineRevertModal}
                toggle={baselineRevertToggle}
              />
              <ViewBaseline
                modal={baselineViewModal}
                toggle={baselineViewToggle}
              />
              <AddBaseline
                modal={baselineModal}
                toggle={baselineToggle}
                recreate={false}
              />
              <ExportModal
                modal={exportProjectModal}
                toggle={exportProjectToggle}
                assets={assets}
                type={project?.project_type}
                project={project}
              />
            </ButtonDropdown>
          </div>
          {divSpace}
          <div>
            <Link
              className='btn btn-add float-right'
              style={{ color: 'white', width: btnWidth }}
              onClick={(event) => {
                if (unsavedChanges?.length > 0) {
                  event.preventDefault();
                  reRoute(
                    window.location.pathname.replace('project', 'analysisTree')
                  );
                }
              }}
              to={window.location.pathname.replace('project', 'analysisTree')}
            >
              View Analysis Tree
            </Link>
            {divSpace}
            <Link
              className='btn btn-secondary float-right'
              onClick={(event) => {
                if (unsavedChanges?.length > 0) {
                  event.preventDefault();
                  reRoute('/projects');
                }
              }}
              to='/projects'
              style={{ width: btnWidth }}
            >
              Back to Project List
            </Link>
            {divSpace}
            <div>
              {/* Upload Image Panel */}
              {error && <p>{error}</p>}
              {project._id !== undefined && (
                <UploadImage
                  project_id={project._id}
                  setSelectedImage={setSelectedImage}
                />
              )}
            </div>
          </div>
          <div />
        </div>
      </CardTitle>
      <br />
      <Row>
        <div style={{ marginLeft: '15px' }}>
          {/* Project Name Panel */}
          <ProjectNamePanel data={project} save={save} />
        </div>
        <Col md= {2} style={{ marginLeft: '100px' }}>
          {/* Project Type Panel */}
          <ProjectTypePanel data={project} save={save} />
        </Col>
        <Col md= {3} style={{ marginLeft: '100px' }}>

          {/* Project ID Panel */}
          <ProjectIdPanel data={project} save={save} />
          </Col>

      </Row>
      <br />
      {/* Description Panel */}
      <DescriptionPanel
        toggle={toggleDescription}
        openPanel={openDescription}
        data={project}
        save={save}
      />
      {/* Project Scope Panel */}
      <ScopePanel
        toggle={toggleScope}
        openPanel={openScope}
        data={project}
        save={save}
      />
      {/* Review Comments Panel */}
      <CommentsPanel
        toggle={toggleReview}
        openPanel={openReview}
        editable={editableComments}
      />
      {/* News Panel  */}
      <NewsPanel toggle={toggleNews} openPanel={openNews} project={project} />
      {/* Project Members Panel  */}
      <MembersPanel
        toggle={toggleMembers}
        openPanel={openMembers}
        data={project}
      />
      {/* Assumptions Panel */}
      <AssumptionsPanel />
      {/* Change log Panel  */}
      <ChangeLogPanel
        toggle={toggleChangelog}
        openPanel={openChangelog}
        project={project}
      />
      {/* Recycle bin Panel  */}
      {projectRole === ProjectRole.Owner.value && (
        <RecycleBinPanel toggle={toggleRecyclebin} openPanel={openRecyclebin} />
      )}

      {/* Image Gallery  */}
      <div>
        {project._id !== undefined && (
          <ImageGallery project_id={project._id} newImage={selectedImage} />
        )}
      </div>

      <SaveAll modal={routeParams?.reRoute} decision={decision} />
    </Container>
  );
};

ProjectDetail.propTypes = {
  setProject: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  project: state.project.project,
  loading: state.project.loading,
  profile: state.profile.profile,
  projectRole: state.profile.projectRole,
  routeParams: state.save.routeParams,
  unsavedChanges: state.save.unsavedChanges,
  assets: state.assets.assets,
});
export default connect(mapStateToProps, {
  reRoute,
  cancelReroute,
  cancelUnsavedChanges,
  logout,
  setProject,
  getProjectBaselines,
  applyProjectBaseline,
})(ProjectDetail);
