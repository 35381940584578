import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ModalBody,
  ModalHeader,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
} from 'reactstrap';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import CatalogRoleAssignment from './admin-components/CatalogRoleAssignment';
import ProjectRoleAssignment from './admin-components/ProjectRoleAssignment';

const AddUserRoleModal = ({ modal, toggle, data }) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Add Role to User</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <NavbarBrand>
            <NavbarToggler id='ProjectSelection'>
              <i className='fa fa-caret-down' />
            </NavbarToggler>
            Projects
          </NavbarBrand>

          <UncontrolledCollapse toggler='#ProjectSelection'>
            <ProjectRoleAssignment data={data} toggle={toggle} />
          </UncontrolledCollapse>

          <br />

          <NavbarBrand>
            <NavbarToggler id='ReftreeCatalogSelection'>
              <i className='fa fa-caret-down' />
            </NavbarToggler>
            Reftree Catalogues
          </NavbarBrand>

          <UncontrolledCollapse toggler='#ReftreeCatalogSelection'>
            <CatalogRoleAssignment data={data} toggle={toggle} reftree={true} />
          </UncontrolledCollapse>

          <NavbarBrand>
            <NavbarToggler id='ControlCatalogSelection'>
              <i className='fa fa-caret-down' />
            </NavbarToggler>
            Control Catalogues
          </NavbarBrand>

          <UncontrolledCollapse toggler='#ControlCatalogSelection'>
            <CatalogRoleAssignment
              data={data}
              toggle={toggle}
              reftree={false}
            />
          </UncontrolledCollapse>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

export default connect()(AddUserRoleModal);
