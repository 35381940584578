import React, { Fragment } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import {
  ImpactCategory,
  ProjectType,
  ReviewState,
  SearchType,
  SecurityGoal,
} from '../../constants/Enums';

/**
 * Analysis tree.
 *
 * Main page to show root nodes and subtrees.
 *
 *  @param index the index of the search criteria
 *  @param searchField the data of the search field(payload,type)
 *  @param changeSearch changes the search type for the specific field
 *  @param addField adds a new search field
 *  @param removeField removes the current field
 *  @param updateField updates a field's payload value
 *  @param updateUpperBound updates the upper bound of a number based search criteria
 *  @param updateLowerBound updates the lower bound of a number based search criteria
 *
 */
const SearchDropdowns = ({
  index,
  searchField,
  changeSearch,
  addField,
  removeField,
  updateField,
  updateUpperBound,
  updateLowerBound,
  projectType,
}) => {
  var round = { borderRadius: '20px' };
  var searchType =
    searchField.searchType === SearchType.SecurityGoal
      ? `${ProjectType[projectType].ThreatSecurityType} `
      : `${searchField.searchType} `;
  var searchTypeText =
    searchField?.searchType === undefined ? 'Select Type ' : searchType;
  var buttonStyle = {
    width: `${Math.max(150, 10 * searchTypeText.length + 5)}px`,
  };
  var tooltip = (targetId) => {
    return (
      <UncontrolledTooltip target={targetId}>
        There are two fields shown, the left one for the lowest and the right
        one for the highest range
      </UncontrolledTooltip>
    );
  };
  const selectValue = 'Select Value ';
  return (
    <InputGroup>
      {searchField?.searchType === SearchType.Impact ? (
        <>
          <UncontrolledButtonDropdown addonType='append' on>
            <DropdownToggle split outline className='search-sub-option'>
              {searchField?.payload === undefined
                ? selectValue
                : `${
                    ProjectType[projectType].ImpactLevelTypes[
                      searchField?.payload
                    ]
                  } `}
            </DropdownToggle>
            <DropdownMenu>
              {Object.entries(ProjectType[projectType].ImpactLevelTypes).map(
                (impactEntry) => {
                  return (
                    <Fragment>
                      <DropdownItem
                        onClick={() => updateField(index, impactEntry[0])}
                        className='search-sub-option'
                      >
                        {impactEntry[1]}
                      </DropdownItem>
                    </Fragment>
                  );
                }
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ) : searchField?.searchType === SearchType.ImpactCategory ? (
        <>
          <UncontrolledButtonDropdown addonType='append' on>
            <DropdownToggle split outline className='search-sub-option'>
              {searchField?.payload === undefined
                ? selectValue
                : `${ImpactCategory[searchField?.payload]?.name} `}
            </DropdownToggle>
            <DropdownMenu>
              {Object.entries(ImpactCategory).map((impactCategory) => {
                return (
                  <Fragment>
                    <DropdownItem
                      onClick={() => updateField(index, impactCategory[0])}
                      className='search-sub-option'
                    >
                      {impactCategory[1].name}
                    </DropdownItem>
                  </Fragment>
                );
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ) : searchField?.searchType === SearchType.SecurityGoal ? (
        <>
          <UncontrolledButtonDropdown addonType='append' on>
            <DropdownToggle split outline className='search-sub-option'>
              {searchField?.payload === undefined
                ? selectValue
                : `${SecurityGoal[searchField?.payload]?.name} `}
            </DropdownToggle>
            <DropdownMenu>
              {Object.entries(SecurityGoal).map((securityGoal) => {
                return (
                  <Fragment>
                    <DropdownItem
                      onClick={() => updateField(index, securityGoal[0])}
                      className='search-sub-option'
                    >
                      {securityGoal[1].name}
                    </DropdownItem>
                  </Fragment>
                );
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ) : searchField?.searchType === SearchType.Risk ? (
        <>
          <Input
            value={
              searchField?.payload?.['lower'] === undefined
                ? 0
                : searchField.payload['lower']
            }
            type='number'
            placeholder='Lower Bound'
            onChange={(event) => {
              updateLowerBound(index, event.target.value);
            }}
            className='input-field margin-top-zero'
          />
          <Input
            value={
              searchField?.payload?.['upper'] === undefined
                ? 0
                : searchField.payload['upper']
            }
            type='number'
            placeholder='Upper Bound'
            onChange={(event) => {
              updateUpperBound(index, event.target.value);
            }}
            className='input-field margin-top-zero'
          />
        </>
      ) : searchField?.searchType === SearchType.Likelihood ? (
        <>
          <Input
            min={0}
            max={1}
            step={0.1}
            value={
              searchField?.payload?.['lower'] === undefined
                ? 0
                : searchField.payload['lower']
            }
            type='number'
            placeholder='Lower Bound'
            onChange={(event) => {
              updateLowerBound(index, event.target.value);
            }}
            className='input-field margin-top-zero'
          />
          <Input
            invalid={
              searchField?.payload?.['lower'] > searchField?.payload?.['upper']
            }
            min={0}
            max={1}
            step={0.1}
            value={
              searchField?.payload?.['upper'] === undefined
                ? 0
                : searchField.payload['upper']
            }
            type='number'
            placeholder='Upper Bound'
            onChange={(event) => {
              updateUpperBound(index, event.target.value);
            }}
            className='input-field margin-top-zero'
          />
        </>
      ) : searchField?.searchType === SearchType.ReviewStatus ? (
        <UncontrolledButtonDropdown addonType='append' on>
          <DropdownToggle split outline className='search-sub-option'>
            {searchField?.payload === ReviewState.Reviewed ||
            searchField?.payload === ReviewState.Unreviewed
              ? `${searchField.payload} `
              : 'Select State '}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => updateField(index, ReviewState.Reviewed)}
              className='search-sub-option'
            >
              Reviewed
            </DropdownItem>
            <DropdownItem
              onClick={() => updateField(index, ReviewState.Unreviewed)}
            >
              Unreviewed
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ) : searchField?.searchType === SearchType.Id ? (
        <Input
          disabled={searchField?.searchType === undefined}
          value={searchField.payload}
          type='number'
          placeholder='Id'
          onChange={(event) => {
            updateField(index, event.target.value);
          }}
          className='input-field margin-top-zero'
        />
      ) : (
        <Input
          disabled={
            searchField?.searchType === undefined ||
            searchField?.searchType === SearchType.Exclamation
          }
          onChange={(event) => {
            updateField(index, event.target.value);
          }}
          value={searchField.payload === undefined ? '' : searchField?.payload}
          className='input-field margin-top-zero'
        />
      )}
      <InputGroupAddon addonType='append'></InputGroupAddon>
      <UncontrolledButtonDropdown addonType='append' on>
        <DropdownToggle
          split
          outline
          className='btn-add btn-attack-tree'
          style={buttonStyle}
        >
          {searchTypeText}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => changeSearch(index, SearchType.Name)}>
            {SearchType.Name}
          </DropdownItem>
          <DropdownItem onClick={() => changeSearch(index, SearchType.Id)}>
            {SearchType.Id}
          </DropdownItem>
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.ReviewStatus)}
          >
            {SearchType.ReviewStatus}
          </DropdownItem>
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.Likelihood)}
            id='likelihood'
          >
            {SearchType.Likelihood}
          </DropdownItem>
          {tooltip('likelihood')}
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.Risk)}
            id='risk'
          >
            {SearchType.Risk}
          </DropdownItem>
          {tooltip('risk')}
          <DropdownItem onClick={() => changeSearch(index, SearchType.Impact)}>
            {SearchType.Impact}
          </DropdownItem>
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.SecurityGoal)}
          >
            {ProjectType[projectType]?.ThreatSecurityType}
          </DropdownItem>
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.ImpactCategory)}
          >
            {SearchType.ImpactCategory}
          </DropdownItem>
          <DropdownItem
            onClick={() => changeSearch(index, SearchType.Exclamation)}
          >
            {SearchType.Exclamation}
          </DropdownItem>
        </DropdownMenu>
        {index === 0 ? (
          <Button
            className='plus-minus plus'
            outline
            onClick={() => addField()}
            style={round}
          >
            +
          </Button>
        ) : (
          <Button
            className='plus-minus minus'
            outline
            onClick={() => removeField(index)}
            style={round}
          >
            -
          </Button>
        )}
      </UncontrolledButtonDropdown>
      {searchField?.payload?.['lower'] > searchField?.payload?.['upper'] && (
        <FormFeedback style={{ marginLeft: '5px' }}>
          Lower value is higher than upper value
        </FormFeedback>
      )}
    </InputGroup>
  );
};

export default SearchDropdowns;
