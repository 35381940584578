import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { capitalizeStart } from '../../../helpers/genericHelper';

/**
 * Risk tooltip
 *
 * A tooltip component for risks
 *
 *  @param riskObject the risk object of the associated threat (scenario)
 *  @param identifier used to identify the unique id for the tooltip
 *
 */
const RiskTooltips = ({ riskObject, identifier }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Tooltip
        placement='left'
        isOpen={tooltipOpen}
        target={`RiskTooltip-${identifier}`}
        toggle={toggle}
      >
        {Object.entries(riskObject).map(
          ([riskCategory, riskItem]) =>
            riskItem && (
              <Fragment>
                <div style={{ fontWeight: 'bold' }}>
                  {capitalizeStart(riskCategory)}:
                </div>
                All control risk: {riskItem.all_controls}
                <br />
                No control risk: {riskItem.no_controls}
                <br />
                Proposed control risk: {riskItem.proposed_controls}
                <br />
                Implemented control risk: {riskItem.implemented_controls}
                <br />
              </Fragment>
            )
        )}
      </Tooltip>
    </span>
  );
};

export default RiskTooltips;
