import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { ProjectRole } from '../../../constants/Enums';
import { btnEdit } from '../../../constants/Style';
import { updateProject } from '../../../redux/actions/projects';
import {
  cancelUnsavedChanges,
  unsavedChanges,
} from '../../../redux/actions/save';
import store from '../../../redux/store';

/**
 * Project Scope Panel
 *
 * Panel for displaying and editing project scope
 *
 * @param toggle: triggers/toggles the modal to close/open
 * @param openPanel: checks if panel is open or closed to change caret
 * @param data: parameter for the specific project in the project details page
 * @param updateProject: redux action to update a project
 * @param profile: fetches profile of the current user in the system
 */

const ScopePanel = ({
  toggle,
  openPanel,
  data,
  updateProject,
  profile,
  save,
  unsavedChanges,
  projectRole,
}) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    if (editMode) {
      setFormData({
        ...formData,
        scope: data?.scope,
      });

      setReviewData({
        ...reviewData,
        scope_review_state: data?.scope_review_state,
      });
    }
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    scope: '',
  });
  const [reviewData, setReviewData] = useState({
    scope_review_state: undefined,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      scope: data?.scope,
    });
    setReviewData({
      ...reviewData,
      scope_review_state: data?.scope_review_state,
    });
  }, [data]);

  const [fieldId, setFieldId] = useState(null);
  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      const id = unsavedChanges();
      setFieldId(id);
    }
  };

  const onChecked = (event) => {
    const reviewPatch = { scope_review_state: event.target.checked };
    updateProject(data._id, reviewPatch);
  };

  const { dispatch } = store;
  const onSubmit = async () => {
    updateProject(data._id, formData);
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };

  useEffect(() => {
    if (save && editMode) {
      data?.participants
        ?.find((member) => parseInt(member.user_id) === parseInt(profile?.id))
        ?.role.includes('reviewer')
        ? updateProject(data._id, reviewData)
        : updateProject(data._id, formData);

      changeMode();
      setFieldId(null);
    }
  }, [save]);

  return (
    <div>
      <CardHeader className='bold'>
        <div style={{ width: '150px' }}>
          Project Scope
          {data?.baseline === undefined &&
          !editMode &&
          (data?.participants
            ?.find(
              (member) => parseInt(member.user_id) === parseInt(profile?.id)
            )
            ?.role.includes('owner') ||
            data?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor')) ? (
            <Button
              className='float-right'
              onClick={() => changeMode()}
              disabled={projectRole === ProjectRole.Reader.value}
              style={btnEdit}
            >
              <i className='fa fa-edit' />
            </Button>
          ) : null}
        </div>
      </CardHeader>

      <Card>
        <CardBody style={{ marginTop: '-25px' }}>
          {editMode ? (
            <div>
              <Row>
                <Col lg={8}>
                  <textarea
                    name='scope'
                    onChange={onChange}
                    value={formData.scope}
                    className='description-edit'
                  />
                </Col>
                <br />
              </Row>
              <br />
              <div style={{ marginLeft: '520px', marginTop: '-25px' }}>
                <td className='no-border'>
                  <Button
                    className='btn-add'
                    onClick={() => {
                      onSubmit();
                      changeMode();
                    }}
                  >
                    Save
                  </Button>
                </td>
                <td className='no-border'>
                  <Button
                    className='btn-danger'
                    onClick={() => {
                      changeMode();
                      dispatch(cancelUnsavedChanges(fieldId));
                      setFieldId(null);
                    }}
                  >
                    Cancel
                  </Button>
                </td>
              </div>
            </div>
          ) : (
            <div>
              {' '}
              <Row>
                <Col lg={8}>
                  <div className='description-view'>{formData.scope}</div>
                </Col>
              </Row>
            </div>
          )}

          <Fragment>
            <Col lg={4}>
              <div className='float-right'>
                <Input
                  name='scope_review_state'
                  disabled={projectRole !== ProjectRole.Reviewer.value}
                  type='checkbox'
                  checked={data?.scope_review_state}
                  onClick={onChecked}
                />
                <Label>Reviewed</Label>
              </div>
            </Col>
          </Fragment>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  projectRole: state.profile.projectRole,
});

export default connect(mapStateToProps, { updateProject, unsavedChanges })(
  ScopePanel
);
