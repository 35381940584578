import { faBan, faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { NewsAction, ProjectRole } from '../../../constants/Enums';
import { LocalTimeWithoutSeconds } from '../../../constants/StringConstants';
import { getControlCatalogs } from '../../../redux/actions/profile';
import { updateNews } from '../../../redux/actions/projects';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import CustomDataTableStyles from '../../layout/DataTableStyles';

/**
 * Project News Panel
 *
 * TODO: To implement news feature
 * TODO: Ask Anna for a mock up sample of this feature
 * TODO: Add functionalities and redirect link to Action Buttons
 *
 * @param toggle: triggers/toggles the modal to close/open
 * @param openPanel: checks if panel is open or closed to change caret
 */

const NewsPanel = ({
  toggle,
  openPanel,
  project,
  projectNews,
  userControlCatalogs,
  userRefTreeCatalogs,
  projectRole,
  getControlCatalogs,
  updateNews,
}) => {
  const history = useHistory();

  //Columns if user has not the role of as an owner
  const [news, setNews] = useState({});

  useEffect(() => {
    if (
      userControlCatalogs === undefined &&
      userRefTreeCatalogs === undefined
    ) {
      getControlCatalogs(project._id);
    }
  }, []);
  const columns = useMemo(() => [
    {
      name: 'News ID',
      selector: 'news_id',
      sortable: true,
      width: '160px',
    },

    {
      name: 'Name',
      selector: 'object_name',
      sortable: true,
      align: 'left',
    },
    {
      name: 'Modified Date',
      sortable: true,
      selector: 'timestamp',
      cell: (row) => LocalTimeWithoutSeconds(row.timestamp),
      width: '200px',
    },
    {
      name: 'Entry type',
      selector: 'object_type',
      sortable: true,
      width: '160px',
    },
    {
      name: 'Processing Status',
      selector: 'status',
      sortable: true,
    },
    {
      name: 'Processed by',
      selector: 'user_name',
      sortable: true,
    },
    {
      cell: (row) => (
        <ButtonGroup>
          {/* TODO: redirect to entry details page */}
          <Button
            onClick={() => {
              let catalogs = userRefTreeCatalogs.concat(userControlCatalogs);
              let catalog = catalogs.find((reftree) =>
                reftree.children?.some(
                  (child) => parseInt(child) === parseInt(row.object_id)
                )
              );
              history.push(
                `/catalogues/${catalog?._id}/entry/${row.object_id}`
              );
            }}
            id={`viewButton${row?.news_id}`}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <UncontrolledTooltip target={`viewButton${row?.news_id}`}>
            View
          </UncontrolledTooltip>
          {(projectRole === ProjectRole.Editor.value ||
            projectRole === ProjectRole.Owner.value) && (
            <Fragment>
              {/* TODO: Apply changes to project */}
              <Button
                onClick={() => {
                  applyToggle();
                  setNews(row);
                }}
                className='btn btn-add'
                id={`applyButton${row?.news_id}`}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <UncontrolledTooltip target={`applyButton${row?.news_id}`}>
                Apply
              </UncontrolledTooltip>
              <DraggableModal isOpen={applyModal} toggle={applyToggle}>
                <ModalHeader>
                  <div className='confirm-modal-header'>Apply Changes</div>
                </ModalHeader>
                <div className='confirm-modal-line' />
                <ModalBody className='confirm-modal-body'>
                  Warning! The change will be applied to each instance of the
                  entry of the global catalogue within this project. Are you
                  sure you want to proceed?
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{ marginLeft: '270px' }}
                    className='btn-confirm-delete'
                    onClick={() =>
                      updateNews(project._id, news.news_id, NewsAction.APPLY)
                    }
                  >
                    Ok
                  </Button>
                  <Button
                    style={{ marginRight: '25px' }}
                    className='btn-cancel-delete'
                    onClick={applyToggle}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </DraggableModal>

              {/* TODO: Ignore changes to project */}
              <Button
                onClick={() => {
                  ignoreToggle();
                  setNews(row);
                }}
                id={`ignoreButton${row?.news_id}`}
                className='btn-danger'
              >
                <FontAwesomeIcon icon={faBan} />
              </Button>
              <UncontrolledTooltip target={`ignoreButton${row?.news_id}`}>
                Ignore
              </UncontrolledTooltip>
              <DraggableModal isOpen={ignoreModal} toggle={ignoreToggle}>
                <ModalHeader>
                  <div className='confirm-modal-header'>Ignore Changes</div>
                </ModalHeader>
                <div className='confirm-modal-line' />
                <ModalBody className='confirm-modal-body'>
                  Warning! If you ignore the news the entry of the global
                  catalogue {news.name} used in this project will no longer be
                  associated with the global catalogue. Are you sure you want to
                  proceed?
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{ marginLeft: '270px' }}
                    className='btn-confirm-delete'
                    onClick={() =>
                      updateNews(project._id, news.news_id, NewsAction.IGNORE)
                    }
                  >
                    Ok
                  </Button>
                  <Button
                    style={{ marginRight: '25px' }}
                    className='btn-cancel-delete'
                    onClick={ignoreToggle}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </DraggableModal>
            </Fragment>
          )}
        </ButtonGroup>
      ),
    },
  ]);

  //Sets toggle for apply modal
  const [applyModal, setApplyModal] = useState(false);
  const applyToggle = () => {
    setApplyModal(!applyModal);
  };

  //Sets toggle for ignore modal
  const [ignoreModal, setIgnoreModal] = useState(false);
  const ignoreToggle = () => {
    setIgnoreModal(!ignoreModal);
  };
  return (
    <div>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        {'  '}News
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            <DataTable
              defaultSortField='timestamp'
              defaultSortAsc={false}
              data={projectNews}
              columns={columns}
              pagination
              customStyles={CustomDataTableStyles}
            />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projectNews: state.project.news,
  projectRole: state.profile.projectRole,
  userControlCatalogs: state.profile.userControlCatalogs,
  userRefTreeCatalogs: state.profile.userRefTreeCatalogs,
});
export default connect(mapStateToProps, { getControlCatalogs, updateNews })(
  NewsPanel
);
