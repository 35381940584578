import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, Container } from 'reactstrap';
import { getControlCatalogs } from '../../redux/actions/profile';
import { getProjects } from '../../redux/actions/projects';
import AddUserRoleModal from './AddUserRoleModal';
import CatalogTable from './admin-components/CatalogTable';
import ProjectTable from './admin-components/ProjectTable';

/**
 * Expanded user management component
 *
 * This the Expanded area when clicking the user in the user management page
 * Provides role/project information about the selected user
 *
 *  @param data redux state mapped to props, value to show sidebar or not
 *  @param projects redux state mapped to props, list of projects
 *  @param setAlert redux action mapped to props, used to display alerts
 *
 */
const ExpandedUserManagementComponent = ({ data }) => {
  //Sets Modal for adding new members
  const [addModal, setAddModal] = useState(false);
  const addToggle = () => setAddModal(!addModal);

  return (
    <Container fluid='xs'>
      <br />
      <div className='card-actions float-right'>
        <Button className='btn-add' onClick={() => addToggle()}>
          <FontAwesomeIcon icon={faPlusSquare} /> Add Role for User
        </Button>
        <AddUserRoleModal modal={addModal} toggle={addToggle} data={data} />
      </div>
      <CardBody>
        <ProjectTable data={data} />

        <br />

        <CatalogTable data={data} />
      </CardBody>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getProjects,
  getControlCatalogs,
})(ExpandedUserManagementComponent);
