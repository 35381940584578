import {
  NO_SIDEBAR,
  SET_SELECTED_SIDEBAR_PATH,
  SIDEBAR_VISIBILITY_HIDE,
  SIDEBAR_VISIBILITY_SHOW,
  SIDEBAR_VISIBILITY_TOGGLE,
  WITH_SIDEBAR,
} from './types';

export const toggleSidebar = () => async (dispatch) => {
  dispatch({
    type: SIDEBAR_VISIBILITY_TOGGLE,
  });
};

export const showSidebar = () => async (dispatch) => {
  dispatch({
    type: SIDEBAR_VISIBILITY_SHOW,
  });
};

export function hideSidebar() {
  return {
    type: SIDEBAR_VISIBILITY_HIDE,
  };
}

export const noSidebar = () => async (dispatch) => {
  dispatch({
    type: NO_SIDEBAR,
  });
};

export const withSidebar = () => async (dispatch) => {
  dispatch({
    type: WITH_SIDEBAR,
  });
};

export const setSidebarPath = (path) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_SIDEBAR_PATH,
    payload: path,
  });
};
