import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { removeLicenseAlert } from '../../redux/actions/licenseAlert';

/**
 *
 * Alert component
 * Used to create alerts
 *
 *  @param alerts redux store value mapped to state, available user alerts
 *  @param removeLicenseAlert redux action to remove alert
 */
const LicenseAlert = ({ profile, modal, removeLicenseAlert, expiryDate }) => {
  return (
    <Container fluid='md'>
      <Modal isOpen={modal}>
        <ModalHeader>License expiration</ModalHeader>
        <ModalBody>
          {Date.now - expiryDate <= 0
            ? 'Your ISAT license is expired. All users have only reader role.'
            : ''}
          <Button
            onClick={() => {
              removeLicenseAlert();
            }}
          >
            Understood
          </Button>
          {profile?.id === 0 && Date.now - expiryDate <= 0 ? (
            <Button
              onClick={() => {
                removeLicenseAlert();
              }}
            >
              Upload license
            </Button>
          ) : (
            <Button
              onClick={() => {
                removeLicenseAlert();
              }}
            >
              Extend license
            </Button>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  modal: state.licenseAlert.modal,
  expiryDate: state.licenseAlert.expiryDate,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, { removeLicenseAlert })(LicenseAlert);
