import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { LogType } from '../../constants/Enums';
import { Cancel } from '../../constants/StringConstants';
import {
  deleteRecycleBin,
  getAdminDeletionLogs,
  getCatalogDeletionLogs,
  getProjectDeletionLogs,
  removeDeletedItem,
  restoreDeletedItem,
} from '../../redux/actions/logging';
import { setProject } from '../../redux/actions/projects';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import CustomDataTableStyles from '../layout/DataTableStyles';
import RecycleBinColumns from './admin-components/RecycleBinColumns';
import RecycleBinExpandedComponent from './RecycleBinExpandedComponent';
import RecycleBinSearchComponent from './RecycleBinSearchComponent';

//This file shows the recycle bin page where deleted objects
// can be restored or deleted permanently

const DeletionAction = {
  restore: 'restore',
  delete: 'delete',
  empty: 'empty',
};
const ModalConfirmation = ({
  header,
  body,
  buttonConfirm,
  toggle,
  modal,
  modalAction,
  actionType,
  actionData,
  restoreItem,
  deleteItem,
  emptyBin,
}) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>{header}</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>{body}</Label>
          <div>
            {' '}
            <ButtonGroup>
              <Button
                className={
                  buttonConfirm === 'Restore'
                    ? 'btn-add'
                    : 'btn-confirm-delete mr-15'
                }
                name={buttonConfirm}
                onClick={() => {
                  switch (modalAction) {
                    case DeletionAction.delete:
                      deleteItem(actionData);
                      break;
                    case DeletionAction.restore:
                      restoreItem(actionData);
                      break;
                    case DeletionAction.empty:
                      emptyBin(actionData, actionType);
                      break;

                    default:
                      break;
                  }
                  toggle();
                }}
              >
                {buttonConfirm}
              </Button>
              <Button
                className={
                  buttonConfirm === 'Restore'
                    ? 'btn-danger'
                    : 'btn-cancel-delete ml-15'
                }
                name={Cancel}
                onClick={toggle}
              >
                {Cancel}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const RecycleBinPanel = ({
  projectDeletionLogs,
  catalogDeletionLogs,
  projectRecycleBinRetriever,
  catalogRecycleBinRetriever,
  restoreDeletedItem,
  project,
  catalog,
  deleteRecycleBin,
  removeDeletedItem,
  catalogRecycleBin = false,
  toggle,
  openPanel,
}) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [buttonConfirm, setButtonConfirm] = useState('');
  const [actionData, setActionData] = useState(undefined);
  const [actionType, setActionType] = useState(undefined);
  const [modalAction, setModalAction] = useState(undefined);

  const restore = (data) => {
    setHeader('Restore Object');
    setBody(
      'Are you sure you want to restore this object to its original location?'
    );
    setButtonConfirm('Restore');
    setActionData(data);
    setModalAction(DeletionAction.restore);
    toggleModal();
  };

  const remove = (data) => {
    setHeader('Remove Permanently');
    setBody(
      'Warning! The object will be permanently deleted and cannot be restored again. Are you sure you want to proceed?'
    );
    setActionData(data);
    setModalAction(DeletionAction.delete);
    setButtonConfirm('Remove');
    toggleModal();
  };

  const emptyRecycleBin = (logType) => {
    setModalAction(DeletionAction.empty);
    setActionType(logType);
    switch (logType) {
      case LogType.Catalog:
        setActionData(catalog);
        break;
      case LogType.Project:
        setActionData(project);
        break;
      default:
        break;
    }
    setHeader('Empty Recycle Bin');
    setBody(
      'Warning! All objects in this recycle bin will be permanently deleted and cannot be restored again. Are you sure you want to proceed?'
    );
    setButtonConfirm('Remove');
    toggleModal();
  };

  const [filteredProjectLogs, setFilteredProjectLogs] = useState(undefined);
  const [filteredCatalogLogs, setFilteredCatalogLogs] = useState(undefined);

  useEffect(() => {
    catalogRecycleBin
      ? catalog && catalogRecycleBinRetriever(catalog)
      : project && projectRecycleBinRetriever(project);
  }, [project, catalog]);

  return (
    <Fragment>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        Recycle Bin
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            <Button
              disabled={
                catalogRecycleBin
                  ? catalogDeletionLogs?.length === 0
                  : projectDeletionLogs?.length === 0
              }
              className={'float-right'}
              onClick={() =>
                emptyRecycleBin(
                  catalogRecycleBin ? LogType.Catalog : LogType.Project
                )
              }
            >
              Empty Recycle Bin
            </Button>
            <RecycleBinSearchComponent
              id='project'
              data={
                catalogRecycleBin ? catalogDeletionLogs : projectDeletionLogs
              }
              filteredData={
                catalogRecycleBin ? filteredCatalogLogs : filteredProjectLogs
              }
              setFilteredData={
                catalogRecycleBin
                  ? setFilteredCatalogLogs
                  : setFilteredProjectLogs
              }
            />
            <DataTable
              defaultSortField='creation_date'
              defaultSortAsc={false}
              columns={RecycleBinColumns(restore, remove)}
              data={
                catalogRecycleBin ? filteredCatalogLogs : filteredProjectLogs
              }
              persistTableHead
              customStyles={CustomDataTableStyles}
              pagination
              expandableRowsComponent={
                <RecycleBinExpandedComponent
                  data={
                    catalogRecycleBin
                      ? filteredCatalogLogs
                      : filteredProjectLogs
                  }
                />
              }
              expandOnRowClicked
              expandableRows
            />
          </CardBody>
        </Card>
      </Collapse>
      <ModalConfirmation
        header={header}
        body={body}
        modal={modal}
        buttonConfirm={buttonConfirm}
        toggle={toggleModal}
        modalAction={modalAction}
        actionType={actionType}
        actionData={actionData}
        restoreItem={restoreDeletedItem}
        emptyBin={deleteRecycleBin}
        deleteItem={removeDeletedItem}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  adminDeletionLogs: state.logging.adminDeletionLogs,
  projectDeletionLogs: state.logging.projectDeletionLogs,
  catalogDeletionLogs: state.logging.catalogDeletionLogs,
  project: state.project.project,
  catalog: state.catalog.catalog,
  profile: state.profile.profile,
  loading: state.project.loading,
});

export default connect(mapStateToProps, {
  getAdminDeletionLogs,
  projectRecycleBinRetriever: getProjectDeletionLogs,
  catalogRecycleBinRetriever: getCatalogDeletionLogs,
  restoreDeletedItem,
  setProject,
  deleteRecycleBin,
  removeDeletedItem,
})(RecycleBinPanel);
