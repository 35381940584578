import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavbarBrand,
  NavbarToggler,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  CopyMoveType,
  EntityType,
  OwnerType,
  ProjectRole,
  RefTreeActionType,
  RefTreeModType,
} from '../../constants/Enums';
import { GenerateNodeId } from '../../constants/StringConstants';
import {
  SetRefTreeParams,
  togglePlacementReviews,
  toggleReview,
} from '../../redux/actions/analysistree';
import { setEntity } from '../../redux/actions/entities';
import NodeContextMenu from './NodeContextMenu';
import AssetNode from './nodetypes/AssetNode';
import ControlNode from './nodetypes/ControlNode';
import {
  CollapseOffsetX,
  CollapseOffsetY,
  cutNodeColor,
  highlightNodeColor,
} from './nodetypes/nodeObjects/NodeConstants';
import { EditConfirm } from './nodetypes/nodeObjects/NodeDetailsPage';
import ProjectNode from './nodetypes/ProjectNode';
import ThreatNode from './nodetypes/ThreatNode';
import VulnerabilityNode from './nodetypes/VulnerabilityNode';
import RefTreeModificationModal from './RefTreeModificationModal';

/**
 * Node Context Menu
 *
 * A wrapper component used to apply context menu for attack tree nodes
 *
 *  @param entity the selected entity data
 *  @param className the classname assigned to the label component
 *  @param nodeData the current node data
 *  @param searchIds redux store mapped to state, Ids used for search in the attack tree
 *  @param setEntity redux action mapped to state, used to set entity in store
 *  @param SelectedSubtree redux store mapped to state, the type used for copy/cut functions of trees
 *  @param SelectedType redux action mapped to state, used to add subtrees
 *  @param project redux store mapped to state, the current project
 *
 */
const NodeLabelComponent = ({
  entity,
  className,
  nodeData,
  searchIds,
  setEntity,
  SelectedSubtree,
  SelectedType,
  project,
  projectRole,
  reftrees,
  toggleReview,
  catalogRole,
  SetRefTreeParams,
  disableRefTreeWarnings,
  collapsedReviews,
  togglePlacementReviews,
  showReferenceTrees,
  readOnly = false,
}) => {
  const history = useHistory();
  const nodeCollapse = nodeData?._collapsed;
  const { projectId, refTreeId, catalogId } = useParams();
  const reviewed = nodeData?.review_state;
  const query = new URLSearchParams(window.location.search);
  const rootId = query.get('rootId');
  const treeNodeDetails = '/analysisTree/node-details/';
  const openNodeDetail = async (nodeData) => {
    //Redirects to project details instead if project node
    if (nodeData.projectType !== undefined) {
      history.push(`/${project._id}/project`);
      return;
    }

    await setEntity(nodeData);

    let url = '';
    if (rootId) {
      url = `/${project._id}${treeNodeDetails}${nodeData._id}?rootId=${rootId}&parentId=${nodeData.parent?._id}`;
    } else if (refTreeId) {
      url = `/catalogues/${catalogId}/reftree/${refTreeId}/node-details/${nodeData?._id}?parentId=${nodeData.parent?._id}`;
    } else {
      url = `/${project?._id}${treeNodeDetails}${nodeData?._id}'?parentId=${nodeData.parent?._id}`;
    }
    if (
      (projectId &&
        projectRole !== ProjectRole.Owner.value &&
        projectRole !== ProjectRole.Editor.value &&
        projectRole !== ProjectRole.Demo.value) ||
      (catalogId &&
        catalogRole !== ProjectRole.Owner.value &&
        catalogRole !== ProjectRole.Editor.value &&
        catalogRole !== ProjectRole.Demo.value)
    ) {
      history.push(url);
    } else {
      history.push(`${url}&edit=true`);
    }
  };

  // this is for when clicking the middle mouse button to be implemented.
  const mouseDownHandler = (event) => {
    if (readOnly) {
      return;
    }
    if (event.button === 1) {
      if (nodeData?._id === undefined) {
        window.open(`${window.location.origin}/${project._id}/project`);
      } else if (
        projectRole !== ProjectRole.Owner.value &&
        projectRole !== ProjectRole.Editor.value &&
        projectRole !== ProjectRole.Demo.value
      ) {
        window.open(
          `${window.location.origin}/${project._id}${treeNodeDetails}${nodeData._id}`
        );
      } else {
        window.open(
          `${window.location.origin}/${project._id}${treeNodeDetails}${nodeData._id}?edit=true'`
        );
      }
    }
  };

  useEffect(() => {
    if (nodeData?.reftree_placement_review_state !== undefined) {
      forceRender();
    }
  }, [showReferenceTrees]);

  //Used to trigger force renders
  const [, updateState] = useState();
  const forceRender = useCallback(() => updateState({}), []);
  const entityType = nodeData?.entity_type;
  const operator = nodeData?.operator;
  var filt = false;
  if (searchIds?.length <= 0) {
    if (parseInt(entity?._id) === parseInt(nodeData?._id)) {
      filt = true;
    }
  } else {
    filt = searchIds?.includes(nodeData._id);
  }
  const filtered = filt;

  const RefTreeName = () => {
    const currentReftree = reftrees?.find(
      (tree) =>
        tree._id === nodeData?.owner?.id &&
        nodeData?.owner?.owner_type === OwnerType.RefTree
    );

    const backgroundColor =
      nodeData.reference_type === 'Reftree' ? '#28a745' : '#155824';

    if (currentReftree !== undefined) {
      return (
        <Fragment>
          <div
            id={`reftreeNameTooltip${GenerateNodeId(
              nodeData._id,
              nodeData?.index
            )}`}
            style={{
              marginLeft: '-30px',
              width: '407px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              padding: '0px 5px',
              paddingBottom: '0px',
              background: backgroundColor,
              color: 'white',
              borderRadius: '5px',
              fontFamily: 'inherit',
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: '3px',
            }}
          >
            {currentReftree.name}
          </div>

          <UncontrolledTooltip
            target={`reftreeNameTooltip${GenerateNodeId(
              nodeData._id,
              nodeData?.index
            )}`}
          >
            {currentReftree.name}
          </UncontrolledTooltip>
        </Fragment>
      );
    } else {
      return null;
    }
  };
  var btnLeftDistance = nodeData?.children?.length === 1 ? 87 : 80;

  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const toggleEditConfim = () => setEditConfirmModal(!editConfirmModal);
  const [refTreeModType, setModType] = useState(undefined);
  const [refTreeNextToggle] = useState(undefined);

  const [refTreeModWarningModal, openRefTreeModWarningModal] = useState(false);
  const toggleRefTreeModWarningModal = () =>
    openRefTreeModWarningModal((prevState) => !prevState);

  return (
    <Container fluid>
      <NodeContextMenu nodeData={nodeData} readOnly={readOnly}>
        <Col fluid={true}>
          {nodeData?.owner?.owner_type === OwnerType.RefTree && <RefTreeName />}
          <Card
            //If node is cut into clipboard, marks it grey, then checks if highlighted then marks as teal
            style={{
              // width: NodeWidth,
              width:
                nodeData?.owner?.owner_type === OwnerType.RefTree
                  ? '409px'
                  : '400px',
              marginLeft: '-30px',
              borderRadius: '10px',
              borderWidth:
                nodeData?.owner?.owner_type === OwnerType.RefTree && 'thick',
              backgroundColor:
                SelectedType === CopyMoveType.Move &&
                SelectedSubtree._id === nodeData?._id
                  ? cutNodeColor
                  : filtered
                  ? highlightNodeColor
                  : null,
            }}
            className={
              nodeData?.owner?.owner_type === OwnerType.RefTree
                ? 'border-dark'
                : 'border-secondary'
            }
          >
            <CardBody
              style={{
                padding: '5px',
                background: filtered
                  ? highlightNodeColor
                  : nodeData.entity_type === 'control'
                  ? 'rgb(150, 250, 190)'
                  : 'none',
              }}
              onDoubleClick={() => {
                if (readOnly) {
                  return;
                }
                if (nodeData?.owner?.owner_type === OwnerType.RefTree) {
                  if (disableRefTreeWarnings) {
                    SetRefTreeParams(
                      RefTreeModType.Editing,
                      RefTreeActionType.Edit,
                      nodeData
                    );
                    openNodeDetail(nodeData);
                    return;
                  }
                  if (
                    nodeData?.parent?.owner?.owner_type !== OwnerType.RefTree &&
                    nodeData.entity_type === 'control'
                  ) {
                    toggleEditConfim();
                  } else {
                    setModType(RefTreeModType.Editing);
                    toggleRefTreeModWarningModal();
                  }
                } else {
                  openNodeDetail(nodeData);
                }
              }}
              onMouseUp={mouseDownHandler}
            >
              <Container fluid>
                {/* Differentiates kind of node to be used */}
                {nodeData.depth === 0 && entityType === undefined && (
                  <ProjectNode nodeData={nodeData} />
                )}
                {entityType === EntityType.asset && (
                  <AssetNode nodeData={nodeData} />
                )}

                {entityType === EntityType.threat && (
                  <ThreatNode nodeData={nodeData} />
                )}

                {entityType === EntityType.vulnerability && (
                  <VulnerabilityNode nodeData={nodeData} />
                )}

                {entityType === EntityType.control && (
                  <ControlNode nodeData={nodeData} readOnly={readOnly} />
                )}
                {nodeData?._id !== undefined && (
                  <Fragment>
                    {nodeData?.reftree_placement_review_state?.length > 0 && (
                      <Row>
                        <NavbarBrand
                          className='mr-auto placement'
                          onClick={togglePlacementReviews}
                        >
                          <font style={{ marginLeft: '-10px' }}>
                            Review Placement
                          </font>
                          <NavbarToggler
                            className='mr-2'
                            style={{ transform: 'scale(0.8)' }}
                          >
                            <i className='fas fa-bars' />
                          </NavbarToggler>
                        </NavbarBrand>
                        <Collapse isOpen={!collapsedReviews}>
                          {nodeData.reftree_placement_review_state.map(
                            (placementReview) => {
                              return (
                                <Fragment>
                                  <FormGroup check>
                                    <Label check className='placement'>
                                      <Input
                                        disabled={
                                          projectRole !==
                                            ProjectRole.Reviewer.value ||
                                          readOnly
                                        }
                                        type='checkbox'
                                        checked={placementReview.review_state}
                                        onClick={() => {
                                          toggleReview(
                                            nodeData,
                                            placementReview.starting_node_id
                                          );
                                        }}
                                      />
                                      Placement: ID#
                                      {placementReview.starting_node_id}
                                    </Label>
                                  </FormGroup>
                                </Fragment>
                              );
                            }
                          )}
                        </Collapse>
                      </Row>
                    )}
                    <Row className='float-right'>
                      <Form inline>
                        <FormGroup check>
                          <Label
                            style={{ color: 'black', fontSize: '11px' }}
                            check
                          >
                            <Input
                              disabled={
                                (catalogRole !== ProjectRole.Reviewer.value &&
                                  projectRole !== ProjectRole.Reviewer.value) ||
                                readOnly
                              }
                              type='checkbox'
                              checked={reviewed}
                              onClick={() => {
                                toggleReview(nodeData);
                              }}
                            />
                            Reviewed
                          </Label>
                        </FormGroup>
                      </Form>
                    </Row>
                  </Fragment>
                )}
              </Container>
            </CardBody>
          </Card>
        </Col>
        <div
          style={{
            position: 'absolute',
            top: CollapseOffsetY,
            left: CollapseOffsetX + (nodeCollapse ? 65 : btnLeftDistance),
            transform: 'translate(-50%, -55%)',
          }}
        >
          {nodeData._children && !readOnly && (
            <>
              <Button
                style={{
                  focus: 'none',
                  padding: '0px',
                  marginLeft:
                    nodeCollapse &&
                    nodeData?.owner?.owner_type === OwnerType.RefTree
                      ? '13px'
                      : '0px',
                  height: '20px',
                  width: '17px',
                  background: 'white',
                  color: 'rgb(20,20,70)',
                  transform: 'scale(1.5)',
                  border: 'none',
                }}
                name={'.CollapseButton'}
                id={`collapseButton${GenerateNodeId(
                  nodeData._id,
                  nodeData?.index
                )}`}
                onClick={forceRender}
              >
                <i
                  className={
                    !nodeCollapse ? 'fa fa-minus-square' : 'fa fa-plus-square'
                  }
                />
                <UncontrolledTooltip
                  placement='right'
                  target={`collapseButton${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {!nodeCollapse
                    ? 'Collapse all children'
                    : 'Show all direct children'}
                </UncontrolledTooltip>
              </Button>
              {operator && nodeData?.children?.length > 1 && !nodeCollapse && (
                <Badge
                  color='light'
                  pill
                  style={{
                    width: '40px',
                    marginLeft: '-10px',
                    transform: 'translateY(3px)',
                  }}
                >
                  {operator}
                </Badge>
              )}
            </>
          )}
        </div>
      </NodeContextMenu>

      <RefTreeModificationModal
        type={refTreeModType}
        modal={refTreeModWarningModal}
        toggle={toggleRefTreeModWarningModal}
        nextToggle={refTreeNextToggle}
        nodeData={nodeData}
        fromTree={rootId || refTreeId ? false : true}
        confirmEdit={() => openNodeDetail(nodeData)}
      />

      <EditConfirm
        toggle={toggleEditConfim}
        modal={editConfirmModal}
        confirmEdit={() => openNodeDetail(nodeData)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  searchIds: state.analysistree.searchIds,
  entity: state.entities.entity,
  SelectedSubtree: state.analysistree.SelectedSubtree,
  SelectedType: state.analysistree.SelectedType,
  project: state.project.project,
  projectRole: state.profile.projectRole,
  reftrees: state.profile.userReftrees,
  catalogRole: state.catalog.catalogRole,
  disableRefTreeWarnings: state.analysistree.disableRefTreeWarnings,
  collapsedReviews: state.analysistree.collapsedReviews,
  showReferenceTrees: state.analysistree.showReferenceTrees,
});

export default connect(mapStateToProps, {
  setEntity,
  toggleReview,
  SetRefTreeParams,
  togglePlacementReviews,
})(NodeLabelComponent);
