import React, { useEffect, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { setProject, updateProject } from '../../../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DraggableModal from '../../../entities/EntityModals/DraggableModal';
import { RiskDecision } from '../../../../constants/Enums';
import { InputTemplate } from '../../../entities/EntityModals/InputTemplate';
import axiosMethods from '../../../../Axios/axios';
import { capitalizeStart } from '../../../helpers/genericHelper';

export const GoalModal = ({ modal, toggle, type, riskDecision, controls, save, update, riskControl, updateIndex = null }) => {
  const [text, setText] = useState('')
  const [link, setLink] = useState(-1)
  const [riskDecisionType, setRiskDecisionType] = useState('')

  const handleChangeText = (event) => {
    setText(event.target.value)
  }

  const handleChangeLink = (event) => {
    setLink(event.target.value)
  }

  const handletoggle = () => {
    setText('')
    setLink(-1)
  }

  const handleSave = async () => {
    
    if(updateIndex !== null) {
      let payload = {}
      if (riskDecision === RiskDecision.reducing.toLowerCase()) {
        payload = {
          text: text,
          link: link,
          type: type
        }
      }
      else {
        payload = {
          text: text,
          link: -1,
          type: type,
        }
      }
      update(payload)
    }
    else {
      const payload = {
        text: text,
        type: type,
        link: link
      }
      save(payload)

    }
    handletoggle()
  }

  useEffect(() => {
    if (riskControl !== undefined){
      setText(riskControl?.text)
      setLink(riskControl?.link)
      setRiskDecisionType(type)
    }
  }, [riskControl])

  return (
    modal && (
      <Fragment>
        <DraggableModal isOpen={modal} toggle={toggle}>
          <ModalHeader className='modal-header' toggle={toggle}>
            {riskControl ? `Edit ${type}` : `Add ${type}`}
          </ModalHeader>
          <div className='modal-line' style={{ zIndex: 1 }} />
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='text'>{type}</Label>
                <Input
                  type='textarea'
                  name='text'
                  id='text'
                  placeholder={'Enter ' + type}
                  value={text}
                  onChange={handleChangeText}
                />
              </FormGroup>
              {riskDecision === RiskDecision.reducing.toLowerCase() && <FormGroup>
                <Label for='control'>Control</Label>
                <InputTemplate
                  type='select'
                  name='control'
                  onChange={handleChangeLink}
                  value={link}
                  selectDefault
                >

                  {controls.map((control) => (
                    <option value={control._id}>
                      {control.name}
                    </option>
                  ))}
                </InputTemplate>
              </FormGroup>}

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='btn-add' onClick={handleSave}>
              Save
            </Button>
            <Button color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </DraggableModal>
      </Fragment>
    )
  );
};
