import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import { InputTemplate } from '../../../entities/EntityModals/InputTemplate';
const { ProjectType, SecurityGoal } = require('../../../../constants/Enums');

export const threatDetails = (
  entity,
  fieldChange,
  editEntity,
  editMode,
  project,
  updateimpact,
  selectedProjectType
) => {
  return editMode ? (
    <>
      <Row>
        <Col md={3}>
          <Label>Impact: </Label>
        </Col>
      </Row>
      <Row>
        <Col md={1} />
        <Col md={2}>
          <Label>Safety: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='safety'
            onChange={(event) => {
              updateimpact(event.target.name, event.target.value);
            }}
            value={editEntity?.impact?.safety ?? entity?.impact?.safety}
            options={
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes
            }
          >
            <option value={''}>None</option>
          </InputTemplate>
        </Col>
      </Row>
      <Row>
        <Col md={1} />
        <Col md={2}>
          <Label>Financial: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='financial'
            onChange={(event) => {
              updateimpact(event.target.name, event.target.value);
            }}
            value={editEntity?.impact?.financial ?? entity?.impact?.financial}
            options={
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes
            }
          >
            <option value={''}>None</option>
          </InputTemplate>
        </Col>
      </Row>
      <Row>
        <Col md={1} />
        <Col md={2}>
          <Label>Operational: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='operational'
            onChange={(event) => {
              updateimpact(event.target.name, event.target.value);
            }}
            value={
              editEntity?.impact?.operational ?? entity?.impact?.operational
            }
            options={
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes
            }
          >
            <option value={''}>None</option>
          </InputTemplate>
        </Col>
      </Row>
      <Row>
        <Col md={1} />
        <Col md={2}>
          <Label>Privacy: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='privacy'
            onChange={(event) => {
              updateimpact(event.target.name, event.target.value);
            }}
            value={editEntity?.impact?.privacy ?? entity?.impact?.privacy}
            options={
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes
            }
          >
            <option value={''}>None</option>
          </InputTemplate>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Label>Operator: </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='operator'
            onChange={fieldChange}
            value={editEntity?.operator ?? entity?.operator}
          >
            <option selected>OR</option>
            <option>AND</option>
          </InputTemplate>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Label>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ThreatSecurityType
            }
            :{' '}
          </Label>
        </Col>
        <Col md={5}>
          <InputTemplate
            type='select'
            name='security_goal'
            onChange={fieldChange}
            value={editEntity?.security_goal ?? entity?.security_goal}
            selectDefault
          >
            {Object.entries(SecurityGoal).map((goal) => {
              return <option value={goal[1].value}>{goal[1].name}</option>;
            })}
          </InputTemplate>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row>
        <Col md={3}>
          <Label>Impact: </Label>
        </Col>
      </Row>

      {entity?.impact?.safety !== undefined && (
        <Row>
          <Col md={1} />
          <Col md={2}>
            <Label>Safety: </Label>
          </Col>
          <Col md={5}>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes[entity?.impact?.safety]
            }
          </Col>
        </Row>
      )}
      {entity?.impact?.financial !== undefined && (
        <Row>
          <Col md={1} />
          <Col md={2}>
            <Label>Financial: </Label>
          </Col>
          <Col md={5}>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes[entity?.impact?.financial]
            }
          </Col>
        </Row>
      )}
      {entity?.impact?.operational !== undefined && (
        <Row>
          <Col md={1} />
          <Col md={2}>
            <Label>Operational: </Label>
          </Col>
          <Col md={5}>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes[entity?.impact?.operational]
            }
          </Col>
        </Row>
      )}
      {entity?.impact?.privacy !== undefined && (
        <Row>
          <Col md={1} />
          <Col md={2}>
            <Label>Privacy: </Label>
          </Col>
          <Col md={5}>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ImpactLevelTypes[entity?.impact?.privacy]
            }
          </Col>
        </Row>
      )}

      <Row>
        <Col md={3}>
          <Label>Operator: </Label>
        </Col>
        <Col md={5}>{entity?.operator}</Col>
      </Row>
      <Row>
        <Col md={3}>
          <Label>
            {
              ProjectType[project?.project_type ?? selectedProjectType]
                .ThreatSecurityType
            }
            :{' '}
          </Label>
        </Col>
        <Col md={5}>{entity?.security_goal}</Col>
      </Row>
    </>
  );
};
