import {
  ADD_THREAT,
  LOAD_THREATS,
  LOGOUT,
  PROJECT_LOADING_ERROR,
  UPDATE_THREAT,
} from '../actions/types';

const initialState = {
  threats: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newThreats = Object.assign([], state.threats);
  switch (type) {
    case LOAD_THREATS:
      return {
        ...state,
        threats: payload,
      };
    case ADD_THREAT:
      newThreats.push(payload);
      return {
        ...state,
        threats: newThreats,
      };
    case UPDATE_THREAT:
      newThreats = newThreats.map((threat) => {
        return threat._id === payload._id ? { ...threat, ...payload } : threat;
      });
      return {
        ...state,
        threats: newThreats,
      };
    case PROJECT_LOADING_ERROR:
    case LOGOUT:
      return {
        threats: [],
      };
    default:
      return state;
  }
}
