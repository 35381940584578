import {
  LOAD_ENTITIES,
  LOGOUT,
  PROJECT_LOADING_ERROR,
  SET_ENTITY,
  SET_NODE_DATA,
  UPDATE_ENTITY,
} from '../actions/types';

const initialState = {
  entity: null,
  entities: null,
  loading: true,
  error: false,
  nodeData: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ENTITY:
      return {
        ...state,
        entity: payload,
      };
    case UPDATE_ENTITY:
      return {
        ...state,
        entity: { ...state.entity, ...payload },
      };
    case PROJECT_LOADING_ERROR:
    case LOGOUT:
      return {
        entity: null,
        entities: null,
      };
    case LOAD_ENTITIES:
      return {
        ...state,
        entities: payload,
      };
    case SET_NODE_DATA:
      return {
        ...state,
        nodeData: payload,
      };
    default:
      return state;
  }
}
