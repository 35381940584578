import { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import axios from '../../../Axios/axios';

const UploadImage = ({ project_id, setSelectedImage }) => {
  // State to handle error messages
  const [error, setError] = useState('');

  // Reference for file input and button width
  const fileInputRef = useRef(null);
  const btnWidth = '180px';

  // Function to convert file to base64
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  // Function to handle image upload
  const handleImageUpload = async (file) => {
    try {
      const blob = await fileToBase64(file);
      const data = { file: blob, filename: file.name };

      // POST request to upload image
      const response = await axios.project.post(`${project_id}/image/`, data);

      if (response.status === 201) {
        // Image uploaded successfully, update the UI
        setSelectedImage(URL.createObjectURL(file));
      } else {
        // Handle error or show an error message to the user
        setError('Failed to upload the image.');
      }
    } catch (error) {
      // Handle any network or other errors here
      setError('An error occurred while uploading the image.');
      console.error(error);
    }
  };

  // Click handler for the button to trigger file input
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleImageUpload(file); // Call the handleImageUpload function
    }
  };

  return (
    <div>
      {error && <p>{error}</p>}
      <input
        ref={fileInputRef}
        type='file'
        accept='image/*'
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
      <Button
        className='btn btn-secondary float-right'
        style={{ width: btnWidth }}
        onClick={handleButtonClick}
      >
        Upload Image
      </Button>
    </div>
  );
};

export default UploadImage;
