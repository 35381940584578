import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { UserPermissions } from '../../../constants/Enums';
import { selectedColor } from '../../../constants/StringConstants';
import { adminAddMember, getProjects } from '../../../redux/actions/projects';
import { hasPermission } from '../../helpers/genericHelper';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import AutoCompleteInput from '../../projects/project-components/AutoCompleteInput';
import RoleSelect from '../../projects/project-components/RoleSelect';

/**
 * Project Role Assingment Modal
 *
 * Displays available projects to be assigned on the selected user
 *
 * @param  getProjects redux action to retrieve all available projects
 * @param adminAddMember  redux action for adding new member in a project
 * @param projects state for the list of projects
 * @param data parameter for the selected user
 * @param toggle for toggling modals in this table
 */

const ProjectRoleAssignment = ({
  getProjects,
  adminAddMember,
  projects,
  data,
  toggle,
}) => {
  const isCreator = hasPermission(data?.permissions, UserPermissions.Creator);

  useEffect(() => {
    getProjects();
  }, []);

  const [member, setMember] = useState({
    user_id: '',
    role: undefined,
  });

  const [project, setProject] = useState({
    _id: undefined,
  });

  //Event handling for inputs
  const onChangeRole = (event) => {
    setMember({
      ...member,
      [event.target.name]:
        event.target.value === '' ? undefined : event.target.value,
      user_id: parseInt(data?._id),
    });
  };

  const onSubmit = async () => {
    adminAddMember(project._id, member);
  };

  const selection = (row) => {
    setProject({
      ...project,
      _id: row,
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row._id === project?._id,
      style: {
        backgroundColor: selectedColor,
      },
    },
  ];

  const columns = React.useMemo(() => [
    {
      name: 'Projects',
      selector: 'name',
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Button
            id={`checkBox${row._id}`}
            onClick={() => {
              selection(row._id);
            }}
          >
            <FontAwesomeIcon
              icon={project._id === row._id ? faCheckSquare : faSquare}
            />
          </Button>
          <UncontrolledTooltip target={`checkBox${row._id}`}>
            Select
          </UncontrolledTooltip>
        </>
      ),
    },
  ]);

  //Method for search input
  var options = projects.map((project) => project.name);
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);
  const searchItems =
    projects &&
    projects.filter(
      (project) =>
        project.name &&
        project.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search Project'
      />
    );
  }, [searchText, resetPaginationToggle]);

  return (
    <Fragment>
      <FormGroup>
        <DataTable
          columns={columns}
          data={searchItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          conditionalRowStyles={conditionalRowStyles}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          customStyles={CustomDataTableStyles}
        />
        <br />
        <Label>Role:</Label>
        <RoleSelect
          isCreator={isCreator}
          onChange={onChangeRole}
          data={member.role}
        />
      </FormGroup>
      <FormGroup>
        <div className='float-right'>
          <Button
            className='btn-add'
            disabled={member?.role === undefined || project._id === undefined}
            onClick={() => {
              onSubmit();
              toggle();
              setSearchText('');
            }}
          >
            Save
          </Button>
          <Button
            className='btn-danger'
            onClick={() => {
              toggle();
              setSearchText('');
            }}
          >
            Cancel
          </Button>
        </div>
      </FormGroup>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  projects: state.profile.adminProjects,
});

export default connect(mapStateToProps, {
  getProjects,
  adminAddMember,
})(ProjectRoleAssignment);
