import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
//Redux
import { connect, Provider } from 'react-redux';
import './App.css';
import Routes from './components/routing/Routes';
import { refresh } from './redux/actions/auth';
import store from './redux/store';

const App = ({ isAuthenticated, refresh }) => {
  useEffect(() => {
    refresh();
  }, []);
  return (
    <Provider store={store}>
      <Routes isAuthenticated={isAuthenticated} />
    </Provider>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { refresh })(App);
