// Asset list main page
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setProject } from '../../../redux/actions/projects';
import ThreatTable from './ThreatTable';
// view for threats
const Threats = ({ threats, loading, project, setProject, profile }) => {
  useEffect(() => {
    if (profile !== undefined) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);

  return (
    <div style={{ backfaceVisibility: 'visible', background: 'white' }}>
      <ThreatTable threats={threats} />{' '}
    </div>
  );
};

const mapStateToProps = (state) => ({
  threats: state.threats.threats,
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, { setProject })(Threats);
