import React, { Fragment, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import DraggableModal from '../../components/entities/EntityModals/DraggableModal';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { deleteCatalogBaseline } from '../../redux/actions/catalog';
import { deleteProjectBaseline } from '../../redux/actions/projects';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AutoCompleteInput from '../projects/project-components/AutoCompleteInput';

/**
 * View Baseline
 *
 * Modal for selecting Baselines to view
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
const ViewBaseline = ({
  modal,
  toggle,
  project,
  catalog,
  projectBaselines,
  catalogBaselines,
  catalogBaseline = false,
  deleteCatalogBaseline,
  deleteProjectBaseline,
}) => {
  const baselines = catalogBaseline ? catalogBaselines : projectBaselines;
  const [baseline, setBaseline] = useState(undefined);

  //Event handling for inputs
  const onChange = (baseline) => {
    setBaseline(baseline);
  };

  const deleteBaseline = () => {
    catalogBaseline
      ? deleteCatalogBaseline(baseline.baseline_id)
      : deleteProjectBaseline(baseline.baseline_id);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const toggleDeleteConfirmationModal = () =>
    setDeleteConfirmationModal(!deleteConfirmationModal);

  const columns = React.useMemo(() => [
    {
      name: 'Baseline ID',
      selector: (row) => row.baseline_id,
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Name',
      selector: 'baseline_name',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <div id={`name_${row.baseline_id}`}>
          {row.baseline_name}
          <UncontrolledTooltip target={`name_${row.baseline_id}`}>
            {row.baseline_name}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      name: 'Creation Date',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '150px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      name: 'Comment',
      selector: 'baseline_comment',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <div id={`comment_${row.baseline_id}`}>
          {row.baseline_comment}
          <UncontrolledTooltip target={`comment_${row.baseline_id}`}>
            {row.baseline_comment}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      //Actions for every project
      cell: (row) => (
        <>
          <Button
            id={`viewButton${row._id}`}
            onClick={() => {
              onChange(row);
              catalogBaseline
                ? window.open(
                    `/catalogues/${catalog._id}/details?baselineId=${row.baseline_id}`
                  )
                : window.open(
                    `/${project._id}/project/?baselineId=${row.baseline_id}`
                  );
            }}
          >
            <i className='fa fa-info' />
          </Button>
          <UncontrolledTooltip target={`viewButton${row._id}`}>
            View
          </UncontrolledTooltip>

          <Button
            id={`deleteButton${row._id}`}
            className='btn-danger'
            onClick={() => {
              onChange(row);
              toggleDeleteModal();
            }}
          >
            <i className='fa fa-trash' />
          </Button>
          <UncontrolledTooltip target={`deleteButton${row._id}`}>
            Delete
          </UncontrolledTooltip>
        </>
      ),
    },
  ]);

  const conditionalRowStyles = [
    {
      when: (row) => row._id === baseline?._id,
      style: {
        backgroundColor: '#d2f0b4',
      },
    },
  ];

  //Method for search input
  var options = baselines
    ? baselines.map((baseline) => baseline.baseline_name)
    : [];
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);
  const searchItems =
    baselines &&
    baselines?.filter((baseline) =>
      baseline?.baseline_name
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search Baselines'
      />
    );
  }, [searchText, resetPaginationToggle]);

  return (
    <Fragment>
      <DraggableModal
        size='lg'
        isOpen={modal}
        toggle={toggle}
        unmountOnClose={false}
      >
        <ModalHeader>
          <div className='modal-header'>View/Delete Baseline</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          Select an existing baseline:
          <DataTable
            defaultSortAsc='_id'
            pagination
            columns={columns}
            persistTableHead
            data={searchItems}
            subHeader
            paginationResetDefaultPage={resetPaginationToggle}
            subHeaderComponent={subHeaderComponentMemo}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={CustomDataTableStyles}
          />
        </ModalBody>
        <ModalFooter>
          <Button className='btn-danger' onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <DraggableModal
          size='lg'
          isOpen={deleteModal}
          toggle={toggleDeleteModal}
        >
          <ModalHeader>
            <div className='modal-header'>Delete baseline</div>
          </ModalHeader>
          <div className='modal-line' />
          <ModalBody className='modal-body'>
            Are you sure you want to delete this baseline?
          </ModalBody>
          <ModalFooter>
            <FormGroup>
              <Button
                className='btn-confirm-delete mr-15'
                onClick={() => {
                  toggleDeleteConfirmationModal();
                }}
              >
                Delete
              </Button>
              <Button
                className='btn-cancel-delete ml-15'
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
            </FormGroup>
          </ModalFooter>
          <DraggableModal
            size='xs'
            isOpen={deleteConfirmationModal}
            toggle={toggleDeleteConfirmationModal}
          >
            <ModalHeader>
              <div className='modal-header'>Delete confirmation</div>
            </ModalHeader>
            <div className='modal-line' />
            <ModalBody className='modal-body'>
              Are you sure you want to continue with this operation?
            </ModalBody>
            <ModalFooter>
              <FormGroup>
                <Button
                  className='btn-confirm-delete mr-15'
                  onClick={() => {
                    deleteBaseline();
                    toggleDeleteConfirmationModal();
                    toggleDeleteModal();
                  }}
                >
                  Delete
                </Button>
                <Button
                  className='btn-cancel-delete ml-15'
                  onClick={() => {
                    toggleDeleteConfirmationModal();
                    toggleDeleteModal();
                  }}
                >
                  Cancel
                </Button>
              </FormGroup>
            </ModalFooter>
          </DraggableModal>
        </DraggableModal>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  catalog: state.catalog.catalog,
  projectBaselines: state.project.baselines,
  catalogBaselines: state.catalog.baselines,
});

export default connect(mapStateToProps, {
  deleteCatalogBaseline,
  deleteProjectBaseline,
})(ViewBaseline);
