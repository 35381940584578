import React, { Fragment } from 'react';
import { FormFeedback, Input, Label } from 'reactstrap';

export const InputTemplate = ({
  label,
  type,
  name,
  onChange,
  value,
  options,
  children = undefined,
  defaultValue,
  formFeedback,
  invalid,
  placeholder,
  step,
  selectDefault = false,
  min = undefined,
  max = undefined,
  id,
  innerRef = undefined,
  style = undefined,
}) => {
  return (
    <Fragment>
      {label ? <Label>{label}</Label> : null}

      <Input
        min={min}
        max={max}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        defaultValue={defaultValue}
        invalid={invalid}
        placeholder={placeholder}
        step={step}
        innerRef={innerRef}
        style={style}
      >
        {type === 'select' && selectDefault && (
          <option value='' selected disabled>
            Select one of the following
          </option>
        )}
        {children}
        {options !== undefined &&
          Array.isArray(options) &&
          options.map((option) => {
            return <option>{option}</option>;
          })}

        {options !== undefined &&
          typeof options === 'object' &&
          Object.entries(options).map((option) => {
            return <option value={option[0]}>{option[1]}</option>;
          })}
      </Input>
      {formFeedback !== undefined && (
        <FormFeedback>{formFeedback}</FormFeedback>
      )}
    </Fragment>
  );
};
