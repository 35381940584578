import {
  REMOVE_LICENSE_ALERT,
  SET_LICENSE_ALERT,
  SET_LICENSE_EXPIRY,
} from './types';

export const setLicenseAlert = () => (dispatch) => {
  dispatch({
    type: SET_LICENSE_ALERT,
  });
};

export const removeLicenseAlert = () => (dispatch) => {
  dispatch({ type: REMOVE_LICENSE_ALERT });
};

export const setLicenseExpiry = (date) => (dispatch) => {
  dispatch({ type: SET_LICENSE_EXPIRY, payload: date });
};
