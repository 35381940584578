import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { setProject, updateProject } from '../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ExportReviewModal from './ExportReviewModal';

const reviewTypeEnum = {
  inspection: 'Inspection',
  walkthrough: 'Walkthrough',
  'peer review': 'Peer Review',
  'offline review': 'Offline Review',
};
const reviewObjectTypeEnum = {
  'technical document': 'Technical Document',
  'general document': 'General Document',
  process: 'Process',
};
const reviewStatusEnum = {
  ACCEPTED: 'The review object is not accepted.',
  CONDITIONALLY_ACCEPTED: 'The review object is conditionally accepted.',
  NOT_ACCEPTED: 'The review object is accepted.',
};

const ReviewProtocolPage = ({
  loading,
  project,
  setProject,
  updateProject,
  profile,
}) => {
  const [protocol, setProtocol] = useState();
  const [editMode, setEditMode] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [preparation, setPrepartaion] = useState();
  const [meeting, setMeeting] = useState();
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [references, setReferences] = useState([
    { name: '', version: '', link: '' },
  ]);
  const [reviewTeam, setReviewTeam] = useState([
    { role: '', name: '', signature: '' },
  ]);

  const handleExportButtonClick = () => {
    setExportModalOpen(true);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    updateProject(project._id, { review_protocol: protocol })
  };

  const handleCancel = () => {
    setProtocol(project?.review_protocol);
    setReferences(
      project?.review_protocol?.references ?? [
        { name: '', version: '', link: '' },
      ]
    );
    setReviewTeam(
      project?.review_protocol?.review_team_effort ?? [
        { role: '', name: '', signature: '' },
      ]
    );
    setEndTime();
    setStartTime();
    setMeeting();
    setPrepartaion();
    setEditMode(false);
  };

  const addTeam = () => {
    setReviewTeam([
      ...reviewTeam,
      {
        role: '',
        name: '',
        signature: '',
      },
    ]);
  };
  const deleteTeam = (index) => {
    const newTeam = [...reviewTeam];
    newTeam.splice(index, 1);
    setReviewTeam(newTeam);
    setProtocol({ ...protocol, review_team_effort: newTeam });
  };
  const handleTeamChange = (index, event, field) => {
    const newTeam = [...reviewTeam];
    newTeam[index][field] = event;
    setReviewTeam(newTeam);
    setProtocol({ ...protocol, review_team_effort: newTeam });
  };
  const addReference = () => {
    setReferences([...references, { name: '', version: '', link: '' }]);
  };
  const deleteReference = (index) => {
    const newReferences = [...references];
    newReferences.splice(index, 1);
    setReferences(newReferences);
    setProtocol({ ...protocol, references: newReferences });
  };

  const handleInputChange = (index, event, field) => {
    const newReferences = [...references];
    newReferences[index][field] = event;
    setReferences(newReferences);
    setProtocol({ ...protocol, references: newReferences });
  };

  const convertDate = (date) => {
    if (Date.parse(date) !== NaN && date !== undefined) {
      const newDate = new Date(date);
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const day = newDate.getDate();

      return `${month}/${day}/${year}`;
    }
    return '';
  };

  const convertTime = (date) => {
    if (Date.parse(date) !== NaN && date !== undefined) {
      const newDate = new Date(date);
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
    }
    return '';
  };

  const getDuration = (end, start) => {
    if (
      isNaN(Date.parse(start)) === false &&
      isNaN(Date.parse(end)) === false
    ) {
      const startDate = new Date(start);
      const startValue = startDate.getHours() * 60 + startDate.getMinutes();

      const endDate = new Date(end);
      const endValue = endDate.getHours() * 60 + endDate.getMinutes();

      const timeDifference = endValue - startValue;

      const hoursDifference = Math.floor(timeDifference / 60);
      const minutesDifference = Math.floor(timeDifference % 60);

      // Format the result
      return `${hoursDifference.toString().padStart(2, '0')}:${minutesDifference
        .toString()
        .padStart(2, '0')}`;
    }
    return '';
  };

  useEffect(() => {
    if (profile !== null) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);

  useEffect(() => {
    if (project?.review_protocol) {
      let newProtocol = JSON.parse(JSON.stringify(project?.review_protocol));
      setProtocol(newProtocol);
      if (newProtocol?.references !== undefined) {
        setReferences(newProtocol?.references);
      }
      if (newProtocol?.review_team_effort !== undefined) {
        setReviewTeam(newProtocol?.review_team_effort);
      }
    }

    if(editMode === true) {
      setEditMode(false)
    }
  }, [project]);

  return (
    <div>
      <h2>Review Protocol</h2>
      <div
        className='card-actions float-right'
        style={{ marginBottom: '10px' }}
      >
        {editMode ? (
          <>
            <Button className='btn-add' onClick={handleSave}>
              Save
            </Button>
            <Button color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button color='add' onClick={handleExportButtonClick}>
              Export
            </Button>
            <Button color='primary' onClick={handleEdit}>
              Edit
            </Button>
          </>
        )}
        {project && (
          <ExportReviewModal
            modal={isExportModalOpen}
            toggle={() => setExportModalOpen(!isExportModalOpen)}
            currentProject={project}
          />
        )}
      </div>
      <Table>
        <tbody>
          <tr>
            <th colSpan={'10'}>Project and review data</th>
          </tr>
          <tr>
            <td>Project No:</td>
            <td>{project?.project_id}</td>
            <td>Project phase:</td>
            <td>
              {editMode ? (
                <Input
                  type='text'
                  id='project_phase'
                  placeholder='Phase'
                  value={protocol?.project_phase}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      project_phase: e.target.value,
                    })
                  }
                />
              ) : (
                protocol?.project_phase
              )}
            </td>
          </tr>
          <tr>
            <td>Project Name:</td>
            <td>{project?.name}</td>
            <td>Date:</td>
            <td>
              {editMode ? (
                <Input
                  type='date'
                  id='date'
                  value={protocol?.date?.substring(0, 10)}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      date: new Date(e.target.value).toISOString(),
                    })
                  }
                />
              ) : (
                convertDate(protocol?.date)
              )}
            </td>
          </tr>
          <tr>
            <td>Customer:</td>
            <td>
              {editMode ? (
                <Input
                  type='text'
                  id='customer'
                  placeholder='Customer'
                  value={protocol?.customer}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      customer: e.target.value,
                    })
                  }
                />
              ) : (
                protocol?.customer
              )}
            </td>
            <td>Start:</td>
            <td>
              {editMode ? (
                <Input
                  type='time'
                  id='start'
                  value={startTime ?? convertTime(protocol?.start)}
                  onChange={(e) => {
                    setStartTime(e.target.value);
                    const date = new Date();
                    date.setUTCHours(e.target.value.split(':')[0]);
                    date.setUTCMinutes(e.target.value.split(':')[1]);
                    setProtocol({
                      ...protocol,
                      start: date,
                    });
                  }}
                />
              ) : (
                convertTime(protocol?.start)
              )}
            </td>
          </tr>
          <tr>
            <td>Review Object Type:</td>
            <td>
              {editMode ? (
                <Input
                  type='select'
                  id='review_object_type'
                  placeholder='Review Object Type'
                  value={protocol?.review_object?.type}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol.review_object,
                        type: e.target.value,
                      },
                    })
                  }
                >
                  <option selected hidden>
                    Select review object type
                  </option>
                  {Object.keys(reviewObjectTypeEnum).map((key) => (
                    <option value={key}>{reviewObjectTypeEnum[key]}</option>
                  ))}
                </Input>
              ) : (
                reviewObjectTypeEnum[protocol?.review_object?.type]
              )}
            </td>
            <td>End:</td>
            <td>
              {editMode ? (
                <Input
                  type='time'
                  id='end'
                  value={endTime ?? convertTime(protocol?.end)}
                  onChange={(e) => {
                    const date = new Date();
                    date.setUTCHours(e.target.value.split(':')[0]);
                    date.setUTCMinutes(e.target.value.split(':')[1]);
                    setProtocol({
                      ...protocol,
                      end: date,
                    });
                    setEndTime(e.target.value);
                  }}
                />
              ) : (
                convertTime(protocol?.end)
              )}
            </td>
          </tr>
          <tr>
            <td>Review Type:</td>
            <td>
              {editMode ? (
                <Input
                  type='select'
                  id='review_type'
                  placeholder='Review Type'
                  value={protocol?.review_type}
                  onChange={(e) =>
                    setProtocol({ ...protocol, review_type: e.target.value })
                  }
                >
                  <option selected hidden>
                    Select review type
                  </option>
                  {Object.keys(reviewTypeEnum).map((key) => (
                    <option value={key}>{reviewTypeEnum[key]}</option>
                  ))}
                </Input>
              ) : (
                reviewTypeEnum[protocol?.review_type]
              )}
            </td>
            <td>Duration:</td>
            <td> {!editMode && getDuration(protocol?.end, protocol?.start)}</td>
          </tr>
          <tr>
            <th colSpan={'10'}>Review object</th>
          </tr>
          <tr>
            <td>Name:</td>
            <td>Page/chapter:</td>
            <td>Version:</td>
            <td>Date:</td>
            <td>Link:</td>
          </tr>
          {editMode ? (
            <tr>
              <td>
                <Input
                  type='text'
                  id='name'
                  placeholder='Name'
                  value={protocol?.name}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol?.review_object,
                        name: e.target.value,
                      },
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type='text'
                  id='page'
                  placeholder='Page'
                  value={protocol?.page}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol?.review_object,
                        page: e.target.value,
                      },
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type='text'
                  id='version'
                  placeholder='Version'
                  value={protocol?.version}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol?.review_object,
                        version: e.target.value,
                      },
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type='date'
                  id='date'
                  placeholder='Date'
                  value={protocol?.review_object?.date?.substring(0, 10)}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol.review_object,
                        date: new Date(e.target.value).toISOString(),
                      },
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type='text'
                  id='link'
                  placeholder='Link'
                  value={protocol?.link}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_object: {
                        ...protocol.review_object,
                        link: e.target.value,
                      },
                    })
                  }
                />
              </td>
            </tr>
          ) : (
            <tr>
              <td>{protocol?.review_object?.name}</td>
              <td>{protocol?.review_object?.page}</td>
              <td>{protocol?.review_object?.version}</td>
              <td>{convertDate(protocol?.review_object?.date)}</td>
              <td>{protocol?.review_object?.link}</td>
            </tr>
          )}
          <tr>
            <th colSpan={'10'}>Reference (optional)</th>
          </tr>
          <tr>
            <td>No:</td>
            <td>Name:</td>
            <td>Version:</td>
            <td>Date:</td>
            <td>Link:</td>
          </tr>
          {editMode ? (
            <>
              {references.map((reference, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Input
                      type='text'
                      value={reference.name}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'name')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='text'
                      value={reference.version}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'version')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='date'
                      value={reference?.date?.substring(0, 10)}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          new Date(e.target.value).toISOString(),
                          'date'
                        )
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='text'
                      value={reference.link}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'link')
                      }
                    />
                  </td>
                  <td>
                    <Button
                      className='btn-danger'
                      onClick={() => deleteReference(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Button className='btn-add' onClick={addReference}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </td>
              </tr>
            </>
          ) : (
            <>
              {references.map((reference, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{reference.name}</td>
                  <td>{reference.version}</td>
                  <td>{convertDate(reference.date)}</td>
                  <td>{reference.link}</td>
                </tr>
              ))}
            </>
          )}
          <tr>
            <th colSpan={'6'}>Review team and effort</th>
          </tr>
          <tr>
            <td>Role:</td>
            <td>Name:</td>
            <td>Preparation effort [hh:mm]</td>
            <td>Meeting effort [hh:mm]</td>
            <td>Signature:</td>
          </tr>
          {editMode ? (
            <>
              {reviewTeam.map((reviewTeam, index) => (
                <tr key={index}>
                  <td>
                    <Input
                      type='text'
                      value={reviewTeam.role}
                      onChange={(e) =>
                        handleTeamChange(index, e.target.value, 'role')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='text'
                      value={reviewTeam.name}
                      onChange={(e) =>
                        handleTeamChange(index, e.target.value, 'name')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='time'
                      value={preparation ?? convertTime(reviewTeam.preparation)}
                      onChange={(e) => {
                        const date = new Date();
                        date.setUTCHours(e.target.value.split(':')[0]);
                        date.setUTCMinutes(e.target.value.split(':')[1]);
                        handleTeamChange(
                          index,
                          date.toISOString(),
                          'preparation'
                        );
                        setPrepartaion(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      type='time'
                      value={meeting ?? convertTime(reviewTeam.meeting)}
                      onChange={(e) => {
                        const date = new Date();
                        date.setUTCHours(e.target.value.split(':')[0]);
                        date.setUTCMinutes(e.target.value.split(':')[1]);
                        handleTeamChange(index, date.toISOString(), 'meeting');
                        setMeeting(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      type='text'
                      value={reviewTeam.signature}
                      onChange={(e) =>
                        handleTeamChange(index, e.target.value, 'signature')
                      }
                    />
                  </td>
                  <td>
                    <Button
                      className='btn-danger'
                      onClick={() => deleteTeam(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Button className='btn-add' onClick={addTeam}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </td>
              </tr>
            </>
          ) : (
            <>
              {reviewTeam.map((reviewTeam, index) => (
                <tr key={index}>
                  <td>{reviewTeam?.role}</td>
                  <td>{reviewTeam?.name}</td>
                  <td>{convertTime(reviewTeam?.preparation)}</td>
                  <td>{convertTime(reviewTeam?.meeting)}</td>
                  <td>{reviewTeam?.signature}</td>
                </tr>
              ))}
            </>
          )}
          <tr>
            <th colSpan={'10'}>Identified findings</th>
          </tr>
          <tr>
            <td>Category</td>
            <td>Amount</td>
            <td>Open</td>
          </tr>
          <tr>
            <td>Significant</td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) => checklist.evaluation === 'significant'
                ).length
              }
            </td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) =>
                    checklist.evaluation === 'significant' &&
                    checklist.status === 'open'
                ).length
              }
            </td>
          </tr>
          <tr>
            <td>Optimize</td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) => checklist.evaluation === 'optimize'
                ).length
              }
            </td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) =>
                    checklist.evaluation === 'optimize' &&
                    checklist.status === 'open'
                ).length
              }
            </td>
          </tr>
          <tr>
            <td>Check</td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) => checklist.evaluation === 'check'
                ).length
              }
            </td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) =>
                    checklist.evaluation === 'check' &&
                    checklist.status === 'open'
                ).length
              }
            </td>
          </tr>
          <tr>
            <td>Info</td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) => checklist.evaluation === 'info'
                ).length
              }
            </td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) =>
                    checklist.evaluation === 'info' &&
                    checklist.status === 'open'
                ).length
              }
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{project?.review_checklist?.length}</td>
            <td>
              {
                project?.review_checklist?.filter(
                  (checklist) => checklist.status === 'open'
                ).length
              }
            </td>
          </tr>
          <tr>
            <th colSpan={'10'}>Review Status</th>
          </tr>
          <tr>
            <td>Status</td>
            {editMode ? (
              <td>
                <Input
                  type='select'
                  id='review_object_type'
                  placeholder='Review Object Type'
                  value={protocol?.review_status?.status}
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_status: {
                        ...protocol?.review_status,
                        status: e.target.value,
                      },
                    })
                  }
                >
                  <option selected hidden>
                    Select review status
                  </option>
                  {Object.keys(reviewStatusEnum).map((key) => (
                    <option value={key}>{reviewStatusEnum[key]}</option>
                  ))}
                </Input>
              </td>
            ) : (
              <td>{reviewStatusEnum[protocol?.review_status?.status]}</td>
            )}
            <td>Justification</td>
            {editMode ? (
              <td>
                <Input
                  type='textarea'
                  onChange={(e) =>
                    setProtocol({
                      ...protocol,
                      review_status: {
                        ...protocol?.review_status,
                        justification: e.target.value,
                      },
                    })
                  }
                />
              </td>
            ) : (
              <td>{protocol?.review_status?.justification}</td>
            )}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { setProject, updateProject })(ReviewProtocolPage)
);
