import { CatalogType } from '../../constants/Enums';
import {
  ADD_NEW_CATALOG,
  ADD_NEW_REFTREE,
  ADD_REFTREES,
  DELETE_CATALOG,
  DELETE_REFTREE,
  DEL_PROFILE,
  GET_PROFILES,
  LOAD_ADMIN_CATALOGS,
  LOAD_ADMIN_PROJECTS,
  LOAD_PROFILES,
  LOGOUT,
  SET_CATALOG,
  SET_CONTROL_CATALOGS,
  SET_PROFILE,
  SET_PROJECT_ROLE,
  SET_REFTREES,
  SET_REFTREE_CATALOGS,
} from '../actions/types';

const initialState = {
  profile: null,
  projectRole: null,
  profiles: [], //will get list of profiles
  userControlCatalogs: undefined,
  userRefTreeCatalogs: undefined,
  userReftrees: undefined,
  loading: false,
  adminProject: [],
  adminControlCatalogs: [],
  adminRefTreeCatalogs: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let userReftrees = [];
  switch (type) {
    case LOAD_PROFILES:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
      };
    case DEL_PROFILE:
      const newProfiles = Object.assign([], state.profiles);
      const deleteIndex = newProfiles.findIndex(
        (profile) => profile._id === payload
      );
      newProfiles.splice(deleteIndex, 1);
      return {
        ...state,
        profiles: newProfiles,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case SET_PROJECT_ROLE:
      return {
        ...state,
        projectRole: payload,
      };
    case SET_REFTREE_CATALOGS:
      return {
        ...state,
        userRefTreeCatalogs: payload,
      };
    case SET_CONTROL_CATALOGS:
      return {
        ...state,
        userControlCatalogs: payload,
      };
    case SET_REFTREES:
      return {
        ...state,
        userReftrees: payload,
      };
    case ADD_REFTREES:
      return {
        ...state,
        userReftrees: [...state.userReftrees, ...payload],
      };
    case ADD_NEW_REFTREE:
      userReftrees = state?.userReftrees ? [...state?.userReftrees] : [];
      userReftrees.push(payload);
      return {
        ...state,
        userReftrees: userReftrees,
      };
    case DELETE_REFTREE:
      userReftrees = Object.assign([], state.userReftrees);
      userReftrees = userReftrees.filter(
        (refTree) => parseInt(refTree._id) !== parseInt(payload)
      );
      return {
        ...state,
        userReftrees: userReftrees,
      };
    case LOGOUT:
      return {
        ...state,
        profile: null,
        projectRole: null,
        profiles: [],
      };
    case ADD_NEW_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        const controlCatalog = Object.assign([], state.userControlCatalogs);
        const controlIndex = controlCatalog.findIndex(
          (ctrlCatalog) => ctrlCatalog._id === payload._id
        );
        controlIndex !== -1
          ? controlCatalog.splice(controlIndex, 1, payload)
          : controlCatalog.push(payload);
        return {
          ...state,
          userControlCatalogs: controlCatalog,
        };
      } else {
        const refTreeCatalog = Object.assign([], state.userRefTreeCatalogs);
        const reftreeIndex = refTreeCatalog.findIndex(
          (rtCatalog) => rtCatalog._id === payload._id
        );
        reftreeIndex !== -1
          ? refTreeCatalog.splice(reftreeIndex, 1, payload)
          : refTreeCatalog.push(payload);
        return {
          ...state,
          userRefTreeCatalogs: refTreeCatalog,
        };
      }

    case DELETE_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        let controlCatalog = [...state.userControlCatalogs];
        controlCatalog = controlCatalog.filter(
          (catalog) => parseInt(catalog._id) !== parseInt(payload._id)
        );
        return {
          ...state,
          userControlCatalogs: controlCatalog,
        };
      } else {
        let refTreeCatalog = [...state.userRefTreeCatalogs];
        refTreeCatalog = refTreeCatalog.filter(
          (catalog) => parseInt(catalog._id) !== parseInt(payload._id)
        );
        return {
          ...state,
          userRefTreeCatalogs: refTreeCatalog,
        };
      }

    case SET_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        const controlCatalogs = Object.assign([], state.userControlCatalogs);
        const controlIndex = controlCatalogs.findIndex(
          (controlCatalog) => controlCatalog._id === payload._id
        );
        controlIndex !== -1 && controlCatalogs.splice(controlIndex, 1, payload);
        return {
          ...state,
          userControlCatalogs: controlCatalogs,
        };
      } else {
        const refTreeCatalogs = Object.assign([], state.userRefTreeCatalogs);
        const reftreeIndex = refTreeCatalogs.findIndex(
          (rtCatalog) => rtCatalog._id === payload._id
        );
        reftreeIndex !== -1 && refTreeCatalogs.splice(reftreeIndex, 1, payload);
        return {
          ...state,
          userRefTreeCatalogs: refTreeCatalogs,
        };
      }
    case LOAD_ADMIN_PROJECTS:
      return {
        ...state,
        adminProjects: payload,
      };
    case LOAD_ADMIN_CATALOGS:
      const ControlCatalogs = payload?.filter(
        (catalog) => catalog.content_type === CatalogType.Control
      );
      const ReferenceTreeCatalogs = payload?.filter(
        (catalog) => catalog.content_type === CatalogType.ReferenceTree
      );
      return {
        ...state,
        adminControlCatalogs: ControlCatalogs,
        adminRefTreeCatalogs: ReferenceTreeCatalogs,
      };
    default:
      return state;
  }
}
