export const ToolInformation = {
  header: 'Frequently Asked Questions',
  information: '',
  FAQ: [
    {
      id: 1,
      question: 'Why is my project page empty? ',
      answer:
        'Testing security requirements and testing in general can be very costly. Usually, 50% of development cost go into testing. There is a lot of saving potential if organized cleverly.',
      answerList: [
        {
          answer:
            'Maybe you are not assigned to any project yet. Contact your ISAT-Admin or Owner of the project to which you want to get access. ',
        },
        {
          answer:
            'You are an Admin and can’t see any project -> see the chapter “Manage users (for ISAT)” in the manual for further information',
        },
        {
          answer:
            'You are assigned to a project but cannot see it on the page -> Please send a bug report',
        },
      ],
    },
    {
      id: 2,
      question:
        'Why can’t I remove a user from a project? Why can’t I revoke the role “Owner” from a user?',
      answer:
        'If a user is the only user with the role “Owner” in the project, he can’t be removed from the project or assigned another role. There always must be at least one user with the role “Owner” in each project. ',
      answerList: [
        {
          answer:
            'If there is at least one other “Owner” assigned to a project or the user, you wish to remove doesn’t have the role “Owner” -> Please send a bug report',
        },
      ],
    },
    {
      id: 3,
      question: 'Why can’t I remove the role “Creator” from a user?',
      answer:
        'If a user has the role “Owner” or “Editor” assigned to him in any project or catalogue he can’t be revoked from the role “Creator”.',
      answerList: [
        {
          answer:
            'If the user doesn’t have the role “Owner” or “Editor” assigned to him in any project or catalogue -> Please send a bug report',
        },
      ],
    },
    {
      id: 4,
      question: 'Why can’t I assign the role “Creator” to a user?',
      answer:
        'Because the license you are using doesn’t allow to assign the role “Creator” to an additional user',
      answerList: [
        {
          answer: 'You have two options:',
          children: [
            {
              answer: 'Revoke the role “Creator” from another user',
            },
            {
              answer: 'Get in contact with us to extend your license',
            },
          ],
        },
      ],
    },
    {
      id: 5,
      question: 'Which error messages exist?',
      answer: 'See the chapter “Error messages” in the manual',
    },
    {
      id: 6,
      question: 'Why do certain error messages appear?',
      answer: 'See the chapter “Error messages” in the manual',
    },
    {
      id: 7,
      question: 'How to fix / bypass them?',
      answer: 'See the chapter “Error messages” in the manual',
    },
  ],
};
