import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { deleteNode } from '../../../../redux/actions/analysistree';
import DraggableModal from '../../../entities/EntityModals/DraggableModal';

/**
 * Node deletion modal
 *
 * A modal used for deleting attack tree nodes
 *
 *  @param modal the modal that uses the component
 *  @param toggle the toggle of said modal
 *  @param nodeData the node assigned for deletion
 *
 */
const NodeDeletionModal = ({ modal, toggle, nodeData, deleteNode }) => {
  const onSubmit = async (event) => {
    event.preventDefault();
    deleteNode(nodeData);
  };

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Delete Node</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          Warning! This node{' '}
          {nodeData?.children?.length > 0 &&
            ' and all child nodes of this node '}
          will be deleted and you can not restore them. Are you sure you want to
          proceed?
          <Form>
            <FormGroup style={{ marginTop: 30 }}>
              <Button className='btn-cancel-delete mr-15' onClick={toggle}>
                No, Cancel
              </Button>
              <Button
                className='btn-confirm-delete ml-15'
                type='submit'
                onClick={(event) => {
                  onSubmit(event);
                  toggle();
                }}
              >
                Yes, delete
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

export default connect(null, { deleteNode })(NodeDeletionModal);
