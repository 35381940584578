import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { CommentStatus } from '../../../../constants/Enums';
import { LocalTimeWithoutSeconds } from '../../../../constants/StringConstants';
import { btnEdit, btnReply, btnTrash } from '../../../../constants/Style';
import {
  addComment,
  deleteComment,
  updateComment,
} from '../../../../redux/actions/comments';
const ASCENDING = 'Ascending';
const DESCENDING = 'Descending';

const ReviewComment = ({
  type,
  project,
  entity,
  profile,
  addComment,
  updateComment,
  catalog,
  deleteComment,
  editable = true,
}) => {
  const [parent, setParent] = useState(undefined);
  const [comments, setComments] = useState(undefined);

  useEffect(() => {
    if (parent?.comments !== undefined && parent?.comments?.length > 0) {
      setComments(
        parent?.comments?.sort((a, b) =>
          a.creation_date < b.creation_date ? 1 : -1
        )
      );
    }
  }, [parent]);

  const [newComment, setNewComment] = useState('');
  const [sortBy, setSortBy] = useState(DESCENDING);

  const sorting = (entity, sortBy) => {
    const sorted = Object.assign([], entity?.comments);
    if (sortBy === ASCENDING) {
      sortComments(
        sorted?.sort((a, b) => (a.creation_date > b.creation_date ? 1 : -1))
      );
    } else {
      sortComments(
        sorted?.sort((a, b) => (a.creation_date < b.creation_date ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (type === 'entity') {
      setParent(entity);
      sorting(entity, sortBy);
    } else if (type === 'project') {
      setParent(project);
      sorting(project, sortBy);
    } else {
      setParent(catalog);
      sorting(catalog, sortBy);
    }
  }, [entity, project, type, catalog]);

  const sort = (sortBy) => {
    setSortBy(sortBy.target.value);
    sorting(parent, sortBy.target.value);
  };

  const sortComments = (comments) => setComments(comments);
  const newCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  // changes view to edit mode
  const editMode = (comment) => {
    const editComments = comments.filter((comment) => true);
    const index = editComments.findIndex(
      (com) => parseInt(com.id) === parseInt(comment.id)
    );
    editComments[index].editMode = true;
    editComments[index].editText = editComments[index].text;
    setComments(editComments);
  };

  // changes view to reply mode
  const replyMode = (comment) => {
    const editComments = comments.filter((comment) => true);
    const index = editComments.findIndex(
      (com) => parseInt(com.id) === parseInt(comment.id)
    );
    editComments[index].replyMode = true;
    setComments(editComments);
  };

  // on change of edit comment
  const editComment = (comment, event) => {
    const editComments = comments.filter((comment) => true);
    const index = editComments.findIndex(
      (com) => parseInt(comment.id) === parseInt(com.id)
    );
    editComments[index].editText = event.target.value;
    setComments(editComments);
  };

  // on cancel of edit,add,reply
  const cancel = (comment) => {
    if (comment) {
      const editComments = comments.filter((comment) => true);
      const index = editComments.findIndex(
        (com) => parseInt(comment.id) === parseInt(com.id)
      );
      editComments[index].editMode = false;
      editComments[index].editText = editComments[index].text;
      setComments(editComments);
    } else {
      setNewComment('');
    }
  };

  // on saving
  const save = (comment) => {
    if (comment) {
      updateComment(parent, comment, type);
    } else {
      addComment(parent, newComment, type);
      setNewComment('');
    }
  };

  const replyComment = (event, comment) => {
    const editComments = comments.filter((comment) => true);
    const index = editComments.findIndex(
      (com) => parseInt(comment.id) === parseInt(com.id)
    );
    editComments[index].replyText = event.target.value;
    setComments(editComments);
  };

  const delComment = (comment) => {
    deleteComment(parent, comment, type);
  };

  const cancelReply = (comment) => {
    const editComments = comments.filter((comment) => true);
    const index = editComments.findIndex(
      (com) => parseInt(comment.id) === parseInt(com.id)
    );
    editComments[index].replyMode = false;
    editComments[index].replyText = '';
    setComments(editComments);
  };

  const saveReplyText = (comment) => {
    addComment(parent, comment, type);
  };

  const printComment = (comment, index) => {
    return displayComment(comment, index);
  };

  var commentTimestamp = (time) => {
    return LocalTimeWithoutSeconds(time);
  };

  function displayComment(comment, index) {
    return (
      <>
        <Row className='padding-top-bottom'>
          <Col
            className='background'
            style={{
              textAlign: 'center',
              maxWidth: '180px',
            }}
          >
            <Row>
              <Col>
                <i
                  className='fa fa-user large'
                  style={{ margin: 'auto', padding: '5px 0' }}
                />
              </Col>
            </Row>
            <Row>
              <Col>{comment.user_name}</Col>
            </Row>
          </Col>
          <Col style={{ maxWidth: '180px' }}>
            <div style={{ fontSize: '0.8rem' }}>
              {commentTimestamp(comment.last_modified)}
            </div>
            <div>
              <span className='bold'>{comment.status.toUpperCase()}</span>
            </div>
          </Col>
          <Col style={{ width: 'calc(100% - 430px)' }}>
            <div>{checkEditMode(comment, index)}</div>
            <br />
            {comment.status !== CommentStatus.Deleted &&
            editable &&
            !comment.editMode
              ? addReply(comment, index)
              : null}
          </Col>
          <Col style={{ maxWidth: '30px', paddingRight: '20px' }}>
            {parseInt(profile?.id) === comment?.user_id &&
            !comment?.editMode &&
            comment.status !== CommentStatus.Deleted &&
            !comment?.replyMode ? (
              <>
                <Button style={btnEdit} onClick={() => editMode(comment)}>
                  <i className='fa fa-edit' />
                </Button>
                <Button style={btnTrash} onClick={() => delComment(comment)}>
                  <i className='fa fa-trash' />
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>{printChildren(comment, index)}</Col>
        </Row>
      </>
    );
  }

  function checkEditMode(comment, index) {
    if (comment.status === CommentStatus.Deleted) {
      return (
        <div className='deleted-comment'>
          This comment was deleted by the author
        </div>
      );
    } else if (comment.editMode) {
      return (
        <>
          <Input
            type='textarea'
            name='editComment'
            onChange={(event) => editComment(comment, event)}
            value={comment.editText}
          />
          <br />
          <Button
            className='float-right btn-add'
            onClick={() => save(comment)}
            disabled={comment?.editText?.trim()?.length <= 0}
          >
            Save
          </Button>
          <Button
            className='float-right btn-danger'
            onClick={() => cancel(comment)}
          >
            Cancel
          </Button>
        </>
      );
    } else {
      return <div className='display-linebreak'>{comment.text}</div>;
    }
  }

  const printChildren = (comment, index) => {
    if (Array.isArray(comment.children) && comment?.children?.length > 0) {
      return (
        <div style={{ paddingLeft: '60px' }}>
          {comment.children.map((reply, replyIndex) =>
            displayComment(reply, replyIndex)
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const addReply = (comment, index) => {
    if (comment.replyMode) {
      return (
        <>
          <Input
            type='textarea'
            name='reply'
            onChange={(event) => replyComment(event, comment)}
            value={comment.replyText}
          />{' '}
          <br />
          <Button
            className='float-right btn-add'
            onClick={() => saveReplyText(comment)}
            disabled={comment?.replyText?.trim()?.length <= 0}
          >
            Save
          </Button>
          <Button
            className='float-right btn-danger'
            onClick={() => cancelReply(comment)}
          >
            Cancel
          </Button>
          <br />
        </>
      );
    } else {
      return (
        <Button
          className='float-right'
          style={btnReply}
          onClick={() => replyMode(comment)}
        >
          <i className='fa fa-reply' /> Reply
        </Button>
      );
    }
  };

  return (
    <>
      {comments?.length > 0 ? (
        <>
          <div style={{ position: 'absolute', right: 0 }}>
            <Label>Sort by:</Label>
            <Input type='select' name='sort' onChange={sort}>
              <option>{DESCENDING}</option>
              <option>{ASCENDING}</option>
            </Input>
          </div>
          <div>
            <br />
            <br />
            <br />
          </div>
        </>
      ) : null}

      {editable && (
        <div>
          <div style={{ width: 'calc(100% - 40px)', margin: 'auto' }}>
            <Label>Add New Comment</Label>
            <Input
              type='textarea'
              name='newComment'
              onChange={newCommentChange}
              value={newComment}
            />
            <br />
            {newComment?.trim()?.length > 0 ? (
              <div align={'right'}>
                <Button className='btn-add' onClick={() => save()}>
                  Save
                </Button>
                <Button className='btn-danger' onClick={() => cancel()}>
                  Cancel
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div>
        {comments?.map((comment, index) =>
          comment.child ? null : (
            <div className='display-linebreak'>
              {printComment(comment, index)}
            </div>
          )
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  entity: state.entities.entity,
  project: state.project.project,
  catalog: state.catalog.catalog,
});

export default connect(mapStateToProps, {
  addComment,
  updateComment,
  deleteComment,
})(ReviewComment);
