import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import DraggableModal from '../../../entities/EntityModals/DraggableModal';

export const AssumptionsModal = ({
  modal,
  toggle,
  save,
  update,
  project,
  assumption,
  updateIndex = null,
}) => {
  const [note, setNote] = useState('');
  const [selectedAssumption ,setSelectedAssumption] = useState('');

  const handleChangeText = (event) => {
    setNote(event.target.value);
  };

  const handleToggle = () => {
    setSelectedAssumption('')
    setNote('')
    toggle()
  }

  const handleSave = async() => {
    const payload = {
      assumption: selectedAssumption,
      note: note
    }
    if (updateIndex !== null)
    {
      update(payload)
    }
    else {
      save(payload)
    }
    handleToggle()
  }

  useEffect(() => {
    if (assumption !== undefined) {
      setNote(assumption?.note);
      setSelectedAssumption(assumption?.assumption);
    }
  }, [assumption]);

  return (
    modal && (
      <Fragment>
        <DraggableModal isOpen={modal} toggle={toggle}>
          <ModalHeader className='modal-header' toggle={toggle}>
            {assumption ? `Edit` : `Add`}
          </ModalHeader>
          <div className='modal-line' style={{ zIndex: 1 }} />
          <ModalBody>
            <Form>
              <FormGroup>
                <Table>
                  <thead>
                    <th>Assumption</th>
                    <th>Select</th>
                  </thead>
                  <tbody>
                    {project?.assumptions?.map((assumption) => (
                      <tr>
                        <td>{assumption}</td>
                        <td>
                          <Button onClick={() => setSelectedAssumption(assumption)}>
                            <FontAwesomeIcon 
                              icon={assumption === selectedAssumption ? faCheckSquare : faSquare }
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FormGroup>
              <FormGroup>
                <Label>
                    Notes
                </Label>
                <Input
                  type='textarea'
                  name='note'
                  id='note'
                  placeholder={'Enter notes'}
                  value={note}
                  onChange={handleChangeText}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='btn-add' onClick={handleSave}>
              Save
            </Button>
            <Button color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </DraggableModal>
      </Fragment>
    )
  );
};
