import {
  CANCEL_REROUTE,
  CANCEL_UNSAVED_CHANGES,
  REROUTE,
  UNSAVED_CHANGES,
} from '../actions/types';

const initialState = {
  unsavedChanges: [],
  routeParams: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CANCEL_UNSAVED_CHANGES:
      let newChanges = [];
      if (payload !== undefined) {
        newChanges = state.unsavedChanges.filter(
          (change) => change !== payload
        );
      }
      return {
        ...state,
        unsavedChanges: newChanges,
      };
    case UNSAVED_CHANGES:
      const changes = state.unsavedChanges.filter((change) => true);
      changes.push(payload);
      return {
        ...state,
        unsavedChanges: changes,
      };
    case REROUTE:
      return {
        ...state,
        routeParams: payload,
      };
    case CANCEL_REROUTE:
      return {
        ...state,
        routeParams: undefined,
      };
    default:
      return state;
  }
}
