import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { OwnerType } from '../../../constants/Enums';
import { copyRefTree } from '../../../redux/actions/analysistree';
import useDebounce from '../../helpers/Debouncer';
import { nameFiltering } from '../../helpers/genericHelper';
import DraggableModal from './DraggableModal';
import { InputTemplate } from './InputTemplate';

/**
 * Reference Modal
 *
 * A modal component used to add reference trees from existing nodes
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param parent the parent node for update/creation
 *
 */
const CopyReftreeModal = ({
  reftree,
  node,
  toggle,
  modal,
  RefTreeCatalogs,
  defaultReftreeCatalogs,
  goToEdit,
}) => {
  const [filteredCatalogs, setFilteredCatalogs] = useState(RefTreeCatalogs);

  const defaultCatalog = (catalogs) => {
    let tree = undefined;
    if (node?.owner?.owner_type === OwnerType.RefTree) {
      tree = catalogs.find((catalog) =>
        catalog.children.includes(node.owner.id)
      );
      return tree?._id;
    } else {
      if (Array.isArray(defaultReftreeCatalogs) && Array.isArray(catalogs)) {
        const defaultCatalog = defaultReftreeCatalogs.find(
          (catalog) => catalog?.catalog_type === 'default'
        );
        tree = catalogs.find((catalog) => catalog?._id === defaultCatalog?._id);
        return tree?._id;
      }
    }
    return undefined;
  };

  const [formData, setFormData] = useState({
    name: undefined,
    description: undefined,
    catalog: undefined,
  });

  useEffect(() => {
    changeFormData({
      ...formData,
      catalog: defaultCatalog(filteredCatalogs),
    });
  }, []);

  const changeFilteredCatalogs = (catalogData) =>
    setFilteredCatalogs(catalogData);

  const changeFormData = (newFormData) => setFormData(newFormData);

  const [searchString, setSearchString] = useState('');
  const deboucedSearchString = useDebounce(searchString, 500);

  const onChange = (e) =>
    changeFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async () => {
    await copyRefTree(formData, reftree, node);
    goToEdit();
  };

  useEffect(() => {
    const filteredCatalogs = nameFiltering(
      RefTreeCatalogs,
      deboucedSearchString
    );
    changeFilteredCatalogs(filteredCatalogs);
  }, [deboucedSearchString]);

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader>
          <div className='modal-header'>Add Reference Tree</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <InputTemplate
                label='* Name'
                type='text'
                name='name'
                value={formData.name}
                onChange={onChange}
                innerRef={focusRef}
              />
            </FormGroup>
            <FormGroup>
              <InputTemplate
                label='Description'
                type='textarea'
                name='description'
                value={formData.description}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type='text'
                name='search'
                value={searchString}
                placeholder='Search'
                onChange={(e) => setSearchString(e.target.value)}
              ></Input>
            </FormGroup>
            {filteredCatalogs?.length === 0 &&
            (deboucedSearchString !== '' ||
              deboucedSearchString !== undefined) ? (
              <Alert color='info'>No results found</Alert>
            ) : null}
            <FormGroup>
              <InputTemplate
                label='Catalog'
                type='select'
                name='catalog'
                value={formData.catalog}
                onChange={onChange}
                selectDefault
              >
                {filteredCatalogs?.map((catalogItem) => {
                  return (
                    <option value={catalogItem._id}>{catalogItem.name}</option>
                  );
                })}
              </InputTemplate>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={
                  formData.name === undefined || formData.catalog === undefined
                }
                className='float-right btn-add'
                onClick={() => {
                  onSubmit();
                  toggle();
                }}
              >
                Save
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  controls: state.controls.controls,
  defaultReftreeCatalogs: state.project.reftree_catalogs,
  RefTreeCatalogs: state.profile.userRefTreeCatalogs,
});
export default connect(mapStateToProps, {})(CopyReftreeModal);
