import axios from 'axios';
import { logout } from '../redux/actions/auth';
import store from '../redux/store';
import { BaseUrl } from './axios';
import SessionStorage from './SessionStorage';
const sessionStorageService = SessionStorage.getService();

axios.defaults.withCredentials = true;

const { dispatch } = store;

// Use for adding the authorization header located in session storage
// on axios request.
axios.interceptors.request.use(
  async (request) => {
    request.headers['Content-Type'] = 'application/json';
    let token = sessionStorageService.getAccessToken();
    if (token && token !== 'undefined') {
      //Continues with request if user is logging in, refreshing access token, or testing access rights
      if (request.url === BaseUrl + 'user/login') {
        return request;
      }
      request.headers['X-CSRF-TOKEN'] = token;
      return request;
    }

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

let authTokenRequest;

const refresh = () => {
  if (!authTokenRequest) {
    let refreshToken = sessionStorageService.getRefreshToken();
    //Continues with request, which would redirect to login, if refresh token is not present
    let refreshConfig = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': refreshToken,
      },
    };

    authTokenRequest = axios.patch(BaseUrl + 'user/login', null, refreshConfig);
  }

  return authTokenRequest;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.retryRequest
    ) {
      if (
        error.response.data.msg === 'Signature has expired' ||
        error.response.data.msg === 'CSRF double submit tokens do not match'
      ) {
        if (
          error.config.method === 'patch' &&
          error.config.url === '/api/user/login'
        ) {
          if (
            error.response.data.msg === 'Signature has expired' ||
            error.response.data.msg === 'CSRF double submit tokens do not match'
          ) {
            dispatch(logout());
          }
        } else {
          return refresh().then((response) => {
            sessionStorageService.setAccessToken(response.data.access_csrf);
            authTokenRequest = null;
            error.config.retryRequest = true;
            return axios(error.config);
          });
        }
      } else if (
        error.response.data.msg === 'Missing cookie "access_token_cookie"' ||
        error.response.data.msg === 'JWT was not accepted. Please log in again.'
      ) {
        dispatch(logout());
      } else if (error.response.data.msg === 'Missing CSRF token') {
        dispatch(logout());
      } else {
        return Promise.reject(error);
      }
    }
    //Error 422 means that the signature has expired, prompting a logout
    else if (error?.response?.status === 422) {
      dispatch(logout(true));
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
