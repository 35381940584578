import { default as React, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { importProject } from '../../../redux/actions/projects';
import store from '../../../redux/store';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
/**
 * Import Project Modal
 *
 * Modal for accepting files to be imported to create a new project
 *
 * TODO: Input from the backend and redux functionality
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */

const ImportProject = ({ modal, toggle }) => {
  const { dispatch } = store;
  const [formData, setFormData] = useState([]);

  const handleChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (event) => {
      setFormData(event.target.result);
    };
  };
  const submit = () => {
    dispatch(importProject(formData));
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Import New Project</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Select File</Label>
              <Input
                type='file'
                name='file'
                id='exampleFile'
                onChange={handleChange}
              />
              <FormText color='muted'>
                <i>*Files must be in .json format</i>
              </FormText>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button
              className='btn-add'
              onClick={() => {
                submit();
                toggle();
              }}
            >
              Import
            </Button>
            <Button className='btn-danger' onClick={toggle}>
              Cancel
            </Button>
          </FormGroup>
        </ModalFooter>
      </DraggableModal>
    </Fragment>
  );
};

export default ImportProject;
