import React from 'react';
import Tree from 'react-d3-tree';
import JSONTree from 'react-json-tree';
import { Container } from 'reactstrap';
import { JSONtheme } from '../../constants/Enums';
import NodeLabelComponent from '../analysistree/NodeLabelComponent';
import {
  NodeDistanceX,
  NodeDistanceY,
  NodeOffsetX,
  NodeOffsetY,
  nodeSeparationNonSiblings,
  nodeSeparationSiblings,
  PageHeight,
  PageWidth,
} from '../analysistree/nodetypes/nodeObjects/NodeConstants';
import { setChildren } from '../helpers/AnalysisTreeHelper';
import { renameKeys } from '../helpers/genericHelper';
//This the Expanded area when clicking the entities in the change log
const RecycleBinExpandedComponent = ({ data }) => {
  const subtreeData =
    data?.parent?.resource !== undefined &&
    data?.root_object?.object_type === 'Subtree' &&
    setChildren(
      [{ ...data.parent.resource, children: [data.root_object.id] }],
      data.objects.Entity
    )[0];

  return (
    <pre display='inline-block' className='json-text'>
      <JSONTree data={renameKeys(data.objects)} theme={JSONtheme} />
      {data?.root_object?.object_type === 'Subtree' && (
        <Container fluid className='analysis-tree-container'>
          <div
            style={{
              flex: '1 1 auto',
              height: '50vh',
              border: '2px solid rgb(240,240,240)',
              borderRadius: '5px',
            }}
          >
            <Tree
              data={subtreeData}
              //Used to start how deep the tree opens
              //Changed to focus on search
              translate={{ x: PageWidth / 7, y: PageHeight / 4 }}
              allowForeignObjects
              nodeSvgShape={{ shape: 'none' }}
              pathFunc='step'
              nodeSize={{
                x: NodeDistanceX,
                y: NodeDistanceY(undefined, subtreeData, true),
              }}
              nodeLabelComponent={{
                render: <NodeLabelComponent readOnly={true} />,
                //foreignObjectWrapper sets node offset in relation to node svg shape
                foreignObjectWrapper: {
                  x: NodeOffsetX,
                  y: NodeOffsetY,
                },
              }}
              separation={{
                siblings: nodeSeparationSiblings,
                nonSiblings: nodeSeparationNonSiblings,
              }}
              collapsible={false}
            />
          </div>
        </Container>
      )}
    </pre>
  );
};

export default RecycleBinExpandedComponent;
