import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CardTitle,
  Col,
  Container,
  NavbarBrand,
  NavbarToggler,
  Row,
  Spinner,
  UncontrolledCollapse,
} from 'reactstrap';
import { ProjectType, RiskType, RiskViewType } from '../../constants/Enums';
import {
  changeRiskView,
  reloadAnalysisTree,
  setProject,
} from '../../redux/actions/projects';
import { InputTemplate } from '../entities/EntityModals/InputTemplate';
import RiskTableComponents from './RiskTableComponents';
/**
 * Risk List Page.
 *
 * Main page for risk tables
 *
 * @param project: redux state mapped to props, the current project data
 * @param loading: redux state mapped to props, sets if the page is currently loading data
 * @param setProject: selects the project to be used in the page(s)
 *
 */
const RiskList = ({
  project,
  loading,
  setProject,
  profile,
  reloadAnalysisTree,
  changeRiskView,
  riskView,
  baselineMode,
}) => {
  //If page is loaded via url or new tab, loads needed data and attaches to store
  if (
    (project === undefined || project === null) &&
    loading === false &&
    profile !== null
  ) {
    var url = window.location.pathname;
    var splitUrl = url.split('/');
    var projectId = splitUrl[1];

    if (projectId !== undefined) {
      const projectTemplate = { _id: projectId };
      setProject(projectTemplate);
    }
  }

  useState(() => {
    !baselineMode && reloadAnalysisTree();
  }, []);

  const link = useMemo(
    () =>
      project?.project_type === ProjectType.ISO_21434.Value
        ? 'entities/threat-scenarios'
        : 'entities/threats',
    [project]
  );

  const LegendArray = () => {
    const legendStyle = {
      width: '150px',
      height: '360px',
      position: 'fixed',
      top: 'calc((100% - 360px)/2)',
      right: '20px',
    };
    const divSpace = <div style={{ height: '10px' }} />;
    return (
      <Col style={legendStyle}>
        Risk Level
        {divSpace}
        <div className='risk-legend-divider' />
        {divSpace}
        {ProjectType[project.project_type].ColorArrayMapping.map(
          (legendMap) => {
            return (
              <Row>
                <Col md={1}>
                  <FontAwesomeIcon
                    className='btn-icon'
                    icon={faSquareFull}
                    style={{ color: legendMap.color }}
                  />
                </Col>
                <Col md={5}>: {legendMap.legend}</Col>
                {divSpace}
              </Row>
            );
          }
        )}
      </Col>
    );
  };
  return loading || project === undefined ? (
    <Container>
      <Spinner className='spinner' />
    </Container>
  ) : (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Risk Stats of {project?.name}</h1>
      </CardTitle>
      <br></br>
      <LegendArray />
      <div className='card-actions'>
        <div style={{ maxWidth: '200px' }}>
          <InputTemplate
            label='Risk Selection'
            type='select'
            onChange={(event) => changeRiskView(event.target.value)}
            options={RiskViewType}
          />
        </div>
        <Row className='risk-header-row'>
          <Col className='icon-col'>
            <NavbarToggler id='allControlRiskToggle' className='mr-2'>
              <i className='fa fa-bars margin-auto' />
            </NavbarToggler>
          </Col>
          <Col>
            <NavbarBrand className='mr-auto'>
              <Link to={`${link}?risk=all`}>Risks with all controls</Link>
            </NavbarBrand>
          </Col>
        </Row>
        <UncontrolledCollapse toggler='#allControlRiskToggle' defaultOpen>
          <RiskTableComponents
            riskType={RiskType.allControls}
            link={`${link}?risk=all`}
          />
        </UncontrolledCollapse>
        <Row className='risk-header-row'>
          <Col className='icon-col'>
            <NavbarToggler id='implementedProposedRiskToggle' className='mr-2 '>
              <i className='fa fa-bars margin-auto' />
            </NavbarToggler>
          </Col>
          <Col>
            {' '}
            <NavbarBrand className='mr-auto'>
              <Link to={`${link}?risk=proposed`}>
                Risks with proposed controls
              </Link>
            </NavbarBrand>
          </Col>
        </Row>

        <UncontrolledCollapse
          toggler='#implementedProposedRiskToggle'
          defaultOpen
        >
          <RiskTableComponents
            riskType={RiskType.proposed}
            link={`${link}?risk=proposed`}
          />
        </UncontrolledCollapse>
        <Row className='risk-header-row'>
          <Col className='icon-col'>
            <NavbarToggler id='implementedRiskToggle' className='mr-2 '>
              <i className='fa fa-bars margin-auto' />
            </NavbarToggler>
          </Col>
          <Col>
            <NavbarBrand className='mr-auto'>
              <Link to={`${link}?risk=implemented`}>
                Risks with implemented controls
              </Link>
            </NavbarBrand>
          </Col>
        </Row>
        <UncontrolledCollapse toggler='#implementedRiskToggle' defaultOpen>
          <RiskTableComponents
            riskType={RiskType.implemented}
            link={`${link}?risk=implemented`}
          />
        </UncontrolledCollapse>
        <Row className='risk-header-row'>
          <Col className='icon-col'>
            <NavbarToggler id='noControlRiskToggle' className='mr-2 '>
              <i className='fa fa-bars margin-auto' />
            </NavbarToggler>
          </Col>
          <Col>
            <NavbarBrand className='mr-auto'>
              <Link to={`${link}?risk=no_control`}>Risks with no controls</Link>
            </NavbarBrand>
          </Col>
        </Row>
        <UncontrolledCollapse toggler='#noControlRiskToggle' defaultOpen>
          <RiskTableComponents
            riskType={RiskType.noControls}
            link={`${link}?risk=no_control`}
          />
        </UncontrolledCollapse>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  baselineMode: state.project.baselineMode,
  riskView: state.project.riskView,
  loading: state.project.loading,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {
  setProject,
  reloadAnalysisTree,
  changeRiskView,
})(RiskList);
