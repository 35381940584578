import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  CardTitle,
  Col,
  Container,
  NavbarBrand,
  NavbarToggler,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import axios from '../../Axios/axios';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { setAlert } from '../../redux/actions/alert';
import { getAdminLogs, getSecurityLogs } from '../../redux/actions/logging';
import { withSidebar } from '../../redux/actions/sidebar';
import AdminLogExpandedComponent from '../admin/AdminLogExpandedComponent';
import CustomDataTableStyles from '../layout/DataTableStyles';

// Admin SecurityLog
const SecurityLog = ({
  isVisible,
  sidebar,
  securityLogRetriever,
  adminLogRetriever,
  securityLogs,
  adminLogs,
  setAlert,
}) => {
  const userName = (row) => (
    <Fragment>
      {row?.user_name !== undefined ? row.user_name : 'System'}
    </Fragment>
  );
  const securityLogColumns = React.useMemo(() => [
    {
      name: 'User ID',
      selector: 'user_id',
      sortable: true,
      maxWidth: '50px',
      cell: (row) => (
        <Fragment>{row?.user_id !== undefined ? row.user_id : null}</Fragment>
      ),
    },
    {
      name: 'Username',
      selector: 'user_name',
      sortable: false,
      maxWidth: '200px',
      cell: (row) => userName(row),
    },
    {
      name: 'IP',
      selector: 'ip',
      sortable: true,
      maxWidth: '75px',
      cell: (row) => (
        <Fragment>{row?.ip !== undefined ? row.ip : 'System'}</Fragment>
      ),
    },
    {
      name: 'Timestamp',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '200px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      name: 'Entry type',
      selector: 'entry_type',
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'Method',
      selector: 'method',
      sortable: true,
      maxWidth: '75px',
    },
    {
      name: 'Path',
      selector: 'path',
      sortable: true,
      maxWidth: '125px',
    },
    {
      name: 'Status Code',
      selector: 'status_code',
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Error Message',
      selector: 'error_message',
      sortable: true,
      wrap: true,
      maxWidth: '200px',
    },
    {
      name: 'Body',
      sortable: true,
      cell: (row) => (
        <Fragment>{row?.body && ` Body: ${JSON.stringify(row.body)}`}</Fragment>
      ),
    },
  ]);

  const adminLogColumns = React.useMemo(() => [
    {
      name: 'User ID',
      selector: 'user_id',
      sortable: true,
      maxWidth: '50px',
      cell: (row) => (
        <Fragment>{row?.user_id !== undefined ? row.user_id : null}</Fragment>
      ),
    },
    {
      name: 'Username',
      selector: 'user_name',
      sortable: false,
      maxWidth: '200px',
      cell: (row) => userName(row),
    },
    {
      name: 'Timestamp',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '200px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      name: 'Alteration Type',
      selector: 'alteration_type',
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'Object ID',
      selector: 'object_id',
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Object Type',
      selector: 'object_type',
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Reference Tree Id',
      selector: 'reference_tree_id',
      sortable: true,
      maxWidth: '50px',
      cell: (row) => (
        <Fragment>
          {row?.reference_tree_id !== undefined ? row.reference_tree_id : 'N/A'}
        </Fragment>
      ),
    },
  ]);
  const securityLogLoadLength = 10;
  const [securityTotalEntries, setSecurityTotalEntries] = useState(undefined);
  const [securityDirection, setSecurityDirection] = useState('descending');
  const [securitySortType, setSecuritySortType] = useState('creation_date');
  const [securityRowsPerPage, setSecurityRowsPerPage] = useState(10);

  const securityChangePage = (page, totalRows) => {
    getSecurityLog(
      securitySortType,
      (page - 1) * securityRowsPerPage,
      securityRowsPerPage,
      securityDirection
    );
  };

  const securityChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setSecurityRowsPerPage(currentRowsPerPage);
    getSecurityLog(
      securitySortType,
      (currentPage - 1) * currentRowsPerPage,
      currentRowsPerPage,
      securityDirection
    );
  };

  const adminLogLoadLength = 10;
  const [globalTotalEntries, setGlobalTotalEntries] = useState(undefined);
  const [globalDirection, setGlobalDirection] = useState('descending');
  const [globalSortType, setGlobalSortType] = useState('creation_date');
  const [globalRowsPerPage, setGlobalRowsPerPage] = useState(10);

  const globalChangePage = (page, totalRows) => {
    getAdminLog(
      globalSortType,
      (page - 1) * globalRowsPerPage,
      globalRowsPerPage,
      globalDirection
    );
  };

  const globalChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setGlobalRowsPerPage(currentRowsPerPage);
    getAdminLog(
      globalSortType,
      (currentPage - 1) * currentRowsPerPage,
      currentRowsPerPage,
      globalDirection
    );
  };

  const globalOnSort = (column, sortDirection, event) => {
    const direction = sortDirection === 'desc' ? 'descending' : 'ascending';
    setGlobalSortType(column.selector);
    setGlobalDirection(direction);

    getAdminLog(column.selector, 0, adminLogLoadLength, direction);
  };

  const securityOnSort = (column, sortDirection, event) => {
    const direction = sortDirection === 'desc' ? 'descending' : 'ascending';
    setSecuritySortType(column.selector);
    setSecurityDirection(direction);

    getSecurityLog(column.selector, 0, securityLogLoadLength, direction);
  };

  useEffect(() => {
    sidebar();
  }, [isVisible]);

  useEffect(() => {
    getSecurityLog(securitySortType, 0, securityLogLoadLength, undefined);

    getAdminLog('creation_date', 0, adminLogLoadLength, undefined);
  }, []);

  const getAdminLog = async (field, index, amount, direction = 'descending') =>
    axios.log.admin
      .post('', {
        sorting: {
          direction: direction,
          limits: [index, index + amount],
          field: field,
        },
      })
      .then((response) => {
        const updatedEntries = response.data.entries;
        adminLogRetriever(updatedEntries);
        globalTotalEntries !== response.data.total_number_of_entries &&
          setGlobalTotalEntries(response.data.total_number_of_entries);
      })
      .catch((error) => {
        setAlert(
          `Error getting admin logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        );
      });

  const getSecurityLog = async (
    field,
    index,
    amount,
    direction = 'descending'
  ) =>
    axios.log.security
      .post('', {
        sorting: {
          direction: direction,
          limits: [index, index + amount],
          field: field,
        },
      })
      .then((response) => {
        const updatedEntries = response.data.entries;
        securityLogRetriever(updatedEntries);
        securityTotalEntries !== response.data.total_number_of_entries &&
          setSecurityTotalEntries(response.data.total_number_of_entries);
      })
      .catch((error) => {
        setAlert(
          `Error getting security logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        );
      });

  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Security Logs</h1>
      </CardTitle>
      <br></br>
      <div className='card-actions'>
        <Row>
          <Col className='icon-col'>
            <NavbarToggler id='SecurityLogToggle' className='mr-2'>
              <FontAwesomeIcon icon={faBars} />
            </NavbarToggler>
          </Col>
          <Col>
            <NavbarBrand className='mr-auto logs-table-name'>
              Security Logs
            </NavbarBrand>
          </Col>
        </Row>
        <UncontrolledCollapse toggler='#SecurityLogToggle' defaultOpen>
          <DataTable
            defaultSortField={securitySortType}
            defaultSortAsc={false}
            pagination
            columns={securityLogColumns}
            persistTableHead
            data={securityLogs}
            customStyles={CustomDataTableStyles}
            onSort={securityOnSort}
            paginationTotalRows={securityTotalEntries}
            paginationServer={true}
            onChangePage={securityChangePage}
            onChangeRowsPerPage={securityChangeRowsPerPage}
            sortServer
          />
        </UncontrolledCollapse>

        <Row>
          <Col className='icon-col'>
            <NavbarToggler id='AdminLogToggle' className='mr-2'>
              <FontAwesomeIcon icon={faBars} />
            </NavbarToggler>
          </Col>
          <Col>
            <NavbarBrand className='mr-auto logs-table-name'>
              Global Change Logs
            </NavbarBrand>
          </Col>
        </Row>
        <UncontrolledCollapse toggler='#AdminLogToggle' defaultOpen>
          <DataTable
            defaultSortField={globalSortType}
            defaultSortAsc={false}
            pagination
            columns={adminLogColumns}
            persistTableHead
            data={adminLogs}
            expandableRowsComponent={
              <AdminLogExpandedComponent data={adminLogs} />
            }
            expandOnRowClicked
            expandableRows
            customStyles={CustomDataTableStyles}
            onSort={globalOnSort}
            paginationTotalRows={globalTotalEntries}
            paginationServer={true}
            onChangePage={globalChangePage}
            onChangeRowsPerPage={globalChangeRowsPerPage}
            sortServer
          />
        </UncontrolledCollapse>
      </div>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  sideBarVisible: state.sidebar.isVisible,
  securityLogs: state.logging.securityLogs,
  adminLogs: state.logging.adminLogs,
});

export default connect(mapStateToProps, {
  isVisible: true,
  sidebar: withSidebar,
  securityLogRetriever: getSecurityLogs,
  adminLogRetriever: getAdminLogs,
  setAlert,
})(SecurityLog);
