import { default as React, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { importCatalog } from '../../redux/actions/catalog';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';

const ImportCatalog = ({ modal, toggle }) => {
  const { dispatch } = store;
  const [formData, setFormData] = useState([]);

  const handleChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (event) => {
      setFormData(event.target.result);
    };
  };
  const submit = () => {
    dispatch(importCatalog(formData));
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Import New Catalog</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Select File</Label>
              <Input
                type='file'
                name='file'
                id='exampleFile'
                accept='.xml, .xsam' // Accept only .xml files
                onChange={handleChange}
              />
              <FormText color='muted'>
                <i>*File must be in .xml format</i>
              </FormText>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button
              className='btn-add'
              onClick={() => {
                submit();
                toggle();
              }}
            >
              Import
            </Button>
            <Button className='btn-danger' onClick={toggle}>
              Cancel
            </Button>
          </FormGroup>
        </ModalFooter>
      </DraggableModal>
    </Fragment>
  );
};

export default ImportCatalog;
