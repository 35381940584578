import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  UncontrolledTooltip,
} from 'reactstrap';
import { CatalogType } from '../../constants/Enums';
import { setCatalogEntry } from '../../redux/actions/catalog';
import store from '../../redux/store';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AddEntry from './AddEntry';
import ExpandedComponent from './ExpandedComponent';
import { ModalConfirmation } from './ModalConfirmation';

export const EntriesPanel = ({
  catalog,
  openPanel,
  toggle,
  refTrees,
  profile,
}) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [deleteRow, setDeleteRow] = useState(null);

  const [data, setData] = useState([]);
  const history = useHistory();
  const { dispatch } = store;
  useEffect(() => {
    const entries = [];
    if (Array.isArray(refTrees) && Array.isArray(catalog?.children)) {
      for (const refTreeId of catalog?.children) {
        const refTree = refTrees.find(
          (ref) => parseInt(ref?._id) === parseInt(refTreeId)
        );
        if (refTree) {
          entries.push(refTree);
        }
      }
    }
    setData(entries);
  }, [catalog, refTrees]);

  const hasRole = (role) =>
    catalog?.participants
      .find((member) => parseInt(member.user_id) === parseInt(profile?.id))
      ?.role.includes(role);

  const entryHeader = useMemo(
    () => [
      {
        name: 'ID',
        selector: '_id',
        sortable: true,
        width: '75px',
      },
      {
        name: 'Name',
        sortable: true,
        selector: 'name',
        cell: (row) => (
          <Link
            to = '#'
            onClick={() => {
              history.push(`entry/${row._id}`);
              dispatch(setCatalogEntry(row, catalog));
            }}
          >
            {row?.name}
          </Link>
        ),
      },
      {
        name: 'Description',
        cell: (row) => (
          <>
            {row.description?.length > 50 ? (
              <>
                <div id={`description${row._id}`}>
                  {row.description.substr(0, 50)}...
                </div>
                <UncontrolledTooltip target={`description${row._id}`}>
                  {row.description}
                </UncontrolledTooltip>
              </>
            ) : (
              <>{row.description} </>
            )}
          </>
        ),
      },
      {
        name: 'Last Modified',
        selector: 'last_modified',
        sortable: true,
        width: '160px',
      },
      {
        name: 'Actions',
        cell: (row) => (
          <>
            {catalog.content_type === CatalogType.ReferenceTree ? (
              <>
                <Button
                  id={`reftreeButton${row?._id}`}
                  onClick={() => {
                    catalog.project_id !== undefined
                      ? window.open(
                          `${window.location.origin}/${catalog?.project_id}/analysisTree/?rootId=${row?.current_version}`
                        )
                      : window.open(
                          `${window.location.origin}/catalogues/${catalog?._id}/reftree/${row?._id}`
                        );
                  }}
                >
                  <i className='fa fa-sitemap' />
                </Button>
                <UncontrolledTooltip target={`reftreeButton${row?._id}`}>
                  View Reference Tree
                </UncontrolledTooltip>
              </>
            ) : null}

            {hasRole('owner') || hasRole('editor') ? (
              <>
                <Button
                  id={`editButton${row?._id}`}
                  onClick={
                    catalog.content_type === CatalogType.Control
                      ? () => {
                          history.push(`entry/${row?._id}?edit=true`);
                          dispatch(setCatalogEntry(row, catalog));
                        }
                      : async () => {
                          window.open(
                            `${window.location.origin}/catalogues/${catalog?._id}/reftree/${row?._id}?edit=true`
                          );
                        }
                  }
                >
                  <i className='fa fa-edit' />
                </Button>
                <UncontrolledTooltip target={`editButton${row?._id}`}>
                  Edit
                </UncontrolledTooltip>
              </>
            ) : null}
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        cell: (row) => (
          <>
            {hasRole('owner') || hasRole('editor') ? (
              <>
                <Button
                  id={`deleteButton${row?._id}`}
                  className='btn-danger'
                  onClick={() => {
                    toggleModal();
                    setDeleteRow(row);
                  }}
                >
                  <i className='fa fa-trash' />
                </Button>
                <UncontrolledTooltip target={`deleteButton${row?._id}`}>
                  Delete
                </UncontrolledTooltip>
              </>
            ) : null}
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const [modalAddEntry, setModalAddEntry] = useState(false);
  const toggleAddEntry = () => {
    setModalAddEntry(!modalAddEntry);
  };

  return (
    <div>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        List of Entries
      </CardHeader>

      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody style={{ zIndex: 0 }}>
            <div
              style={{ marginTop: '-10px' }}
              className='card-actions float-right'
            >
              {(hasRole('owner') || hasRole('editor')) && (
                <Button
                  className='btn-add'
                  onClick={() => {
                    toggleAddEntry();
                  }}
                  x
                >
                  Add new entry
                </Button>
              )}
              <AddEntry
                toggle={toggleAddEntry}
                modal={modalAddEntry}
                catalog={catalog}
              />
            </div>
            <div style={{ marginTop: '-30px' }}>
              <DataTable
                columns={entryHeader}
                data={data}
                pagination
                persistTableHead
                expandableRows
                expandableRowsHideExpander
                expandableRowsComponent={<ExpandedComponent data={[]} />}
                customStyles={CustomDataTableStyles}
              />
            </div>
          </CardBody>
        </Card>
      </Collapse>
      <ModalConfirmation toggle={toggleModal} modal={modal} row={deleteRow} />
    </div>
  );
};
