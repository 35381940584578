import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { ProjectType } from '../../constants/Enums';
import { threatCounter } from '../helpers/genericHelper';

/**
 * Risk table component
 *
 * A table component for risks
 *
 * @param project: redux state mapped to props, the current project data
 * @param riskType: added by parent component, used to set the value used by the tables for likelihood
 * @param threats: redux state mapped to props, the lists of threats from the project
 *
 */
const RiskTableComponents = ({
  project,
  riskType,
  threats,
  link,
  vulnerabilities,
  riskView,
}) => {
  const colorTemplate = (projectType, columnIndex, rowIndex) => {
    return ProjectType[projectType].ColorArray[columnIndex][rowIndex];
  };

  const riskCount = (feasibilityIndex, impactLevel) => {
    const feasibility =
      ProjectType[project.project_type].AttackFeasibility[feasibilityIndex];
    let data = [];
    if (project.project_type === ProjectType.ISO_21434.Value) {
      for (const threat of threats) {
        data = data.concat(
          vulnerabilities.filter((vul) =>
            threat?.children.some(
              (child) => parseInt(child) === parseInt(vul._id)
            )
          )
        );
      }
    } else {
      data = threats;
    }
    return threatCounter(
      data,
      impactLevel,
      feasibility.lower,
      feasibility.upper,
      riskType,
      riskView
    );
  };

  return (
    <Fragment>
      <Table className='risk-table'>
        <thead>
          <tr>
            <td rowSpan={2} colSpan={2}></td>
            {/* likelihood */}
            <th
              style={{ background: 'none' }}
              colSpan={
                ProjectType[project.project_type].AttackFeasibility.length
              }
            >
              {ProjectType[project.project_type].RiskColumnText}
            </th>
          </tr>
          {/* likelihood header cells*/}
          <tr>
            {ProjectType[project.project_type].AttackFeasibility.map(
              (column) => {
                return (
                  <th style={{ width: '120px' }} className='th-background'>
                    {column.value}
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {/* Impact */}
          <th
            scope='row'
            style={{ width: '120px', background: '#FFF', lineHeight: '10' }}
            rowSpan={
              Object.entries(ProjectType[project.project_type].ImpactLevelTypes)
                .length + 1
            }
          >
            {ProjectType[project.project_type].RiskRowText}
          </th>
          {Object.entries(
            ProjectType[project.project_type].ImpactLevelTypes
          ).map((columnArray, columnIndex) => {
            return (
              <tr>
                <th
                  style={{ width: '120px' }}
                  scope='row'
                  className=' th-background'
                >
                  {columnArray[1]}
                </th>
                {ProjectType[project.project_type].AttackFeasibility.map(
                  (cell, rowIndex) => {
                    return (
                      <td
                        style={{
                          backgroundColor: colorTemplate(
                            project.project_type,
                            columnIndex,
                            rowIndex
                          ),
                        }}
                      >
                        <a
                          href={`${link}&impact_level=${columnArray[1]}&feasibility=${cell.value}`}
                          style={{ color: 'black' }}
                        >
                          {riskCount(rowIndex, columnIndex)}
                        </a>
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  riskView: state.project.riskView,
  threats: state.threats.threats,
  vulnerabilities: state.vulnerabilities.vulnerabilities,
});

export default connect(mapStateToProps, null)(RiskTableComponents);
