import { FormControlLabel, Switch } from '@material-ui/core';
import { default as React, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import { exportReviewChecklist } from '../../redux/actions/projects.js';
/**
 * Export.
 *
 * Modal for exporting
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
export const ExportReviewModal = ({
  modal,
  toggle,
  currentProject,
  loading,
}) => {
  const [filename, setFilename] = useState(undefined);
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const downloadData = async () => {
    const downloadUrlToSet = await exportReviewChecklist(currentProject);
    setDownloadUrl(downloadUrlToSet);
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Export Project</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Filename</Label>
              <Input
                type='text'
                onChange={(event) => setFilename(`${event.target.value}.xlsx`)}
              />
            </FormGroup>
            <FormGroup>
              <Button
                className='float-right btn-add'
                onClick={() => {
                  downloadData();
                }}
              >
                Export
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
          <Form>
            <br />
            <br />
            <div>
              {downloadUrl ? (
                <>
                  <Label>Download File: </Label>
                  <h3>
                    <a
                      download={filename}
                      href={downloadUrl}
                      rel='noopener noreferrer'
                    >
                      {filename}
                    </a>
                  </h3>

                  <Label>
                    Right-click this link and choose Save link as to choose
                    download location or change filename
                  </Label>
                </>
              ) : null}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
});

export default ExportReviewModal;
