import axios from '../../Axios/axios';
import { DefaultErrorHandling } from '../../components/helpers/genericHelper';
import { CatalogType } from '../../constants/Enums';
import {
  SET_ENTITY,
  SET_PROJECT,
  SET_PROJECT_COMMENTS,
  SET_PROJECT_CONTROL_CATALOG,
  SET_PROJECT_REFTREE_CATALOG,
  UPDATE_CATALOG,
} from './types';

// get comments for viewing
export const getComments = async (entity) => {
  const newEntity = Object.assign({}, entity);
  const newComments = [];
  if (Array.isArray(newEntity.comments?.content)) {
    const comments = newEntity.comments?.content;
    for (const comment of comments) {
      if (Array.isArray(comment?.children)) {
        for (let index = 0; index < comment.children.length; index++) {
          const replyIndex = comments.findIndex(
            (reply) => parseInt(reply.id) === parseInt(comment.children[index])
          );
          if (replyIndex >= 0) {
            comment.children[index] = comments[replyIndex];
            comments[replyIndex].child = true;
          }
        }
      }
      newComments.push(comment);
    }
  }
  newEntity.comments = newComments;
  return newEntity;
};

// add new comment
export const addComment = (entity, comments, type) => async (dispatch) => {
  let payload = {};
  if (comments?.id >= 0) {
    payload = {
      parentId: comments.id,
      text: comments.replyText,
    };
  } else {
    payload = {
      text: comments,
    };
  }
  if (type === 'entity') {
    const requestString = `${entity._id}/comment/`;
    axios.entity
      .put(requestString, payload)
      .then((response) => {
        EntityUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else if (type === 'project') {
    const requestString = `${entity?._id}/comment/`;
    axios.project
      .put(requestString, payload)
      .then((response) => {
        ProjectCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else {
    const requestString = `${entity._id}/comment/`;
    axios.catalog
      .put(requestString, payload)
      .then(() => {
        CatalogCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  }
};

// update comment
export const updateComment = (entity, comments, type) => async (dispatch) => {
  const payload = {
    text: comments.editText,
  };
  if (type === 'entity') {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.entity
      .patch(requestString, payload)
      .then(() => {
        EntityUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else if (type === 'project') {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.project
      .patch(requestString, payload)
      .then(() => {
        ProjectCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.catalog
      .patch(requestString, payload)
      .then(() => {
        CatalogCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  }
};

// delete comment
export const deleteComment = (entity, comments, type) => async (dispatch) => {
  if (type === 'entity') {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.entity
      .delete(requestString)
      .then(() => {
        EntityUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else if (type === 'project') {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.project
      .delete(requestString)
      .then((response) => {
        ProjectCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  } else {
    const requestString = `${entity._id}/comment/${comments.id}`;
    axios.catalog
      .delete(requestString)
      .then(() => {
        CatalogCommentUpdate(entity, dispatch);
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  }
};

const CatalogCommentUpdate = (entity, dispatch) => {
  axios.catalog
    .get(entity._id)
    .then(async (response) => {
      const entityObject = response.data;
      entityObject.catalog = await getComments(entityObject.catalog);
      entityObject._id = entity._id;
      dispatch({
        type: UPDATE_CATALOG,
        payload: entityObject.catalog,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

const ProjectCommentUpdate = async (entity, dispatch) => {
  axios.project
    .get(entity._id)
    .then(async (response) => {
      const entityObject = response.data;
      entityObject.project = await getComments(entityObject.project);
      entityObject._id = entity._id;
      const refTreeCatalogs = entityObject.catalogs?.map(
        (catalog) => catalog.content_type === CatalogType.ReferenceTree
      );
      const controlCatalogs = entityObject.catalogs?.map(
        (catalog) => catalog.content_type === CatalogType.Control
      );
      dispatch({
        type: SET_PROJECT,
        payload: { ...entityObject.project, _id: entityObject._id },
      });
      dispatch({
        type: SET_PROJECT_REFTREE_CATALOG,
        payload: refTreeCatalogs,
      });
      dispatch({
        type: SET_PROJECT_CONTROL_CATALOG,
        payload: controlCatalogs,
      });
      dispatch({
        type: SET_PROJECT_COMMENTS,
        payload: entityObject.project.comments,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

const EntityUpdate = (entity, dispatch) => {
  axios.entity
    .get(entity._id)
    .then(async (response) => {
      const entityObject = response.data;
      let newEntity = Object.assign({}, entity);
      newEntity.comments = entityObject.comments;
      newEntity = await getComments(newEntity);
      dispatch({
        type: SET_ENTITY,
        payload: newEntity,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};
