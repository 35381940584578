import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProjectRole } from '../../constants/Enums';
import { logout } from '../../redux/actions/auth';
import {
  getCatalogBaselines,
  setBaselineCatalog,
  setCatalog,
} from '../../redux/actions/catalog';
import { getProfiles, getReftrees } from '../../redux/actions/profile';
import { setProject } from '../../redux/actions/projects';
import { cancelReroute, cancelUnsavedChanges } from '../../redux/actions/save';
import ChangeLogPanel from '../admin/ChangeLogPanel';
import RecycleBinPanel from '../admin/RecycleBinPanel';
import AddBaseline from '../baseline/AddBaseline';
import RevertBaseline from '../baseline/RevertBaseline';
import ViewBaseline from '../baseline/ViewBaseline';
import { SaveAll } from '../projects/project-misc/SaveAll';
import CatalogComments from './CatalogCommentsPanel';
import DescriptionPanel from './DescriptionPanel';
import { EntriesPanel } from './EntriesPanel';
import CatalogNamePanel from './NamePanel';
import UsersPanel from './UsersPanel';

/**
 * Project Detail Page
 *
 * Page that shows the details of each project
 * TODO: Editing of project should be done in this page
 *
 * @param loading: loads the spinner if project cannot be retrieved
 * @param project: currently selected project
 *
 */
const CatalogDetails = ({
  catalog,
  catalogRole,
  project,
  refTrees,
  profile,
  routeParams,
  unsavedChanges,
  getCatalogBaselines,
  cancelReroute,
  cancelUnsavedChanges,
  logout,
  setProject,
  setCatalog,
  getReftrees,
  getProfiles,
  setBaselineCatalog,
}) => {
  const catalogTypeLabel =
    catalog?.catalog_type === 'project_specific' ||
    catalog?.catalog_type === 'default'
      ? 'Project'
      : catalog?.catalog_type;

  useEffect(() => {
    if (catalog !== undefined) {
      getCatalogBaselines(catalog._id);
    }
  }, [catalog]);
  const [openDescription, setDescription] = useState(true);
  const toggleDescription = () => setDescription(!openDescription);
  const [openComments, setComments] = useState(true);
  const toggleComments = () => setComments(!openComments);
  const [openUsers, setUsers] = useState(true);
  const toggleUsers = () => setUsers(!openUsers);
  const [openEntries, setEntries] = useState(true);
  const toggleEntries = () => setEntries(!openEntries);
  const [openDetails, setDetails] = useState(true);
  const toggleDetails = () => setDetails(!openDetails);
  const [openChangelog, setChangelog] = useState(true);
  const toggleChangelog = () => setChangelog(!openChangelog);
  const [openRecyclebin, setRecyclebin] = useState(false);
  const toggleRecyclebin = () => setRecyclebin(!openRecyclebin);
  const { catalogId } = useParams();
  const query = new URLSearchParams(window.location.search);
  const baselineId = query.get('baselineId');

  useEffect(() => {
    if (refTrees === undefined) {
      getReftrees(catalog?.project_id);
    }
    if (profile !== null) {
      if (catalog === undefined) {
        const payload = {
          _id: catalogId,
        };
        baselineId
          ? setBaselineCatalog(payload, baselineId)
          : setCatalog(payload);
      }
    }
    if (catalog?.project_id !== undefined) {
      if (project === undefined) {
        setProject({ _id: catalog.project_id });
      }
    }
  }, [catalog, profile]);

  const [dropdownOpen, setOpen] = useState(false);
  const toggleDropDown = () => setOpen(!dropdownOpen);

  const [baselineModal, setBaselineModal] = useState(false);
  const baselineToggle = () => {
    setBaselineModal(!baselineModal);
  };
  const [baselineViewModal, setBaselineViewModal] = useState(false);
  const baselineViewToggle = () => {
    setBaselineViewModal(!baselineViewModal);
  };
  const [baselineRevertModal, setBaselineRevertModal] = useState(false);
  const baselineRevertToggle = () => {
    setBaselineRevertModal(!baselineRevertModal);
  };

  const coloumnData = (label, data, widthPx) => {
    return (
      <div style={{ width: widthPx }}>
        <Col>
          <div className='bold'>{label}</div>
          <h4 className='bold'>{data}</h4>
        </Col>
      </div>
    );
  };
  const [save, setSave] = useState(false);
  const history = useHistory();
  const decision = (type) => {
    if (type === 'Cancel') {
      cancelReroute();
    }
    if (type === 'OK') {
      if (routeParams.link === 'logout') {
        logout();
        history.push('/');
      } else {
        history.push(routeParams.link);
      }
      cancelReroute();
      cancelUnsavedChanges();
    }
    if (type === 'Save All') {
      setSave(true);
      cancelReroute();
      cancelUnsavedChanges();
    }
  };

  window.onbeforeunload = function (event) {
    if (unsavedChanges?.length > 0) {
      event.preventDefault();
      return 'Leaving this page will reset the wizard';
    } else {
      return;
    }
  };

  const reviewerText = 'The user has to be reviewer or higher for this action.';
  return (
    <Container fluid='md'>
      <div style={{ marginTop: 15 }} className='card-actions float-right'>
        {catalog?.project_id === undefined && (
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropDown}
            color='primary'
          >
            <DropdownToggle className='btn-add' caret>
              Actions
            </DropdownToggle>
            <DropdownMenu style={{ marginLeft: '-75px' }}>
              <Fragment>
                <DropdownItem
                  id='NewBaselineTooltip'
                  disabled={
                    catalogRole !== ProjectRole.Owner.value &&
                    catalogRole !== ProjectRole.Editor.value &&
                    catalogRole !== ProjectRole.Demo.value &&
                    catalogRole !== ProjectRole.Reviewer.value
                  }
                  onClick={baselineToggle}
                >
                  Create New
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    catalogRole === ProjectRole.Owner.value ||
                    catalogRole === ProjectRole.Editor.value ||
                    catalogRole === ProjectRole.Demo.value ||
                    catalogRole === ProjectRole.Reviewer.value
                  }
                  target={'NewBaselineTooltip'}
                >
                  {reviewerText}
                </UncontrolledTooltip>

                <DropdownItem
                  id='ViewTooltip'
                  disabled={
                    catalogRole !== ProjectRole.Owner.value &&
                    catalogRole !== ProjectRole.Editor.value &&
                    catalogRole !== ProjectRole.Demo.value &&
                    catalogRole !== ProjectRole.Reviewer.value
                  }
                  onClick={baselineViewToggle}
                >
                  View/Delete Baseline
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    catalogRole === ProjectRole.Owner.value ||
                    catalogRole === ProjectRole.Editor.value ||
                    catalogRole === ProjectRole.Demo.value ||
                    catalogRole === ProjectRole.Reviewer.value
                  }
                  target={'ViewTooltip'}
                >
                  {reviewerText}
                </UncontrolledTooltip>
                <DropdownItem
                  id='RevertTooltip'
                  disabled={
                    catalogRole !== ProjectRole.Owner.value &&
                    catalogRole !== ProjectRole.Editor.value &&
                    catalogRole !== ProjectRole.Reviewer.value
                  }
                  onClick={baselineRevertToggle}
                >
                  Revert To Baseline
                </DropdownItem>
                <UncontrolledTooltip
                  disabled={
                    catalogRole === ProjectRole.Owner.value ||
                    catalogRole === ProjectRole.Editor.value ||
                    catalogRole === ProjectRole.Demo.value ||
                    catalogRole === ProjectRole.Reviewer.value
                  }
                  target={'RevertTooltip'}
                >
                  {reviewerText}
                </UncontrolledTooltip>
              </Fragment>
            </DropdownMenu>

            <AddBaseline
              modal={baselineModal}
              toggle={baselineToggle}
              catalogBaseline={true}
            />
            <ViewBaseline
              modal={baselineViewModal}
              toggle={baselineViewToggle}
              catalogBaseline={true}
            />
            <RevertBaseline
              modal={baselineRevertModal}
              toggle={baselineRevertToggle}
              catalogBaseline={true}
            />
          </ButtonDropdown>
        )}
      </div>
      <CardTitle>
        <h1>Catalog Details [#{catalog?._id}]</h1>
      </CardTitle>
      <br />
      <Row>
        {coloumnData('', '', 20)}
        <CatalogNamePanel data={catalog} type='catalog' save={save} />
        {coloumnData('Type', catalogTypeLabel, 200)}
      </Row>

      <br />

      <DescriptionPanel
        toggle={toggleDescription}
        openPanel={openDescription}
        data={catalog}
        type='catalog'
        save={save}
        catalogRole={catalogRole}
      />
      <br />

      <EntriesPanel
        catalog={catalog}
        toggle={toggleEntries}
        openPanel={openEntries}
        refTrees={refTrees}
        profile={profile}
      />

      <CatalogComments
        toggle={toggleComments}
        openPanel={openComments}
        editable={catalogRole !== ProjectRole.Reader.value}
      />

      {catalog?.catalog_type === 'global' ? (
        <UsersPanel data={catalog} toggle={toggleUsers} openPanel={openUsers} />
      ) : null}

      <ChangeLogPanel
        catalog={catalog}
        toggle={toggleChangelog}
        openPanel={openChangelog}
      />
      {catalogRole === ProjectRole.Owner.value && (
        <RecycleBinPanel toggle={toggleRecyclebin} openPanel={openRecyclebin} />
      )}
      {catalog?.project_id !== undefined ? (
        <Fragment>
          <CardHeader
            style={{ cursor: 'pointer' }}
            onClick={toggleDetails}
            className='bold'
          >
            {' '}
            {openDetails ? (
              <i className='fa fa-caret-down' />
            ) : (
              <i className='fa fa-caret-right' />
            )}{' '}
            {'  '}Project Details
          </CardHeader>{' '}
          <Collapse isOpen={openDetails}>
            <Card>
              <CardBody>
                <Col>Project: {catalog?.project_id}</Col>
                <Col>Name: {project?.name}</Col>
              </CardBody>
            </Card>
          </Collapse>
        </Fragment>
      ) : null}

      <br />
      <br />
      <br />
      <br />

      <SaveAll modal={routeParams?.reRoute} decision={decision} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  catalog: state.catalog.catalog,
  catalogRole: state.catalog.catalogRole,
  project: state.project.project,
  refTrees: state.profile.userReftrees,
  profile: state.profile.profile,
  routeParams: state.save.routeParams,
  unsavedChanges: state.save.unsavedChages,
});

export default connect(mapStateToProps, {
  CatalogDetails,
  cancelReroute,
  cancelUnsavedChanges,
  logout,
  setProject,
  setCatalog,
  getReftrees,
  getCatalogBaselines,
  setBaselineCatalog,
})(CatalogDetails);
