import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeSettings,
  insertSettings,
  getSettings,
} from '../../redux/actions/settings';
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Input,
  FormGroup,
  Label,
  CardTitle,
  NavbarToggler,
  NavbarBrand,
  UncontrolledCollapse,
} from 'reactstrap';

const initialState = {
  isat_uri: '',
  mail_server: '',
  mail_port: '',
  mail_use_tls: '',
  mail_username: '',
  mail_password: '',
  mail_default_sender: '',
  ldap_server: '',
  ldap_bind_username: '',
  ldap_bind_password: '',
  ldap_base: '',
  ldap_name_attribute: '',
  ldap_user_filter: '',
  ldap_active: false,
};

const Settings = () => {
  const dispatch = useDispatch();
  const [startUp, setStartUp] = useState(true);
  const { settings, env } = useSelector((state) => state.settings);
  const [environment, setEnvironment] = useState(env);
  const [state, setState] = useState(settings || initialState);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    setState(settings);
    setEnvironment(env);
  }, [settings, env]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSwitchChange = () => {
    setState({ ...state, ldap_active: !state.ldap_active });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const fieldsToSubmit = { ...state };
    for (const key in fieldsToSubmit) {
      if (environment && environment[key] !== undefined) {
        delete fieldsToSubmit[key];
      }
    }
    dispatch(insertSettings(fieldsToSubmit));
    dispatch(getSettings());
  };

  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Settings</h1>
      </CardTitle>
      <br />
      <Row>
        <Col className='icon-col'>
          <NavbarToggler id='SettingsToggler' className='mr-2'>
            <FontAwesomeIcon icon={faBars} />
          </NavbarToggler>
        </Col>
        <Col>
          <NavbarBrand className='mr-auto logs-table-name'>
            Admin Settings
          </NavbarBrand>
        </Col>
      </Row>
      <UncontrolledCollapse toggler='#SettingsToggler' defaultOpen>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for='isat_uri' className='mb-2 mt-2'>
                  ISAT URI
                </Label>
                <Input
                  id='isat_uri'
                  name='isat_uri'
                  value={state.isat_uri ?? ''}
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
                <Label for='mail_server' className='mb-2 mt-2'>
                  Mail Server
                </Label>
                <Input
                  id='mail_server'
                  name='mail_server'
                  value={state.mail_server ?? ''}
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
                <Label for='mail_port' className='mb-2 mt-2'>
                  Mail Port
                </Label>
                <Input
                  id='mail_port'
                  name='mail_port'
                  value={state.mail_port ?? ''}
                  type='number'
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
                <Label for='mail_use_tls' className='mb-2 mt-2'>
                  Mail Use TLS
                </Label>
                <Input
                  id='mail_use_tls'
                  name='mail_use_tls'
                  value={state.mail_use_tls ?? ''}
                  type='select'
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                >
                  <option value='' disabled hidden>
                    Select one
                  </option>
                  <option>True</option>
                  <option>False</option>
                </Input>
                <Label for='mail_username' className='mb-2 mt-2'>
                  Mail Username
                </Label>
                <Input
                  id='mail_username'
                  name='mail_username'
                  value={state.mail_username ?? ''}
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
                <Label for='mail_password' className='mb-2 mt-2'>
                  Mail Password
                </Label>
                <Input
                  id='mail_password'
                  name='mail_password'
                  value={state.mail_password ?? ''}
                  type='password'
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
                <Label for='mail_default_sender' className='mb-2 mt-2'>
                  Mail Default Sender
                </Label>
                <Input
                  id='mail_default_sender'
                  name='mail_default_sender'
                  value={state.mail_default_sender ?? ''}
                  onChange={handleChange}
                  className='mb-2 mt-2'
                  title={
                    environment
                      ? 'This field cannot be edited as it is an environment parameter'
                      : ''
                  }
                  disabled={environment}
                />
              </FormGroup>

              <FormGroup check className='mb-2 mt-2'>
                <Label check>
                  <Input
                    type='checkbox'
                    id='ldap_active'
                    name='ldap_active'
                    checked={state.ldap_active}
                    onChange={handleSwitchChange}
                  />
                  {' LDAP Active'}
                </Label>
              </FormGroup>

              {state.ldap_active && (
                <>
                  {/* LDAP settings */}
                  <Label for='ldap_server' className='mb-2 mt-2'>
                    LDAP Server
                  </Label>
                  <Input
                    id='ldap_server'
                    name='ldap_server'
                    value={state.ldap_server ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                  <Label for='ldap_bind_username' className='mb-2 mt-2'>
                    LDAP Bind DN
                  </Label>
                  <Input
                    id='ldap_bind_username'
                    name='ldap_bind_username'
                    value={state.ldap_bind_username ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                  <Label for='ldap_bind_password' className='mb-2 mt-2'>
                    LDAP Bind Password
                  </Label>
                  <Input
                    id='ldap_bind_password'
                    name='ldap_bind_password'
                    value={state.ldap_bind_password ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                  <Label for='ldap_base' className='mb-2 mt-2'>
                    LDAP Base
                  </Label>
                  <Input
                    id='ldap_base'
                    name='ldap_base'
                    value={state.ldap_base ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                  <Label for='ldap_user_filter' className='mb-2 mt-2'>
                    LDAP User Filter
                  </Label>
                  <Input
                    id='ldap_user_filter'
                    name='ldap_user_filter'
                    value={state.ldap_user_filter ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                  <Label for='ldap_name_attribute' className='mb-2 mt-2'>
                    LDAP Name Attribute
                  </Label>
                  <Input
                    id='ldap_name_attribute'
                    name='ldap_name_attribute'
                    value={state.ldap_name_attribute ?? ''}
                    onChange={handleChange}
                    className='mb-2 mt-2'
                    title={
                      environment
                        ? 'This field cannot be edited as it is an environment parameter'
                        : ''
                    }
                    disabled={environment}
                  />
                </>
              )}
            </Form>
            {!environment && (
              <Button
                className='btn-add'
                type='submit'
                color='success'
                outline
                style={{
                  display: 'block',
                  marginLeft: 0,
                  marginRight: 'auto',
                  marginTop: '1rem',
                  marginBottom: 0,
                  width: '10%',
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Col>
        </Row>
      </UncontrolledCollapse>
    </Container>
  );
};

export default Settings;
