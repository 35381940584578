import { LogType } from '../../constants/Enums';
import {
  EMPTY_RECYCLE_BIN,
  LOAD_ADMIN_DELETION_LOGS,
  LOAD_ADMIN_LOGS,
  LOAD_CATALOG_CHANGE_LOGS,
  LOAD_CATALOG_DELETION_LOGS,
  LOAD_DELETED_USERS,
  LOAD_PROJECT_CHANGE_LOGS,
  LOAD_PROJECT_DELETION_LOGS,
  LOAD_SECURITY_LOGS,
  REMOVE_RECYCLE_BIN_ENTRY,
  RESTORE_DELETED_USER,
} from '../actions/types';

const initialState = {
  securityLogs: undefined,
  adminLogs: undefined,
  adminDeletionLogs: undefined,
  projectDeletionLogs: undefined,
  catalogDeletionLogs: undefined,
  projectChangeLogs: undefined,
  catalogChangeLogs: undefined,
  deletedUsers: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let projectBin = Object.assign([], state.projectDeletionLogs);
  let catalogBin = Object.assign([], state.catalogDeletionLogs);
  let adminBin = Object.assign([], state.adminDeletionLogs);
  switch (type) {
    case LOAD_SECURITY_LOGS:
      return {
        ...state,
        securityLogs: payload,
      };
    case LOAD_ADMIN_LOGS:
      return {
        ...state,
        adminLogs: payload,
      };
    case LOAD_ADMIN_DELETION_LOGS:
      return {
        ...state,
        adminDeletionLogs: payload,
      };
    case LOAD_PROJECT_DELETION_LOGS:
      return {
        ...state,
        projectDeletionLogs: payload,
      };
    case LOAD_CATALOG_DELETION_LOGS:
      return {
        ...state,
        catalogDeletionLogs: payload,
      };
    case LOAD_PROJECT_CHANGE_LOGS:
      return {
        ...state,
        projectChangeLogs: payload,
      };
    case LOAD_CATALOG_CHANGE_LOGS:
      return {
        ...state,
        catalogChangeLogs: payload,
      };
    case REMOVE_RECYCLE_BIN_ENTRY:
      projectBin = projectBin.filter((entry) => entry._id !== payload);
      catalogBin = catalogBin.filter((entry) => entry._id !== payload);
      adminBin = adminBin.filter((entry) => entry._id !== payload);
      return {
        ...state,
        projectDeletionLogs: projectBin,
        catalogDeletionLogs: catalogBin,
        adminDeletionLogs: adminBin,
      };

    case EMPTY_RECYCLE_BIN:
      projectBin = projectBin.filter((entry) => entry._id !== payload);
      catalogBin = catalogBin.filter((entry) => entry._id !== payload);
      adminBin = adminBin.filter((entry) => entry._id !== payload);
      switch (payload) {
        case LogType.Admin:
          adminBin = [];
          break;
        case LogType.Catalog:
          catalogBin = [];
          break;
        case LogType.Project:
          projectBin = [];
          break;

        default:
          break;
      }
      return {
        ...state,
        projectDeletionLogs: projectBin,
        catalogDeletionLogs: catalogBin,
        adminDeletionLogs: adminBin,
      };
    case LOAD_DELETED_USERS:
      return {
        ...state,
        deletedUsers: payload,
      };
    case RESTORE_DELETED_USER:
      let deletedUsers = Object.assign([], state.deletedUsers);
      deletedUsers = deletedUsers.filter((user) => user._id !== payload._id);
      return {
        ...state,
        deletedUsers: deletedUsers,
      };
    default:
      return state;
  }
}
