import React, { Fragment, useState } from 'react';
import { Item, Menu, MenuProvider, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  FormGroup,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  CopyMoveType,
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  OwnerType,
  ProjectRole,
  ProjectType,
  RefTreeActionType,
  RefTreeModType,
  RiskDecision,
} from '../../constants/Enums';
import { GenerateNodeId } from '../../constants/StringConstants';
import {
  copySubtree,
  cutSubtree,
  pasteSubtree,
  SetRefTreeParams,
} from '../../redux/actions/analysistree';
import { setEntity } from '../../redux/actions/entities';
import { SET_NODE_DATA } from '../../redux/actions/types';
import store from '../../redux/store';
import AddControlFromCatalogModal from '../entities/EntityModals/AddControlFromCatalogModal';
import AssetModal from '../entities/EntityModals/AssetModal';
import ControlCatalogModal from '../entities/EntityModals/ControlCatalogModal';
import ControlModal from '../entities/EntityModals/ControlModal';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import RefTreeCatalogModal from '../entities/EntityModals/RefTreeCatalogModal';
import RefTreeModal from '../entities/EntityModals/RefTreeModal';
import ThreatModal from '../entities/EntityModals/ThreatModal';
import VulnerabilityModal from '../entities/EntityModals/VulnerabilityModal';
import NodeDeletionModal from './nodetypes/nodeObjects/NodeDeletionModal';
import { EditConfirm } from './nodetypes/nodeObjects/NodeDetailsPage';
import RefTreeModificationModal from './RefTreeModificationModal';

/**
 * Node Context Menu
 *
 * A wrapper component used to apply context menu for attack tree nodes
 *
 *  @param nodeData the data of the node
 *  @param children the children components implemented inside this context menu wrapper
 *  @param project redux store mapped to state, the project data
 *  @param selectedSubtree redux store mapped to state, the subtree selected
 *  @param copyMoveType redux store mapped to state, the type used for copy/cut functions of trees
 *  @param cutSubtree redux action mapped to state, used to cut subtree details to store and mark as cut
 *  @param copySubtree redux action mapped to state,used to copy subtree details to store and mark as copy
 *  @param pasteSubtree redux store mapped to state, used to paste subtrees to nodes as child
 *  @param setEntity redux action mapped to state, used to set entity in store
 *
 */
const NodeContextMenu = ({
  nodeData,
  children,
  project,
  projectRole,
  selectedSubtree,
  copyMoveType,
  cutSubtree,
  copySubtree,
  pasteSubtree,
  setEntity,
  SetRefTreeParams,
  disableRefTreeWarnings,
  catalogRole,
  readOnly,
}) => {
  const { projectId, refTreeId, catalogId } = useParams();
  const query = new URLSearchParams(window.location.search);
  const rootId = query.get('rootId');
  const { dispatch } = store;
  const history = useHistory();
  //Boolean check if pasting subtree is allowed
  const pastableCheck = () => {
    if (selectedSubtree === undefined) {
      return true;
    } else {
      if (nodeData?.entity_type !== undefined) {
        switch (nodeData.entity_type) {
          case EntityType.asset:
            //If asset and subtree is threat, allow
            if (selectedSubtree.entity_type === EntityType.threat) {
              return false;
            }
            break;
          case EntityType.threat:
            //If threat and subtree is vulnerability, allow
            if (selectedSubtree.entity_type === EntityType.vulnerability) {
              return false;
            }
            break;
          case EntityType.vulnerability:
            //If vulnerability and subtree is vulnerability or control, allow
            if (
              selectedSubtree.entity_type === EntityType.vulnerability ||
              selectedSubtree.entity_type === EntityType.control
            ) {
              return false;
            }
            break;
          case EntityType.control:
            //disallow if node is control
            return true;

          default:
            return true;
        }
      }
    }
    return true;
  };

  //Disallows creation of vulnerabilities if controls are already present in the children of the node
  const vulnerabilityCreationCheck = () => {
    let disabled = false;
    if (nodeData?.entity_type === EntityType.threat) {
      return false;
    }
    if (nodeData?._children?.length > 0) {
      nodeData._children.map((child) => {
        if (child.entity_type === EntityType.control) {
          disabled = true;
        }
        return child;
      });
    }
    if (nodeData?.risk_decision === RiskDecision.avoiding.toLowerCase()) {
      disabled = true;
    }

    return disabled;
  };

  //Disallows creation of controls if vulnerabilities are already present in the children of the node
  const controlCreationCheck = () => {
    let disabled = false;
    if (nodeData?._children?.length > 0) {
      nodeData._children.map((child) => {
        if (child.entity_type === EntityType.vulnerability) {
          disabled = true;
        }
        return child;
      });
    }
    if (nodeData?.risk_decision === RiskDecision.avoiding.toLowerCase()) {
      disabled = true;
    }
    return disabled;
  };

  const [refTreeModType, setModType] = useState(undefined);
  const [refTreeNextToggle, setRefTreeNextToggle] = useState(undefined);

  const [refTreeModWarningModal, openRefTreeModWarningModal] = useState(false);
  const toggleRefTreeModWarningModal = () =>
    openRefTreeModWarningModal((prevState) => !prevState);

  //Entity creation
  const [assetCreationModal, openAssetCreationModal] = useState(false);
  const toggleAssetCreation = () =>
    openAssetCreationModal((prevState) => !prevState);
  const [threatCreationModal, openThreatCreationModal] = useState(false);
  const toggleThreatCreation = () =>
    openThreatCreationModal((prevState) => !prevState);
  const [vulnerabilityCreationModal, openVulnerabilityCreationModal] =
    useState(false);
  const toggleVulnerabilityCreation = () =>
    openVulnerabilityCreationModal((prevState) => !prevState);
  const [controlCreationModal, openControlCreationModal] = useState(false);
  const toggleControlCreation = () =>
    openControlCreationModal((prevState) => !prevState);

  const [controlCatalogCreationModal, openControlCatalogCreationModal] =
    useState(false);
  const toggleControlCatalogCreation = () =>
    openControlCatalogCreationModal((prevState) => !prevState);

  const [addControlToCatalogModal, openAddControlToCatalogModal] =
    useState(false);
  const toggleAddControlToCatalogCreation = () =>
    openAddControlToCatalogModal((prevState) => !prevState);

  const [RefTreeCatalogCreationModal, openRefTreeCatalogCreationModal] =
    useState(false);
  const toggleRefTreeCatalogCreation = () =>
    openRefTreeCatalogCreationModal((prevState) => !prevState);

  const [RefTreeCreationModal, openRefTreeCreationModal] = useState(false);
  const toggleRefTreeCreation = () =>
    openRefTreeCreationModal((prevState) => !prevState);

  //Entity update toggle
  const [entityUpdateModal, openEntityUpdateModal] = useState(false);
  const toggleEntityUpdate = () =>
    openEntityUpdateModal((prevState) => !prevState);

  const [nodeDeletionModal, openNodeDeletionModal] = useState(false);
  const toggleNodeDeletionModal = () =>
    openNodeDeletionModal((prevState) => !prevState);

  const [addReftreeEstimationModal, setAddReftreeEstimationModal] =
    useState(false);
  const toggleAddReftreeEstimationModal = () =>
    setAddReftreeEstimationModal(!addReftreeEstimationModal);

  const [vulnerabilityEstimationModal, setVulnerabilityEstimationModal] =
    useState(false);
  const toggleVulnerabilityEstimationModal = () =>
    setVulnerabilityEstimationModal(!vulnerabilityEstimationModal);

  const [
    addVulnerabilityEstimationChange,
    setAddVulnerabilityEstimationChange,
  ] = useState(false);
  const [addReftreeEstimationChange, setAddReftreeEstimationChange] =
    useState(false);

  const [
    AddChildFromControlCatalogCreationModal,
    openAddChildFromControlCatalogCreationModal,
  ] = useState(false);
  const toggleAddChildFromControlCatalogCreation = () =>
    openAddChildFromControlCatalogCreationModal((prevState) => !prevState);

  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const toggleEditConfim = () => setEditConfirmModal(!editConfirmModal);
  const treeNodeDetails = '/analysisTree/node-details/';
  const parentId = '&parentId=';
  const confirmEdit = async () => {
    await setEntity(nodeData);
    let url = '';

    if (rootId) {
      url = `/${project._id}${treeNodeDetails}${nodeData._id}?rootId=${rootId}&edit=true&fromTree=true${parentId}${nodeData.parent?._id}`;
    } else if (refTreeId) {
      url = `/catalogues/${catalogId}/reftree/${refTreeId}/node-details/${nodeData?._id}?edit=true&fromTree=true${parentId}${nodeData.parent?._id}`;
    } else {
      url = `/${project._id}${treeNodeDetails}${nodeData._id}?edit=true&fromTree=true${parentId}${nodeData.parent?._id}`;
    }
    history.push(url);
  };

  const differentChildWarning =
    'A node cannot have a vulnerability and a control as child nodes at the same time.';
  return (
    <Fragment>
      <MenuProvider id={`menu_id${nodeData?._id}_${nodeData?.index}`}>
        {!readOnly && children}
      </MenuProvider>
      <Menu id={`menu_id${nodeData?._id}_${nodeData?.index}`}>
        {/* Node details */}
        <Item
          onClick={() => {
            if (nodeData?._id !== undefined) {
              setEntity(nodeData);
              dispatch({
                type: SET_NODE_DATA,
                payload: nodeData,
              });
              let url = '';
              if (rootId) {
                url = `/${project._id}${treeNodeDetails}${nodeData._id}?rootId=${rootId}&fromTree=true${parentId}${nodeData?.parent?._id}`;
              } else if (refTreeId) {
                url = `/catalogues/${catalogId}/reftree/${refTreeId}/node-details/${nodeData?._id}?fromTree=true${parentId}${nodeData?.parent?._id}`;
              } else {
                url = `/${project._id}${treeNodeDetails}${nodeData._id}?fromTree=true${parentId}${nodeData?.parent?._id}`;
              }
              history.push(url);
            } else {
              history.push(`/${project._id}/project`);
            }
          }}
        >
          Open node details page
        </Item>
        {/* Edit node  */}
        <Item
          disabled={
            nodeData?.entity_type === undefined ||
            (projectId &&
              projectRole !== ProjectRole.Owner.value &&
              projectRole !== ProjectRole.Editor.value &&
              projectRole !== ProjectRole.Demo.value) ||
            (catalogId &&
              catalogRole !== ProjectRole.Owner.value &&
              catalogRole !== ProjectRole.Editor.value &&
              catalogRole !== ProjectRole.Demo.value)
          }
          onClick={() => {
            if (nodeData.owner.owner_type === OwnerType.RefTree) {
              if (disableRefTreeWarnings) {
                SetRefTreeParams(
                  RefTreeModType.Editing,
                  RefTreeActionType.Edit,
                  nodeData
                );
                confirmEdit();
                return;
              }
              if (
                nodeData?.owner?.owner_type === OwnerType.RefTree &&
                nodeData.entity_type === 'control'
              ) {
                toggleEditConfim();
              } else {
                setModType(RefTreeModType.Editing);
                toggleRefTreeModWarningModal();
              }
            } else {
              confirmEdit();
            }
          }}
        >
          <div
            style={{ width: '100%', height: '100%' }}
            id={`editNodeTooltip${GenerateNodeId(
              nodeData._id,
              nodeData?.index
            )}`}
          >
            {/* TODO: */}
            Edit Node
          </div>
          <UncontrolledTooltip
            disabled={nodeData?.entity_type !== undefined}
            placement='right'
            target={`editNodeTooltip${GenerateNodeId(
              nodeData._id,
              nodeData?.index
            )}`}
          >
            For editing project information, please use the project details
            page.
          </UncontrolledTooltip>
        </Item>
        {/* Disabled if entity type is undefined, or user is not owner nor editor */}
        {((projectId &&
          (projectRole === ProjectRole.Owner.value ||
            projectRole === ProjectRole.Editor.value ||
            projectRole === ProjectRole.Demo.value)) ||
          (catalogId &&
            (catalogRole === ProjectRole.Owner.value ||
              catalogRole === ProjectRole.Editor.value ||
              catalogRole === ProjectRole.Demo.value))) && (
          <Fragment>
            {nodeData?.entity_type === undefined && (
              <Item onClick={toggleAssetCreation}>Add Asset</Item>
            )}
            {nodeData?.entity_type === EntityType.asset && (
              <Item onClick={toggleThreatCreation}>
                Add{' '}
                {project?.project_type === ProjectType.ISO_21434.Value
                  ? 'Damage Scenario'
                  : 'Threat'}
              </Item>
            )}
            {(nodeData?.entity_type === EntityType.threat ||
              nodeData?.entity_type === EntityType.vulnerability) && (
              <Item
                disabled={vulnerabilityCreationCheck}
                onClick={() => {
                  if (
                    nodeData?.likelihood_estimation_method !==
                      LikelihoodEstimation[LikelihoodMethod.Automatically] &&
                    nodeData.entity_type === EntityType.vulnerability
                  ) {
                    //Estimation modal first, if vulnerability and estimation is not automatic
                    toggleVulnerabilityEstimationModal();
                  } else if (nodeData.owner.owner_type === OwnerType.RefTree) {
                    if (disableRefTreeWarnings) {
                      SetRefTreeParams(
                        RefTreeModType.Addition,
                        RefTreeActionType.Edit,
                        nodeData
                      );
                      toggleVulnerabilityCreation();
                      return;
                    }
                    setRefTreeNextToggle(() => toggleVulnerabilityCreation);
                    setModType(RefTreeModType.Addition);
                    toggleRefTreeModWarningModal();
                  } else {
                    toggleVulnerabilityCreation();
                  }
                }}
              >
                <div
                  style={{ width: '100%', height: '100%' }}
                  id={`vulnerabilityCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {/* Shows threat scenario if node is asset with specific project type */}
                  Add{' '}
                  {project?.project_type === ProjectType.ISO_21434.Value &&
                  nodeData.entity_type === EntityType.threat
                    ? 'Threat Scenario'
                    : 'Vulnerability'}
                </div>
                <UncontrolledTooltip
                  disabled={!vulnerabilityCreationCheck}
                  placement='right'
                  target={`vulnerabilityCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {nodeData?.risk_decision ===
                  RiskDecision.avoiding.toLowerCase()
                    ? 'Avoiding Threat Scenario'
                    : differentChildWarning}
                </UncontrolledTooltip>
              </Item>
            )}

            {nodeData?.entity_type === EntityType.vulnerability && (
              <Item
                disabled={controlCreationCheck}
                onClick={() => {
                  if (nodeData.owner.owner_type === OwnerType.RefTree) {
                    if (disableRefTreeWarnings) {
                      SetRefTreeParams(
                        RefTreeModType.Addition,
                        RefTreeActionType.Edit,
                        nodeData
                      );
                      toggleControlCreation();
                      return;
                    }
                    setRefTreeNextToggle(() => toggleControlCreation);
                    setModType(RefTreeModType.Addition);
                    toggleRefTreeModWarningModal();
                  } else {
                    toggleControlCreation();
                  }
                }}
              >
                <div
                  style={{ width: '100%', height: '100%' }}
                  id={`controlCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  Add Control
                </div>
                <UncontrolledTooltip
                  disabled={!controlCreationCheck}
                  placement='right'
                  target={`controlCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {nodeData?.risk_decision ===
                  RiskDecision.avoiding.toLowerCase()
                    ? 'Avoiding Threat Scenario'
                    : differentChildWarning}
                </UncontrolledTooltip>
              </Item>
            )}
            {nodeData?.entity_type === EntityType.control && (
              <Item onClick={toggleAddControlToCatalogCreation}>
                Add Control to catalogue
              </Item>
            )}
            {nodeData?.entity_type === EntityType.vulnerability && (
              <Item
                disabled={controlCreationCheck}
                onClick={() => {
                  if (nodeData.owner.owner_type === OwnerType.RefTree) {
                    if (disableRefTreeWarnings) {
                      SetRefTreeParams(
                        RefTreeModType.Addition,
                        RefTreeActionType.Edit,
                        nodeData
                      );
                      toggleAddChildFromControlCatalogCreation();
                      return;
                    }
                    setRefTreeNextToggle(
                      () => toggleAddChildFromControlCatalogCreation
                    );
                    setModType(RefTreeModType.Addition);
                    toggleRefTreeModWarningModal();
                  } else {
                    toggleAddChildFromControlCatalogCreation();
                  }
                }}
              >
                <div
                  style={{ width: '100%', height: '100%' }}
                  id={`controlCatalogCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  Add Control from catalogue
                </div>
                <UncontrolledTooltip
                  disabled={!controlCreationCheck}
                  placement='right'
                  target={`controlCatalogCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {nodeData?.risk_decision ===
                  RiskDecision.avoiding.toLowerCase()
                    ? 'Avoiding Threat Scenario'
                    : differentChildWarning}
                </UncontrolledTooltip>
              </Item>
            )}
            <Separator />
            {nodeData?.entity_type !== undefined && (
              <Item
                onClick={() => {
                  if (nodeData.owner.owner_type === OwnerType.RefTree) {
                    if (
                      nodeData?.parent?.owner?.owner_type === OwnerType.Project
                    ) {
                      toggleNodeDeletionModal();
                      return;
                    }
                    if (disableRefTreeWarnings) {
                      SetRefTreeParams(
                        RefTreeModType.Deletion,
                        RefTreeActionType.Edit,
                        nodeData
                      );
                      toggleNodeDeletionModal();
                      return;
                    }
                    setRefTreeNextToggle(() => toggleNodeDeletionModal);
                    setModType(RefTreeModType.Deletion);
                    toggleRefTreeModWarningModal();
                  } else {
                    toggleNodeDeletionModal();
                  }
                }}
              >
                Delete node
              </Item>
            )}
            <Item
              disabled={nodeData?._id === undefined}
              onClick={() => {
                window.open(
                  `${window.location.origin}/${project._id}/analysisTree/?rootId=${nodeData._id}`
                );
              }}
            >
              Open subtree starting at this node in new tab
            </Item>
            <Separator />

            {(nodeData.entity_type === EntityType.vulnerability ||
              nodeData.entity_type === EntityType.threat) && (
              <Item
                disabled={vulnerabilityCreationCheck}
                onClick={() => {
                  if (nodeData.owner.owner_type === OwnerType.RefTree) {
                    if (disableRefTreeWarnings) {
                      SetRefTreeParams(
                        RefTreeModType.Addition,
                        RefTreeActionType.Edit,
                        nodeData
                      );
                      toggleRefTreeCatalogCreation();
                      return;
                    }
                    setRefTreeNextToggle(() => toggleRefTreeCatalogCreation);
                    setModType(RefTreeModType.Addition);
                    toggleRefTreeModWarningModal();
                  } else {
                    if (
                      nodeData?.likelihood_estimation_method !==
                        LikelihoodEstimation[LikelihoodMethod.Automatically] &&
                      nodeData.entity_type === EntityType.vulnerability
                    ) {
                      toggleAddReftreeEstimationModal();
                    } else {
                      toggleRefTreeCatalogCreation();
                    }
                  }
                }}
              >
                <div
                  style={{ width: '100%', height: '100%' }}
                  id={`referenceTreeCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  Add reference tree as child
                </div>
                <UncontrolledTooltip
                  disabled={!vulnerabilityCreationCheck}
                  placement='right'
                  target={`referenceTreeCheck${GenerateNodeId(
                    nodeData._id,
                    nodeData?.index
                  )}`}
                >
                  {nodeData?.risk_decision ===
                  RiskDecision.avoiding.toLowerCase()
                    ? 'Avoiding Threat Scenario'
                    : differentChildWarning}
                </UncontrolledTooltip>
              </Item>
            )}

            {nodeData.entity_type === EntityType.vulnerability && (
              <Item onClick={toggleRefTreeCreation}>
                Create new reference tree starting at this node
              </Item>
            )}
            <Separator />
            <Item
              onClick={() => {
                copySubtree(nodeData);
              }}
              disabled={
                nodeData?.entity_type === EntityType.asset ||
                nodeData?.entity_type === undefined
              }
            >
              Copy subtree starting at this node
            </Item>
            <Item
              onClick={() => {
                if (nodeData.owner.owner_type === OwnerType.RefTree) {
                  if (disableRefTreeWarnings) {
                    SetRefTreeParams(
                      RefTreeModType.Cut,
                      RefTreeActionType.Edit,
                      nodeData
                    );
                    cutSubtree(nodeData);
                    return;
                  }
                  if (nodeData.owner.id !== nodeData.parent.owner.id) {
                    setModType(RefTreeModType.Transfer);
                  } else {
                    setModType(RefTreeModType.Cut);
                  }
                  toggleRefTreeModWarningModal();
                } else {
                  cutSubtree(nodeData);
                }
              }}
              disabled={
                nodeData?.entity_type === EntityType.asset ||
                nodeData?.entity_type === undefined
              }
            >
              Cut subtree starting at this node
            </Item>
            <Item
              onClick={() => {
                if (nodeData.owner.owner_type === OwnerType.RefTree) {
                  if (disableRefTreeWarnings) {
                    const params = {
                      type: RefTreeModType.Pasting,
                      action: RefTreeActionType.Edit,
                      params: {
                        node: nodeData,
                      },
                    };
                    pasteSubtree(nodeData, params);
                    return;
                  }
                  setModType(RefTreeModType.Pasting);
                  toggleRefTreeModWarningModal();
                } else {
                  pasteSubtree(nodeData);
                }
              }}
              disabled={pastableCheck}
            >
              <div
                style={{ width: '100%', height: '100%' }}
                id='TooltipExample'
              >
                Paste cut/copied subtree as child
              </div>
              <UncontrolledTooltip
                disabled={!pastableCheck || selectedSubtree === undefined}
                placement='top'
                target='TooltipExample'
              >
                The {copyMoveType === CopyMoveType.Copy ? 'copied' : 'cut'}{' '}
                subtree cannot be attached to this node because the node type of
                its root is not allowed as child for the current node. Allowed
                parent-child-relationships are:
                <br></br>- Project as parent of asset
                <br></br>- Asset as parent of{' '}
                {project?.project_type === ProjectType.ISO_21434.Value
                  ? 'damage scenario'
                  : 'threat'}
                <br></br>-{' '}
                {project?.project_type === ProjectType.ISO_21434.Value
                  ? 'Damage scenario'
                  : 'Threat'}{' '}
                as parent of vulnerability
                <br></br>- Vulnerability as parent of exclusively
                vulnerabilities
                <br></br>- Vulnerability as parent of exclusively controls
              </UncontrolledTooltip>
            </Item>
          </Fragment>
        )}
      </Menu>
      {/* Entity creation modals*/}
      {assetCreationModal && (
        <AssetModal modal={assetCreationModal} toggle={toggleAssetCreation} />
      )}
      {threatCreationModal && (
        <ThreatModal
          modal={threatCreationModal}
          toggle={toggleThreatCreation}
          parent={nodeData}
        />
      )}
      {vulnerabilityCreationModal && (
        <VulnerabilityModal
          modal={vulnerabilityCreationModal}
          toggle={toggleVulnerabilityCreation}
          parent={nodeData}
          changeParentEstimation={addVulnerabilityEstimationChange}
        />
      )}
      {controlCreationModal && (
        <ControlModal
          modal={controlCreationModal}
          toggle={toggleControlCreation}
          parent={nodeData}
        />
      )}
      {controlCatalogCreationModal && (
        <ControlCatalogModal
          modal={controlCatalogCreationModal}
          toggle={toggleControlCatalogCreation}
          parent={nodeData.parent}
          node={nodeData}
        />
      )}
      {addControlToCatalogModal && (
        <ControlCatalogModal
          modal={addControlToCatalogModal}
          toggle={toggleAddControlToCatalogCreation}
          parent={nodeData.parent}
          node={nodeData}
        />
      )}

      <DraggableModal
        isOpen={addReftreeEstimationModal}
        toggle={toggleAddReftreeEstimationModal}
      >
        <ModalHeader>
          <div className='confirm-modal-header'>
            Likelihood estimation change
          </div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          Warning! The previous likelihood estimation of this node will be
          overwritten when a child is added that is not a control. Do you want
          to proceed?
          <FormGroup>
            <Button
              type='submit'
              className='float-right btn-confirm-delete'
              onClick={() => {
                toggleRefTreeCatalogCreation();
                toggleAddReftreeEstimationModal();
                setAddReftreeEstimationChange(true);
              }}
            >
              Ok
            </Button>
            <Button
              className='float-right btn-cancel-delete mr-15'
              onClick={() => {
                setAddReftreeEstimationChange(false);
                toggleAddReftreeEstimationModal();
              }}
            >
              Cancel
            </Button>
          </FormGroup>
        </ModalBody>
      </DraggableModal>
      <DraggableModal
        isOpen={vulnerabilityEstimationModal}
        toggle={toggleVulnerabilityEstimationModal}
      >
        <ModalHeader>
          <div className='confirm-modal-header'>
            Likelihood estimation change
          </div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          Are you sure you want to overwrite the previous likelihood estimation?
          <FormGroup>
            <Button
              type='submit'
              className='float-right btn-confirm-delete'
              onClick={() => {
                toggleVulnerabilityEstimationModal();
                setAddVulnerabilityEstimationChange(true);
                if (nodeData.owner.owner_type === OwnerType.RefTree) {
                  setRefTreeNextToggle(() => toggleVulnerabilityCreation);
                  setModType(RefTreeModType.Addition);
                  toggleRefTreeModWarningModal();
                } else {
                  toggleVulnerabilityCreation();
                }
              }}
            >
              Ok
            </Button>
            <Button
              className='float-right btn-cancel-delete mr-15'
              onClick={() => {
                setAddVulnerabilityEstimationChange(false);
                toggleVulnerabilityEstimationModal();
              }}
            >
              Cancel
            </Button>
          </FormGroup>
        </ModalBody>
      </DraggableModal>

      {RefTreeCatalogCreationModal && (
        <RefTreeCatalogModal
          modal={RefTreeCatalogCreationModal}
          toggle={toggleRefTreeCatalogCreation}
          parent={nodeData}
          changeParentEstimation={addReftreeEstimationChange}
        />
      )}

      {RefTreeCreationModal && (
        <RefTreeModal
          modal={RefTreeCreationModal}
          toggle={toggleRefTreeCreation}
          node={nodeData}
        />
      )}

      {/* Entity update modals*/}
      {entityUpdateModal && nodeData?.entity_type === EntityType.asset && (
        <AssetModal
          modal={entityUpdateModal}
          toggle={toggleEntityUpdate}
          id={nodeData._id}
        />
      )}
      {entityUpdateModal && nodeData?.entity_type === EntityType.threat && (
        <ThreatModal
          modal={entityUpdateModal}
          toggle={toggleEntityUpdate}
          id={nodeData._id}
        />
      )}
      {entityUpdateModal &&
        nodeData?.entity_type === EntityType.vulnerability && (
          <VulnerabilityModal
            modal={entityUpdateModal}
            toggle={toggleEntityUpdate}
            id={nodeData._id}
          />
        )}
      {entityUpdateModal && nodeData?.entity_type === EntityType.control && (
        <ControlModal
          modal={entityUpdateModal}
          toggle={toggleEntityUpdate}
          id={nodeData._id}
        />
      )}

      {nodeDeletionModal && (
        <NodeDeletionModal
          modal={nodeDeletionModal}
          toggle={toggleNodeDeletionModal}
          nodeData={nodeData}
        />
      )}

      {refTreeModWarningModal && (
        <RefTreeModificationModal
          type={refTreeModType}
          modal={refTreeModWarningModal}
          toggle={toggleRefTreeModWarningModal}
          nextToggle={refTreeNextToggle}
          nodeData={nodeData}
          fromTree={rootId || refTreeId ? false : true}
          confirmEdit={confirmEdit}
        />
      )}
      {AddChildFromControlCatalogCreationModal && (
        <AddControlFromCatalogModal
          modal={AddChildFromControlCatalogCreationModal}
          toggle={toggleAddChildFromControlCatalogCreation}
          parent={nodeData}
        />
      )}

      <EditConfirm
        toggle={toggleEditConfim}
        modal={editConfirmModal}
        confirmEdit={confirmEdit}
      />
      {readOnly && children}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedSubtree: state.analysistree.SelectedSubtree,
  copyMoveType: state.analysistree.SelectedType,
  project: state.project.project,
  projectRole: state.profile.projectRole,
  catalogRole: state.catalog.catalogRole,
  disableRefTreeWarnings: state.analysistree.disableRefTreeWarnings,
});

export default connect(mapStateToProps, {
  cutSubtree,
  copySubtree,
  pasteSubtree,
  setEntity,
  SetRefTreeParams,
})(NodeContextMenu);
