import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

const Page404 = () => (
  <React.Fragment>
    <Container className='h-100'>
      <div className='text-center'>
        <h1 className='display-1 font-weight-bold'>404</h1>
        <p className='h1'>Page not found.</p>
        <p className='h2 font-weight-normal mt-3 mb-4'>
          The page you are looking for might have been removed.
        </p>
        <Link to='/'>
          <button color='primary' size='lg'>
            Return to website
          </button>
        </Link>
      </div>
    </Container>
  </React.Fragment>
);

export default withRouter(Page404);
