import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import { InputTemplate } from '../../entities/EntityModals/InputTemplate';

/**
 * Node Context Menu
 *
 * A modal for updating/creating assets
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param addEntity redux action mapped to state, creation of a new entity
 *
 */
const TARASUpportModal = ({ modal, toggle }) => {
  const [formData, setFormData] = useState({
    message: undefined,
    email: undefined,
    errorCategory: undefined,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const onSubmit = async () => {
    //send ticket
  };

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader>
          <div className='modal-header'>Support for conducting a TARA</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Non
            blandit massa enim nec dui nunc mattis enim ut. Proin sagittis nisl
            rhoncus mattis rhoncus urna.
            <br />
            <br />
            <small>* required field</small>
            <FormGroup>
              <InputTemplate
                label='* Email'
                type='text'
                name='email'
                value={formData?.email}
                onChange={onChange}
                innerRef={focusRef}
                //check format
                invalid={
                  formData?.email === undefined /* || TODO: not right format */
                }
                formFeedback='Invalid or missing email'
              />
              <br />
              <InputTemplate
                label='* Message'
                type='textarea'
                name='message'
                value={formData?.message}
                onChange={onChange}
                invalid={
                  formData?.message === undefined || formData?.message === ''
                }
                formFeedback='Missing message'
              />
              <br />
              <Button
                disabled={
                  formData.errorCategory === undefined ||
                  formData.message === undefined ||
                  formData.email === undefined
                }
                type='submit'
                className='float-right btn-add'
                style={{ marginRight: 0 }}
                onClick={() => {
                  onSubmit();
                  toggle();
                }}
              >
                Submit
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

export default connect(null, {})(TARASUpportModal);
