import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideNav, {
  NavIcon,
  NavItem,
  NavText,
  Toggle,
} from '@trendmicro/react-sidenav';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProjectType } from '../../constants/Enums';
import { reloadAnalysisTree } from '../../redux/actions/projects';
import { reRoute } from '../../redux/actions/save';
import { setSidebarPath, toggleSidebar } from '../../redux/actions/sidebar';
import { FindPathFromRoutes } from '../helpers/genericHelper';
import {
  analysisTreeRoutes,
  projectRoutes,
  projectSelected,
  projectSelected as subRoutes,
  sidebar as mainRoutes,
  sidebarNotice,
  sidebarSupport,
} from '../routing/index';
import './sidenav.css';

const upperButtonsId = 'upperButtons';
const lowerButtonsId = 'lowerButtons';
const togglerId = 'sidebarToggler';

function preventOverlappingOfSidebarButtons() {
  // Component of buttons in the upper section of the sidebar
  var upperButtons = document.getElementById(upperButtonsId);

  // Component of buttons in the lower section of the sidebar
  var lowerButtons = document.getElementById(lowerButtonsId);

  // The sidebar toggler element
  var toggler = document.getElementById(togglerId);

  var upperDimensions = upperButtons?.getBoundingClientRect();
  var lowerDimensions = lowerButtons?.getBoundingClientRect();
  var upperButtonsBottomY = upperDimensions?.y + upperDimensions?.height;
  var lowerButtonsTopY = lowerDimensions?.top;
  var lowerButtonsBottomY = lowerDimensions?.y + lowerDimensions?.height;
  var togglerDimensions = toggler?.getBoundingClientRect();
  var togglerTopY = togglerDimensions?.top;

  if (upperButtonsBottomY > lowerButtonsTopY) {
    lowerButtons.style.position = 'static';
  }
  if (lowerButtonsBottomY + 1 < togglerTopY) {
    lowerButtons.style.position = 'absolute';
  }
}

window.addEventListener('resize', preventOverlappingOfSidebarButtons);

const Sidebar = ({
  isVisible,
  isOpen,
  project,
  selectedPath,
  location,
  history,
  toggleSidebar,
  setSidebarPath,
  unsavedChanges,
  reRoute,
  reloadAnalysisTree,
  baselineMode,
}) => {
  const onSelect = (selected) => {
    let fixedUrl = selected;
    const urlId = ':projectId';
    if (selected.includes(urlId)) {
      fixedUrl = selected.replace(urlId, project._id);
    }

    if (selected === analysisTreeRoutes.path && !baselineMode) {
      reloadAnalysisTree();
    }

    if (window.location.pathname !== fixedUrl) {
      if (unsavedChanges?.length > 0) {
        reRoute(fixedUrl);
        return;
      }
      history.push({
        pathname: fixedUrl,
      });
    }
    setSidebarPath(selected);
  };

  useEffect(() => {
    let currentPath = String(location.pathname);
    //Checks if path has IDs on it
    if (/\d/.test(currentPath)) {
      currentPath = FindPathFromRoutes(projectSelected, currentPath);
    }
    if (currentPath !== selectedPath) {
      setSidebarPath(currentPath);
    }
  }, [location.pathname]);
  const sidebarStyle = (name) => {
    var background;
    var locations = window.location.pathname.split('/');
    switch (name) {
      case 'Projects':
        background = locations[1] === 'projects' ? '#bdbec0' : '';
        break;
      case 'Project Details':
        background = locations[2] === 'project' ? '#bdbec0' : '';
        break;
      case 'Analysis Tree':
        background = locations[2] === 'analysisTree' ? '#bdbec0' : '';
        break;
      case 'Risks':
        background = locations[2] === 'risk' ? '#bdbec0' : '';
        break;
      case 'Entities':
        background = locations[2] === 'entities' ? '#bdbec0' : '';
        break;
      case 'Catalogues':
        background = locations[1] === 'catalogues' ? '#bdbec0' : '';
        break;
      case 'Legal Notice':
        background = locations[1] === 'legal-notice' ? '#bdbec0' : '';
        break;
      case 'Tool':
        background = locations[1] === 'tool' ? '#bdbec0' : '';
        break;
      case 'TARA':
        background = locations[1] === 'tara' ? '#bdbec0' : '';
        break;
      case 'Advisory':
        background = locations[1] === 'advisory' ? '#bdbec0' : '';
        break;
      default:
        background = '';
    }
    return { background: background };
  };
  const sidebarComponent = (
    category,
    index,
    topSeparator = '',
    bottomSeparator = ''
  ) => {
    return category.children ? (
      <NavItem
        eventKey={category.path}
        style={{ borderTop: topSeparator, borderBottom: bottomSeparator }}
      >
        {category?.icon && (
          <NavIcon style={sidebarStyle(category.name)}>
            <FontAwesomeIcon icon={category.icon} fixedWidth />
          </NavIcon>
        )}
        <NavText>{category.name}</NavText>
        {category.children.map((route, index) =>
          project?.project_type === ProjectType.ISO_21434.Value ? (
            <NavItem
              eventKey={route.path}
              key={index}
              style={{ width: '200px' }}
            >
              {category?.icon && (
                <NavIcon>
                  <FontAwesomeIcon icon={route.icon} fixedWidth />
                </NavIcon>
              )}
              {route.name === 'Threats' ? (
                <NavText>Damage Scenarios</NavText>
              ) : (
                <NavText>{route.name}</NavText>
              )}
            </NavItem>
          ) : (
            route.name !== 'Threat Scenarios' &&
            route.name !== 'Cybersecurity Control' && (
              <NavItem
                eventKey={route.path}
                key={index}
                style={{ width: '200px' }}
              >
                {category?.icon && (
                  <NavIcon>
                    <FontAwesomeIcon icon={route.icon} fixedWidth />
                  </NavIcon>
                )}
                <NavText>{route.name}</NavText>
              </NavItem>
            )
          )
        )}
      </NavItem>
    ) : (
      <NavItem
        eventKey={category.path}
        style={{ borderTop: topSeparator, borderBottom: bottomSeparator }}
      >
        {category?.icon && (
          <NavIcon style={sidebarStyle(category.name)}>
            <FontAwesomeIcon icon={category.icon} fixedWidth />
          </NavIcon>
        )}
        <NavText>{category.name}</NavText>
      </NavItem>
    );
  };

  const toggle = () => {
    toggleSidebar();
  };

  const sidebarSupportAndNotice = (index, category) =>
    index === 0
      ? sidebarComponent(category, index, '1px solid #7ac142')
      : sidebarComponent(category, index);

  return (
    <Fragment>
      {isVisible ? (
        <SideNav
          onSelect={onSelect}
          onToggle={toggle}
          className='sidenav'
          expanded={isOpen}
          style={{
            position: 'fixed',
            opacity: 100,
            width: isOpen ? '200px' : '65px',
            top: '60px',
            paddingTop: '20px',
          }}
        >
          <SideNav.Nav selected={selectedPath} id={upperButtonsId}>
            {mainRoutes.map((category, index) =>
              project && category.path === projectRoutes.path
                ? subRoutes.map((category, index) =>
                    sidebarComponent(category, index)
                  )
                : sidebarComponent(category, index)
            )}
          </SideNav.Nav>
          <SideNav.Nav
            selected={selectedPath}
            style={{ position: 'absolute', bottom: '110px' }}
            id={lowerButtonsId}
          >
            {sidebarSupport.map((category, index) =>
              sidebarSupportAndNotice(index, category)
            )}
            {sidebarNotice.map((category, index) =>
              sidebarSupportAndNotice(index, category)
            )}
          </SideNav.Nav>

          <Toggle
            componentClass={(obj) => {
              return (
                <Fragment>
                  <button
                    className='sidenavbar---toggle'
                    onClick={obj.onClick}
                    style={{ width: isOpen ? '200px' : '65px' }}
                    id={togglerId}
                  >
                    {isOpen ? (
                      <div className='toggler-div'>
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className='sidebar-toggle'
                          style={{ marginRight: '20px', marginLeft: '-25px' }}
                        />
                        Hide Sidebar
                      </div>
                    ) : (
                      <div className='toggler-div'>
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className='sidebar-toggle'
                        />
                      </div>
                    )}
                  </button>
                </Fragment>
              );
            }}
          />
        </SideNav>
      ) : (
        <SideNav style={{ position: 'fixed', opacity: 100 }} />
      )}
    </Fragment>
  );
};

export default withRouter(
  connect(
    (store) => ({
      isOpen: store.sidebar.isOpen,
      isVisible: store.sidebar.isVisible,
      project: store.project.project,
      baselineMode: store.project.baselineMode,
      unsavedChanges: store.save.unsavedChanges,
      selectedPath: store.sidebar.selectedPath,
    }),
    { toggleSidebar, reloadAnalysisTree, setSidebarPath, reRoute }
  )(Sidebar)
);
