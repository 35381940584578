import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProjectRole } from '../../constants/Enums';
import { btnEdit } from '../../constants/Style';
import { updateCatalog, updateEntry } from '../../redux/actions/catalog';
import { cancelUnsavedChanges, unsavedChanges } from '../../redux/actions/save';
import store from '../../redux/store';

const NamePanel = ({ data, type, save, unsavedChanges, catalogRole }) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    if (editMode) {
      setFormData({ ...formData, name: data?.name });
    }
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    name: '',
  });
  const { entryId } = useParams();

  useEffect(() => {
    setFormData({
      ...formData,
      name: data?.name,
    });
  }, [data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('edit')) {
      const editParam = urlParams.get('edit');

      editParam === 'true' &&
        catalogRole !== ProjectRole.Reader.value &&
        catalogRole !== ProjectRole.Reviewer.value &&
        setEditMode(true);
    }
  }, []);
  const [fieldId, setFieldId] = useState(null);

  const { dispatch } = store;
  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      const id = unsavedChanges();
      setFieldId(id);
    }
  };

  const onSubmit = async () => {
    if (type === 'catalog') {
      dispatch(updateCatalog(data._id, formData));
    } else {
      dispatch(updateEntry(entryId, formData));
    }
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };
  const nameLabel = (label) => {
    return (
      <font style={{ marginRight: 20 }} className='bold'>
        {label}
      </font>
    );
  };

  useEffect(() => {
    if (save && editMode) {
      dispatch(updateCatalog(data._id, formData));
      setFieldId(null);
      changeMode();
    }
  }, [save]);

  return (
    <div>
      {editMode ? (
        <div>
          <Row>
            <Col lg='6'>
              {type === 'catalog'
                ? nameLabel('Name:')
                : nameLabel('Entry Name:')}

              <Button
                id='saveButtton'
                className='btn-add btn-small'
                onClick={() => {
                  onSubmit();
                  changeMode();
                }}
              >
                <i className='fa fa-save' />
              </Button>
              <UncontrolledTooltip target='saveButtton'>
                Save
              </UncontrolledTooltip>
              <Button
                id='cancelButtton'
                className='btn-danger btn-small'
                onClick={() => {
                  changeMode();
                  dispatch(cancelUnsavedChanges(fieldId));
                  setFieldId(null);
                }}
              >
                <i className='fa fa-times' />
              </Button>
              <UncontrolledTooltip target='cancelButtton'>
                Cancel
              </UncontrolledTooltip>

              <Input
                type='text'
                name='name'
                onChange={onChange}
                value={formData.name}
                style={{ width: 400 }}
              />
              <br />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <div style={{ width: 85 }}>
            <Button
              disabled={
                catalogRole === ProjectRole.Reader.value ||
                catalogRole === ProjectRole.Reviewer.value
              }
              className='float-right'
              onClick={() => changeMode()}
              color='secondary'
              style={btnEdit}
            >
              <i className='fa fa-edit' />
            </Button>
            <div className='bold'>Name</div>
          </div>
          <Label>
            <h4 className='bold' style={{ paddingRight: 100 }}>
              {data?.name}
            </h4>
          </Label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  catalogRole: state.catalog.catalogRole,
});

export default connect(mapStateToProps, { unsavedChanges })(NamePanel);
