import classnames from 'classnames';
import { default as React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { INVENSITYContact } from '../../../constants/URLConstants';
import SearchComponent from '../SearchComponent';
import { TARAInformation } from './TARAInformation';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */
const TARA = ({}) => {
  //Declares and displays the project list
  useEffect(() => {}, []);

  const [activeTab, setActiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container fluid='md'>
      <CardTitle>
        <h1>TARA FAQ</h1>
        <div className='card-actions float-right'>
          <Button
            color='primary'
            onClick={() => {
              window.open(INVENSITYContact);
            }}
            style={{ width: '200px' }}
          >
            Contact
          </Button>
        </div>
      </CardTitle>
      <Row>
        <Col xl='2'>
          <Nav vertical pills>
            {TARAInformation.map((info, index) => {
              return (
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({ active: activeTab === index })}
                    onClick={() => {
                      toggle(index);
                    }}
                  >
                    {info.header}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Col>
        <Col xl='10'>
          <TabContent activeTab={activeTab}>
            {TARAInformation.map((info, index) => {
              return (
                <TabPane tabId={index}>
                  <Card body>
                    <CardBody>
                      <SearchComponent data={info.FAQ} />
                    </CardBody>
                  </Card>
                </TabPane>
              );
            })}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(TARA));
