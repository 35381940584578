import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import {
  EntityType,
  ImpactCategory,
  ProjectType,
  SecurityGoal,
} from '../../../constants/Enums';
import { addEntity } from '../../../redux/actions/entities';
import DuplicateEntityModal from '../EntityModals/DuplicateEntityModal';
import DraggableModal from './DraggableModal';
import { InputTemplate } from './InputTemplate';

/**
 * Threat Modal
 *
 * A modal for updating/creating threats
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param addEntity redux action mapped to state, creation of a new entity
 *  @param parent parent supplied by the parent component for update/creation
 *  @param project redux state mapped to props, contains current project data
 *
 */
const ThreatModal = ({
  addEntity,
  toggle,
  modal,
  parent,
  project,
  selectedProjectType,
  threats,
}) => {
  const [formData, setFormData] = useState({
    _id: undefined,
    name: undefined,
    description: undefined,
    operator: 'OR',
    impact: undefined,
    security_goal: undefined,
  });

  const projectType = project?.project_type ?? selectedProjectType;

  const onChange = (e) => {
    //Sets form data as undefined when user picks None
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const updateImpact = (type, value) => {
    setFormData({
      ...formData,
      impact: { ...formData.impact, [type]: value === '' ? undefined : value },
    });
  };

  const onSubmit = async () => {
    addEntity(formData, EntityType.threat, parent);
  };

  const [sameNameModal, setSameNameModal] = useState(false);
  const toggleSameNameModal = () => setSameNameModal(!sameNameModal);

  const hasSameName =
    threats.find((threat) => threat.name === formData?.name) !== undefined;

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader>
          <div className='modal-header'>
            New {ProjectType[projectType].ThreatType}
          </div>
        </ModalHeader>
        <div className='modal-line' />

        <ModalBody className='modal-body'>
          <Form>
            <small>* required field</small>

            <FormGroup>
              <InputTemplate
                label='* Name'
                type='text'
                name='name'
                value={formData.name}
                onChange={onChange}
                formFeedback={'Name cannot be blank'}
                invalid={formData.name === '' || formData.name === undefined}
                innerRef={focusRef}
              />
              <InputTemplate
                label={ProjectType[projectType].ThreatSecurityType}
                type='select'
                name='security_goal'
                onChange={onChange}
                selectDefault
              >
                {Object.entries(SecurityGoal).map((goal) => {
                  return <option value={goal[1].value}>{goal[1].name}</option>;
                })}
              </InputTemplate>
              <InputTemplate
                label='* Description'
                type='textarea'
                name='description'
                value={formData.description}
                onChange={onChange}
              />
              {Object.entries(ImpactCategory).map((category) => {
                return (
                  <InputTemplate
                    label={`${category[1].name} Impact Level`}
                    type='select'
                    name={category[1].value}
                    onChange={(event) =>
                      updateImpact(event.target.name, event.target.value)
                    }
                    selectDefault
                  >
                    {Object.entries(
                      ProjectType[projectType].ImpactLevelTypes
                    ).map((level) => {
                      return <option value={level[0]}>{level[1]}</option>;
                    })}
                  </InputTemplate>
                );
              })}
              <InputTemplate
                label='* Operator'
                type='select'
                name='operator'
                onChange={onChange}
              >
                <option selected>OR</option>
                <option>AND</option>
              </InputTemplate>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={
                  formData.name === undefined ||
                  formData.description === undefined
                }
                type='submit'
                className='float-right btn-add'
                onClick={(event) => {
                  if (hasSameName) {
                    event.preventDefault();
                    toggleSameNameModal();
                  } else {
                    onSubmit();
                    toggle();
                  }
                }}
              >
                Save
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div>
            <p>
              Definition ISO 21434: A damage scenario can include: - relation
              between the functionality of the item and the adverse consequence
              - description of harm to the road user; and/or - relevant assets.“
              A damage scenario can correspond to multiple threat scenarios, and
              a threat scenario can lead to multiple damage scenarios.
            </p>
            <p>
              Example ISO 21434: - “Vehicle cannot be driven at night because
              the headlamp function was inhibited while parked” - “Front
              collision with a narrow stationary object caused by unintended
              turning-off of headlamp during night driving at medium speed”
            </p>
            <div>
              <p>
                A damage scenario can include: - Relation between the
                functionality of the item and the adverse consequence -
                Description of harm to the road user; and/or - Relevant assets
              </p>
            </div>
          </div>
        </ModalFooter>
      </DraggableModal>
      <DuplicateEntityModal
        toggle={toggleSameNameModal}
        modal={sameNameModal}
        parentModalAddEntity={onSubmit}
        parentModalToggle={toggle}
        entityToCreate={formData}
        entityType={EntityType.threat}
        projectType={projectType}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  selectedProjectType: state.analysistree.selectedProjectType,
  threats: state.threats.threats,
});

export default connect(mapStateToProps, { addEntity })(ThreatModal);
