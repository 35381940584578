import { combineReducers } from 'redux';
import alert from './alert';
import analysistree from './analysistree';
import assets from './assets';
import auth from './auth';
import catalog from './catalog';
import controls from './controls';
import entities from './entities';
import licenseAlert from './licenseAlert';
import logging from './logging';
import profile from './profile';
import project from './project';
import save from './save';
import sidebar from './sidebar';
import threats from './threats';
import vulnerabilities from './vulnerabilities';
import settings from './settings';
export default combineReducers({
  alert,
  licenseAlert,
  auth,
  profile,
  project,
  analysistree,
  threats,
  assets,
  controls,
  vulnerabilities,
  sidebar,
  entities,
  catalog,
  logging,
  save,
  settings
});
