import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { CatalogType } from '../../constants/Enums';
import {
  addMember,
  deleteMember,
  updateMember,
} from '../../redux/actions/catalog';
import { getControlCatalogs, getProfiles } from '../../redux/actions/profile';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import CustomDataTableStyles from '../layout/DataTableStyles';
import DeleteModal from '../projects/project-components/DeleteModal';
import RoleSelect from '../projects/project-components/RoleSelect';
import SearchComponent from '../projects/project-components/SearchComponent';
import AddMember from './AddMember';

/**
 * Users Panel
 *
 * Panel for displaying, adding, editing and deleting project members
 * TODO: To fetch users from the backend and display user names instead of Id
 * TODO: update edit feature when the inputs from the backend is finalized
 *
 * @param toggle: triggers/toggles the modal to close/open
 * @param openPanel: checks if panel is open or closed to change caret
 * @param data: parameter for the specific project in the project details page
 * @param project: place holder for the project state during editing
 * @param deleteMember: redux action for deleting a  member
 * @param updateMember: redux action for updating a  member
 * @param profile: fetches profile of the current user in the system
 */

const UsersPanel = ({
  toggle,
  openPanel,
  data,
  catalog,
  profiles,
  profile,
  updateMember,
  catalogRole,
}) => {

  const [editIds, setEditIds] = useState([]);
  const triggerEdit = (editMember) => {
    setEditIds([...editIds, editMember.user_id]);
  };

  const history = useHistory();

  const removeEditId = (member) => {
    const newIds = Object.assign([], editIds);
    if (newIds.includes(member.user_id)) {
      setEditIds(newIds.filter((id) => id !== member.user_id));
    }
  };

  const [member, setMember] = useState({
    user_id: '',
    role: '',
  });
  const { dispatch } = store;
  useEffect(() => {
    setMember({
      ...member,
      user_id: editIds.user_id,
      role: editIds.role,
    });
  }, [catalog]);

  //Event handling for inputs
  const onChange = (event, userId) => {
    setMember({
      ...member,
      [event.target.name]: event.target.value,
      user_id: userId,
    });
  };

  //TODO: Method for submission of edited part in the catalog members
  const onSubmit = async () => {
    await updateMember(catalog?._id, member, catalog);
    removeEditId(member);
  };

  const selection = (row) => {
    setMember({
      ...member,
      user_id: row,
    });
  };

  const rowRemoveEditId = (row) =>
    removeEditId({
      user_id: row.user_id,
    });

  //Columns for project members table
  const columns = useMemo(() => [
    {
      name: 'Member ID',
      selector: 'user_id',
      sortable: true,
      cell: (row) => (
        <>
          {
            profiles.find(
              (member) => parseInt(member._id) === parseInt(row.user_id)
            )?.name
          }
        </>
      ),
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
      cell: (row) => {
        if (editIds.includes(row.user_id)) {
          if (
            row.role === 'owner' &&
            catalog.participants.filter((member) => member.role === 'owner')
              .length === 1
          ) {
            return <Fragment>{row.role}</Fragment>;
          } else {
            return (
              <Fragment>
                <RoleSelect
                  onChange={(event) => onChange(event, row.user_id)}
                  data={member.role}
                  defaultValue={row.role}
                />
              </Fragment>
            );
          }
        } else {
          return <Fragment>{row.role}</Fragment>;
        }
      },
    },
    {
      cell: (row) => (
        <ButtonGroup>
          {editIds.includes(row.user_id) ? (
            <Fragment>
              {row.role === 'owner' &&
              catalog.participants.filter((member) => member.role === 'owner')
                .length === 1 ? (
                <div>
                  <DraggableModal
                    isOpen={editIds.includes(row.user_id)}
                    toggle={() => rowRemoveEditId(row)}
                  >
                    <ModalHeader>
                      <div className='confirm-modal-header'>
                        Edit Catalog Member
                      </div>
                    </ModalHeader>
                    <div className='confirm-modal-line' />
                    <ModalBody className='confirm-modal-body'>
                      There has to be at least one team member with the role
                      owner at all times!
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className='btn-add'
                        onClick={() => rowRemoveEditId(row)}
                      >
                        Ok
                      </Button>
                    </ModalFooter>
                  </DraggableModal>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      selection(row.user_id);
                      onSubmit();
                    }}
                    size='sm'
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      removeEditId(row);
                    }}
                    size='sm'
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {catalogRole === 'owner' ? (
                <>
                  <Button
                    id={`editButton${row?.user_id}`}
                    onClick={() => triggerEdit(row)}
                  >
                    <i className='fa fa-edit' />
                  </Button>
                  <UncontrolledTooltip target={`editButton${row?.user_id}`}>
                    Edit
                  </UncontrolledTooltip>
                  <Button
                    id={`deleteButton${row?.user_id}`}
                    className='btn-danger'
                    onClick={() => deleteToggle(row)}
                  >
                    <i className='fa fa-trash' />
                  </Button>
                  <UncontrolledTooltip target={`deleteButton${row?.user_id}`}>
                    Delete
                  </UncontrolledTooltip>
                  {row.role === 'owner' &&
                  catalog.participants.filter(
                    (member) => member.role === 'owner'
                  ).length === 1 ? (
                    <div>
                      <DraggableModal
                        isOpen={deleteModal && deleteId === row.user_id}
                        toggle={deleteToggle}
                      >
                        <ModalHeader>
                          <div className='confirm-modal-header'>
                            Delete Catalog Member
                          </div>
                        </ModalHeader>
                        <div className='confirm-modal-line' />
                        <ModalBody className='confirm-modal-body'>
                          There has to be at least one team member with the role
                          owner at all times!
                          <br />
                          <br />
                          <Button
                            className='btn-add float-right'
                            onClick={deleteToggle}
                          >
                            Ok
                          </Button>
                        </ModalBody>
                      </DraggableModal>
                    </div>
                  ) : (
                    <>
                      {parseInt(profile?.id) === row?.user_id ? (
                        <div>
                          <DeleteModal
                            isOpen={deleteModal && deleteId === row.user_id}
                            toggle={deleteToggle}
                            onClick={async () => {
                              deleteToggle();
                              await deleteTeamMember(row.user_id);
                              await dispatch(getControlCatalogs());
                              if (
                                catalog.content_type ===
                                CatalogType.ReferenceTree
                              ) {
                                history.push('/catalogues/refTree');
                              } else {
                                history.push('/catalogues/controls');
                              }
                            }}
                            header='Delete Catalog Member'
                            message='You are about to remove yourself from the team.
                             You wont have access to this catalog anymore and will be redirected to the catalogs page. 
                             Are you sure you want to proceed?'
                          />
                        </div>
                      ) : (
                        <div>
                          <div>
                            <DeleteModal
                              isOpen={deleteModal && deleteId === row.user_id}
                              toggle={deleteToggle}
                              onClick={() => {
                                deleteToggle();
                                deleteTeamMember(row.user_id);
                              }}
                              header='Delete Catalog Member'
                              message='Are you sure you want to remove the user from the team?'
                            />
                          </div>
                        </div>
                      )}{' '}
                    </>
                  )}
                </>
              ) : null}
            </Fragment>
          )}
        </ButtonGroup>
      ),
    },
  ]);

  //Sets Modal for deleting project members
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const deleteToggle = (row) => {
    if (!row) {
      setDeleteId(-1);
    } else {
      setDeleteId(row.user_id);
    }
    setDeleteModal(!deleteModal);
  };

  const deleteTeamMember = async (row) => {
    await dispatch(deleteMember(catalog?._id, row));
  };

  //Method for search input
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const searchItems =
    data?.participants &&
    data?.participants.filter(
      (member) =>
        member.role.toString().toLowerCase().includes(searchText) ||
        parseInt(member.user_id) ===
          parseInt(
            profiles.find(
              (profile) =>
                profile.name.toString().toLowerCase().includes(searchText) ||
                profile.name.toString().toUpperCase().includes(searchText)
            )?._id
          )
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (searchText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setSearchText('');
      }
    };
    return (
      <SearchComponent
        onSearch={(e) => setSearchText(e.target.value)}
        onClear={handleClear}
        searchText={searchText}
      />
    );
  }, [searchText, resetPaginationToggle]);

  //Sets Modal for adding new members
  const [addModal, setAddModal] = useState(false);
  const addToggle = () => setAddModal(!addModal);

  //TODO: to add role check on displaying columns
  return (
    <div>
      <CardHeader style={{ cursor: 'pointer' }} onClick={toggle}>
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        <font className='bold'>Team</font>
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            <div>
              {catalog?.participants
                ?.find(
                  (member) => parseInt(member.user_id) === parseInt(profile?.id)
                )
                ?.role.includes('owner') ? (
                <>
                  {' '}
                  <Button
                    className='btn-add float-right'
                    onClick={() => addToggle()}
                  >
                    Add New Member
                  </Button>
                  <AddMember
                    modal={addModal}
                    toggle={addToggle}
                    currentMembers={data.participants}
                  />{' '}
                </>
              ) : null}
            </div>
            <br />
            <DataTable
              defaultSortAsc='id'
              data={searchItems}
              columns={columns}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={CustomDataTableStyles}
            />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  profile: state.profile.profile,
  catalog: state.catalog.catalog,
  catalogRole: state.catalog.catalogRole,
});

export default connect(mapStateToProps, {
  addMember,
  updateMember,
})(UsersPanel);
