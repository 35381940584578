import React, { Fragment } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';

const ModalError = ({ isOpen, toggle, message, header, onClick }) => {
  return (
    <Fragment>
      <DraggableModal
        isOpen={isOpen}
        toggle={toggle}
        size='lg'
        unmountOnClose={false}
      >
        <ModalHeader>
          <div className='modal-header'>{header}</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>{message}</ModalBody>
        <ModalFooter>
          <Button
            className='btn-add'
            onClick={onClick}
            style={{
              fontWeight: 'bold',
              border: 'solid rgb(200,200,200) 1px',
              margin: 'auto',
            }}
          >
            Okay
          </Button>
        </ModalFooter>
      </DraggableModal>
    </Fragment>
  );
};

export default ModalError;
