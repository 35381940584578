export const GuidanceInformation = [
  {
    header: 'What are the benefits?',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'The goals of the document review are:',
        answer: [
          'Checking of documents with objective test criteria.',
          'Error detection and correction.',
          'Reducing ambiguity.',
          'Consideration of standards and rules.',
          'Consideration of experience from older and other projects.',
          'Consideration of experience of other people besides the author.',
          'Integration and agreement of relevant stakeholders.',
          'Decision of release of document.',
          'Checking the compliance with the given project requirements.',
        ],
      },
      {
        id: 2,
        question: 'Effects of document reviews:',
        answer: [
          'Increased quality of document',
          'Cost and time reduction (errors are detected in an earlier development state)',
          'Improvement of the development process',
          'Compliance with common quality standards',
          'Maintain consistency',
        ],
      },
    ],
  },
  {
    header: 'When it is required?',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'When it is required?',
        answer: [
          'Document reviews are usually planned at the project milestones. If documents are the basis for further development, the documents shall be reviewed before they are used or released and not at the late project milestones.',
          'Document reviews are required for any documents defined in the Configuration Item List. The reviews shall be performed corresponding to the proceeding Walkthrough or Inspection. The reviews are intended to verify that the associated work products fulfill the project requirements, and the technical requirements with respect to use cases and failure modes. For an electronic development, the proceeding of the reviews depends on the ASIL classification of the product.',
          'A successful document review is the prerequisite for the release of a document.',
        ],
      },
    ],
  },
  {
    header: 'What is the goal of a review?',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'What is the goal of a review?',
        answer: [
          'A work product is to be checked for compliance with the requirements in terms of content and form. Was the desired result achieved in the right quality?',
          'Correct in terms of content free of contradictions clearly, comprehensibly and understandably formulated according to the requirements',
        ],
      },
    ],
  },
  {
    header: 'How to work with this document?',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'How to work with this document?',
        answer: [
          'For all reviews that consider a checklist, this sheet shall be used.',
          'Sufficient time must be provided for participants to prepare for review --> Distribution of documents approximately two weeks prior to the review date.',
          'All review findings are to be documented in the checklist by the review participants in advance.',
          'At the review meeting, all findings are discussed and, if necessary, measures are initiated.',
          'A substitute must always attend the review meeting if the actual reviewer is unable to attend.',
          'The checklists to be used are to be checked off if the items are OK, otherwise an entry must be made.',
          'In case of "significant" findings, a mandatory measure must be defined --> The review cannot be accepted (= RED) or only conditionally (= YELLOW).',
          'For findings to be "optimized", a measure can be defined, the review result can be conditionally accepted. --> YELLOW',
          'To close a review, there must be no more findings with rating "significant", everything must be clarified.',
          'Findings rated as "info" do not require a measure.',
          'To complete a review, there must be no more findings rated "to be checked", everything must be clarified and given a new rating.',
        ],
      },
    ],
  },
];

export const EvaluationCriteria = [
  {
    header: 'Significant',
    information: [
      'A finding shall be considered "significant" if, if not addressed, it will result in:',
      'At least one project objective cannot be achieved.',
      'The requirements for the work result cannot be met',
    ],
  },
  {
    header: 'Optimize',
    information: [
      'A finding is classified as "optimize", if:',
      'Changes result in an improvement in form, readability, or structure.',
    ],
  },
  {
    header: 'Info',
    information: [
      'A finding is classified as "info" if: ',
      'A brief explanation is necessary for understanding',
      'There is no deviation, but a special point should be highlighted.',
    ],
  },
  {
    header: 'Check',
    information: [
      'A finding is classified as "check" if:',
      'It is not clear whether it is a deviation.',
      'Questions arise from the review item that need to be reconciled with other documents or require consultation with persons not participating in the review.',
    ],
  },
  {
    header: 'OK',
    information: 'No findings',
  },
];
