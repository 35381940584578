import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  dismissRegisterError,
  register,
  resetRegister,
} from '../../redux/actions/auth';
import { createUser } from '../../redux/actions/profile';
import DraggableModal from '../entities/EntityModals/DraggableModal';

/**
 * Register Modal.
 *
 * Modal component for registration
 *
 *  @param modal stateful value from parent component, checks if modal should be shown
 *  @param toggle stateful value from parent component, triggers modal opening/closing
 *  @param register redux state dispatch, registers with entered details
 *  @param registerError  redux store value, signals registration error
 *  @param registered redux store value, signals registration success
 *  @param registerErrorMessage redux store value, registration failure message
 *  @param dismissRegisterError redux state dispatch, dimisses the registration error
 *  @param addUser boolean value to trigger admin-based user registration
 *
 */
const RegisterModal = ({
  modal,
  toggle,
  register,
  registerError,
  registered,
  createUser,
  registerErrorMessage,
  dismissRegisterError,
  addUser = false,
  resetRegister,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    mail: '',
    password: undefined,
    password2: undefined,
  });
  const { name, mail, password, password2 } = formData;
  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  const onSubmit = async (e) => {
    if (!addUser) {
      e.preventDefault();
      register({
        name,
        mail,
        password,
        password2,
      });
    } else {
      createUser({
        name,
        mail,
      });
      toggle();
    }
  };

  if (registered && modal) {
    resetRegister();
    toggle();
  }

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <DraggableModal isOpen={modal} toggle={toggle}>
      <ModalHeader>
        <div className='modal-header'>
          {addUser ? 'Add new ISAT user' : 'Sign Up'}
        </div>
      </ModalHeader>
      <div className='modal-line' />
      {registerError && (
        <Alert
          color='danger'
          isOpen={registerError}
          toggle={dismissRegisterError}
          style={{ width: '100%', marginLeft: 0 }}
        >
          {registerErrorMessage}
        </Alert>
      )}
      <ModalBody className='modal-body'>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <FormGroup>
            <Label>The username and email has to be unique</Label>
            <Input
              type='text'
              placeholder='Name'
              name='name'
              value={name}
              onChange={(e) => onChange(e)}
              required
              innerRef={focusRef}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type='email'
              placeholder='Email Address'
              name='mail'
              value={mail}
              onChange={(e) => onChange(e)}
              required
            />
          </FormGroup>
          {!addUser && (
            <Fragment>
              <FormGroup>
                <Label>Password must be at least 8 characters long</Label>
                <Input
                  type='password'
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength='8'
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type='password'
                  placeholder='Confirm Password'
                  name='password2'
                  value={password2}
                  onChange={(e) => onChange(e)}
                  minLength='8'
                />
              </FormGroup>
            </Fragment>
          )}
          <Row>
            <div style={{ marginLeft: 'calc(50% - 160px)' }}>
              <Input
                type='submit'
                className='btn btn-add credential-form'
                style={{ background: '#7ac142' }}
                //disables if:
                //password isn't matched(doesn't apply on adding user)
                //password is blank
                //mail format is wrong
                disabled={
                  !addUser &&
                  (password === undefined || password2 === undefined)
                }
                value={!addUser ? 'Register' : 'Add user'}
              />
            </div>
            <div style={{ marginLeft: '20px' }}>
              <Input
                type='button'
                className='btn-danger credential-form'
                value='Cancel'
                onClick={toggle}
              />
            </div>
          </Row>
        </Form>
      </ModalBody>
    </DraggableModal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  registerError: state.auth.registerError,
  registerErrorMessage: state.auth.registerErrorMessage,
  registered: state.auth.registered,
});

export default connect(mapStateToProps, {
  register,
  dismissRegisterError,
  createUser,
  resetRegister,
})(RegisterModal);
