import { EntityType } from '../../../../constants/Enums';

export const LikelihoodBaseColor = '#82df83';
export const LikelihoodWarningColor = 'yellow';
export const NodeTableDataFontSize = '10.5px';
export const LikelihoodBaseValue = 0.01;

//Sets area of Nodes, affects distance between nodes
export const NodeDistanceX = 475;
export const NodeDistanceY = (
  viewSelectionData = undefined,
  analysisTree,
  collapsedReviews,
  impactViews
) => {
  let baseDistance = 200;

  let viewSelectionCount = 0;
  //view selection buttons determine extra height
  //If it is undefined, assume full length
  if (viewSelectionData !== undefined) {
    for (const [, value] of Object.entries(viewSelectionData)) {
      if (value) {
        baseDistance += 30;
        viewSelectionCount++;
      }
    }
  }

  let addedHeight = 0;
  if (!collapsedReviews) {
    addedHeight = calculateLargestThreatNode(analysisTree) + 25;
  } else {
    addedHeight = 25;
  }

  if (impactViews) {
    addedHeight += impactViews.length * 30 * viewSelectionCount;
  }
  return baseDistance + addedHeight;
};

const calculateLargestThreatNode = (analysisTree) => {
  if (
    analysisTree?.entity_type === EntityType.vulnerability ||
    analysisTree?.entity_type === EntityType.control
  ) {
    return 0;
  }
  let largest = 0;
  switch (analysisTree?.entity_type) {
    case undefined:
    case EntityType.asset:
      if (analysisTree?.children?.length > 0) {
        analysisTree.children.map((child) => {
          const childValue = calculateLargestThreatNode(child);
          largest = childValue > largest ? childValue : largest;
          return child;
        });
      }
      break;
    case EntityType.threat:
      if (analysisTree.reftree_placement_review_state?.length > 0) {
        return analysisTree.reftree_placement_review_state.length * 25; //TODO: adjust
      }
      break;
    default:
      return 0;
  }
  return largest;
};

//Width and Length of the main card element
export const NodeWidth = 300;
export const NodeHeight = 175;

//Offset of the node element from its base position in tree
export const NodeOffsetX = -220;
export const NodeOffsetY = -55;

//Offset distance of collapse buttons
export const CollapseOffsetX = 355;
export const CollapseOffsetY = 55;

//Distance between siblings and non siblings in node(relative)
export const nodeSeparationSiblings = 1;
export const nodeSeparationNonSiblings = 1;

export const PageWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const PageHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

//Adjustments take into account node size
export const nodeWidthAdjustment = PageWidth / 7;
export const nodeHeightAdjustment = PageHeight / 2.25;

export const baseFocusX = nodeWidthAdjustment;
export const baseFocusY = nodeHeightAdjustment;

export const noAnimation = 0;
export const fastAnimation = 350;

export const cutNodeColor = '#999999';
export const highlightNodeColor = '#5abece';
