import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CardTitle,
  Col,
  Container,
  Label,
  ModalBody,
  ModalHeader,
  NavbarBrand,
  NavbarToggler,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from 'reactstrap';
import { LogType, UserPermissions } from '../../constants/Enums';
import {
  Cancel,
  LocalTimeWithoutSeconds,
} from '../../constants/StringConstants';
import {
  deleteRecycleBin,
  getAdminDeletionLogs,
  removeDeletedItem,
  restoreDeletedItem,
} from '../../redux/actions/logging';
import { setProject } from '../../redux/actions/projects';
import { withSidebar } from '../../redux/actions/sidebar';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission } from '../helpers/genericHelper';
import CustomDataTableStyles from '../layout/DataTableStyles';
import RecycleBinExpandedComponent from './RecycleBinExpandedComponent';
import RecycleBinSearchComponent from './RecycleBinSearchComponent';

//This file shows the recycle bin page where deleted objects
// can be restored or deleted permanently

const DeletionAction = {
  restore: 'restore',
  delete: 'delete',
  empty: 'empty',
};
const ModalConfirmation = ({
  header,
  body,
  buttonConfirm,
  toggle,
  modal,
  modalAction,
  actionData,
  restoreItem,
  deleteItem,
  emptyBin,
}) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>{header}</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>{body}</Label>
          <div>
            {' '}
            <ButtonGroup>
              <Button
                className={
                  buttonConfirm === 'Restore'
                    ? 'btn-add'
                    : 'btn-confirm-delete mr-15'
                }
                name={buttonConfirm}
                onClick={() => {
                  switch (modalAction) {
                    case DeletionAction.delete:
                      deleteItem(actionData);
                      break;
                    case DeletionAction.restore:
                      restoreItem(actionData);
                      break;
                    case DeletionAction.empty:
                      emptyBin(actionData, LogType.Admin);
                      break;
                    default:
                      break;
                  }
                  toggle();
                }}
              >
                {buttonConfirm}
              </Button>
              <Button
                className={
                  buttonConfirm === 'Restore'
                    ? 'btn-danger'
                    : 'btn-cancel-delete ml-15'
                }
                name={Cancel}
                onClick={toggle}
              >
                {Cancel}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const RecycleBin = ({
  isVisible,
  adminDeletionLogs,
  sidebar,
  getAdminDeletionLogs,
  restoreDeletedItem,
  profile,
  deleteRecycleBin,
  removeDeletedItem,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [buttonConfirm, setButtonConfirm] = useState('');
  const [actionData, setActionData] = useState(undefined);
  const [modalAction, setModalAction] = useState(undefined);

  const restore = (data) => {
    setHeader('Restore Object');
    setBody(
      'Are you sure you want to restore this object to its original location?'
    );
    setButtonConfirm('Restore');
    setActionData(data);
    setModalAction(DeletionAction.restore);
    toggle();
  };

  const remove = (data) => {
    setHeader('Remove Permanently');
    setBody(
      'Warning! The object will be permanently deleted and cannot be restored again. Are you sure you want to proceed?'
    );
    setActionData(data);
    setModalAction(DeletionAction.delete);
    setButtonConfirm('Remove');
    toggle();
  };

  const emptyRecycleBin = (logType) => {
    setModalAction(DeletionAction.empty);
    setHeader('Empty Recycle Bin');
    setBody(
      'Warning! All objects in this recycle bin will be permanently deleted and cannot be restored again. Are you sure you want to proceed?'
    );
    setButtonConfirm('Remove');
    toggle();
  };

  const adminColumns = useMemo(() => [
    {
      name: 'ID',
      sortable: true,
      maxWidth: '100px',
      cell: (row) => (
        <Fragment>
          {row?.root_object?.object_type !== 'User' ? row.root_object?.id : ''}
        </Fragment>
      ),
      selector: (row) => row.root_object?.id,
    },
    {
      name: 'Type',
      sortable: true,
      maxWidth: '100px',
      cell: (row) => <Fragment>{row.root_object.object_type}</Fragment>,
      selector: (row) => row.root_object.object_type,
    },
    {
      name: 'Deleted By',
      sortable: true,
      selector: 'user_name',
      maxWidth: '150px',
    },
    {
      name: 'Deleted',
      sortable: true,
      maxWidth: '200px',
      selector: 'date',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      //Actions for every project
      cell: (row) => (
        <ButtonGroup>
          {/* Button that redirects to the details page of the project */}
          <Button
            id={`restoreButton${row._id}`}
            className='btn-add'
            onClick={() => restore(row)}
          >
            <i className='fa fa-trash-restore' />
          </Button>
          <UncontrolledTooltip target={`restoreButton${row._id}`}>
            Restore
          </UncontrolledTooltip>

          {/* Button for selecting the project on attack tree */}
          {row?.root_object?.object_type !== 'User' && (
            <Fragment>
              <Button
                id={`removeButton${row._id}`}
                className='btn-danger'
                onClick={() => remove(row)}
              >
                <i className='fa fa-trash' />
              </Button>
              <UncontrolledTooltip target={`removeButton${row._id}`}>
                Remove
              </UncontrolledTooltip>
            </Fragment>
          )}
        </ButtonGroup>
      ),
    },
  ]);

  const [filteredAdminLogs, setFilteredAdminLogs] = useState(undefined);

  useEffect(() => {
    sidebar();
  }, [isVisible]);

  useEffect(() => {
    getAdminDeletionLogs();
  }, []);
  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Recycle Bin</h1>
      </CardTitle>
      <br></br>
      <div className='card-actions'>
        {hasPermission(profile?.permissions, UserPermissions.Admin) && (
          <Fragment>
            <Row>
              <Col>
                <NavbarBrand className='mr-auto'>Admin Recycle Bin</NavbarBrand>
              </Col>
              <Col>
                <NavbarToggler
                  id='AdminRecycleBinToggle'
                  className='mr-2 float-right'
                >
                  <FontAwesomeIcon icon={faBars} />
                </NavbarToggler>
              </Col>
            </Row>

            <UncontrolledCollapse toggler='#AdminRecycleBinToggle' defaultOpen>
              <Button
                className={'float-right'}
                onClick={() => emptyRecycleBin(LogType.Admin)}
              >
                Empty Recycle Bin
              </Button>
              <RecycleBinSearchComponent
                disabled={adminDeletionLogs?.length === 0}
                adminBin={true}
                id='admin'
                data={adminDeletionLogs}
                filteredData={filteredAdminLogs}
                setFilteredData={setFilteredAdminLogs}
              />
              <DataTable
                defaultSortField='creation_date'
                defaultSortAsc={false}
                columns={adminColumns}
                data={filteredAdminLogs}
                persistTableHead
                customStyles={CustomDataTableStyles}
                pagination
                expandableRowsComponent={
                  <RecycleBinExpandedComponent data={filteredAdminLogs} />
                }
                expandOnRowClicked
                expandableRows
              />
            </UncontrolledCollapse>
          </Fragment>
        )}
        <ModalConfirmation
          header={header}
          body={body}
          modal={modal}
          buttonConfirm={buttonConfirm}
          toggle={toggle}
          modalAction={modalAction}
          actionData={actionData}
          restoreItem={restoreDeletedItem}
          emptyBin={deleteRecycleBin}
          deleteItem={removeDeletedItem}
        />
      </div>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  sideBarVisible: state.sidebar.isVisible,
  adminDeletionLogs: state.logging.adminDeletionLogs,
  profile: state.profile.profile,
  loading: state.project.loading,
});

export default connect(mapStateToProps, {
  sidebar: withSidebar,
  getAdminDeletionLogs,
  restoreDeletedItem,
  setProject,
  deleteRecycleBin,
  removeDeletedItem,
})(RecycleBin);
