import React from 'react';
import { connect } from 'react-redux';
import FadeAlert from '../layout/Alert';
import LicenseAlert from '../layout/LicenseAlert';
import Navbar from '../layout/Navbar';
const Dashboard = ({ 
  children, isOpen, project 
}) => {
  var containerStyle;
  var padding;
  
  // Disable the padding for the sign in page
  if (
    window.location.pathname === '/' ||
    window.location.pathname.includes('/password-reset/')
  ) {
    padding = 0;
  } else {
    padding = isOpen ? '200px' : '65px';
  }

  containerStyle = { paddingLeft: padding };
  return (
    <React.Fragment>
      <div className='topbar'>
        <Navbar />
      </div>
      <div>
        {/* <Sidebar /> */}
        <div style={containerStyle}>
          <LicenseAlert />
          <FadeAlert />
          {project?.baseline !== undefined && (
            <h1>
              Baseline {project.baseline.version}: {project.baseline_comment}
            </h1>
          )}
          {children}
          {/* <Footer /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (store) => ({
    isOpen: store.sidebar.isOpen,
    project: store.project.project,
  }),
  null
)(Dashboard);
