import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  ProjectRole,
  ProjectType,
  RefTreeModType,
  RiskDecision,
  SecurityGoal,
} from '../../../../constants/Enums';
import { Cancel, OK, SaveAll } from '../../../../constants/StringConstants';
import {
  clearSearchIds,
  loadReftreeFromId,
  setSearchIds,
  toggleReview,
} from '../../../../redux/actions/analysistree';
import { setCatalog } from '../../../../redux/actions/catalog';
import { setEntity, updateEntity } from '../../../../redux/actions/entities';
import {
  getControlCatalogs,
  getReftrees,
} from '../../../../redux/actions/profile';
import {
  reloadAnalysisTree,
  setProject,
} from '../../../../redux/actions/projects';
import store from '../../../../redux/store';
import CopyReftreeModal from '../../../entities/EntityModals/CopyReftreeModal';
import DraggableModal from '../../../entities/EntityModals/DraggableModal';
import { capitalizeStart } from '../../../helpers/genericHelper';
import RefTreeModificationModal from '../../RefTreeModificationModal';
import { control } from './ControlDetails';
import ReviewComment from './ReviewComment';
import { threatDetails } from './ThreatDetails';
import { vulnerability } from './VulnerabilityDetails';
import { InputTemplate } from '../../../entities/EntityModals/InputTemplate';
import RiskDecisionControl from './RiskDecisionControl'
import {AssumptionsTable} from './AssumptionsTable';

// displays Modal popup when cancel button is clicked on edit mode
const CancelConfirmation = ({ decision, modal, toggle, disableSaveCheck }) => {
  const chooseDesicion = (event) => {
    decision(event.target.name);
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Exit editing of Node?</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! There are unsaved changes
            {disableSaveCheck && ' as well as missing input values'}. Do you
            want to continue and loose all unsaved changes?
          </Label>
          <div align={'right'}>
            {' '}
            <ButtonGroup>
              <Button size='sm' name={OK} onClick={chooseDesicion}>
                {' '}
                {OK}
              </Button>
              <Button
                size='sm'
                className='btn-add'
                disabled={disableSaveCheck}
                name={SaveAll}
                onClick={chooseDesicion}
              >
                {' '}
                {SaveAll}
              </Button>
              <Button
                size='sm'
                className='btn-danger'
                name={Cancel}
                onClick={chooseDesicion}
              >
                {' '}
                {Cancel}{' '}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

export const EditConfirm = ({ modal, toggle, confirmEdit }) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Edit Node?</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! When you make changes to this node, all changes will be
            applied to all instances of this node within all projects this node
            is referenced.
          </Label>
          <div>
            <ButtonGroup>
              <Button
                className='btn-confirm-delete mr-15'
                onClick={() => {
                  confirmEdit();
                  toggle();
                }}
              >
                {OK}
              </Button>
              <Button
                className='btn-cancel-delete ml-15'
                name={Cancel}
                onClick={toggle}
              >
                {Cancel}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

// to show node details page
const NodeDetailsPage = ({
  assets,
  threats,
  loading,
  project,
  entities,
  entity,
  updateEntity,
  setEntity,
  setSearchIds,
  clearSearchIds,
  setProject,
  projectRole,
  fromCatalog,
  rights,
  userRefTreeCatalogs,
  userControlCatalogs,
  userReftrees,
  profile,
  catalogRole,
  disableRefTreeWarnings,
  nodeData,
  reloadAnalysisTree,
  selectedProjectType,
  loadReftreeFromId,
  toggleReview,
}) => {
  const [modal, setModal] = useState(false);
  const { nodeId, catalogId, refTreeId, projectId } = useParams();
  const toggle = () => setModal(!modal);
  const [editMode, setEditMode] = useState(null);
  const [editEntity, setEditEntity] = useState(null);
  const query = new URLSearchParams(window.location.search);
  const rootId = query.get('rootId');
  const changeMode = () => {
    setEditMode(!editMode);
    setEditEntity(null);
  };
  const history = useHistory();
  const [openReview, setReview] = useState(false);
  const [openPlacement, setPlacement] = useState(false);
  const [openRiskDecision, setRiskDecision] = useState(true);
  const toggleComments = () => setReview(!openReview);
  const toggleRiskDecision = () => setRiskDecision(!openRiskDecision);
  const togglePlacement = () => setPlacement(!openPlacement);
  const { dispatch } = store;
  const urlParams = new URLSearchParams(window.location.search);
  const [fromEntities, setFromEntities] = useState(null);

  const [parent, setParent] = useState(null);

  const [riskDecisionValue, setRiskDecisionValue] = useState('')

  useEffect(() => {
    if (urlParams.has('edit')) {
      const editParam = urlParams.get('edit');

      editParam === 'true' && setEditMode(true);
    }
    if (urlParams.has('fromEntities')) {
      setFromEntities(urlParams.get('fromEntities'));
    }
  }, []);

  useEffect(() => {
    if (entity !== undefined) {
      setRiskDecisionValue(entity?.risk_decision)
    }
  },[entity])

  const CatalogFilter = (cat) =>
    cat?.children?.some(
      (child) =>
        parseInt(child) ===
        parseInt(
          userReftrees.find(
            (refTree) => parseInt(refTree?._id) === parseInt(entity?.owner?.id)
          )?._id
        )
    );

  useEffect(() => {
    if (profile !== null) {
      if (
        project === undefined ||
        project === null ||
        entity === undefined ||
        entity === null
      ) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        const projectId = splitUrl[1];
        const rootId = parseInt(
          new URLSearchParams(window.location.search).get('rootId')
        );
        if (projectId !== undefined && !project && !isNaN(projectId)) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate, rootId);
        } else {
          if (
            Array.isArray(userControlCatalogs) &&
            Array.isArray(userReftrees)
          ) {
            const refTreeCatalog = userRefTreeCatalogs?.find((cat) =>
              CatalogFilter(cat)
            );
            if (refTreeCatalog?.project_id) {
              setProject({ _id: refTreeCatalog.project_id });
            }
          }
          if (
            Array.isArray(userRefTreeCatalogs) &&
            Array.isArray(userReftrees)
          ) {
            const controlCatalog = userControlCatalogs?.find((cat) =>
              CatalogFilter(cat)
            );
            if (controlCatalog?.project_id) {
              setProject({ _id: controlCatalog.project_id });
            }
          }
        }
        if (Array.isArray(entities)) {
          const entity = entities?.find(
            (ent) => parseInt(ent._id) === parseInt(splitUrl[4])
          );

          async function getData() {
            await setEntity(entity);
          }
          entity && getData();
        }
      }
    }

    if( entity !== undefined && parseInt(nodeId) !== entity?._id) {
      const entityItem = entities?.find((entityItem) => (entityItem?._id === parseInt(nodeId)))
      if (entityItem !== undefined) {
        setEntity(entityItem)

      }
    }

    if (
      userRefTreeCatalogs === undefined ||
      userControlCatalogs === undefined
    ) {
      let id = undefined;
      if (isFinite(projectId)) {
        id = projectId;
      }
      dispatch(getControlCatalogs(id));
    }
    if (userReftrees === undefined && entity?.owner?.owner_type === 'reftree') {
      let id = undefined;
      if (isFinite(projectId)) {
        id = projectId;
      }
      dispatch(getReftrees(id));
    }
    if (refTreeId && nodeId && catalogId && entity === null) {
      setEntity({
        _id: nodeId,
      });
    }
    if (profile !== null && catalogRole === undefined && catalogId) {
      dispatch(
        setCatalog({
          _id: catalogId,
        })
      );
    }
    if (urlParams.has('parentId') && entities !== null) {
      setParent(
        entities.find(
          (entity) =>
            parseInt(entity._id) === parseInt(urlParams.get('parentId'))
        )
      );
    }

    if (entity?.entity_type === 'vulnerability'){
      setParent(threats.find((threat => (threat?.children?.includes(entity?._id)))))
    }
  }, [
    project,
    entities,
    userRefTreeCatalogs,
    userControlCatalogs,
    userReftrees,
    profile,
    entity,
    nodeId
  ]);

  const disableSaveCheck =
    editEntity === null ||
    (editEntity.likelihood_estimation_method ===
      LikelihoodEstimation[LikelihoodMethod.Category] &&
      ((entity.elapsed_time === undefined &&
        editEntity.elapsed_time === undefined) ||
        (entity.specialist_knowledge === undefined &&
          editEntity.specialist_knowledge === undefined) ||
        (entity.item_knowledge === undefined &&
          editEntity.item_knowledge === undefined) ||
        (entity.window_of_opportunity === undefined &&
          editEntity.window_of_opportunity === undefined) ||
        (entity.equipment === undefined &&
          editEntity.equipment === undefined))) ||
    editEntity?.likelihood_comment?.length < 8;

  const ShowNode = () => {
    let url = '';
    if (rootId) {
      reloadAnalysisTree(rootId);
      url = `/${project._id}/analysisTree/?rootId=${rootId}&focusNode=${entity._id}`;
    } else if (refTreeId) {
      reloadAnalysisTree(refTreeId);
      url = `/catalogues/${catalogId}/reftree/${refTreeId}?focusNode=${entity._id}`;
    } else {
      url = `/${project._id}/analysisTree?focusNode=${entity?._id}`;
    }
    clearSearchIds();
    history.push(url);
  };

  const fieldChange = (event) => {
    if (
      event.target.name === 'implemented_state' ||
      event.target.name === 'proposed_state'
    ) {
      setEditEntity({
        ...editEntity,
        [event.target.name]:
          editEntity?.[event.target.name] === undefined
            ? true
            : !editEntity[event.target.name],
      });
    } else {
      setEditEntity({
        ...editEntity,
        [event.target.name]: event.target.value,
      });
    }
  };

  // * To do saving of other fields when backend is ready
  const save = async () => {
    //selective deletion of impact data if threat is being edited
    if (editEntity?.impact !== undefined) {
      editEntity.impact?.safety === '' && delete editEntity.impact.safety;
      editEntity.impact?.financial === '' && delete editEntity.impact.financial;
      editEntity.impact?.operational === '' &&
        delete editEntity.impact.operational;
      editEntity.impact?.privacy === '' && delete editEntity.impact.privacy;
    }
    const payload = {
      _id: entity._id,
      ...editEntity,
    };
    const update = await updateEntity(payload, entity.entity_type);
    if (update) {
      changeMode();
    }
  };

  const reviewChange = (event) => {
    const payload = {
      _id: entity._id,
      review_state: !entity.review_state,
    };
    updateEntity(payload, entity.entity_type);
    setEntity({ ...entity, review_state: !entity.review_state });
  };

  const decision = async (decision) => {
    await decision;
    if (decision === OK) {
      setEditEntity(null);
      toggle();
      changeMode();
    } else if (decision === SaveAll) {
      toggle();
      save();
    } else {
      toggle();
    }
  };

  const commonProperties = (entity) => {
    const damageScenarios = [
      {
        header: 'Damage Scenario Examples',
        title: 'Scenario 1: Vehicle cannot be driven at night',
        description:
          'Vehicle cannot be driven at night because the headlamp function was inhibited while parked',
      },
      {
        title: 'Scenario 2: Front collision with a narrow stationary object',
        description:
          'Front collision with a narrow stationary object caused by unintended turning-off of headlamp during night driving at medium speed',
      },
    ];

    const threatScenarios = [
      {
        header: 'Threat Scenario Examples',
        title: 'Scenario',
        description:
          "Spoofing of a signal leads to loss of integrity of the data communication of the 'Lamp Request' signal to the power switch actuator ECU, potentially causing the headlamp to turn off unintentionally",
      },
    ];

    // Check the entity_type and choose the appropriate scenarios
    let selectedScenarios = [];

    if (entity?.entity_type === 'threat') {
      selectedScenarios = damageScenarios;
    } 
    else if (
        (project?.project_type === ProjectType.ISO_21434.Value ||
        selectedProjectType === ProjectType.ISO_21434.Value) &&
        parent?.entity_type === 'threat' && entity?.entity_type === 'vulnerability'
    ) {
        selectedScenarios = threatScenarios;
    } 
    else {
        selectedScenarios = [];
    }

    return editMode ? (
      <div className='border-top-dark'>
        {entity?.entity_type === EntityType.asset && (
          <Row>
            <Col md={5}>
              <div className='d-flex align-items-center'>
                <h3>
                  <Label>
                    {
                      ProjectType[project?.project_type ?? selectedProjectType]
                        .ThreatSecurityType
                    }
                    :{' '}
                  </Label>
                </h3>
              </div>
            </Col>
          </Row>
        )}
        <h3>Description: </h3>
        <Input
          type='textarea'
          name='description'
          value={editEntity?.description ?? entity?.description}
          onChange={fieldChange}
        />
        <ul>
          {selectedScenarios.map((scenario, index) => (
            <div key={index}>
              <h3>{scenario.header}</h3>
              <strong>{scenario.title}</strong>
              <p>{scenario.description}</p>
            </div>
          ))}
        </ul>
      </div>
    ) : (
      <div className='border-top-dark'>
        <h3>Description: </h3>
        <p className='display-linebreak'>{entity?.description}</p>
        <br />
        <ul>
          {selectedScenarios.map((scenario, index) => (
            <div key={index}>
              <h3>{scenario.header}</h3>
              <strong>{scenario.title}</strong>
              <p>{scenario.description}</p>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  const [likelihoodModal, setLikelihoodModal] = useState(false);
  const toggleLikelihoodModal = () => setLikelihoodModal(!likelihoodModal);

  const setLikelihoodEstimationMethod = (method) => {
    const payload = { ...editEntity, likelihood_estimation_method: method };
    delete payload.elapsed_time;
    delete payload.specialist_knowledge;
    delete payload.item_knowledge;
    delete payload.window_of_opportunity;
    delete payload.equipment;
    delete payload.likelihood_comment;
    setEditEntity(payload);
  };

  const updateImpact = (category, level) => {
    let newImpact = { ...editEntity?.impact };
    if (editEntity?.impact === undefined) {
      newImpact = entity?.impact;
    }
    newImpact[category] = level;
    setEditEntity({ ...editEntity, impact: newImpact });
  };

  const [placeholderEstimationMethod, setPlaceholderEstimationMethod] =
    useState(undefined);

  const getDetails = (entity) => {
    var details;
    switch (entity?.entity_type) {
      case EntityType.asset:
        details = threatDetails(
          entity,
          fieldChange,
          editEntity,
          editMode,
          project,
          selectedProjectType
        );
        break;
      case EntityType.threat:
        details = threatDetails(
          entity,
          fieldChange,
          editEntity,
          editMode,
          project,
          updateImpact,
          selectedProjectType
        );
        break;
      case EntityType.vulnerability:
        details = vulnerability(
          entity,
          fieldChange,
          editEntity,
          editMode,
          project,
          likelihoodModal,
          toggleLikelihoodModal,
          setLikelihoodEstimationMethod,
          placeholderEstimationMethod,
          setPlaceholderEstimationMethod,
          selectedProjectType,
          asset,
          threat
        );
        break;
      case EntityType.control:
        details = control(entity, fieldChange, editEntity, editMode);
        break;
      default:
        break;
    }
    return details;
  };

  //Using a likelihood value and project type, gets the matching feasibility text
  const AttackFeasibility = (likelihood) => {
    var feasibility = ProjectType[
      project?.project_type ?? selectedProjectType
    ]?.AttackFeasibility.find(
      (feas) => likelihood >= feas.lower && likelihood <= feas.upper
    );
    return (
      <font style={{ color: feasibility?.color }}>{feasibility?.value}</font>
    );
  };

  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const toggleEditConfim = () => setEditConfirmModal(!editConfirmModal);
  const [refTreeModWarningModal, openRefTreeModWarningModal] = useState(false);
  const toggleRefTreeModWarningModal = () =>
    openRefTreeModWarningModal((prevState) => !prevState);

  const [RefTreeCreation, openRefTreeCreation] = useState(false);
  const toggleRefTreeCreation = () =>
    openRefTreeCreation((prevState) => !prevState);

  const [reftreeLoading, setReftreeLoading] = useState(false);
  useEffect(() => {
    if (
      entity?.owner?.owner_type === 'reftree' &&
      entity &&
      userReftrees?.find(
        (refTree) => parseInt(refTree?._id) === parseInt(entity?.owner?.id)
      ) === undefined &&
      !reftreeLoading
    ) {
      setReftreeLoading(true);
      loadReftreeFromId(entity.owner.id);
    }
  }, [entity, userReftrees]);

  const catalogChildrenFilter = (cat) =>
    cat?.children?.some(
      (child) =>
        parseInt(child) ===
        parseInt(
          userReftrees &&
            userReftrees.find(
              (refTree) => parseInt(refTree._id) === parseInt(entity.owner.id)
            )?._id
        )
    );

  const threat = threats.find((item) => item?.children?.includes(entity?._id));

  const asset = assets.find(
    (item) =>
      item?.children?.some((child) => child._id === threat?._id) ||
      item?.children?.includes(threat?._id)
  );

  const riskDecisionType =
    riskDecisionValue === RiskDecision.avoiding.toLowerCase()
      ? false
      : riskDecisionValue === RiskDecision.reducing.toLowerCase()
      ? 'Goal'
      : 'Claim';
  return (
    <Container fluid='md'>
      <h1>Node Details</h1>
      <Card>
        <CardHeader>
          <CardTitle>
            <Row>
              <Col md={5}>
                {editMode ? (
                  <Input
                    type='text'
                    name='name'
                    value={editEntity?.name ?? entity?.name}
                    onChange={fieldChange}
                  />
                ) : (
                  <h2> {entity?.name}</h2>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Form inline>
                  <FormGroup check>
                    <Label check>
                      Reviewed:{' '}
                      <Input
                        type='checkbox'
                        checked={entity?.review_state}
                        name='review_state'
                        onChange={reviewChange}
                        disabled={editMode}
                      />
                    </Label>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                {(project?.project_type === ProjectType.ISO_21434.Value ??
                  selectedProjectType === ProjectType.ISO_21434.Value) &&
                entity?.entity_type === 'threat' ? (
                  <>
                    {' '}
                    {'DAMAGE SCENARIO'}
                    {': #'}
                    {entity?._id}
                  </>
                ) : (
                  <>
                    {(project?.project_type === ProjectType.ISO_21434.Value ??
                      selectedProjectType === ProjectType.ISO_21434.Value) &&
                    (parent?.entity_type === 'threat' && entity?.entity_type === 'vulnerability')  ? (
                      <>
                        {'THREAT SCENARIO'}
                        {': #'}
                        {entity?._id}
                      </>
                    ) : (
                      <>
                        {entity?.entity_type?.toString()?.toUpperCase()}
                        {': #'}
                        {entity?._id}{' '}
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <br />
            {entity?.owner?.owner_type === 'reftree' ? (
              <>
                <Row>
                  <Col md={5}>
                    Entry name:{' '}
                    {userReftrees &&
                      userReftrees?.find(
                        (refTree) =>
                          parseInt(refTree._id) === parseInt(entity.owner.id)
                      )?.name}
                  </Col>
                  <Col>
                    Catalog:{' '}
                    {Array.isArray(userRefTreeCatalogs) &&
                      userRefTreeCatalogs?.find((cat) =>
                        catalogChildrenFilter(cat)
                      )?.name}
                    {Array.isArray(userControlCatalogs) &&
                      userControlCatalogs?.find((cat) =>
                        catalogChildrenFilter(cat)
                      )?.name}
                  </Col>
                </Row>
              </>
            ) : null}

            <br />
            <div align={'left'}>
              {' '}
              {editMode ? (
                <ButtonGroup>
                  <Button
                    size='sm'
                    className='btn-add'
                    disabled={disableSaveCheck}
                    onClick={() => save()}
                  >
                    {' '}
                    Save
                  </Button>
                  <Button
                    size='sm'
                    className='btn-danger'
                    onClick={() => {
                      if (editEntity === null) {
                        changeMode();
                      } else {
                        toggle();
                      }
                    }}
                  >
                    {' '}
                    {Cancel}
                  </Button>
                  <CancelConfirmation
                    decision={decision}
                    modal={modal}
                    toggle={toggle}
                    disableSaveCheck={disableSaveCheck}
                  />
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  {((projectId &&
                    (projectRole === ProjectRole.Owner.value ||
                      projectRole === ProjectRole.Editor.value ||
                      projectRole === ProjectRole.Demo.value)) ||
                    (catalogId &&
                      (catalogRole === ProjectRole.Owner.value ||
                        catalogRole === ProjectRole.Editor.value ||
                        catalogRole === ProjectRole.Demo.value)) ||
                    rights) && (
                    <Button
                      size='sm'
                      onClick={() => {
                        if (
                          userControlCatalogs.some((cat) =>
                            cat.children.some(
                              (child) =>
                                parseInt(child) ===
                                parseInt(
                                  userReftrees.find(
                                    (refTree) =>
                                      parseInt(refTree._id) ===
                                      parseInt(entity.owner.id)
                                  )?._id
                                )
                            )
                          )
                        ) {
                          toggleEditConfim();
                        } else if (entity.owner.owner_type === 'reftree') {
                          if (disableRefTreeWarnings) {
                            changeMode();
                          } else {
                            toggleRefTreeModWarningModal();
                          }
                        } else {
                          changeMode();
                        }
                      }}
                    >
                      {' '}
                      Edit
                    </Button>
                  )}
                  {fromCatalog ? null : (
                    <Button
                      size='sm'
                      className='btn-add'
                      onClick={() => ShowNode()}
                    >
                      {' '}
                      Show Node in Tree
                    </Button>
                  )}
                </ButtonGroup>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <h3>Node Properties</h3>
          </div>
          <Row>
            <Col>
              {' '}
              {entity?.entity_type !== EntityType.asset ? (
                <div className='border-top-dark'>{getDetails(entity)}</div>
              ) : null}
              {commonProperties(entity)}
            </Col>
            <Col>
              {(entity?.no_control_likelihood ||
                entity?.implemented_control_likelihood ||
                entity?.proposed_control_likelihood ||
                entity?.all_control_likelihood) && (
                <div className='border-top-dark'>
                  <h3>Likelihood:</h3>
                  {entity?.no_control_likelihood && (
                    <Row>
                      <Col md={3}>
                        <Label>No Control Likelihood: </Label>
                      </Col>
                      <Col md={5}>{entity?.no_control_likelihood}</Col>
                    </Row>
                  )}
                  {entity?.proposed_control_likelihood && (
                    <Row>
                      <Col md={3}>
                        <Label>Proposed Control Likelihood: </Label>
                      </Col>
                      <Col md={5}>{entity?.proposed_control_likelihood}</Col>
                    </Row>
                  )}
                  {entity?.implemented_control_likelihood && (
                    <Row>
                      <Col md={3}>
                        <Label>Implemented Control Likelihood: </Label>
                      </Col>
                      <Col md={5}>{entity?.implemented_control_likelihood}</Col>
                    </Row>
                  )}
                  {entity?.all_control_likelihood && (
                    <Row>
                      <Col md={3}>
                        <Label> All Control Likelihood: </Label>
                      </Col>
                      <Col md={5}>{entity?.all_control_likelihood}</Col>
                    </Row>
                  )}
                  {entity?.no_control_risk && (
                    <Row>
                      <Col md={3}>
                        <Label> No Control Risk: </Label>
                      </Col>
                      <Col md={5}>{entity?.no_control_risk}</Col>
                    </Row>
                  )}
                  {entity?.proposed_control_risk && (
                    <Row>
                      <Col md={3}>
                        <Label>Proposed Control Risk: </Label>
                      </Col>
                      <Col md={5}>{entity?.proposed_control_risk}</Col>
                    </Row>
                  )}
                  {entity?.implemented_control_risk && (
                    <Row>
                      <Col md={3}>
                        <Label>Implemented Control Risk: </Label>
                      </Col>
                      <Col md={5}>{entity?.implemented_control_risk}</Col>
                    </Row>
                  )}
                  {entity?.all_control_risk && (
                    <Row>
                      <Col md={3}>
                        <Label> All Control Risk: </Label>
                      </Col>
                      <Col md={5}>{entity?.all_control_risk}</Col>
                    </Row>
                  )}
                  {entity?.no_control_likelihood && (
                    <Row>
                      <Col md={6}>
                        <Label>
                          No control{' '}
                          {ProjectType[
                            project?.project_type ?? selectedProjectType
                          ]?.RiskColumnText?.toLowerCase()}
                          :
                        </Label>
                      </Col>
                      <Col md={5}>
                        {AttackFeasibility(entity?.no_control_likelihood)}
                      </Col>
                    </Row>
                  )}
                  {entity?.implemented_control_likelihood && (
                    <Row>
                      <Col md={6}>
                        <Label>
                          Implemented control{' '}
                          {ProjectType[
                            project?.project_type ?? selectedProjectType
                          ]?.RiskColumnText?.toLowerCase()}
                          :
                        </Label>
                      </Col>
                      <Col md={5}>
                        {AttackFeasibility(
                          entity?.implemented_control_likelihood
                        )}
                      </Col>
                    </Row>
                  )}
                  {entity?.proposed_control_likelihood && (
                    <Row>
                      <Col md={6}>
                        <Label>
                          Proposed control{' '}
                          {ProjectType[
                            project?.project_type ?? selectedProjectType
                          ]?.RiskColumnText?.toLowerCase()}
                          :
                        </Label>
                      </Col>
                      <Col md={5}>
                        {AttackFeasibility(entity?.proposed_control_likelihood)}
                      </Col>
                    </Row>
                  )}
                  {entity?.all_control_likelihood && (
                    <Row>
                      <Col md={6}>
                        <Label>
                          All control{' '}
                          {ProjectType[
                            project?.project_type ?? selectedProjectType
                          ]?.RiskColumnText?.toLowerCase()}
                          :
                        </Label>
                      </Col>
                      <Col md={5}>
                        {AttackFeasibility(entity?.all_control_likelihood)}
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Col>
            {entity?.risk && (
              <Col>
                <div className='border-top-dark'>
                  <h3>Risks:</h3>
                  {entity?.entity_type === EntityType.vulnerability &&
                    project?.project_type === ProjectType.ISO_21434.Value && (
                      <Row>
                        <Col md={3}>
                          <Label>Risk Decision:</Label>
                        </Col>
                        <Col>
                          {editMode ? (
                            <InputTemplate
                              type='select'
                              name='risk_decision'
                              onChange={(event) => {
                                setRiskDecisionValue(event.target.value)
                                fieldChange(event)}}
                              value={
                                editEntity?.risk_decision ??
                                entity?.risk_decision
                              }
                              selectDefault
                            >
                              {Object.entries(RiskDecision).map((decision) => (
                                <option value={decision[0]}>
                                  {decision[1]}
                                </option>
                              ))}
                            </InputTemplate>
                          ) : (
                            RiskDecision[entity?.risk_decision]
                          )}
                        </Col>
                      </Row>
                    )}

                  {Object.entries(entity?.risk).map(
                    (riskItem) =>
                      riskItem && (
                        <Fragment>
                          <Row>
                            <Col md={3}>
                              <Label> {capitalizeStart(riskItem[0])}:</Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={1} />
                            <Col md={5}>
                              <Label>All control risk</Label>
                            </Col>
                            <Col md={5}>{riskItem[1].all_controls}</Col>
                          </Row>
                          <Row>
                            <Col md={1} />
                            <Col md={5}>
                              <Label>No control risk:</Label>
                            </Col>
                            <Col md={5}>{riskItem[1].no_controls}</Col>
                          </Row>
                          <Row>
                            <Col md={1} />
                            <Col md={5}>
                              <Label>Proposed control risk:</Label>
                            </Col>
                            <Col md={5}>{riskItem[1].proposed_controls}</Col>
                          </Row>
                          <Row>
                            <Col md={1} />
                            <Col md={5}>
                              <Label>Implemented control risk:</Label>
                            </Col>
                            <Col md={5}>{riskItem[1].implemented_controls}</Col>
                          </Row>
                        </Fragment>
                      )
                  )}
                </div>
              </Col>
            )}
          </Row>
          {entity?.reftree_placement_review_state !== undefined && (
            <>
              {' '}
              <CardHeader
                style={{ cursor: 'pointer' }}
                onClick={togglePlacement}
              >
                {' '}
                {openPlacement ? (
                  <FontAwesomeIcon icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} />
                )}{' '}
                {'  '}Review Placement
              </CardHeader>{' '}
              <Collapse isOpen={openPlacement}>
                <Col>
                  {entity?.reftree_placement_review_state.map(
                    (placement_review) => {
                      return (
                        <FormGroup check>
                          <Label check>
                            <Input
                              disabled={
                                projectRole !== ProjectRole.Reviewer.value
                              }
                              type='checkbox'
                              checked={placement_review.review_state}
                              onClick={() => {
                                toggleReview(
                                  entity,
                                  placement_review.starting_node_id
                                );
                              }}
                            />
                            Placement: ID#
                            {placement_review.starting_node_id}
                          </Label>
                        </FormGroup>
                      );
                    }
                  )}
                </Col>
              </Collapse>{' '}
            </>
          )}
          {riskDecisionValue && riskDecisionType && (
            <>
              {' '}
              <CardHeader
                style={{ cursor: 'pointer' }}
                onClick={toggleRiskDecision}
              >
                {openRiskDecision ? (
                  <FontAwesomeIcon icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} />
                )}
                {` Cybersecurity ${riskDecisionType}`}
              </CardHeader>
              <Collapse isOpen={openRiskDecision}>
                <RiskDecisionControl
                  type={riskDecisionType}
                  entityId={entity?._id}
                  riskDecisionControls={entity?.risk_decision_control?.content}
                  riskDecision = {riskDecisionValue}
                />
              </Collapse>
            </>
          )}
           {riskDecisionValue === RiskDecision.avoiding.toLowerCase() && (
            <>
              {' '}
              <CardHeader
                style={{ cursor: 'pointer' }}
                onClick={toggleRiskDecision}
              >
                {openRiskDecision ? (
                  <FontAwesomeIcon icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} />
                )}
                {`Assumptions`}
              </CardHeader>
              <Collapse isOpen={openRiskDecision}>
                <AssumptionsTable
                  project = {project}
                  entityId={entity?._id}
                  entity={entity}
                />
              </Collapse>
            </>
          )}
          <CardHeader style={{ cursor: 'pointer' }} onClick={toggleComments}>
            {' '}
            {openReview ? (
              <FontAwesomeIcon icon={faCaretDown} />
            ) : (
              <FontAwesomeIcon icon={faCaretRight} />
            )}{' '}
            {'  '}Review Comments
          </CardHeader>{' '}
          <Collapse isOpen={openReview}>
            <ReviewComment
              type={'entity'}
              editable={projectRole !== ProjectRole.Reader.value}
            />
          </Collapse>
        </CardBody>
      </Card>
      <EditConfirm
        toggle={toggleEditConfim}
        modal={editConfirmModal}
        confirmEdit={changeMode}
      />
      <RefTreeModificationModal
        type={RefTreeModType.Editing}
        modal={refTreeModWarningModal}
        toggle={toggleRefTreeModWarningModal}
        nodeData={nodeData}
        fromTree={rootId || refTreeId ? false : true}
        fromEntities={fromEntities}
        confirmEdit={changeMode}
        nextToggle={toggleRefTreeCreation}
      />
      <CopyReftreeModal
        modal={RefTreeCreation}
        toggle={toggleRefTreeCreation}
        node={nodeData}
        goToEdit={changeMode}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assets.assets,
  threats: state.threats.threats,
  loading: state.auth.loading,
  project: state.project.project,
  entity: state.entities.entity,
  entities: state.entities.entities,
  analysistree: state.analysistree.analysistree,
  subtrees: state.analysistree.Subtrees,
  projectRole: state.profile.projectRole,
  userRefTreeCatalogs: state.profile.userRefTreeCatalogs,
  userControlCatalogs: state.profile.userControlCatalogs,
  userReftrees: state.profile.userReftrees,
  profile: state.profile.profile,
  catalogRole: state.catalog.catalogRole,
  disableRefTreeWarnings: state.analysistree.disableRefTreeWarnings,
  nodeData: state.entities.nodeData,
  selectedProjectType: state.analysistree.selectedProjectType,
});
export default connect(mapStateToProps, {
  updateEntity,
  setEntity,
  setSearchIds,
  setProject,
  loadReftreeFromId,
  reloadAnalysisTree,
  toggleReview,
  clearSearchIds,
})(NodeDetailsPage);
