import React from 'react';
import { CardHeader, Collapse } from 'reactstrap';
import ReviewComment from '../../analysistree/nodetypes/nodeObjects/ReviewComment';

/**
 * Review Comments Panel
 *
 * Panel for review comments
 * TODO: To add functions for this feature (response, editing, and deletion of comments)
 *
 * @param toggle: triggers/toggles the modal to close/open
 * @param openPanel: checks if panel is open or closed to change caret
 */

const CommentsPanel = ({ toggle, openPanel, editable }) => {
  return (
    <div>
      <CardHeader style={{ cursor: 'pointer' }} onClick={toggle}>
        {openPanel ? (
          <>
            <i className='fa fa-caret-down' />
            <font className='bold'> Comments</font>
          </>
        ) : (
          <>
            <i className='fa fa-caret-right' />
            <font className='bold'> Comments</font>
          </>
        )}
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <ReviewComment type={'project'} editable={editable}></ReviewComment>
      </Collapse>
    </div>
  );
};

export default CommentsPanel;
