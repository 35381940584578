import {
  GET_SETTINGS,
  INITIALIZE_SETTINGS,
  INSERT_SETTINGS,
} from '../actions/types';

const initialState = {
  msg: '',
  settings: [],
  env: false, 
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case INITIALIZE_SETTINGS:
    case INSERT_SETTINGS:
      return {
        ...state,
        msg: payload,
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: payload.settings,
        env: Boolean(payload.env), 
      };
    default:
      return state;
  }
}
