import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Label } from 'reactstrap';
import { ProjectType } from '../../../constants/Enums';
import { duplicateProject } from '../../../redux/actions/projects';
import TypeSelect from '../project-components/TypeSelect';

/**
 * Modal for creating a project as a duplicate
 *
 * TODO: to be revised based on the mock-up provided
 *
 * @param data: to be delared in the project list
 */

const DuplicateProject = ({ data, duplicateProject, toggle }) => {
  // project to be copied
  const [selection, setSelection] = useState(null);
  // to fields to be copied
  const [project, setProject] = useState({
    name: false,
    project_type: false,
    description: false,
    scope: false,
    accepted_entity_types: [],
    inherit_reftrees: false,
    copy_participants: false,
    _id: '',
  });
  // actual payload to be sent in api
  const [payload, setPayload] = useState(null);

  // check if the missing fields
  const [checkField, setCheckField] = useState(false);

  // on project change
  const onChange = (event) => {
    setSelection(event.value);
    setProject({ ...project, _id: event.value._id });
  };

  // duplicate project
  const create = () => {
    duplicateProject(payload);
  };

  // choose which fields are going to be copied
  const chooseField = (event) => {
    setProject({ ...project, [event.target.id]: !project[event.target.id] });
  };

  // set the missing fields
  const setField = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  };

  // creates payload with the fields to be copied
  const check = () => {
    setCheckField(true);
    const payload = { ...project };
    if (payload.project_type) {
      payload.project_type = selection.project_type;
    } else {
      payload.project_type = Object.entries(ProjectType)[0][1].Value;
    }
    if (payload.scope) {
      payload.scope = selection.scope;
    }
    if (payload.description) {
      payload.description = selection.description;
    }
    var accepted_entity_types = [];
    for (const types of project.accepted_entity_types) {
      if (accepted_entity_types.length < types.split(',').length) {
        accepted_entity_types = types.split(',');
      }
    }
    payload.accepted_entity_types = accepted_entity_types;
    setPayload(payload);
  };

  // choses which entities are being copied
  const acceptEntity = (event) => {
    let types = [...project.accepted_entity_types];
    // if checked put entities to be copied in an array
    // else remove it from the array.
    if (event.target.checked) {
      setProject({
        ...project,
        accepted_entity_types: types.concat(event.target.id),
      });
    } else {
      types = types.filter((type) => event.target.id !== type);
      setProject({
        ...project,
        accepted_entity_types: types,
      });
    }
  };

  return (
    <div>
      {checkField ? (
        <>
          {project.name ? null : (
            <>
              <Label>*Project Name</Label>
              <Input type='text' name='name' onChange={setField} />
            </>
          )}
          {project.project_type ? null : <TypeSelect onChange={setField} />}
          {project.scope ? null : (
            <div>
              <Label>*Project Scope</Label>
              <Input
                type='textarea'
                name='scope'
                onChange={setField}
                style={{ resize: 'none' }}
              />
            </div>
          )}
          {project.description ? null : (
            <div>
              <Label>*Project Description:</Label>
              <Input
                type='textarea'
                name='description'
                onChange={setField}
                style={{ resize: 'none' }}
              />
            </div>
          )}
          <Button
            className='btn-add'
            disabled={
              payload.name === false ||
              payload.description === false ||
              payload.scope === false
            }
            onClick={() => {
              create();
              toggle();
            }}
          >
            {' '}
            Create
          </Button>
          <Button className='btn-danger' onClick={toggle}>
            Cancel
          </Button>
          <br />
          <div className='float-right'>
            <small>*Required Fields</small>
          </div>
        </>
      ) : (
        <>
          <Select
            name='select'
            options={data?.map((project) => ({
              label: project.name,
              value: project,
            }))}
            onChange={onChange}
          >
            {data.name}
          </Select>
          {selection !== null ? (
            <div>
              <br />
              <Col>
                <Input
                  type='checkbox'
                  id='project_type'
                  onChange={chooseField}
                />
                <Label>Project Type</Label>
              </Col>
              <Col>
                <Input type='checkbox' id='scope' onChange={chooseField} />
                <Label>Project Scope</Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id='description'
                  onChange={chooseField}
                />
                <Label>Project Description</Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id='copy_participants'
                  onChange={chooseField}
                />
                <Label>Copy Participants</Label>
              </Col>
              <Col>
                <Input type='checkbox' id='asset' onChange={acceptEntity} />
                <Label>Assets</Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id={['asset', 'threat']}
                  onChange={acceptEntity}
                />
                <Label>Assets and Threats </Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id={['asset', 'threat', 'vulnerability']}
                  onChange={acceptEntity}
                />
                <Label>Analysis tree without control </Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id={['asset', 'threat', 'vulnerability', 'control']}
                  onChange={acceptEntity}
                />
                <Label>Analysis tree with control </Label>
              </Col>
              <Col>
                <Input
                  type='checkbox'
                  id='inherit_reftrees'
                  onChange={chooseField}
                />
                <Label>Inherit Reference Trees</Label>
              </Col>
            </div>
          ) : null}
          <br />
          <Button
            className='float-right btn-add'
            disabled={selection === null}
            onClick={() => check()}
          >
            Continue
          </Button>
          <Button className='float-right btn-danger' onClick={toggle}>
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

export default connect(null, { duplicateProject })(DuplicateProject);
