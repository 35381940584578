import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Container, Spinner } from 'reactstrap';

//This class is for the spinner used in loading the data during api calls
export const SpinnerLoader = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 1000 });

  return (
    promiseInProgress && (
      <Container>
        <Spinner className='spinner' />
      </Container>
    )
  );
};
