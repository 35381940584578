import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { CatalogType, UserPermissions } from '../../constants/Enums';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { btnArrow } from '../../constants/Style';
import { deleteCatalog, setCatalog } from '../../redux/actions/catalog';
import { getControlCatalogs, getReftrees } from '../../redux/actions/profile';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission } from '../helpers/genericHelper';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AddCatalog from './AddCatalog';
import ExpandedComponent from './ExpandedComponent';
import ImportCatalog from './ImportCatalog';

const ModalConfirmation = ({ toggle, modal, row }) => {
  const { dispatch } = store;
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Confirm Delete</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! This catalogue will be deleted and you cannot restore it.
            If any entry of the catalogue are referenced within any project, the
            respective reference(s) will be replaced by a copy of the catalogue
            entry. These copies will no longer be associated with any reference
            catalogues. Are you sure you want to proceed?
          </Label>
          <div>
            <ButtonGroup>
              <Button
                className='btn-confirm-delete mr-15'
                onClick={() => {
                  toggle();
                  dispatch(deleteCatalog(row));
                }}
              >
                Ok
              </Button>
              <Button className='btn-cancel-delete ml-15' onClick={toggle}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const RefTreeCatalog = ({ refTreeCatalogs, profile, project, refTrees }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [modalAddCatalog, setModalAddCatalog] = useState(false);
  const toggleAddCatalog = () => {
    setModalAddCatalog(!modalAddCatalog);
  };

  const [addCatalogType, setAddCatalogType] = useState(null);
  //Sets modal for importing catalog
  const [importModal, setImportModal] = useState(false);
  const toggleImportModal = () => setImportModal(!importModal);

  const { dispatch } = store;

  const history = useHistory();
  const viewCatalog = (catalog) => {
    dispatch(setCatalog(catalog));
    history.push(`${catalog._id}/details`);
  };

  const editCatalog = (catalog) => {
    dispatch(setCatalog(catalog));
    history.push(`${catalog._id}/details?edit=true`);
  };
  const [expandType, setExpandType] = useState([]);

  const [expandedDescription, setExpandedDescription] = useState([]);

  const removeDescription = (id) => {
    const descriptions = expandedDescription.filter((row) => row !== id);
    const types = expandType.filter(
      (row) => parseInt(row._id) !== parseInt(id)
    );
    setExpandedDescription(descriptions);
    setExpandType(types);
  };

  const [expandedEntries, setExpandedEntries] = useState([]);

  const expandEntries = (id) => {
    removeDescription(id);
    const entries = expandedEntries.filter((row) => true);
    entries.push(id);
    setExpandedEntries(entries);
    const types = expandType.filter(
      (row) => parseInt(row._id) !== parseInt(id)
    );
    const newType = {
      _id: id,
      type: 'entries',
    };
    types.push(newType);
    setExpandType(types);
  };

  const removeEntries = (id) => {
    const entries = expandedEntries.filter((row) => row !== id);
    const types = expandType.filter(
      (row) => parseInt(row._id) !== parseInt(id)
    );
    setExpandedEntries(entries);
    setExpandType(types);
  };

  const [deleteRow, setDeleteRow] = useState(null);

  const getTypeIcon = (catalogType, rowId) => {
    var classname, tooltipText;
    switch (catalogType) {
      case 'global':
        classname = 'fa-globe';
        tooltipText = 'Global';
        break;
      case 'default':
      case 'project_specific':
        classname = 'fa-tasks';
        tooltipText = 'Project';
        break;
      default:
        classname = 'fa-question';
        tooltipText = 'Unknown';
    }
    return (
      <>
        <i id={`typeIcon${rowId}`} className={`btn-icon fa ${classname}`} />
        <UncontrolledTooltip target={`typeIcon${rowId}`}>
          {tooltipText}
        </UncontrolledTooltip>
      </>
    );
  };

  //selector should be changed to be able to read from backend
  const projectColumns = useMemo(
    () => [
      {
        name: '',
        cell: (row) => (
          <>
            {expandedEntries.includes(row._id) ? (
              <>
                <div className={'float-right'}>
                  <button
                    style={btnArrow}
                    onClick={() => removeEntries(row._id)}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {' '}
                <>
                  <div className={'float-right'}>
                    <button
                      style={btnArrow}
                      onClick={() => expandEntries(row._id)}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </div>
                </>
              </>
            )}
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: 'ID',
        selector: '_id',
        sortable: true,
        width: '80px',
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        align: 'left',
        cell: (row) => (
          <Link
            to = '#'
            onClick={() => {
              viewCatalog(row);
            }}
          >
            {row?.name}
          </Link>
        ),
      },
      {
        name: 'Description',
        cell: (row) => (
          <>
            {row.description?.length > 50 ? (
              <>
                <div id={`description${row._id}`}>
                  {row.description.substr(0, 50)}...
                </div>
                <UncontrolledTooltip target={`description${row._id}`}>
                  {row.description}
                </UncontrolledTooltip>
              </>
            ) : (
              <>{row.description} </>
            )}
          </>
        ),
      },

      {
        name: 'Type',
        selector: 'catalog_type',
        sortable: true,
        width: '80px',
        cell: (row) => getTypeIcon(row.catalog_type, row?._id),
      },
      {
        name: 'Entries',
        selector: `children`,
        sortable: true,
        width: '80px',
        cell: (row) => <>{row.children?.length}</>,
      },
      {
        name: 'Last Modified',
        selector: 'last_modified',
        sortable: true,
        width: '160px',
        cell: (row) => LocalTimeWithoutSeconds(row.last_modified),
      },
      {
        name: 'Actions',
        cell: (row) => (
          <>
            {hasPermission(profile?.permissions, UserPermissions.Admin) ||
            (row?.catalog_type === 'project_specific' &&
              (project?.participants
                ?.find(
                  (member) =>
                    parseInt(member?.user_id) === parseInt(profile?.id)
                )
                ?.role.includes('owner') ||
                project?.participants
                  ?.find(
                    (member) =>
                      parseInt(member?.user_id) === parseInt(profile?.id)
                  )
                  ?.role.includes('editor'))) ||
            row?.participants
              ?.find(
                (member) => parseInt(member?.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('owner') ||
            row?.participants
              ?.find(
                (member) => parseInt(member?.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor') ? (
              <>
                <Button
                  id={`editButton${row._id}`}
                  onClick={() => {
                    editCatalog(row);
                  }}
                >
                  <i className='fa fa-edit' />
                </Button>
                <UncontrolledTooltip target={`editButton${row._id}`}>
                  Edit
                </UncontrolledTooltip>
              </>
            ) : null}
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        cell: (row) => (
          <>
            {hasPermission(profile?.permissions, UserPermissions.Admin) ||
            (row?.catalog_type === 'project_specific' &&
              (project?.participants
                ?.find(
                  (member) =>
                    parseInt(member?.user_id) === parseInt(profile?.id)
                )
                ?.role.includes('owner') ||
                project?.participants
                  .find(
                    (member) =>
                      parseInt(member?.user_id) === parseInt(profile?.id)
                  )
                  ?.role.includes('editor'))) ||
            row?.participants
              ?.find(
                (member) => parseInt(member?.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('owner') ||
            row?.participants
              ?.find(
                (member) => parseInt(member?.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor') ? (
              <>
                <Button
                  id={`deleteButton${row._id}`}
                  className='btn-danger'
                  onClick={() => {
                    toggle();
                    setDeleteRow(row);
                  }}
                >
                  <i className='fa fa-trash' />
                </Button>
                <UncontrolledTooltip target={`deleteButton${row._id}`}>
                  Delete
                </UncontrolledTooltip>
              </>
            ) : null}
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [expandedDescription, expandedEntries, profile]
  );
  //Filter

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);
  const [data, setData] = useState([]);
  const filteredEntries = refTrees?.filter(
    (item) =>
      item.name?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      item.description
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  const filteredItems = data?.filter(
    (item) =>
      item.name?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      item.description
        ?.toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      filteredEntries.some(
        (entry) => parseInt(entry.catalog_id) === parseInt(item._id)
      )
  );

  useEffect(() => {
    if (refTreeCatalogs === undefined) {
      dispatch(getControlCatalogs());
    }
    if (refTrees === undefined) {
      dispatch(getReftrees());
    }
  }, [refTrees]);

  useEffect(() => {
    let data = [];
    if (project) {
      data = refTreeCatalogs?.filter(
        (treeCatalog) =>
          treeCatalog.catalog_type === 'global' ||
          parseInt(treeCatalog.project_id) === parseInt(project._id)
      );
    } else {
      data = refTreeCatalogs?.filter(
        (treeCatalog) => treeCatalog.catalog_type === 'global'
      );
    }
    setData(data);
  }, [project, refTreeCatalogs, profile]);

  const [dropdownOpen, setOpen] = useState(false);
  const toggleDropDown = () => setOpen(!dropdownOpen);
  return (
    <Container fluid='md'>
      <h1>Reference Tree Catalogue</h1>
      {hasPermission(profile?.permissions, UserPermissions.Admin) ||
      project?.participants
        ?.find((member) => parseInt(member?.user_id) === parseInt(profile?.id))
        ?.role.includes('owner') ||
      project?.participants
        ?.find((member) => parseInt(member?.user_id) === parseInt(profile?.id))
        ?.role.includes('editor') ? (
        <div align={'right'}>
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropDown}
            color='primary'
          >
            <DropdownToggle className='btn-add' caret>
              Add Catalogue
            </DropdownToggle>
            <DropdownMenu className='mr-15'>
              <DropdownItem
                onClick={() => {
                  toggleAddCatalog();
                  setAddCatalogType('new');
                }}
              >
                New Catalogue
              </DropdownItem>
              <AddCatalog
                toggle={toggleAddCatalog}
                modal={modalAddCatalog}
                type={addCatalogType}
                data={refTreeCatalogs}
                content={CatalogType.ReferenceTree}
              />
              <DropdownItem
                onClick={() => {
                  toggleAddCatalog();
                  setAddCatalogType('copy');
                }}
              >
                Copy Catalogue
              </DropdownItem>
              <DropdownItem onClick={toggleImportModal}>
                Import Catalogue
              </DropdownItem>
              <ImportCatalog modal={importModal} toggle={toggleImportModal} />
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      ) : null}

      <br></br>
      <br></br>
      <div align={'right'}>
        <Col md={3}>
          <Input
            id='search'
            type='text'
            placeholder='Search'
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Col>
      </div>
      <DataTable
        columns={projectColumns}
        defaultSortField='_id'
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} //  a hook to reset pagination to page 1
        persistTableHead
        expandableRows
        expandableRowsHideExpander
        expandableRowExpanded={(row) =>
          expandedEntries.includes(row._id) ||
          expandedDescription.includes(row._id) ||
          (filterText &&
            filteredEntries?.some(
              (entry) => parseInt(entry.catalog_id) === parseInt(row._id)
            ))
        }
        expandableRowsComponent={
          <ExpandedComponent
            catalog={refTreeCatalogs}
            type={expandType}
            filteredEntries={filteredEntries}
          />
        }
        customStyles={CustomDataTableStyles}
      />

      <ModalConfirmation toggle={toggle} modal={modal} row={deleteRow} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  refTreeCatalogs: state.profile.userRefTreeCatalogs,
  project: state.project.project,
  profile: state.profile.profile,
  refTrees: state.profile.userReftrees,
});

export default connect(mapStateToProps)(RefTreeCatalog);
