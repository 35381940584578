const CustomDataTableStyles = {
  rows: {
    style: {
      minHeight: '3rem', // override the row height
      borderColor: 'var(--light-background)',
    },
    stripedStyle: {
      backgroundColor: '#FFFFFF !important',
    },
  },
  headRow: {
    style: {
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: 'var(--light-background) !important',
      border: 'none',
    },
  },
  headCells: {
    style: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  pagination: {
    style: {
      color: 'var(--font-color)',
      fontSize: '0.8rem',
    },
    pageButtonsStyle: {
      color: 'var(--font-color)',
      fill: 'var(--font-color)',
    },
  },
};

export default CustomDataTableStyles;
