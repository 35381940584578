import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Alert, Container } from 'reactstrap';
import { removeAlert } from '../../redux/actions/alert';

/**
 *
 * Alert component
 * Used to create alerts
 *
 *  @param alerts redux store value mapped to state, available user alerts
 *  @param removeAlert redux action to remove alert
 */
const FadeAlert = ({ alerts, removeAlert }) =>
  alerts !== null &&
  alerts?.length > 0 &&
  alerts.map((alert) => <AlertModal alert={alert} removeAlert={removeAlert} />);
FadeAlert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const AlertModal = ({ alert, removeAlert }) => {
  return (
    <Container fluid='md'>
      <Alert
        toggle={() => {
          removeAlert(alert.id);
        }}
        style={{
          zIndex: 0,
          color: 'white',
          background: alert.alertType === 'success' ? '#7ac142' : '#dc3545',
        }}
      >
        {alert.msg}
      </Alert>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});
export default connect(mapStateToProps, { removeAlert })(FadeAlert);
