export const loginUrl = '/';
export const passwordResetUrl = '/password-reset';
export const INVENSITYUrl = 'https://www.invensity.com/';
export const INVENSITYContact = INVENSITYUrl + 'contact/?lang=en';
export const SystemsEngineering =
  'https://www.invensity.com/consulting/systems-engineering/?lang=en';
export const SoftwareEngineering =
  'https://www.invensity.com/consulting/software-engineering/?lang=en';
export const SafetyManagement =
  'https://www.invensity.com/consulting/safety-management/?lang=en';
export const ProjectManagement =
  'https://www.invensity.com/consulting/project-management/?lang=en';
