import Grid from '@material-ui/core/Grid';
import React, { useReducer } from 'react';
import { Card, Container } from 'reactstrap';
import Item from './searchComponents/Item';
import SearchBar from './searchComponents/SearchBar';

// Initial State And Reducer Function
const initialState = {
  search: '',
  searchData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SEARCH_INPUT':
      return { ...state, search: action.payload };
    case 'SEARCH_DATA':
      return { ...state, searchData: action.payload };
    default:
      throw new Error();
  }
};

// The Component
const SearchComponent = ({ data }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const highlightStyle = 'style="background: #2769AA; color: white;"';

  const regexReplace = (input, stringMatch) =>
    input.replace(
      new RegExp(stringMatch, 'gi'),
      (match) => '<mark ' + highlightStyle + `>${match}</mark>`
    );

  // Search And Highlight Function
  const handleInput = (e) => {
    let str = e.target.value;
    dispatch({ type: 'SEARCH_INPUT', payload: str });
    const newArr = data
      ?.filter(
        (item) =>
          item.question.toLowerCase().includes(str.toLowerCase()) ||
          item.answer.toLowerCase().includes(str.toLowerCase()) ||
          item?.answerList?.find(
            (answer) =>
              answer.answer.includes(str.toLowerCase()) ||
              answer?.children?.find((child) =>
                child.answer.includes(str.toLowerCase())
              ) !== undefined
          ) !== undefined
      )
      .map((item) => {
        if (str.length > 0) {
          let newQuestion = regexReplace(item.question, str);
          let newAnswer = regexReplace(item.answer, str);
          let newAnswerList = item?.answerList?.map((answer) => {
            let mainAnswer = answer.answer.includes(str.toLowerCase())
              ? regexReplace(answer.answer, str)
              : answer.answer;

            let updatedAnswer = { answer: mainAnswer };

            if (answer?.children?.length > 0) {
              let updatedChildren = answer.children.map((child) => {
                let childAnswer = child.answer.includes(str.toLowerCase())
                  ? regexReplace(child.answer, str)
                  : child.answer;
                return { ...child, answer: childAnswer };
              });
              updatedAnswer.children = updatedChildren;
            }

            return updatedAnswer;
          });
          return {
            ...item,
            question: newQuestion,
            answer: newAnswer,
            answerList: newAnswerList,
            isOpen: true,
          };
        }
      });

    dispatch({ type: 'SEARCH_DATA', payload: newArr });
  };

  // Return Statement
  return (
    <Container fluid='md'>
      <Card>
        <Grid container>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs={12}>
              {data.length > 1 && <SearchBar onInput={(e) => handleInput(e)} />}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {state.search?.length > 0 && state.searchData?.length > 0
              ? state.searchData.map((post) => (
                  <Item
                    id={post.id}
                    title={post.question}
                    body={post.answer}
                    answerList={post.answerList}
                    link={post?.link}
                    isSearching={true}
                    isOpenBySearch={post.isOpen}
                  />
                ))
              : data?.map((post) => (
                  <Item
                    id={post.id}
                    title={post.question}
                    body={post.answer}
                    answerList={post.answerList}
                    link={post?.link}
                    isSearching={state.search.length > 0}
                  />
                ))}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default SearchComponent;
