import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import { Cancel, OK } from '../../constants/StringConstants';
import { btnEdit } from '../../constants/Style';
import avatar from '../../img/avatar.png';
import { setAlert } from '../../redux/actions/alert';
import { confirmPassword } from '../../redux/actions/auth';
import { updateAccount } from '../../redux/actions/profile';
import { getProjects } from '../../redux/actions/projects';
import { withSidebar } from '../../redux/actions/sidebar';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';

// confirmation modal
const Confirmation = ({ decision, modal, toggle, onChange }) => {
  const chooseDesicion = (event) => {
    decision(event.target.name);
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <div className='modal-header'>Edit</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Label>
            You are about to change your user credentials. After changing your
            credentials, you will be logged out from ISAT and have to log in
            again with your new credentials. Please insert your current password
            in order to proceed.
          </Label>
          <Input type='password' onChange={onChange} />
          <br />
          <div align='right'>
            <ButtonGroup>
              <Button className='btn-add' name={OK} onClick={chooseDesicion}>
                {OK}
              </Button>
              <Button
                className='btn-danger'
                name={Cancel}
                onClick={chooseDesicion}
              >
                {Cancel}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

// error confirmation
const Error = ({ modal, toggle }) => {
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Error</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>The inserted password is incorrect.</Label>
          <br />
          <div align={'right'}>
            <ButtonGroup>
              <Button className='btn-add' name={OK} onClick={toggle}>
                {OK}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

// Account Profile
const Profile = ({
  isVisible,
  withSidebar,
  profile,
  projects,
  getProjects,
  loading,
  updateAccount,
}) => {
  const fieldType = {
    name: 'name',
    password: 'password',
  };
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [password, setPassword] = useState(null);
  const toggleModalConfirmation = () => {
    setModalConfirmation(!modalConfirmation);
  };
  const [editField, setEditField] = useState(null);
  const [modalError, setModalError] = useState(false);
  const toggleModalError = () => setModalError(!modalError);
  const [editUserMode, setEditUserMode] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [payload, setPayload] = useState({});
  useEffect(() => {
    withSidebar();
    getProjects();
  }, [isVisible]);

  const decision = async (decision) => {
    await decision;
    if (decision === OK) {
      if (await confirmPassword(profile.name, password)) {
        toggleModalConfirmation();
        if (editField === 'name') {
          setEditUserMode(true);
        } else {
          setEditPassword(true);
        }
      } else {
        toggleModalError();
        toggleModalConfirmation();
      }
    } else {
      toggleModalConfirmation();
    }
  };
  const onChange = (event) => {
    setPassword(event.target.value);
  };

  const changeUserName = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  };

  const changePassword = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  };

  const cancel = () => {
    setEditUserMode(false);
    setEditPassword(false);
  };

  const { dispatch } = store;

  const save = async () => {
    const updatePayload = payload;
    if (editPassword) {
      if (updatePayload.password !== updatePayload.password_confirm) {
        dispatch(setAlert('Inserted password do not match', 'danger'));
        return;
      }
      updatePayload.password_confirm = undefined;
    }

    updateAccount(profile.id, updatePayload);
  };

  const edit = (editType) => {
    toggleModalConfirmation();
    setEditField(editType);
  };

  return loading || profile === undefined ? (
    <Container>
      <Spinner className='spinner' />
    </Container>
  ) : (
    <Container fluid='md'>
      <CardTitle>
        <h1>Account Page</h1>
      </CardTitle>
      <Row>
        <Col xs={3} className='center border-right-dark'>
          <h3>Profile</h3>
        </Col>
        <Col>
          <h3>Projects</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className='center border-right-dark'>
          <Row>
            <img
              src={avatar}
              style={{
                height: '200px',
                width: '200px',
              }}
            />
          </Row>
          <Row>
            <br />
            <br />
            {editUserMode ? (
              <div
                style={{
                  width: '350px',
                  margin: 'auto',
                }}
              >
                <Label>Edit User Name</Label>
                <Input
                  name='name'
                  type='text'
                  value={payload?.name ?? profile.name}
                  onChange={changeUserName}
                />
                <ButtonGroup className='float-right'>
                  <Button className='btn-add' name='Save' onClick={save}>
                    Save
                  </Button>
                  <Button className='btn-danger' name={Cancel} onClick={cancel}>
                    {Cancel}
                  </Button>
                </ButtonGroup>
              </div>
            ) : (
              <div style={{ margin: 'auto' }}>
                <Button
                  name='name'
                  className='float-right'
                  style={btnEdit}
                  onClick={() => edit(fieldType.name)}
                >
                  <i className='fa fa-edit' />
                </Button>
                <font className='mr-15'>{profile?.name}</font>
              </div>
            )}
          </Row>
          <Row>
            <span>{profile?.mail}</span>
          </Row>
          <Row>
            {editPassword ? (
              <div
                style={{
                  width: '350px',
                  margin: 'auto',
                }}
              >
                <Label>New Password</Label>
                <Input
                  type='password'
                  name='password'
                  onChange={changePassword}
                  minLength='8'
                />
                <Label style={{ fontSize: 10, color: 'red' }}>
                  <i>*New Password must have minimum of 8 characters</i>
                </Label>
                <Label>Confirm New Password</Label>
                <Input
                  type='password'
                  name='password_confirm'
                  minLength='8'
                  onChange={changePassword}
                />

                <br />
                <ButtonGroup className='float-right'>
                  <Button
                    className='btn-add'
                    name='Save'
                    onClick={save}
                    disabled={
                      payload.password === undefined ||
                      payload.password_confirm === undefined ||
                      payload.password?.length < 8 ||
                      payload.password_confirm?.length < 8
                    }
                  >
                    Save
                  </Button>
                  <Button className='btn-danger' name={Cancel} onClick={cancel}>
                    {Cancel}
                  </Button>
                </ButtonGroup>
              </div>
            ) : (
              <Button
                className='btn-add'
                name='password'
                onClick={() => edit(fieldType.password)}
              >
                Change Password
              </Button>
            )}
          </Row>

          <br />
          {(profile?.permissions.includes(UserPermissions.Admin) ||
            profile?.permissions.includes(UserPermissions.Creator)) && (
            <Row>
              <span>Global Roles:</span>
            </Row>
          )}
          {profile?.permissions.includes(UserPermissions.Admin) && (
            <Row>
              <span>Admin</span>
            </Row>
          )}
          {profile?.permissions.includes(UserPermissions.Creator) && (
            <Row>
              <span>Creator</span>
            </Row>
          )}
        </Col>
        <Col className='margin-left'>
          <Row className='table-row-even'>
            <Col className='table-header'>Name</Col>
            <Col xs={4} className='table-header'>
              Role
            </Col>
          </Row>

          {projects
            .filter((project) =>
              project.participants.some(
                (participant) =>
                  parseInt(participant.user_id) === parseInt(profile?.id)
              )
            )
            .map((project, i) => (
              <Row className={i % 2 ? 'table-row-even' : 'table-row-odd'}>
                <Col>{project.name}</Col>
                <Col xs={4}>
                  {
                    project.participants.find(
                      (participant) =>
                        parseInt(participant.user_id) === parseInt(profile?.id)
                    ).role
                  }
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
      <Confirmation
        decision={decision}
        modal={modalConfirmation}
        toggle={toggleModalConfirmation}
        onChange={onChange}
      />
      <Error modal={modalError} toggle={toggleModalError} />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  sideBarVisible: state.sidebar.isVisible,
  profile: state.profile.profile,
  projects: state.project.projects,
  loading: state.project.loading,
});

export default connect(mapStateToProps, {
  getProjects,
  withSidebar,
  updateAccount,
})(Profile);
