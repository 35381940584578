import React, { Fragment } from 'react';
import { Button, ButtonGroup, Label, ModalBody, ModalHeader } from 'reactstrap';
import { deleteEntry } from '../../redux/actions/catalog';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';

export const ModalConfirmation = ({ toggle, modal, row }) => {
  const { dispatch } = store;
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Confirm Delete</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! This entry will be deleted and you cannot restore it. If
            this entry is referenced within any project, the respective
            reference(s) will be replaced by a copy of the catalogue entry.
            These copies will no longer be associated with the reference entry.
            Are you sure you want to proceed?
          </Label>
          <div>
            <br />
            <ButtonGroup>
              <Button
                className='btn-confirm-delete mr-15'
                onClick={() => {
                  toggle();
                  dispatch(deleteEntry(row));
                }}
              >
                Ok
              </Button>
              <Button className='btn-cancel-delete ml-15' onClick={toggle}>
                Cancel
              </Button>
            </ButtonGroup>
            <br />
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};
