import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import {
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  ProjectType,
} from '../../../constants/Enums';
import { addEntity } from '../../../redux/actions/entities';
import DraggableModal from './DraggableModal';
import DuplicateEntityModal from './DuplicateEntityModal';
import { InputTemplate } from './InputTemplate';

/**
 * Vulnerability Modal
 *
 * A modal for updating/creating vulnerabilities
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param addEntity redux action mapped to state, creation of a new entity
 *  @param parent parent supplied by the parent component for creation
 *  @param project redux state mapped to props, contains current project data
 *
 */
const VulnerabilityModal = ({
  addEntity,
  toggle,
  modal,
  parent,
  project,
  changeParentEstimation,
  vulnerabilities,
  threats,
  selectedProjectType,
}) => {
  const projectType = project?.project_type ?? selectedProjectType;

  const isThreatScenario =
    parent.entity_type === EntityType.threat &&
    projectType === ProjectType.ISO_21434.Value;

  const [formData, setFormData] = useState({
    id: undefined,
    name: undefined,
    description: '',
    operator: 'OR',
    no_control_likelihood: undefined,
    likelihood_comment: undefined,
    likelihood_estimation_method: '',
    // likelihood_estimation_method:
    //   LikelihoodEstimation[LikelihoodMethod.Automatically],
  });

  const onChange = (e) => {
    //Sets form data as undefined when user picks None
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  //Used to set likelihood estimation from placeholder
  const setLikelihoodEstimationMethod = (method) => {
    setFormData({ ...formData, likelihood_estimation_method: method });
  };

  //Sets default values as the the first integer value of the same type
  const [categoryCalculationData, setCategoryCalculationDate] = useState({
    elapsed_time: undefined,
    specialist_knowledge: undefined,
    item_knowledge: undefined,
    window_of_opportunity: undefined,
    equipment: undefined,
  });

  const onCategoryDataChange = (e) => {
    setCategoryCalculationDate({
      ...categoryCalculationData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const resetCategoryData = (e) => {
    setFormData({ ...formData, likelihood_comment: undefined });
    setCategoryCalculationDate({
      elapsed_time: undefined,
      specialist_knowledge: undefined,
      item_knowledge: undefined,
      window_of_opportunity: undefined,
      equipment: undefined,
    });
  };

  const onSubmit = async () => {
    let newFormData = {};
    if (
      formData.likelihood_estimation_method ===
      LikelihoodEstimation[LikelihoodMethod.Category]
    ) {
      newFormData = Object.assign(newFormData, {
        ...formData,
        ...categoryCalculationData,
      });
    } else {
      newFormData = Object.assign(newFormData, formData);
    }
    addEntity(
      newFormData,
      EntityType.vulnerability,
      parent,
      changeParentEstimation
    );
  };

  //If the likelihood estimation is category and there are any undefined values, disables button
  const categoryMethodCheck =
    formData.likelihood_estimation_method ===
    LikelihoodEstimation[LikelihoodMethod.Category]
      ? categoryCalculationData.elapsed_time === undefined ||
        categoryCalculationData.equipment === undefined ||
        categoryCalculationData.item_knowledge === undefined ||
        categoryCalculationData.specialist_knowledge === undefined ||
        categoryCalculationData.window_of_opportunity === undefined
      : false;

  //If the likelihood estimation is manual and the likelihood does not fit between 0 and 1, disables button
  const manualMethodCheck =
    formData.likelihood_estimation_method === LikelihoodEstimation.Manually &&
    (formData?.no_control_likelihood <= 1 && formData?.no_control_likelihood > 0
      ? false
      : true);

  const [sameNameModal, setSameNameModal] = useState(false);
  const toggleSameNameModal = () => setSameNameModal(!sameNameModal);

  const ThreatScenarios = vulnerabilities.filter((vulnerability) => {
    return (
      threats.find((threat) => {
        return threat.children.includes(vulnerability._id);
      }) !== undefined
    );
  });

  const VulnerabilitiesWithNoTS = vulnerabilities.filter(
    (vulnerability) => !ThreatScenarios.includes(vulnerability)
  );

  const hasSameName = isThreatScenario
    ? ThreatScenarios.find(
        (threatScenarios) => threatScenarios.name === formData?.name
      ) !== undefined
    : projectType === ProjectType.ISO_21434.Value
    ? VulnerabilitiesWithNoTS.find(
        (vulnerability) => vulnerability.name === formData?.name
      ) !== undefined
    : vulnerabilities.find(
        (vulnerability) => vulnerability.name === formData?.name
      ) !== undefined;

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader>
          <div className='modal-header'>
            New{' '}
            {isThreatScenario
              ? ProjectType[projectType].VulnerabilityType
              : 'Vulnerability'}
          </div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <small>* required field</small>
            <FormGroup>
              <InputTemplate
                label='* Name'
                type='text'
                name='name'
                value={formData.name}
                invalid={formData.name === '' || formData.name === undefined}
                onChange={onChange}
                formFeedback='Name cannot be blank'
                innerRef={focusRef}
              />
              <InputTemplate
                label='* Description'
                type='textarea'
                name='description'
                value={formData.description}
                disabled={
                  formData.description === '' ||
                  formData.description === undefined
                }
                formFeedback='Description cannot be blank'
                onChange={onChange}
              />

              <InputTemplate
                label='* Operator'
                type='select'
                name='operator'
                defaultValue={formData.operator}
                onChange={onChange}
              >
                <option selected>OR</option>
                <option>AND</option>
              </InputTemplate>
              <InputTemplate
                label='* Likelihood estimation'
                type='select'
                name='likelihood_estimation_method'
                defaultValue={formData.likelihood_estimation_method}
                onChange={(e) => {
                  if (
                    formData.likelihood_estimation_method ===
                    LikelihoodEstimation[LikelihoodMethod.Automatically]
                  ) {
                    resetCategoryData();
                  }
                  setFormData({
                    ...formData,
                    likelihood_estimation_method: e.target.value,
                  });
                }}
                formFeedback='Likelihood cannot be empty'
                required
              >
                <option value='' disabled>
                  Choose an option
                </option>{' '}
                {/* Add a default disabled option */}
                {Object.entries(LikelihoodEstimation).map((method) => (
                  <option key={method[1]} value={method[1]}>
                    {method[0]}
                  </option>
                ))}
              </InputTemplate>
            </FormGroup>
            {formData.likelihood_estimation_method ===
            LikelihoodEstimation.Manually ? (
              <Fragment>
                <FormGroup>
                  <InputTemplate
                    min={0}
                    max={1}
                    step={0.01}
                    label='* Likelihood'
                    type='number'
                    name='no_control_likelihood'
                    onChange={onChange}
                    invalid={
                      formData?.no_control_likelihood === undefined ||
                      !(
                        formData.no_control_likelihood <= 1 &&
                        formData.no_control_likelihood >= 0
                      )
                    }
                    formFeedback='The value of the likelihood has to be a number between 0 and
                    1.'
                  />
                  <InputTemplate
                    invalid={
                      formData?.likelihood_comment?.length < 8 ||
                      formData?.likelihood_comment === undefined
                    }
                    label='Comment'
                    type='textarea'
                    name='likelihood_comment'
                    onChange={onChange}
                    formFeedback='The likelihood comment must be at least 8 characters'
                  />
                </FormGroup>
              </Fragment>
            ) : (
              formData.likelihood_estimation_method ===
                LikelihoodEstimation[LikelihoodMethod.Category] && (
                <Fragment>
                  <FormGroup>
                    <InputTemplate
                      label='Elapsed time'
                      type='select'
                      name='elapsed_time'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.elapsed_time === undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(ProjectType[projectType].ElapsedTime).map(
                        (time) => {
                          return <option value={time[0]}>{time[1]}</option>;
                        }
                      )}
                    </InputTemplate>
                    <InputTemplate
                      label='Specialist expertise'
                      type='select'
                      name='specialist_knowledge'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.specialist_knowledge ===
                        undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType[projectType].SpecialistExpertise
                      ).map((expertise) => {
                        return (
                          <option value={expertise[0]}>{expertise[1]}</option>
                        );
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Knowledge of the item'
                      type='select'
                      name='item_knowledge'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.item_knowledge === undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType[projectType].ComponentKnowledge
                      ).map((knowledge) => {
                        return (
                          <option value={knowledge[0]}>{knowledge[1]}</option>
                        );
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Window of opportunity'
                      type='select'
                      name='window_of_opportunity'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={
                        categoryCalculationData.window_of_opportunity ===
                        undefined
                      }
                      formFeedback='Please select a value'
                    >
                      {Object.entries(
                        ProjectType[projectType].OpportunityWindow
                      ).map((window) => {
                        return <option value={window[0]}>{window[1]}</option>;
                      })}
                    </InputTemplate>
                    <InputTemplate
                      label='Equipment'
                      type='select'
                      name='equipment'
                      onChange={onCategoryDataChange}
                      selectDefault
                      invalid={categoryCalculationData.equipment === undefined}
                      formFeedback='Please select a value'
                    >
                      {Object.entries(ProjectType[projectType].Equipment).map(
                        (window) => {
                          return <option value={window[0]}>{window[1]}</option>;
                        }
                      )}
                    </InputTemplate>
                    <InputTemplate
                      label='Comment'
                      type='textarea'
                      name='likelihood_comment'
                      onChange={onChange}
                    />
                  </FormGroup>
                </Fragment>
              )
            )}
            <FormGroup>
              <Button
                disabled={
                  formData.name === undefined ||
                  categoryMethodCheck ||
                  manualMethodCheck ||
                  formData.likelihood_estimation_method === '' ||
                  (formData.likelihood_estimation_method ===
                    LikelihoodEstimation.Manually &&
                    formData.likelihood_comment?.length < 8)
                }
                className='float-right btn-add'
                type='submit'
                onClick={(event) => {
                  if (hasSameName) {
                    event.preventDefault();
                    toggleSameNameModal();
                  } else {
                    onSubmit();
                    toggle();
                  }
                }}
              >
                Save
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div>
            {isThreatScenario && (
              'Threat scenarios shall be identified and include: targeted asset, compromised cybersecurity property of the asset, and cause of compromise of the cybersecurity property.'
            )}
          </div>
        </ModalFooter>
      </DraggableModal>
      <DuplicateEntityModal
        toggle={toggleSameNameModal}
        modal={sameNameModal}
        parentModalAddEntity={onSubmit}
        parentModalToggle={toggle}
        entityToCreate={formData}
        entityType={EntityType.vulnerability}
        projectType={projectType}
        entityLabelOverride={
          isThreatScenario
            ? ProjectType[projectType].VulnerabilityType
            : 'Vulnerability'
        }
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  selectedProjectType: state.analysistree.selectedProjectType,
  vulnerabilities: state.vulnerabilities.vulnerabilities,
  threats: state.threats.threats,
});

export default connect(mapStateToProps, { addEntity })(VulnerabilityModal);
