import {
  ADD_VULNERABILITY,
  LOAD_VULNERABILITIES,
  LOGOUT,
  PROJECT_LOADING_ERROR,
  UPDATE_VULNERABILITY,
} from '../actions/types';

const initialState = {
  vulnerabilities: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newVulnerability = Object.assign([], state.vulnerabilities);
  switch (type) {
    case LOAD_VULNERABILITIES:
      return {
        ...state,
        vulnerabilities: payload,
      };
    case ADD_VULNERABILITY:
      newVulnerability.push(payload);
      return {
        ...state,
        vulnerabilities: newVulnerability,
      };
    case UPDATE_VULNERABILITY:
      newVulnerability = newVulnerability.map((vulnerability) => {
        return vulnerability._id === payload._id
          ? { ...vulnerability, ...payload }
          : vulnerability;
      });
      return {
        ...state,
        vulnerabilities: newVulnerability,
      };
    case PROJECT_LOADING_ERROR:
    case LOGOUT:
      return {
        vulnerabilities: [],
      };
    default:
      return state;
  }
}
