import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Input, Row } from 'reactstrap';

const SearchComponent = ({ searchText, onSearch }) => (
  <Row width='200px'>
    <Col>
      <Input
        id='search'
        type='text'
        placeholder='Search'
        value={searchText}
        onChange={onSearch}
        size='60'
        autoComplete='off'
        autoFocus
      />
    </Col>
    <Col
      xs={4}
      style={{ maxWidth: '20px', margin: 'auto 0', paddingLeft: '0' }}
    >
      <FontAwesomeIcon icon={faSearch} />
    </Col>
  </Row>
);

export default SearchComponent;
