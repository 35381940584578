import { default as React, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import { updateUser } from '../../redux/actions/profile';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission } from '../helpers/genericHelper';

/**
 * Creator Modal.
 *
 * Modal for granting and revoking creator rights
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
export const CreatorModal = ({ modal, toggle, user, updateUser }) => {
  const updateCreatorRole = () => {
    hasPermission(user?.permissions, UserPermissions.Creator)
      ? updateUser(user._id, {
          permissions: user.permissions.filter(
            (item) => item !== UserPermissions.Creator
          ),
        })
      : updateUser(user._id, {
          permissions: [...user?.permissions, UserPermissions.Creator],
        });
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <div className='confirm-modal-header'>
            {hasPermission(user?.permissions, UserPermissions.Creator)
              ? 'Revoke'
              : 'Grant'}{' '}
            creator rights
          </div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          Are you sure you want to{' '}
          {hasPermission(user?.permissions, UserPermissions.Creator)
            ? 'revoke'
            : 'grant'}{' '}
          creator rights{' '}
          {hasPermission(user?.permissions, UserPermissions.Creator)
            ? 'from'
            : 'to'}{' '}
          this user?
          <FormGroup>
            <br />
            <Button
              type='submit'
              className='btn-add'
              onClick={() => {
                toggle();
                updateCreatorRole();
              }}
            >
              Ok
            </Button>
            <Button
              className='btn-danger'
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
          </FormGroup>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, { updateUser })(CreatorModal)
);
