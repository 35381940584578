import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import { addNewCatalog, copyCatalog } from '../../redux/actions/catalog';
import store from '../../redux/store';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission } from '../helpers/genericHelper';

// to do insert fields
const AddCatalog = ({
  toggle,
  modal,
  type,
  data,
  project,
  profile,
  content,
}) => {
  const { dispatch } = store;
  const [items, setItems] = useState(data);
  const [selectedCatalog, setCatalog] = useState(null);
  const [newCatalog, setNewCatalog] = useState({
    name: undefined,
    description: ' ',
    catalog_type: undefined,
    content_type: content,
    project_id: undefined,
  });
  const changeField = (event) => {
    if (event.target.value === 'project_specific') {
      setNewCatalog({
        ...newCatalog,
        [event.target.name]: event.target.value,
        project_id: project._id,
      });
    } else {
      setNewCatalog({ ...newCatalog, [event.target.name]: event.target.value });
    }
  };
  const filter = (event) => {
    if (event.target.value) {
      const filteredItems = data.filter(
        (catalog) =>
          catalog.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          catalog.description
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          catalog.type.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setItems(filteredItems);
    } else {
      setItems(data);
    }
  };

  const selectCatalog = (row) => {
    setCatalog(row);
  };

  const submit = () => {
    if (type === 'copy') {
      dispatch(copyCatalog(selectedCatalog._id, newCatalog));
    } else {
      dispatch(addNewCatalog(newCatalog));
    }
    toggle();
  };

  const setDescription = (event) => {
    if (event.target.checked) {
      setNewCatalog({
        ...newCatalog,
        description: selectedCatalog.description,
      });
    } else {
      setNewCatalog({
        ...newCatalog,
        description: ' ',
      });
    }
  };
  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);

  const secondaryBackground = 'bg-secondary';
  return (
    <DraggableModal
      isOpen={modal}
      toggle={toggle}
      unmountOnClose={false}
      size='lg'
    >
      <ModalHeader>
        <div className='modal-header'>Add Catalogue</div>
      </ModalHeader>
      <div className='modal-line' />
      <ModalBody className='modal-body'>
        <Label>Create New Catalog</Label>
        {type === 'new' ? (
          <>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type='text'
                name='name'
                onChange={changeField}
                innerRef={focusRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type='textarea'
                name='description'
                onChange={changeField}
              />
            </FormGroup>
            <div align={'right'}>
              {' '}
              <ButtonGroup>
                <Button
                  className='btn-add'
                  onClick={submit}
                  disabled={
                    newCatalog.name === undefined ||
                    newCatalog.content_type === undefined
                  }
                >
                  Save
                </Button>
                <Button className='btn-danger' onClick={toggle}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </>
        ) : (
          <>
            <div>
              <Col>
                <Label>Name</Label>
                <Input type='text' name='name' onChange={changeField} />
              </Col>
            </div>
            <div align={'right'}>
              <Col md={4}>
                <Input
                  type='text'
                  onChange={filter}
                  placeholder='Search Catalog'
                />
              </Col>
            </div>
            <br />
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Catalog Type</th>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                {items?.map((row) => (
                  <tr onClick={() => selectCatalog(row)}>
                    <td
                      width='20%'
                      className={
                        row?._id === selectedCatalog?._id
                          ? secondaryBackground
                          : null
                      }
                    >
                      {row?.name}
                    </td>
                    <td
                      width='20%'
                      className={
                        row?._id === selectedCatalog?._id
                          ? secondaryBackground
                          : null
                      }
                    >
                      {row?.catalog_type}
                    </td>
                    <td
                      width='60%'
                      className={
                        row?._id === selectedCatalog?._id
                          ? secondaryBackground
                          : null
                      }
                    >
                      {row?.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {selectedCatalog ? (
              <Row>
                <Col md={2}>
                  <Label>Copy Description?</Label>
                </Col>
                <Col>
                  <Input
                    type='checkbox'
                    name={true}
                    onChange={setDescription}
                  />
                </Col>
              </Row>
            ) : null}
            {/* TODO: add this when backend is implemented for it */}
            <FormGroup>
              <Label>Catalog Type</Label>
              <Input type='select' name='project_id' onChange={changeField}>
                {hasPermission(profile?.permissions, UserPermissions.Admin) ? (
                  <option value={''}>Global Catalog</option>
                ) : null}
                {project?.participants
                  ?.find(
                    (member) =>
                      parseInt(member.user_id) === parseInt(profile.id)
                  )
                  ?.role.includes('owner') ||
                project?.participants
                  ?.find(
                    (member) =>
                      parseInt(member.user_id) === parseInt(profile.id)
                  )
                  ?.role.includes('editor') ? (
                  <option value={project?._id}>Project Specific Catalog</option>
                ) : null}
              </Input>
            </FormGroup>

            <ButtonGroup className='float-right'>
              <Button
                className='btn-add'
                disabled={
                  selectedCatalog === null ||
                  newCatalog.name === null ||
                  newCatalog.name === undefined
                }
                onClick={submit}
              >
                Save
              </Button>
              <Button className='btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalBody>
    </DraggableModal>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  profile: state.profile.profile,
});

export default connect(mapStateToProps)(AddCatalog);
