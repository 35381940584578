import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { setProject, updateProject } from '../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ExportReviewModal from './ExportReviewModal';

const docListedEnum = { yes: 'Yes', no: 'No' };
const releaseStatusEnum = {
  draft: 'Draft',
  obsolete: 'Obsolete',
  released: 'Released',
  'released for project': 'Released for Project',
  'ready for review': 'Ready for Review',
};

const confidentialClassEnum = {
  internal: 'Internal',
  confidential: 'Confidential',
  unconfidential: 'Unconfidential',
};

const CoverPage = ({
  loading,
  project,
  setProject,
  updateProject,
  profile,
}) => {
  const [page, setCoverpage] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [duration, setDuration] = useState();
  const [versions, setVersions] = useState([
    {
      version: '',
      summary: '',
    },
  ]);

  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const handleExportButtonClick = () => {
    setExportModalOpen(true);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    updateProject(project._id, { cover_page: page });
  };

  const handleCancel = () => {
    setCoverpage(project?.cover_page);
    setVersions(project?.cover_page?.version ?? []);
    setEditMode(false);
  };

  const addVersion = () => {
    setVersions([
      ...versions,
      {
        version: '',
        summary: '',
      },
    ]);
  };

  const deleteVersion = (index) => {
    const newVersions = [...versions];
    newVersions.splice(index, 1);
    setVersions(newVersions);
    setCoverpage({ ...page, version: newVersions });
  };

  const handleInputChange = (index, event, field) => {
    const newVersions = [...versions];
    newVersions[index][field] = event;
    setVersions(newVersions);
    setCoverpage({ ...page, version: newVersions });
  };

  const convertDate = (date) => {
    if (Date.parse(date) !== NaN && date !== undefined) {
      const newDate = new Date(date);
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const day = newDate.getDate();

      return `${month}/${day}/${year}`;
    }
    return '';
  };

  const convertTime = (date) => {
    if (Date.parse(date) !== NaN && date !== undefined) {
      const newDate = new Date(date);
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
    }
    return '';
  };

  useEffect(() => {
    if (profile !== null) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);

  useEffect(() => {
    if (project?.cover_page) {
      let newCoverpage = JSON.parse(JSON.stringify(project?.cover_page));
      if (newCoverpage?.author === "" || newCoverpage?.author === undefined) {
        const user = project?.participants.find((user) => (user.role === 'reviewer'))
        if(user) {
          newCoverpage['author'] = user?.user_name
        }
        else {
          newCoverpage['author'] = project?.name
        }
      }
      setCoverpage(newCoverpage);
      if (newCoverpage.version?.length > 0) {
        setVersions(project?.cover_page?.version);
      }
    }

    if (editMode === true) {
      setEditMode(false)
    }

  }, [project, profile]);

  return (
    <div>
      <h2>Cover Page</h2>
      <div
        className='card-actions float-right'
        style={{ marginBottom: '10px' }}
      >
        {editMode ? (
          <>
            <Button className='btn-add' onClick={handleSave}>
              Save
            </Button>
            <Button color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button color='add' onClick={handleExportButtonClick}>
              Export
            </Button>
            <Button color='primary' onClick={handleEdit}>
              Edit
            </Button>
          </>
        )}
      </div>
      <Table>
        <thead>
          <tr>
            <th colSpan={8}>Template Review Checklist</th>
          </tr>
        </thead>
        <tbody>
          {editMode ? (
            <>
              <tr>
                <td style={{ width: '25%' }}>Project:</td>
                <td style={{ width: '25%' }}>{project?.name}</td>
                <td style={{ width: '25%' }}>
                  Document listed in project configuration management:
                </td>
                <td style={{ width: '25%' }}>
                  <Input
                    type='select'
                    id='doc_listed'
                    placeholder='Project'
                    value={page?.doc_listed}
                    onChange={(e) =>
                      setCoverpage({ ...page, doc_listed: e.target.value })
                    }
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {Object.keys(docListedEnum).map((key) => (
                      <option value={key}>{docListedEnum[key]}</option>
                    ))}
                  </Input>
                </td>
              </tr>
              <tr>
                <td>Issue Date:</td>
                <td>
                  <Input
                    type='date'
                    id='issue_date'
                    placeholder='Project'
                    value={page?.issue_date?.substring(0, 10)}
                    onChange={(e) =>
                      setCoverpage({
                        ...page,
                        issue_date: new Date(e.target.value).toISOString(),
                      })
                    }
                  />
                </td>
                <td>Release Status:</td>
                <td>
                  <Input
                    type='select'
                    id='release_status'
                    placeholder='Project'
                    value={page?.release_status}
                    onChange={(e) =>
                      setCoverpage({ ...page, release_status: e.target.value })
                    }
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {Object.keys(releaseStatusEnum).map((key) => (
                      <option value={key}>{releaseStatusEnum[key]}</option>
                    ))}
                  </Input>
                </td>
              </tr>
              <tr>
                <td>Confidentiality Classification:</td>
                <td>
                  <Input
                    type='select'
                    id='confidential_class'
                    placeholder='Project'
                    value={page?.confidential_class}
                    onChange={(e) =>
                      setCoverpage({
                        ...page,
                        confidential_class: e.target.value,
                      })
                    }
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {Object.keys(confidentialClassEnum).map((key) => (
                      <option value={key}>{confidentialClassEnum[key]}</option>
                    ))}
                  </Input>
                </td>
                <td>File location:</td>
                <td>
                  <Input
                    type='text'
                    id='file_location'
                    placeholder='File location'
                    value={page?.file_location}
                    onChange={(e) =>
                      setCoverpage({ ...page, file_location: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Author:</td>
                <td>
                  <Input
                    type='text'
                    id='author'
                    placeholder='Author'
                    value={page?.author}
                    onChange={(e) =>
                      setCoverpage({ ...page, author: e.target.value })
                    }
                  />
                </td>
                <td>Reviewer:</td>
                <td>
                  <Input
                    type='text'
                    id='reviewer'
                    placeholder='Reviewer'
                    value={page?.reviewer}
                    onChange={(e) =>
                      setCoverpage({ ...page, reviewer: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Moderator:</td>
                <td>
                  <Input
                    type='text'
                    id='moderator'
                    placeholder='Moderator'
                    value={page?.moderator}
                    onChange={(e) =>
                      setCoverpage({ ...page, moderator: e.target.value })
                    }
                  />
                </td>
                <td>Review Duration:</td>
                <td>
                  <Input
                    type='time'
                    id='review_duration'
                    value={duration ?? convertTime(page?.review_duration)}
                    onChange={(e) => {
                      setDuration(e.target.value);
                      const date = new Date();
                      date.setUTCHours(e.target.value.split(':')[0]);
                      date.setUTCMinutes(e.target.value.split(':')[1]);
                      setCoverpage({
                        ...page,
                        review_duration: date,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Version:</th>
                <th>Responsible Person</th>
                <th>Date:</th>
                <th colSpan={2}>Change Summary:</th>
              </tr>
              {versions.map((version, index) => (
                <tr key={index}>
                  <td>
                    <Input
                      type='text'
                      value={version?.version}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'version')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='text'
                      value={version?.responsible}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'responsible')
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='date'
                      value={version?.date?.substring(0, 10)}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          new Date(e.target.value).toISOString(),
                          'date'
                        )
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type='textarea'
                      value={version?.summary}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, 'summary')
                      }
                    />
                  </td>
                  <td>
                    <Button
                      className='btn-danger'
                      onClick={() => deleteVersion(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Button className='btn-add' onClick={addVersion}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td style={{ width: '25%' }}>Project:</td>
                <td style={{ width: '25%' }}>{project?.name}</td>
                <td style={{ width: '25%' }}>
                  Document listed in project configuration management:
                </td>
                <td style={{ width: '25%' }}>
                  {docListedEnum[page?.doc_listed]}
                </td>
              </tr>
              <tr>
                <td>Issue Date:</td>
                <td>{convertDate(page?.issue_date)}</td>
                <td>Release Status:</td>
                <td>{releaseStatusEnum[page?.release_status]}</td>
              </tr>
              <tr>
                <td>Confidentiality Classification:</td>
                <td>{confidentialClassEnum[page?.confidential_class]}</td>
                <td>File location:</td>
                <td>{page?.file_location}</td>
              </tr>
              <tr>
                <td>Author:</td>
                <td>{page?.author}</td>
                <td>Reviewer:</td>
                <td>{page?.reviewer}</td>
              </tr>
              <tr>
                <td>Moderator:</td>
                <td>{page?.moderator}</td>
                <td>Review Duration:</td>
                <td>{convertTime(page?.review_duration)}</td>
              </tr>
              <tr>
                <th>Version:</th>
                <th>Responsible</th>
                <th>Date:</th>
                <th>Change Summary:</th>
              </tr>
              {versions.map((version, index) => (
                <tr key={index}>
                  <td>{version.version}</td>
                  <td>{version.responsible}</td>
                  <td>{convertDate(version.date)}</td>
                  <td>{version.summary}</td>
                </tr>
              ))}
            </>
          )}
          <tr>
            <th colSpan={'4'}>1. Scope of this document</th>
          </tr>
          <tr>
            <td colSpan={'4'}>
              The scope of this review checklists refers to the to be reviewed
              document.
            </td>
          </tr>
          <tr>
            <th colSpan={'4'}>2. Purpose of the document</th>
          </tr>
          <tr>
            <td colSpan={'4'}>
              A review is a planned, critical, systematic and documented
              evaluation of working results.
            </td>
          </tr>
          <tr>
            <th colSpan={'4'}>3. Working with this document</th>
          </tr>
          <tr>
            <td colSpan={'4'}>
              Replace all blue text with specific content and fill this document
              with your own content.The cover page is the official front page
              showing all the necessary information. The status, author, version
              and date shall only be changed there. All other sheets will
              automaticly copy the information.The review log shall show what
              changed between different document versions. Abbreviations and
              References shall be filled with all used abbreviations and
              references.
            </td>
          </tr>
          <tr>
            <th colSpan={'4'}>For printed versions: </th>
          </tr>
          <tr>
            <td colSpan={'4'}>
              Printed versions are not under configuration management control.
              Please always refer to the latest valid version under the given
              file path.
            </td>
          </tr>
        </tbody>
      </Table>
      {project && (
        <ExportReviewModal
          modal={isExportModalOpen}
          toggle={() => setExportModalOpen(!isExportModalOpen)}
          currentProject={project}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { setProject, updateProject })(CoverPage)
);
