import React, { Fragment } from 'react';
import { Button, ButtonGroup, Label, ModalBody, ModalHeader } from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
export const SaveAll = ({ decision, modal, toggle }) => {
  const chooseDesicion = (event) => {
    decision(event.target.name);
  };
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='confirm-modal-header'>Exit editing?</div>
        </ModalHeader>
        <div className='confirm-modal-line' />
        <ModalBody className='confirm-modal-body'>
          <Label>
            Warning! There are unsaved changes. Do you want to continue and
            loose all unsaved changes?
          </Label>
          <div align={'right'}>
            {' '}
            <ButtonGroup>
              <Button size='sm' name={'OK'} onClick={chooseDesicion}>
                {' '}
                {'OK'}
              </Button>
              <Button
                size='sm'
                className='btn-add'
                name={'Save All'}
                onClick={chooseDesicion}
              >
                {' '}
                {'Save All'}
              </Button>
              <Button
                size='sm'
                className='btn-danger'
                name={'Cancel'}
                onClick={chooseDesicion}
              >
                {' '}
                {'Cancel'}{' '}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};
