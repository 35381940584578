import React, { Fragment, useState } from 'react';
import {
  Button,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';
import DraggableModal from '../../entities/EntityModals/DraggableModal';

const EditAssumption = ({ modal, toggle, assumption, updateAssumption }) => {
  const [editedAssumption, setEditedAssumption] = useState(assumption);

  const handleInputChange = (e) => {
    setEditedAssumption(e.target.value);
  };

  const handleUpdate = () => {
    updateAssumption(editedAssumption);
    toggle();
  };

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle}>
        <ModalHeader className='modal-header' toggle={toggle}>
          Edit Assumption
        </ModalHeader>
        <div className='modal-line' style={{ zIndex: 1 }} />
        <ModalBody>
          <Label for='assumptions'>Assumptions</Label>
          <Input
            type='textarea'
            name='assumptions'
            id='assumptions'
            placeholder='Enter assumptions'
            value={editedAssumption}
            onChange={handleInputChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='btn-add' onClick={handleUpdate}>
            Update
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </DraggableModal>
    </Fragment>
  );
};

export default EditAssumption;
