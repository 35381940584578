import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Label, Table } from 'reactstrap';
import axiosMethods from '../../../../Axios/axios';
import { setAlert } from '../../../../redux/actions/alert';
import store from '../../../../redux/store';
import DeleteModal from '../../../projects/project-components/DeleteModal';
import { AssumptionsModal } from './AssumptionsModal';

export const AssumptionsTable = ({
  entityId,
  project,
  entity
}) => {
  const { dispatch } = store;
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(undefined);
  const [updateIndex, setUpdateIndex] = useState(undefined);
  const [addModal, setAddModal] = useState(false);


  const[updatedAssumption, setUpdatedAssumption] = useState(undefined)
  const[assumptions, setAssumptions] = useState([]);
  const addToggle = () => setAddModal(!addModal);

  const update = async (item) => {
    const assumptionsList = [...assumptions];
        const assumption = assumptionsList[updateIndex];
        assumption.assumption = item?.assumption;
        assumption.note = item?.note;
        assumptionsList[updateIndex] = assumption;
        await axiosMethods.entity
          .patch(`${entityId}`, {'vulnerability': {'assumptions' : assumptionsList }})
          .then(() => {
            setAssumptions(assumptionsList)
            setUpdateIndex(undefined);
            setUpdatedAssumption(undefined)
          })
          .catch((error) => {
            dispatch(
              setAlert(
                `Error updating assumption encountered. Error message: ${
                  error?.response?.data?.msg ?? error?.response?.data?.message
                }`,
                'danger'
              )
            );
          });
  }

  const save = async (item) => {
    await axiosMethods.entity
      .patch(`${entityId}`, {'vulnerability': {'assumptions':[...assumptions, item]}})
      .then(() => {
        setAssumptions([...assumptions, item])
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error adding assumption encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    const assumptionsList = [...assumptions];
    assumptionsList.splice(deleteIndex,1)
    await axiosMethods.entity
    .patch(`${entityId}`, {'vulnerability': {'assumptions' : assumptionsList }})
    .then(() => {
        setAssumptions(assumptionsList)
        setDeleteIndex(undefined);
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error deleting assumption encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

  const deleteItem = (index) => {
    setDeleteIndex(index);
    deleteToggle();
  };

  const updateItem = (index, assumption) => {
    setUpdateIndex(index);
    setUpdatedAssumption(assumption)
    addToggle()
  };

  useEffect(() => {
    if (entity !== undefined && entity?.assumptions !== undefined && entity?.assumptions?.length > 0){
      setAssumptions(entity?.assumptions)
    }
  }, [entity])

  return (
    <>
      <div>
        <div style={{marginBottom: '15px'}} className='float-right'>
          <Button className='btn-add' onClick={addToggle}>
            Add
          </Button>
        </div>
      </div>
      <div>
        <Table>
          <thead>
            <th>Assumption</th>
            <th> Note</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {assumptions?.map((assumption, index) => (
              <tr>
                <td>
                  {assumption?.assumption}
                </td>
                <td>
                  {assumption?.note}
                </td>
                
                <td>
                  <ButtonGroup>
                    <Button onClick={() => updateItem(index, assumption)}>
                      <i className='fa fa-edit' />
                    </Button>
                    <Button
                      className='btn-danger'
                      onClick={() => deleteItem(index)}
                    >
                      <i className='fa fa-trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteModal
        isOpen={deleteModal && deleteIndex !== undefined}
        toggle={deleteToggle}
        onClick={() => {
          handleDelete();
          deleteToggle();
        }}
        message={`Delete assumption?`}
        header='Delete'
      />
      <AssumptionsModal
        modal={addModal}
        toggle={addToggle}
        updateIndex = {updateIndex}
        project = {project}
        assumption = {updatedAssumption}
        save={(item) => save(item)}
        update= {(item) => update(item)}
      />
    </>
  );
};