// Asset list main page
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setProject } from '../../../redux/actions/projects';
import { CardBody, CardTitle, Container, Table } from 'reactstrap';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { setEntity } from '../../../redux/actions/entities';
//view for threat scenario
const CSTable = ({ vulnerabilities, setProject, project, threats, setEntity }) => {
  const history = useHistory();

  const [data, setData] = useState([]);
  // quick fix on threat scenario not showing after creating in tree
  useEffect(() => {
    var url = window.location.pathname;
    var splitUrl = url.split('/');
    var projectId = splitUrl[1];
    if (projectId !== undefined) {
      const projectTemplate = { _id: projectId };
      setProject(projectTemplate);
    }
  }, []);

  useEffect(() => {
    const data = vulnerabilities.map((vulnerability) => ({
      ...vulnerability,
      parent: threats.find((threat) =>
        threat?.children?.some(
          (child) =>
            parseInt(child) === parseInt(vulnerability._id) ||
            parseInt(child?._id) === parseInt(vulnerability._id)
        )
      ),

    }));
    setData(data);
  }, [vulnerabilities]);

  return (
    <Container fluid='md'>
      <div>
        <CardTitle tag='h1' className='mb-0'>
          <h1>CS Goals & Claims of {project?.name}</h1>
        </CardTitle>
        <CardBody>
          <Table>
            <th>Cybersecurity Description</th>
            <th>Cybersecurity Type</th>
            <th>Threat Scenario</th>
            {data.map((vulnerability) => (
              vulnerability?.risk_decision_control?.content.map((value) => (
                <tr>
                  <td>{value.text}</td>
                  <td>{value.type}</td>
                  <td><Link to = '#' onClick={() => {
                    setEntity(vulnerability)
                    history.push(
                      `/${project._id}/analysisTree/node-details/${vulnerability._id}?parentId=${vulnerability.parent?._id}`
                    );
                  }} > {vulnerability?.name}</Link></td>
                </tr>
              ))
            ))}
          </Table>
        </CardBody>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  vulnerabilities: state.vulnerabilities.vulnerabilities,
  threats: state.threats.threats,
  project: state.project.project,
});
export default withRouter(connect(mapStateToProps, { setProject, setEntity })(CSTable));
