import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Collapse,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import AddAssumptions from './AddAssumptions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateProject } from '../../../redux/actions/projects';
import axios from '../../../Axios/axios';
import EditAssumptions from './EditAssumption';

const AssumptionsPanel = ({ openPanel, project, profile }) => {
  const [addModal, setAddModal] = useState(false);
  const addToggle = () => setAddModal(!addModal);

  const [assumptions, setAssumptions] = useState([]);
  const [collapse, setCollapse] = useState(openPanel);
  const toggleCollapse = () => setCollapse(!collapse);

  const [editModal, setEditModal] = useState(false);
  const [editedAssumption, setEditedAssumption] = useState('');
  const toggleEditModal = () => setEditModal(!editModal);

  const openEditModal = (assumption) => {
    setEditedAssumption(assumption);
    toggleEditModal();
  };

  const updateAssumption = async (updatedAssumption) => {
    const updatedAssumptions = assumptions.map((assump) =>
      assump === editedAssumption ? updatedAssumption : assump
    );
    await axios.project
      .patch(project._id, { assumptions: updatedAssumptions })
      .then(async (response) => {
        setAssumptions(response.data.assumptions);
      });
  };

  const deleteAssumption = async (assumptionToBeDeleted) => {
    const updatedAssumptions = assumptions.filter(
      (assumption) => assumption !== assumptionToBeDeleted
    );
    await axios.project
      .patch(project._id, { assumptions: updatedAssumptions })
      .then(async (response) => {
        setAssumptions(response.data.assumptions);
      });
  };

  const addNewAssumption = (newAssumption) => {
    setAssumptions([...assumptions, newAssumption]);
  };

  useEffect(() => {
    if (project?.assumptions) {
      setAssumptions([...project.assumptions]);
    }
  }, [project]);

  const AssumptionRow = ({ key, assumption, deleteAssumption }) => (
    <tr>
      <td>{assumption}</td>
      <td>
        <Button color='info' onClick={() => openEditModal(assumption)}>
          <i className='fa fa-edit' />
        </Button>
        <Button
          color='danger'
          onClick={async () => await deleteAssumption(assumption)}
        >
          <i className='fa fa-trash' />
        </Button>
      </td>
    </tr>
  );

  return (
    <Card>
      <CardHeader style={{ cursor: 'pointer' }} onClick={toggleCollapse}>
        {collapse ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        <font className='bold'>Assumption</font>
      </CardHeader>
      <Collapse isOpen={collapse}>
        <CardBody>
          {(project?.participants
            ?.find(
              (member) => parseInt(member.user_id) === parseInt(profile?.id)
            )
            ?.role.includes('owner') ||
            project?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor')) && (
            <Button
              style={{ marginBottom: '15px' }}
              className='btn-add float-right'
              onClick={addToggle}
            >
              Add Assumption
            </Button>
          )}

          <AddAssumptions
            modal={addModal}
            toggle={addToggle}
            addNewAssumption={addNewAssumption}
          />
          <Table>
            <thead>
              <tr>
                <th>Assumptions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {assumptions.map((assumption, index) => (
                <AssumptionRow
                  key={index}
                  assumption={assumption}
                  deleteAssumption={deleteAssumption}
                />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Collapse>
      <EditAssumptions
        modal={editModal}
        toggle={toggleEditModal}
        assumption={editedAssumption}
        updateAssumption={updateAssumption}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { updateProject })(AssumptionsPanel)
);
