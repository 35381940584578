import {
  REMOVE_LICENSE_ALERT,
  SET_LICENSE_ALERT,
  SET_LICENSE_EXPIRY,
} from '../actions/types';

const initialState = {
  modal: false,
  expiryDate: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LICENSE_ALERT:
      return { modal: payload.modal, expiryDate: payload.expiryDate };
    case REMOVE_LICENSE_ALERT:
      return { ...state, modal: false };
    case SET_LICENSE_EXPIRY:
      return { ...state, expiryDate: payload };
    default:
      return state;
  }
}
