import axios from '../../Axios/axios';
import { LogType } from '../../constants/Enums';
import { setAlert } from './alert';
import {
  EMPTY_RECYCLE_BIN,
  LOAD_ADMIN_DELETION_LOGS,
  LOAD_ADMIN_LOGS,
  LOAD_CATALOG_CHANGE_LOGS,
  LOAD_CATALOG_DELETION_LOGS,
  LOAD_DELETED_USERS,
  LOAD_PROJECT_CHANGE_LOGS,
  LOAD_PROJECT_DELETION_LOGS,
  LOAD_SECURITY_LOGS,
  REMOVE_RECYCLE_BIN_ENTRY,
  RESTORE_DELETED_USER,
} from './types';

// GET security logs
export const getSecurityLogs = (logs) => async (dispatch) => {
  dispatch({
    type: LOAD_SECURITY_LOGS,
    payload: logs,
  });
};

// GET admin logs
export const getAdminLogs = (logs) => async (dispatch) => {
  dispatch({
    type: LOAD_ADMIN_LOGS,
    payload: logs,
  });
};

export const getDeletedUsers = () => async (dispatch) => {
  axios.user
    .get('/admin/restore-user/')
    .then((response) => {
      dispatch({
        type: LOAD_DELETED_USERS,
        payload: response.data.deleted_users,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting deleted users. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

// GET admin deletion logs
export const getAdminDeletionLogs = () => async (dispatch) => {
  axios.log.adminDeletion
    .get('')
    .then((response) => {
      dispatch({
        type: LOAD_ADMIN_DELETION_LOGS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting admin deletion logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

// GET project deletion logs
export const getProjectDeletionLogs = (project) => async (dispatch) => {
  axios.log.projectDeletion
    .get(project._id, '')
    .then((response) => {
      dispatch({
        type: LOAD_PROJECT_DELETION_LOGS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting project deletion logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

// GET catalog deletion logs
export const getCatalogDeletionLogs = (catalog) => async (dispatch) => {
  axios.log.catalogDeletion
    .get(catalog._id, '')
    .then((response) => {
      dispatch({
        type: LOAD_CATALOG_DELETION_LOGS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting catalog deletion logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

// GET project change logs
export const getProjectChangeLogs = (project) => async (dispatch) => {
  axios.log.projectChangelog
    .get(project._id, '')
    .then((response) => {
      dispatch({
        type: LOAD_PROJECT_CHANGE_LOGS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting project change logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

// GET catalog deletion logs
export const getCatalogChangeLogs = (catalog) => async (dispatch) => {
  axios.log.catalogChangelog
    .get(catalog._id, '')
    .then((response) => {
      dispatch({
        type: LOAD_CATALOG_CHANGE_LOGS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting catalog change logs. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const restoreDeletedItem = (data) => async (dispatch) => {
  axios.recycleBin
    .put(data._id)
    .then((response) => {
      dispatch(setAlert('Entity successfully restored.', 'success'));
      dispatch({
        type: REMOVE_RECYCLE_BIN_ENTRY,
        payload: data._id,
      });
    })
    .catch((error) => {
      if (
        error?.response?.status === 400 &&
        data?.parent?.object_type === 'Entity'
      ) {
        dispatch(
          setAlert(
            `Error restoring item. The parent node with the node ID ${data.parent.resource._id}
              of this object does not exist in the analysis tree anymore. Therefore, the object cannot be restored.`,
            'danger',
            15000
          )
        );
      } else {
        dispatch(
          setAlert(
            `Error restoring item. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger',
            10000
          )
        );
      }
    });
};

export const removeDeletedItem = (data) => async (dispatch) => {
  axios.recycleBin
    .delete(data._id)
    .then((response) => {
      dispatch(setAlert('Entity successfully removed.', 'success'));
      dispatch({
        type: REMOVE_RECYCLE_BIN_ENTRY,
        payload: data._id,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error restoring item. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const deleteRecycleBin =
  (data = undefined, logType) =>
  async (dispatch) => {
    switch (logType) {
      case LogType.Admin:
        axios.recycleBin
          .adminEmpty('')
          .then(() => {
            emptyBinResponse(dispatch, logType);
          })
          .catch((error) => {
            dispatch(
              setAlert(
                `Error emptying admin recycle bin. Error message: ${
                  error?.response?.data?.msg ?? error?.response?.data?.message
                }`,
                'danger'
              )
            );
          });
        break;
      case LogType.Catalog:
        axios.recycleBin
          .catalogDelete(data._id, '')
          .then(() => {
            emptyBinResponse(dispatch, logType);
          })
          .catch((error) => {
            dispatch(
              setAlert(
                `Error emptying catalog recycle bin. Error message: ${
                  error?.response?.data?.msg ?? error?.response?.data?.message
                }`,
                'danger'
              )
            );
          });
        break;
      case LogType.Project:
        axios.recycleBin
          .projectDelete(data._id, '')
          .then(() => {
            emptyBinResponse(dispatch, logType);
          })
          .catch((error) => {
            dispatch(
              setAlert(
                `Error emptying project recycle bin. Error message: ${
                  error?.response?.data?.msg ?? error?.response?.data?.message
                }`,
                'danger'
              )
            );
          });
        break;
      default:
        break;
    }
  };

const emptyBinResponse = (dispatch, logType) => {
  dispatch(setAlert('Recycle bin emptied.', 'success'));
  dispatch({
    type: EMPTY_RECYCLE_BIN,
    payload: logType,
  });
};

export const restoreDeletedUser = (payload) => async (dispatch) => {
  axios.user
    .post('/admin/restore-user/', { user_id: payload._id })
    .then(() => {
      dispatch({
        type: RESTORE_DELETED_USER,
        payload: payload,
      });
      dispatch(setAlert('User successfully restored.', 'success'));
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error restoring deleted user. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};
