import React, { Fragment } from 'react';
import Draggable from 'react-draggable';
import { Modal } from 'reactstrap';

/**
 * Draggable modal object
 *
 * A reactstrap modal appended with dragging functionalities
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *
 */
const DraggableModal = ({
  isOpen,
  toggle,
  unmountOnClose = true,
  size,
  backdrop = true,
  children,
}) => {
  return (
    <Fragment>
      <Draggable>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          unmountOnClose={unmountOnClose}
          size={size}
          backdrop={backdrop}
        >
          {children}
        </Modal>
      </Draggable>
    </Fragment>
  );
};

export default DraggableModal;
