import axios from '../../Axios/axios';
import SessionStorageService from '../../Axios/SessionStorage';
import { DefaultErrorHandling } from '../../components/helpers/genericHelper';
import { CatalogType } from '../../constants/Enums';
import store from '../store';
import { setAlert } from './alert';
import { dismissRegisterError } from './auth';
import {
  AUTH_LOADING,
  DEL_PROFILE,
  GET_PROFILES,
  LOAD_ADMIN_CATALOGS,
  LOAD_ADMIN_PROJECTS,
  LOGOUT,
  NO_SIDEBAR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_CONTROL_CATALOGS,
  SET_PROFILE,
  SET_REFTREES,
  SET_REFTREE_CATALOGS,
} from './types';

const sessionStorageService = SessionStorageService.getService();

// GET available users/profiles
export const getProfiles = () => async (dispatch) => {
  axios.user
    .get('?_id=true&name=true&mail=true&permissions=true')
    .then((response) => {
      dispatch({
        type: GET_PROFILES,
        payload: response.data,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const setProfile = (payload) => async (dispatch) => {
  axios.user
    .get(payload._id)
    .then((response) => {
      dispatch({
        type: SET_PROFILE,
        payload: response.data,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const assignMember = (id, value) => async (dispatch) => {
  const payload = { participants_to_add: [value] };
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      dispatch(setAlert('User has been added on the project', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const createUser = (payload) => async (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
  });
  axios.user
    .put('admin', payload)
    .then(() => {
      dispatch({
        type: AUTH_LOADING,
      });
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch(setAlert('User Creation Successful', 'success'));
      dispatch(getProfiles());
    })
    .catch((error) => {
      const mailError = error?.response?.data?.msg?.mail?.toLowerCase();
      const nameError = error?.response?.data?.msg?.name?.toLowerCase();

      const errorMessage = `Error: 
        ${
          mailError ? mailError + (mailError && nameError ? ' and ' : '') : ''
        }${nameError ? nameError : ''}`;
      dispatch({
        type: REGISTER_FAIL,
        payload: errorMessage,
      });
      setTimeout(() => dispatch(dismissRegisterError()), 5000);
      dispatch({
        type: AUTH_LOADING,
      });
    });
};

// DELETE user on user management
export const deleteUser = (id) => async (dispatch) => {
  axios.user
    .delete(id)
    .then(
      (response) => {
        dispatch({
          type: DEL_PROFILE,
          payload: id,
        });
        dispatch(setAlert('User successfully deleted', 'success'));
        //Loads the remaining projects
        dispatch(getProfiles());
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setAlert('Permission Denied', 'danger'));
        } else {
          dispatch(
            setAlert(
              error?.response?.data?.msg ?? error?.response?.data?.message,
              'danger'
            )
          );
        }
      }
    )
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

//UPDATE user in user management
export const updateUser = (id, profile) => async (dispatch) => {
  axios.user
    .patch(id, profile)
    .then((response) => {
      dispatch(setAlert('User successfully updated', 'success'));
      //Loads the remaining projects
      dispatch(getProfiles());
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const revokeSelfAdmin = (id, profile) => async (dispatch) => {
  axios.user
    .patch(id, profile)
    .then(() => {
      dispatch(setAlert('Admin rights successfuly revoked', 'success'));
      dispatch(getProfiles());
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateAccount = (id, payload) => async (dispatch) => {
  await axios.user
    .patch(id, payload)
    .then(async () => {
      sessionStorageService.clearToken();
      dispatch({ type: LOGOUT });
      dispatch({
        type: NO_SIDEBAR,
      });
      window.location.href = '/';
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

// GET user catalogs
export const getControlCatalogs =
  (id = undefined) =>
  async (dispatch) => {
    let projectId = undefined;
    const state = store.getState();
    const project = state.project.project;
    if (id !== undefined) {
      projectId = id;
    } else if (project !== undefined) {
      projectId = project._id;
    }
    axios.catalog
      .get(projectId !== undefined ? `?project_id=${projectId}` : '')
      .then((response) => {
        const ControlCatalogs = response.data.filter(
          (catalog) => catalog.content_type === CatalogType.Control
        );
        const ReferenceTreeCatalogs = response.data.filter(
          (catalog) => catalog.content_type === CatalogType.ReferenceTree
        );
        dispatch({
          type: SET_REFTREE_CATALOGS,
          payload: ReferenceTreeCatalogs,
        });
        dispatch({
          type: SET_CONTROL_CATALOGS,
          payload: ControlCatalogs,
        });
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting user catalogs. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

// GET user reftrees
export const getReftrees =
  (id = undefined) =>
  async (dispatch) => {
    let projectId = -1;
    const state = store.getState();
    const project = state.project.project;
    if (id !== undefined) {
      projectId = id;
    } else if (project !== undefined) {
      projectId = project._id;
    }
    await axios.refTree
      .get(`?project_id=${projectId}`)
      .then(async (response) => {
        dispatch({
          type: SET_REFTREES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting user reference trees. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

// GET available admin projects
export const getAdminProjects = () => async (dispatch) => {
  const data = {};
  const projectsResponse = await axios.project
    .post(
      '?_id=True&name=True&project_type=True&last_modified=True&description=True&scope=True&participants=True',
      data
    )
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  const projects = [];
  if (Array.isArray(projectsResponse?.data)) {
    for (const projectString of projectsResponse.data) {
      let projectObject = undefined;
      try {
        projectObject = projectString;
      } catch {
        continue;
      }
      projects.push(projectObject);
    }
  }

  //Parses all the JSON project data and dispatches them to the store
  dispatch({
    type: LOAD_ADMIN_PROJECTS,
    payload: projects,
  });
};

export const getAdminCatalogs = () => async (dispatch) => {
  const catalogsResponse = await axios.catalog.post('', {}).catch((error) => {
    dispatch(
      setAlert(
        error?.response?.data?.msg ?? error?.response?.data?.message,
        'danger'
      )
    );
  });
  //Parses all the JSON project data and dispatches them to the store
  dispatch({
    type: LOAD_ADMIN_CATALOGS,
    payload: catalogsResponse?.data,
  });
};

export const getCreatorSlots = () => async (dispatch) => {
  axios.user
    .get('creator/')
    .then(() => {})
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting creator slots. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};
