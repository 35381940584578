import { CatalogType, RiskViewType } from '../../constants/Enums';
import {
  ADD_MEMBER,
  ADD_NEW_PROJECT_CATALOG,
  ADD_PROJECT,
  ADD_PROJECT_BASELINE,
  CANCEL_REROUTE,
  CANCEL_UNSAVED_CHANGES,
  DELETE_PROJECT_BASELINE,
  DELETE_PROJECT_SPECIFIC_CATALOG,
  DEL_MEMBER,
  DEL_PROJECT,
  GET_NEWS,
  IMPORT_PROJECT,
  LOAD_PROJECTS,
  LOAD_PROJECT_BASELINE,
  LOGOUT,
  PROJECT_LOADING,
  PROJECT_LOADING_ERROR,
  REROUTE,
  REVERT_PROJECT_BASELINE,
  SET_CATALOG,
  SET_PROJECT,
  SET_PROJECT_BASELINE_MODE,
  SET_PROJECT_COMMENTS,
  SET_PROJECT_CONTROL_CATALOG,
  SET_PROJECT_REFTREE_CATALOG,
  SET_RISK_VIEW_TYPE,
  UNSAVED_CHANGES,
  UPDATE_MEMBER,
  UPDATE_PROJECT,
} from '../actions/types';

const initialState = {
  project: undefined,
  projects: [],
  loading: false,
  error: false,
  memberId: [],
  comments: [],
  baselines: undefined,
  control_catalogs: undefined,
  reftree_catalogs: undefined,
  news: undefined,
  unsavedChanges: [],
  routeParams: undefined,
  riskView: RiskViewType.All,
  baselineMode: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case LOAD_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case LOAD_PROJECT_BASELINE:
      return {
        ...state,
        baselines: payload,
      };

    case SET_PROJECT_REFTREE_CATALOG:
      return {
        ...state,
        reftree_catalogs: payload,
      };
    case SET_PROJECT_CONTROL_CATALOG:
      return {
        ...state,
        control_catalogs: payload,
      };
    case ADD_NEW_PROJECT_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        const controlCatalog = [...state.control_catalogs];
        controlCatalog.push(payload);
        return {
          ...state,
          control_catalogs: controlCatalog,
        };
      } else {
        const refTreeCatalog = [...state.reftree_catalogs];
        refTreeCatalog.push(payload);
        return {
          ...state,
          reftree_catalogs: refTreeCatalog,
        };
      }
    case DELETE_PROJECT_SPECIFIC_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        let controlCatalog = [...state.control_catalogs];
        controlCatalog = controlCatalog.filter(
          (catalog) => parseInt(catalog._id) !== parseInt(payload._id)
        );
        return {
          ...state,
          control_catalogs: controlCatalog,
        };
      } else {
        let refTreeCatalog = [...state.reftree_catalogs];
        refTreeCatalog = refTreeCatalog.filter(
          (catalog) => parseInt(catalog._id) !== parseInt(payload._id)
        );
        return {
          ...state,
          reftree_catalogs: refTreeCatalog,
        };
      }
    case SET_PROJECT_COMMENTS:
      return {
        ...state,
        comments: payload,
      };
    case SET_PROJECT:
    case DEL_PROJECT:
    case ADD_PROJECT:
    case IMPORT_PROJECT:
    case UPDATE_PROJECT:
      return {
        ...state,
        project: payload,
      };
    case DEL_MEMBER:
      const updatedProject = Object.assign([], state.project);

      const filteredMembers = updatedProject.participants.filter(
        (member) => payload !== member.user_id
      );
      updatedProject.participants = filteredMembers;

      return {
        ...state,
        project: updatedProject,
      };
    case ADD_MEMBER:
    case UPDATE_MEMBER:
    case LOGOUT:
    case PROJECT_LOADING_ERROR:
      return {
        project: undefined,
        projects: [],
      };
    case CANCEL_UNSAVED_CHANGES:
      let newChanges = [];
      if (payload !== undefined) {
        newChanges = state.unsavedChanges.filter(
          (change) => change !== payload
        );
      }
      return {
        ...state,
        unsavedChanges: newChanges,
      };
    case UNSAVED_CHANGES:
      const changes = state.unsavedChanges.filter((change) => true);
      changes.push(payload);
      return {
        ...state,
        unsavedChanges: changes,
      };
    case REROUTE:
      return {
        ...state,
        routeParams: payload,
      };
    case CANCEL_REROUTE:
      return {
        ...state,
        routeParams: undefined,
      };
    case GET_NEWS:
      return {
        ...state,
        news: payload,
      };
    case SET_CATALOG:
      if (payload.content_type === CatalogType.Control) {
        const controlCatalogs = Object.assign([], state.control_catalogs);
        const controlIndex = controlCatalogs.findIndex(
          (controlCatalog) => controlCatalog._id === payload._id
        );
        controlIndex !== -1 && controlCatalogs.splice(controlIndex, 1, payload);
        return {
          ...state,
          control_catalogs: controlCatalogs,
        };
      } else {
        const refTreeCatalogs = Object.assign([], state.reftree_catalogs);
        const reftreeIndex = refTreeCatalogs.findIndex(
          (rtCatalog) => rtCatalog._id === payload._id
        );
        reftreeIndex !== -1 && refTreeCatalogs.splice(reftreeIndex, 1, payload);
        return {
          ...state,
          reftree_catalogs: refTreeCatalogs,
        };
      }
    case SET_RISK_VIEW_TYPE:
      return {
        ...state,
        riskView: payload,
      };
    case SET_PROJECT_BASELINE_MODE:
      return {
        ...state,
        baselineMode: payload,
      };

    case ADD_PROJECT_BASELINE:
      const newBaselines = [...state.baselines, payload];
      return {
        ...state,
        baselines: newBaselines,
      };
    case DELETE_PROJECT_BASELINE:
      const baselines = Object.assign([], state.baselines);
      const baselineIndex = baselines.findIndex(
        (baseline) => baseline._id === payload
      );
      baselines.splice(baselineIndex, 1, payload);
      return {
        ...state,
        baselines: baselines,
      };
    case REVERT_PROJECT_BASELINE:
    default:
      return state;
  }
}
