import React from 'react';
import { Input } from 'reactstrap';
import { ProjectRole } from '../../../constants/Enums';

/**
 * Role Select Component
 *
 * Component for the input(select) of roles
 *
 * @param onChange event of change on Inputs
 */

const RoleSelect = ({ onChange, data, isCreator = false }) => {
  return (
    <div>
      <Input
        type='select'
        name='role'
        onChange={onChange}
        placeholder='Select Role'
        value={data}
      >
        <option value=''>Select role</option>

        {Object.entries(ProjectRole).map((role) => {
          return isCreator ? (
            <option value={role[1].value}>{role[1].name}</option>
          ) : (
            role[1].name !== ProjectRole.Owner.name &&
              role[1].name !== ProjectRole.Editor.name && (
                <option value={role[1].value}>{role[1].name}</option>
              )
          );
        })}
      </Input>
      {!isCreator &&
        'Only users with creator rights can get the role "owner" or "editor"'}
    </div>
  );
};

export default RoleSelect;
