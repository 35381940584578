import { default as React, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import { exportProjectJSON } from '../../../redux/actions/projects';
/**
 * Export.
 *
 * Modal for exporting
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
export const JSONExportModal = ({ modal, toggle, projectId }) => {
  useEffect(() => {
    setDownloadUrl(undefined);
  }, [modal]);
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const downloadData = async () => {
    const downloadUrlToSet = await exportProjectJSON(projectId);
    setDownloadUrl(downloadUrlToSet);
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <div className='modal-header'>Export Project JSON</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <div>
              {downloadUrl ? (
                <>
                  <Label>
                    Download{' '}
                    <a download='' href={downloadUrl} rel='noopener noreferrer'>
                      JSON
                    </a>
                  </Label>
                  <Label>
                    Right-click this link and choose Save link as to choose
                    download location or change filename
                  </Label>
                </>
              ) : (
                <Fragment>
                  Are you sure you want to export the selected project's JSON?
                </Fragment>
              )}
            </div>
          </Form>
          <Form>
            <FormGroup>
              <Button
                className='float-right btn-add'
                onClick={() => {
                  downloadData();
                }}
              >
                Export
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default withRouter(connect({}, {})(JSONExportModal));
