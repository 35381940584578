import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { setProject, updateProject } from '../../redux/actions/projects';
import { withRouter } from 'react-router-dom';
import {
  GeneralItems,
  AssetIdentification,
  ThreatScenarioIdentification,
  ImpactRating,
  AttackPathAnalysis,
  AttackFeasibilityRating,
  RiskValue,
  RiskTreatment,
  CybersecurityGoals,
  CybersecurityControls,
} from './ReviewChecklistItems';
import ExportReviewModal from './ExportReviewModal';

const evaluationOptions = {
  significant: 'Significant',
  optimize: 'Optimize',
  info: 'Info',
  check: 'Check',
  ok: 'OK',
};
const statusOptions = {
  open: 'Open',
  'in progress': 'In Progress',
  done: 'Done',
};

const ReviewChecklist = ({
  loading,
  project,
  setProject,
  updateProject,
  profile,
}) => {
  const [checklist, setChecklist] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const handleExportButtonClick = () => {
    setExportModalOpen(true);
  };
  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    updateProject(project._id, { review_checklist: checklist });
    // Logic to save changes
  };

  const handleCancel = () => {
    setChecklist(project?.review_checklist ?? []);
    setEditMode(false);
  };
  const customWidth = {
    width: '350px',
    textAlign: 'center',
  };

  const handleInputChange = (value, questionId, id) => {
    const newChecklist = JSON.parse(JSON.stringify(checklist));
    const index = checklist.findIndex(
      (item) => item.question_id === questionId
    );
    if (index >= 0) {
      newChecklist[index][id] = value;
      setChecklist([...newChecklist]);
    } else {
      const newItem = { question_id: questionId };
      newItem[id] = value;
      setChecklist([...checklist, newItem]);
    }
  };

  const convertDate = (date) => {
    if (Date.parse(date) !== NaN && date !== undefined) {
      const newDate = new Date(date);
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const day = newDate.getDate();

      return `${month}/${day}/${year}`;
    }
    return '';
  };

  const editItems = (questionId) => (
    <>
      <td>
        <Input
          type='select'
          id='evaluation'
          value={
            checklist.find(
              (checklistItem) => checklistItem.question_id === questionId
            )?.evaluation
          }
          onChange={(event) =>
            handleInputChange(event.target.value, questionId, event.target.id)
          }
        >
          <option selected hidden>
            Select evaluation
          </option>
          {Object.keys(evaluationOptions).map((key) => (
            <option value={key}>{evaluationOptions[key]}</option>
          ))}
        </Input>
      </td>
      <td>
        <Input
          type='text'
          id='measure'
          placeholder='Measure'
          value={
            checklist.find(
              (checklistItem) => checklistItem.question_id === questionId
            )?.measure
          }
          onChange={(event) =>
            handleInputChange(event.target.value, questionId, event.target.id)
          }
        />
      </td>
      <td>
        <Input
          type='text'
          id='responsible'
          placeholder='Responsible'
          value={
            checklist.find(
              (checklistItem) => checklistItem.question_id === questionId
            )?.responsible
          }
          onChange={(event) =>
            handleInputChange(event.target.value, questionId, event.target.id)
          }
        />
      </td>
      <td>
        <Input
          type='date'
          placeholder='Due Date'
          id='due_date'
          value={checklist
            .find((checklistItem) => checklistItem.question_id === questionId)
            ?.due_date?.substring(0, 10)}
          min={new Date().toISOString().split('T')[0]} // Set minimum date to today
          onChange={(event) => {
            handleInputChange(
              new Date(event.target.value).toISOString(),
              questionId,
              event.target.id
            );
          }}
        />
      </td>
      <td>
        <Input
          type='select'
          id='status'
          defaultValue={statusOptions[0]}
          value={
            checklist.find(
              (checklistItem) => checklistItem.question_id === questionId
            )?.status
          }
          onChange={(event) =>
            handleInputChange(event.target.value, questionId, event.target.id)
          }
        >
          <option selected hidden>
            Select status
          </option>
          {Object.keys(statusOptions).map((key) => (
            <option value={key}>{statusOptions[key]}</option>
          ))}
        </Input>
      </td>
      <td>
        <Input
          type='textarea'
          id='comments'
          placeholder='Comments'
          value={
            checklist.find(
              (checklistItem) => checklistItem.question_id === questionId
            )?.comments
          }
          onChange={(event) =>
            handleInputChange(event.target.value, questionId, event.target.id)
          }
        />
      </td>
    </>
  );

  const checklistItem = (questionId) =>
    project?.review_checklist?.find(
      (checklistItem) => checklistItem.question_id === questionId
    );

  const mapItems = (items) => (
    <>
      {items.map((item) => (
        <tr style={{ height: '150px' }} key={item.questionId}>
          <td>{item.questionId}</td>
          <td style={customWidth}>{item.checkpoint}</td>

          {editMode ? (
            editItems(item.questionId)
          ) : (
            <>
              <td>
                {evaluationOptions[checklistItem(item.questionId)?.evaluation]}
              </td>
              <td>{checklistItem(item.questionId)?.measure}</td>
              <td>{checklistItem(item.questionId)?.responsible}</td>
              <td>{convertDate(checklistItem(item.questionId)?.due_date)}</td>
              <td>{statusOptions[checklistItem(item.questionId)?.status]}</td>
              <td>{checklistItem(item.questionId)?.comments}</td>
            </>
          )}
        </tr>
      ))}
    </>
  );

  useEffect(() => {
    if (profile !== null) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);

  useEffect(() => {
    if (project?.review_checklist) {
      let newChecklist = JSON.parse(JSON.stringify(project?.review_checklist));
      setChecklist(newChecklist);
    }

    if (editMode === true) {
      setEditMode(false);

    }
  }, [project]);

  return (
    <div>
      <h2>Review Checklist</h2>
      <div
        className='card-actions float-right'
        style={{ marginBottom: '10px' }}
      >
        {editMode ? (
          <>
            <Button className='btn-add' onClick={() => handleSave()}>
              Save
            </Button>
            <Button color='secondary' onClick={() => handleCancel()}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button color='add' onClick={handleExportButtonClick}>
              Export
            </Button>
            <Button color='primary' onClick={() => handleEdit()}>
              Edit
            </Button>
          </>
        )}
      </div>
      {project && (
        <ExportReviewModal
          modal={isExportModalOpen}
          toggle={() => setExportModalOpen(!isExportModalOpen)}
          currentProject={project}
        />
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: '150px' }}>ID</th>
            <th style={customWidth}>Checkpoints</th>
            <th style={{ width: '200px' }}>Evaluation</th>
            <th style={{ width: '200px' }}>Measure</th>
            <th style={{ width: '200px' }}>Responsible</th>
            <th style={{ width: '200px' }}>Due Date</th>
            <th style={{ width: '200px' }}>Status</th>
            <th style={{ width: '200px' }}>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={editMode ? '8' : '8'}>{'General'}</th>
          </tr>
          {mapItems(GeneralItems)}
          <tr>
            <th colSpan='8'>
              {
                'Asset identification in accordance with ISO/SAE 21434:2021- 15.3: '
              }
            </th>
          </tr>
          {mapItems(AssetIdentification)}
          <tr>
            <th colSpan='8'>
              {
                'Threat scenario identification in accordance with ISO/SAE 21434:2021-15.4:'
              }
            </th>
          </tr>
          {mapItems(ThreatScenarioIdentification)}
          <tr>
            <th colSpan='8'>
              {'Impact rating in accordance with ISO/SAE 21434:2021-15.5'}
            </th>
          </tr>
          {mapItems(ImpactRating)}
          <tr>
            <th colSpan='8'>
              {
                'Attack path analysis in accordance with ISO/SAE 21434:2021-15.6'
              }
            </th>
          </tr>
          {mapItems(AttackPathAnalysis)}
          <tr>
            <th colSpan='8'>
              {
                'Attack feasibility rating in accordance with ISO/SAE 21434:2021-15.7'
              }
            </th>
          </tr>
          {mapItems(AttackFeasibilityRating)}
          <tr>
            <th colSpan='8'>
              {
                'Risk value determination in accordance with ISO/SAE 21434:2021-15.8'
              }
            </th>
          </tr>
          {mapItems(RiskValue)}
          <tr>
            <th colSpan='8'>
              {
                'Risk treatment decision in accordance with ISO/SAE 21434:2021-15.9'
              }
            </th>
          </tr>
          {mapItems(RiskTreatment)}
          <tr>
            <th colSpan='8'>
              {
                'Cybersecurity goals and claims in accorance with ISO/SAE 21434:2021-9.4'
              }
            </th>
          </tr>
          {mapItems(CybersecurityGoals)}
          <tr>
            <th colSpan='8'>{'Cybersecurity controls'}</th>
          </tr>
          {mapItems(CybersecurityControls)}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default withRouter(
  connect(mapStateToProps, { setProject, updateProject })(ReviewChecklist)
);
