import React from 'react';
import { Input } from 'reactstrap';
import { ProjectType } from '../../../constants/Enums';

/**
 * Project Type Select component
 *
 * Component for the input(select) of project type during creating and editing of project
 *
 * @param  data placeholder for the project type on add modal and project type panel
 * @param onChange event of change on Inputs
 */

const TypeSelect = ({ data, onChange }) => {
  return (
    <>
      <Input
        type='select'
        name='project_type'
        id='project_type'
        onChange={onChange}
        placeholder='Select Project Type'
        defaultValue={data ?? Object.entries(ProjectType)[0][1].Value}
      >
        <option disabled selected>
          Please Select Project Type
        </option>
        {Object.entries(ProjectType).map((type) => {
          return (
            <option key={type[1].Value} value={type[1].Value}>
              {type[1].Name}
            </option>
          );
        })}
      </Input>
    </>
  );
};

export default TypeSelect;
