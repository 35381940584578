import {
  ADD_CONTROL,
  LOAD_CONTROLS,
  LOGOUT,
  PROJECT_LOADING_ERROR,
  UPDATE_CONTROL,
} from '../actions/types';

const initialState = {
  controls: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newControls = Object.assign([], state.controls);
  switch (type) {
    case LOAD_CONTROLS:
      return {
        ...state,
        controls: payload,
      };
    case ADD_CONTROL:
      newControls.push(payload);
      return {
        ...state,
        controls: newControls,
      };
    case UPDATE_CONTROL:
      newControls = newControls.map((control) => {
        return control._id === payload._id
          ? { ...control, ...payload }
          : control;
      });

      return {
        ...state,
        controls: newControls,
      };
    case PROJECT_LOADING_ERROR:
    case LOGOUT:
      return {
        controls: [],
      };
    default:
      return state;
  }
}
