import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { CatalogType } from '../../constants/Enums';
import { setCatalog, setCatalogEntry } from '../../redux/actions/catalog';
import { getReftrees } from '../../redux/actions/profile';
import store from '../../redux/store';
import { ModalConfirmation } from './ModalConfirmation';

//This the Expanded area when clicking the projects in the Project list page
const ExpandedComponent = ({
  data,
  refTrees,
  profile,
  type,
  filteredEntries = undefined,
}) => {
  const [entries, setEntries] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [deleteRow, setDeleteRow] = useState(null);
  useEffect(() => {
    if (refTrees === undefined) {
      dispatch(getReftrees(data?.project_id));
    }
    const entries = [];
    if (
      type.find((row) => parseInt(row._id) === parseInt(data._id))?.type ===
        'entries' ||
      filteredEntries !== undefined
    ) {
      for (const child of data.children) {
        let entry = {};
        if (filteredEntries) {
          entry = filteredEntries.find(
            (refTree) => parseInt(refTree._id) === parseInt(child)
          );
        } else {
          entry = refTrees.find(
            (refTree) => parseInt(refTree._id) === parseInt(child)
          );
        }

        if (entry) {
          entries.push(entry);
        }
      }
      setEntries(entries);
    }
  }, [refTrees, data, filteredEntries]);
  const history = useHistory();
  const { dispatch } = store;
  return (
    <div display='inline-block' style={{ fontSize: '0.8rem !important' }}>
      {'       '}
      <br></br>
      {'       '}{' '}
      <span>
        {type.find((row) => parseInt(row._id) === parseInt(data._id))?.type ===
          'entries' || filteredEntries !== undefined ? (
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {entries?.map((row) => (
                <tr>
                  <td>
                    <Link
                      to={`${data._id}/entry/${row._id}`}
                      onClick={() => {
                        dispatch(setCatalog(data));
                        dispatch(setCatalogEntry(row, data));
                      }}
                    >
                      {row?.name}
                    </Link>
                  </td>
                  <td>{row?._id}</td>
                  <td>{row?.description}</td>
                  <td className='expanded-table-td'>
                    {data?.content_type === CatalogType.Control ? (
                      <>
                        <Button
                          id={`editButton${row._id}`}
                          onClick={() => {
                            history.push(
                              `${data._id}/entry/${row._id}?edit=true`
                            );
                            dispatch(setCatalog(data));
                            dispatch(setCatalogEntry(row, data));
                          }}
                        >
                          <i className='fa fa-edit' />
                        </Button>
                        <UncontrolledTooltip target={`editButton${row._id}`}>
                          Edit
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <Button
                          id={`viewReftreeButton${row._id}`}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/catalogues/${data?._id}/reftree/${row?._id}`
                            );
                          }}
                        >
                          <i className='fa fa-sitemap' />
                        </Button>
                        <UncontrolledTooltip
                          target={`viewReftreeButton${row._id}`}
                        >
                          View Reference tree
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                  <td className='expanded-table-td'>
                    {data?.participants
                      ?.find(
                        (member) =>
                          parseInt(member.user_id) === parseInt(profile?.id)
                      )
                      ?.role.includes('owner') ||
                    data?.participants
                      ?.find(
                        (member) =>
                          parseInt(member.user_id) === parseInt(profile?.id)
                      )
                      ?.role.includes('editor') ? (
                      <>
                        <Button
                          id={`deleteButton${row._id}`}
                          className='btn-danger'
                          onClick={() => {
                            toggleModal();
                            setDeleteRow(row);
                          }}
                        >
                          <i className='fa fa-trash' />
                        </Button>
                        <UncontrolledTooltip target={`deleteButton${row._id}`}>
                          Delete
                        </UncontrolledTooltip>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <> {data.description} </>
        )}
      </span>
      <ModalConfirmation toggle={toggleModal} modal={modal} row={deleteRow} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  refTrees: state.profile.userReftrees,
  profile: state.profile.profile,
});

export default connect(mapStateToProps)(ExpandedComponent);
