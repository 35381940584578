import { trackPromise } from 'react-promise-tracker';
import axios from './Axios-Interceptors';
export const BaseUrl = '/api/';

const userAPIUrl = 'user/';
const projectAPIUrl = 'project/';
const importAPIUrl = 'import/';
const entityAPIUrl = 'entity/';
const refTreeAPIUrl = 'reftree/';
const catalogAPIUrl = 'catalog/';
const logAPIUrl = 'log/';
const changelogAPIUrl = 'changelog/';
const adimnLogAPIUrl = 'admin-log/';
const securityLogAPIUrl = 'security-log/';
const recycleBinAPIUrl = 'recycle-bin/';
const passwordResetAPIUrl = 'password-reset/';
const passwordCheckAPIUrl = 'password-check';
const licenseAPIUrl = 'license/';
const backendVersionAPIUrl = 'backend-version/';
const settingsAPIUrl = 'settings/';
const axiosMethods = {
  authentication: {
    login(data) {
      return trackPromise(axios.post(BaseUrl + userAPIUrl + 'login', data));
    },
    createUser(data) {
      return trackPromise(axios.put(BaseUrl + userAPIUrl, data));
    },
    checkPassword(data) {
      return trackPromise(
        axios.post(BaseUrl + userAPIUrl + passwordCheckAPIUrl, data)
      );
    },
    logout() {
      return trackPromise(axios.post(BaseUrl + userAPIUrl + 'logout'));
    },
  },
  user: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + userAPIUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + userAPIUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + userAPIUrl + url, data));
    },
    patch(url, data) {
      return trackPromise(axios.patch(BaseUrl + userAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + userAPIUrl + url));
    },
  },
  project: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + projectAPIUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + projectAPIUrl + url, data));
    },
    patch(url, data) {
      return trackPromise(axios.patch(BaseUrl + projectAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + projectAPIUrl + url));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + projectAPIUrl + url, data));
    },
  },
  import: {
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + importAPIUrl + url, data));
    },
  },
  entity: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + entityAPIUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + entityAPIUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + entityAPIUrl + url, data));
    },
    patch(url, data) {
      return trackPromise(axios.patch(BaseUrl + entityAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + entityAPIUrl + url));
    },
  },
  refTree: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + refTreeAPIUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + refTreeAPIUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + refTreeAPIUrl + url, data));
    },
    patch(url, data) {
      return trackPromise(axios.patch(BaseUrl + refTreeAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + refTreeAPIUrl + url));
    },
  },
  catalog: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + catalogAPIUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + catalogAPIUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + catalogAPIUrl + url, data));
    },
    patch(url, data) {
      return trackPromise(axios.patch(BaseUrl + catalogAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + catalogAPIUrl + url));
    },
  },
  log: {
    admin: {
      get(url) {
        return trackPromise(
          axios.get(BaseUrl + logAPIUrl + adimnLogAPIUrl + url)
        );
      },
      post(url, data) {
        return trackPromise(
          axios.post(BaseUrl + logAPIUrl + adimnLogAPIUrl + url, data)
        );
      },
    },
    security: {
      get(url) {
        return trackPromise(
          axios.get(BaseUrl + logAPIUrl + securityLogAPIUrl + url)
        );
      },
      post(url, data) {
        return trackPromise(
          axios.post(BaseUrl + logAPIUrl + securityLogAPIUrl + url, data)
        );
      },
    },
    adminDeletion: {
      get(url) {
        return trackPromise(axios.get(BaseUrl + recycleBinAPIUrl + url));
      },
      post(url, data) {
        return trackPromise(axios.post(BaseUrl + recycleBinAPIUrl + url, data));
      },
    },
    projectDeletion: {
      get(projectId, url) {
        return trackPromise(
          axios.get(
            BaseUrl + projectAPIUrl + projectId + '/' + recycleBinAPIUrl + url
          )
        );
      },
    },
    catalogDeletion: {
      get(catalogId, url) {
        return trackPromise(
          axios.get(
            BaseUrl + catalogAPIUrl + catalogId + '/' + recycleBinAPIUrl + url
          )
        );
      },
    },
    projectChangelog: {
      get(projectId, url) {
        return trackPromise(
          axios.get(
            BaseUrl + projectAPIUrl + projectId + '/' + changelogAPIUrl + url
          )
        );
      },
      post(projectId, url, data) {
        return trackPromise(
          axios.post(
            BaseUrl + projectAPIUrl + projectId + '/' + changelogAPIUrl + url,
            data
          )
        );
      },
    },
    catalogChangelog: {
      get(catalogId, url) {
        return trackPromise(
          axios.get(
            BaseUrl + catalogAPIUrl + catalogId + '/' + changelogAPIUrl + url
          )
        );
      },
      post(catalogId, url, data) {
        return trackPromise(
          axios.post(
            BaseUrl + catalogAPIUrl + catalogId + '/' + changelogAPIUrl + url,
            data
          )
        );
      },
    },
  },
  passwordReset: {
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + passwordResetAPIUrl + url, data));
    },
  },
  recycleBin: {
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + recycleBinAPIUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + recycleBinAPIUrl + url));
    },
    projectDelete(projectId, url) {
      return trackPromise(
        axios.delete(
          BaseUrl + projectAPIUrl + projectId + '/' + recycleBinAPIUrl + url
        )
      );
    },
    catalogDelete(catalogId, url) {
      return trackPromise(
        axios.delete(
          BaseUrl + catalogAPIUrl + catalogId + '/' + recycleBinAPIUrl + url
        )
      );
    },
    adminEmpty(url) {
      return trackPromise(axios.delete(BaseUrl + recycleBinAPIUrl + url));
    },
  },
  license: {
    get() {
      return trackPromise(axios.get(BaseUrl + licenseAPIUrl));
    },
    post(data) {
      return trackPromise(axios.post(BaseUrl + licenseAPIUrl, data));
    },
  },
  settings: {
    post() {
      return trackPromise(axios.post(BaseUrl + settingsAPIUrl));
    },
    put(data) {
      return trackPromise(axios.put(BaseUrl + settingsAPIUrl, data));
    },
    get(url = '') {
      return trackPromise(axios.get(BaseUrl + settingsAPIUrl + url));
    },
  },
  backendVersion: {
    get() {
      return trackPromise(axios.get(BaseUrl + backendVersionAPIUrl));
    },
  },
};

export default axiosMethods;
