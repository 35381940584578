import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Axios/axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageGallery.css';

const ImageGallery = ({ project_id, newImage }) => {
  // State hooks
  const [imageData, setImageData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  // Fetch image data on initial load and when newImage changes
  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await axios.project.get(`${project_id}/image/`);
        setImageData(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImageData();
  }, [project_id, newImage]);

  // Delete image function
  const deleteImage = async (imageId) => {
    try {
      await axios.project.delete(`${project_id}/image/${imageId}`);
      const updatedImageData = imageData.filter(
        (image) => image._id !== imageId
      );
      setImageData(updatedImageData);
      if (selectedImage && selectedImage._id === imageId) {
        setSelectedImage(null);
        toggleModal();
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  // Toggle Image zoom modals
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleDeleteConfirmationModal = () => {
    setDeleteConfirmationModal(!deleteConfirmationModal);
  };

  // Handle delete confirmation
  const handleDeleteImage = (imageId) => {
    setImageToDelete(imageId);
    toggleDeleteConfirmationModal();
  };

  const confirmDeleteImage = async () => {
    try {
      await deleteImage(imageToDelete);
      toggleDeleteConfirmationModal();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  // Handle image click to open modal
  const handleImageClick = (image) => {
    setSelectedImage(image);
    toggleModal();
  };

  // Render modal for selected image
  const renderModal = () => {
    // Function to calculate image size based on window dimensions
    const calculateAspectRatioFit = (
      srcWidth,
      srcHeight,
      maxWidth,
      maxHeight
    ) => {
      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return { width: srcWidth * ratio, height: srcHeight * ratio };
    };

    const imageSize = selectedImage
      ? calculateAspectRatioFit(
          selectedImage.width,
          selectedImage.height,
          window.innerWidth * 1.5,
          window.innerHeight * 1.5
        )
      : { width: 'auto', height: 'auto' };

    return (
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        size='xl'
        contentClassName='custom-modal-content'
      >
        <ModalBody className='text-center'>
          {selectedImage && (
            <img
              src={`data:image/jpeg;base64,${selectedImage.file}`}
              alt={selectedImage.filename}
              style={imageSize}
            />
          )}
        </ModalBody>
      </Modal>
    );
  };

  // Render delete confirmation modal
  const renderDeleteConfirmationModal = () => {
    return (
      <Modal
        isOpen={deleteConfirmationModal}
        toggle={toggleDeleteConfirmationModal}
      >
        <ModalHeader toggle={toggleDeleteConfirmationModal}>
          Confirm Deletion
        </ModalHeader>
        <ModalBody className='text-center'>
          <p>Are you sure you want to delete this image?</p>
          <div className='d-flex justify-content-center mt-3'>
            <Button
              color='danger'
              className='mr-2'
              onClick={confirmDeleteImage}
            >
              Delete
            </Button>
            <Button color='secondary' onClick={toggleDeleteConfirmationModal}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className='image-gallery-container'>
      <div className='bold'> Images </div>
      {imageData && imageData.length > 0 ? (
        <div>
          <Slider
            dots
            infinite={imageData.length > 3}
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            arrows
          >
            {imageData.map((image, index) => (
              <div key={index} className='image-slide'>
                <div className='image-container'>
                  <button
                    className='delete-icon'
                    onClick={() => handleDeleteImage(image._id)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <img
                    src={`data:image/jpeg;base64,${image.file}`}
                    alt={image.filename}
                    className='fixed-image-size'
                    onClick={() => handleImageClick(image)}
                    style={{ marginBottom: '5px', marginRight: '5px' }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <p>No images found for this project</p>
      )}
      {renderModal()}
      {renderDeleteConfirmationModal()}
    </div>
  );
};

export default ImageGallery;
