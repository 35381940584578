import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CardBody,
  CardTitle,
  Container,
  Label,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  ID,
  Implemented,
  Likelihood,
  Name,
  Proposed,
  Review,
} from '../../../constants/StringConstants';
import EntityTable from '../EntityTable';

// List the Control
const ControlTable = ({ controls, vulnerabilities, project }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const data = [];
    getControlList(vulnerabilities, data);
    setData(data);
  }, [controls, vulnerabilities]);

  const getControlList = (entities, entityList) => {
    for (const entity of entities) {
      for (const child of entity.children) {
        const control = controls.find(
          (con) =>
            parseInt(child) === parseInt(con._id) ||
            parseInt(child?._id) === parseInt(con._id)
        );
        if (control) {
          const newControl = JSON.parse(JSON.stringify(control));
          newControl.parent = entity;
          entityList.push(newControl);
        }
      }
    }
  };

  //Columns
  const columns = useMemo(() => [
    {
      label: ID,
      value: function (datum) {
        return datum?._id;
      },
      field: '_id',
      type: 'number',
    },
    {
      label: Name,
      value: function (datum) {
        return datum?.name;
      },
      field: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      value: function (datum) {
        return datum?.description;
      },
      field: 'description',
      type: 'text',
    },
    {
      label: Review,
      value: function (datum) {
        return datum?.review_state;
      },
      field: 'review_state',
      type: 'checkbox',
    },
    {
      label: 'Review Comments',
      value: function (datum) {
        let count = 0;
        if (datum.comments?.autoid) {
          count = parseInt(datum.comments.autoid);
        }
        return count;
      },
      field: 'comments',
      type: 'number',
    },
    {
      label: Likelihood,
      value: function (datum) {
        return datum?.likelihood;
      },
      field: 'likelihodd',
      type: 'number',
    },
    {
      label: Proposed,
      value: function (datum) {
        return datum?.proposed_state;
      },
      field: 'proposed_state',
      type: 'text',
    },
    {
      label: Implemented,
      value: function (datum) {
        return datum?.implemented_state;
      },
      field: 'implemented_state',
      type: 'text',
    },
    {
      label: 'Likelihood Comments',
      value: function (datum) {
        return datum?.likelihood_comment ?? datum?.likelihood_description;
      },
      field: 'likelihood_comment' || 'likelihood_description',
      type: 'text',
    },
    {
      label: 'Parent',
      value: function (datum) {
        return datum?.parent?.name;
      },
      field: 'parent',
      type: 'text',
    },
    {
      label: 'Assignment',
      value: function (datum) {
        return datum.owner.usage;
      },
      field: 'assignment',
      type: 'select',
      enum: (
        <>
          <option value='A'>Analysis Tree</option>
          <option value='R'>Ref Tree</option>
          <option value='C'>Catalog</option>
        </>
      ),
    },
  ]);

  const [headers, setHeaders] = useState([
    columns.find((column) => column.label === ID),
    columns.find((column) => column.label === Name),
    columns.find((column) => column.label === Likelihood),
    columns.find((column) => column.label === Implemented),
    columns.find((column) => column.label === Proposed),
    columns.find((column) => column.label === Review),
  ]);

  const changeHeaders = (chosenColumns, isChecked) => {
    if (chosenColumns) {
      if (!isChecked) {
        setHeaders([...headers, chosenColumns]);
      } else {
        setHeaders(
          headers?.filter((header) => header?.label !== chosenColumns?.label)
        );
      }
    } else {
      setHeaders([]);
    }
  };

  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Controls in {project?.name}</h1>
      </CardTitle>
      <NavbarBrand
        className='mr-auto float-right'
        style={{ marginTop: '-3rem' }}
      >
        Options
        <NavbarToggler id='optionsNavToggle' className='mr-2 float-right'>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
      </NavbarBrand>
      <UncontrolledCollapse toggler='#optionsNavToggle'>
        <Label>Select Columns</Label>
        <ul>
          {columns.map((column) => {
            var selected = columns?.filter(
              (filteredColumn) =>
                Array.isArray(headers) &&
                headers?.some(
                  (header) => header?.label === filteredColumn?.label
                )
            );
            var isChecked = selected
              .map((header) => header.label)
              .includes(column.label);
            return (
              <li style={{ marginLeft: 50 }}>
                <input
                  type='checkbox'
                  className='btn-icon'
                  checked={isChecked}
                  onClick={() => changeHeaders(column, isChecked)}
                />
                <font style={{ marginLeft: 10 }}>{column.label}</font>
              </li>
            );
          })}
        </ul>
      </UncontrolledCollapse>
      <CardBody>
        <EntityTable title={'Controls'} headers={headers} data={data} />
      </CardBody>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  vulnerabilities: state.vulnerabilities.vulnerabilities,
  project: state.project.project,
});
export default withRouter(connect(mapStateToProps)(ControlTable));
