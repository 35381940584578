import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { CatalogType } from '../../constants/Enums';
import { setCatalog, setCatalogEntry } from '../../redux/actions/catalog';
import { setProject } from '../../redux/actions/projects';
import store from '../../redux/store';
import NodeDetailsPage from '../analysistree/nodetypes/nodeObjects/NodeDetailsPage';
import DescriptionPanel from './DescriptionPanel';
import NamePanel from './NamePanel';
/**
 * Catalog Entry Detail Page
 *
 * Page that shows the details of each entry
 *
 */
const EntryDetails = ({ catalog, entry, profile, catalogRole }) => {
  const [openDescription, setDescription] = useState(true);
  const toggleDescription = () => setDescription(!openDescription);

  const { dispatch } = store;
  const { catalogId, entryId } = useParams();
  const [rights, setRights] = useState(false);
  useEffect(() => {
    if (catalog === undefined) {
      const payload = {
        _id: catalogId,
      };
      dispatch(setCatalog(payload));
    }
    if (catalog && entry === undefined) {
      dispatch(setCatalogEntry({ _id: entryId }, catalog));
      if (catalog?.project_id) {
        dispatch(
          setProject({
            _id: catalog.project_id,
          })
        );
      }
    }
    if (profile) {
      const userRights =
        catalog?.participants
          .find((member) => parseInt(member.user_id) === parseInt(profile?.id))
          ?.role.includes('owner') ||
        catalog?.participants
          .find((member) => parseInt(member.user_id) === parseInt(profile?.id))
          ?.role.includes('editor');
      setRights(userRights);
    }
  }, [catalog, profile]);

  return (
    <Container fluid='md'>
      {catalog?.content_type === CatalogType.ReferenceTree ? (
        <>
          <Row>
            {' '}
            <Col>
              <h3>Entry: {entryId}</h3>
            </Col>
          </Row>
          <br />
          <div className='float-right'>
            <Button
              onClick={() => {
                window.open(
                  `${window.location.origin}/catalogues/${catalog?._id}/reftree/${entryId}`
                );
              }}
            >
              View Reference Tree
            </Button>
          </div>

          <NamePanel data={entry} type='entry' />

          <DescriptionPanel
            toggle={toggleDescription}
            openPanel={openDescription}
            data={entry}
            type='entry'
            catalogRole={catalogRole}
          />

          <br />
        </>
      ) : (
        <NodeDetailsPage fromCatalog={true} rights={rights} />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  catalog: state.catalog.catalog,
  entry: state.catalog.entry,
  catalogRole: state.catalog.catalogRole,
  profile: state.profile.profile,
});

export default connect(mapStateToProps)(EntryDetails);
