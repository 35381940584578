import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { btnEdit } from '../../../constants/Style';
import { updateProject } from '../../../redux/actions/projects';
import {
  cancelUnsavedChanges,
  unsavedChanges,
} from '../../../redux/actions/save';
import store from '../../../redux/store';

/**
 * Project ID Panel
 *
 * Panel for displaying and editing the project ID
 *
 * @param data: parameter for the specific project in the project details page
 * @param updateProject: redux action to update a project
 * @param profile: fetches profile of the current user in the system
 */
const ProjectIdPanel = ({
  data,
  updateProject,
  profile,
  save,
  unsavedChanges,
}) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    if (editMode) {
      setFormData({ ...formData, project_id: data?.project_id });
    }
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    project_id: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      project_id: data?.project_id,
    });
  }, [data]);
  const [fieldId, setFieldId] = useState(null);

  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      const id = unsavedChanges();
      setFieldId(id);
    }
  };

  const { dispatch } = store;
  const onSubmit = async () => {
    updateProject(data._id, formData);
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };

  useEffect(() => {
    if (save && editMode) {
      updateProject(data._id, formData);
      changeMode();
      setFieldId(null);
    }
  }, [save]);

  return (
    <div style={{ marginLeft: '-15px' }}>
      {editMode ? (
        <div>
          <Row>
            <Col lg='6'>
              <div className='bold'>Project ID</div>
              <Input
                type='text'
                name='project_id'
                className='project-info-edit'
                onChange={onChange}
                value={formData.project_id}
                style={{ width: '500px' }}
              />
              <br />
              <div style={{ marginLeft: '320px' }}>
                <td className='no-border'>
                  <Button
                    className='btn-add'
                    onClick={() => {
                      onSubmit();
                      changeMode();
                    }}
                  >
                    Save
                  </Button>
                </td>
                <td className='no-border'>
                  <Button
                    className='btn-danger'
                    onClick={() => {
                      changeMode();
                      dispatch(cancelUnsavedChanges(fieldId));
                      setFieldId(null);
                    }}
                  >
                    Cancel
                  </Button>
                </td>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className='project-details-label' style={{ width: '100%' }}>
          <div style={{ width: '125px' }}>
            {data?.baseline === undefined ? (
              data?.participants
                ?.find(
                  (member) => parseInt(member.user_id) === parseInt(profile?.id)
                )
                ?.role.includes('owner') ||
              data?.participants
                ?.find(
                  (member) => parseInt(member.user_id) === parseInt(profile?.id)
                )
                ?.role.includes('editor') ? (
                <Button
                  className='float-right'
                  onClick={() => changeMode()}
                  style={btnEdit}
                >
                  <i className='fa fa-edit' />
                </Button>
              ) : null
            ) : null}
            <div className='bold'>Project ID</div>
          </div>
          <Label>
            <h4 className='bold'>
              {data?.baseline === undefined ? (
                <>{data?.project_id}</>
              ) : (
                <>{`Baseline ${data?._id}: ${data?.project_id}`}</>
              )}
            </h4>
          </Label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

export default connect(mapStateToProps, { updateProject, unsavedChanges })(
  ProjectIdPanel
);
