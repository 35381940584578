export const EntityType = {
  asset: 'asset',
  threat: 'threat',
  vulnerability: 'vulnerability',
  control: 'control',
};

export const AuthProvider = {
  DATABASE: 'db',
  LDAP: 'ldap',
};

export const ProjectRole = {
  Owner: { name: 'Owner', value: 'owner' },
  Editor: { name: 'Editor', value: 'editor' },
  Reviewer: { name: 'Reviewer', value: 'reviewer' },
  Reader: { name: 'Reader', value: 'reader' },
  Demo: { name: 'Demo', value: 'demo' },
};

export const UserPermissions = {
  Admin: 'user_admin',
  Creator: 'user_creator',
  Demo: 'user_demo',
};

export const LikelihoodColors = {
  0: '#00B050',
  1: '#49C050',
  2: '#92D050',
  3: '#BAD928',
  4: '#E1E100',
  5: '#E1D100',
  6: '#E1C000',
  7: '#E16000',
  8: '#E10000',
};

//Color array is 2d array of color values for risk tables
export const ProjectType = {
  ISO_21434: {
    Name: 'ISO 21434',
    Value: 'ISO_21434',
    ThreatType: 'Damage scenario',
    ThreatSecurityType: 'Security Property',
    VulnerabilityType: 'Threat Scenario',
    ImpactLevelTypes: {
      0: 'Negligible',
      1: 'Moderate',
      2: 'Major',
      3: 'Severe',
    },
    ElapsedTime: {
      0: '<= 1 day',
      1: '<= 1 week',
      2: '<= 2 weeks',
      4: '<= 1 month',
      7: '<= 2 months',
      10: '<= 3 months',
      13: '<= 4 months',
      15: '<= 5 months',
      17: '<= 6 months',
      19: '> 6 months',
    },
    ComponentKnowledge: {
      0: 'Public',
      3: 'Restricted',
      7: 'Confidential',
      11: 'Strictly Confidential',
    },
    OpportunityWindow: {
      0: 'Unlimited',
      1: 'Easy',
      4: 'Moderate',
      10: 'Difficult/None',
    },
    Equipment: {
      0: 'Standard',
      4: 'Specialized',
      7: 'Bespoke',
      9: 'Multiple bespoke',
    },
    SpecialistExpertise: {
      0: 'Layman',
      3: 'Proficient',
      6: 'Expert',
      8: 'Multiple experts',
    },
    AttackFeasibility: [
      { value: 'Very low', lower: 0, upper: 0.0625, color: '#00B050' },
      { value: 'Low', lower: 0.0625, upper: 0.125, color: '#92D050' },
      { value: 'Medium', lower: 0.125, upper: 0.25, color: '#E1E100' },
      { value: 'High', lower: 0.25, upper: 1.5, color: '#E10000' },
    ],
    RiskColumnText: 'Attack feasibility',
    RiskRowText: 'Impact',
    ColorArray: [
      [
        LikelihoodColors[0],
        LikelihoodColors[0],
        LikelihoodColors[0],
        LikelihoodColors[2],
      ],
      [
        LikelihoodColors[2],
        LikelihoodColors[2],
        LikelihoodColors[2],
        LikelihoodColors[4],
      ],
      [
        LikelihoodColors[2],
        LikelihoodColors[4],
        LikelihoodColors[4],
        LikelihoodColors[6],
      ],
      [
        LikelihoodColors[2],
        LikelihoodColors[4],
        LikelihoodColors[6],
        LikelihoodColors[8],
      ],
    ],
    ColorArrayMapping: [
      { color: LikelihoodColors[0], legend: '1' },
      { color: LikelihoodColors[2], legend: '2' },
      { color: LikelihoodColors[4], legend: '3' },
      { color: LikelihoodColors[6], legend: '4' },
      { color: LikelihoodColors[8], legend: '5' },
    ],
  },
  ISO_27000_series: {
    Name: 'ISO 27000 series',
    Value: 'ISO_27000_series',
    ThreatType: 'Threat',
    ThreatSecurityType: 'Security Property',
    VulnerabilityType: 'Vulnerability',
    ImpactLevelTypes: {
      0: 'Very Low',
      1: 'Low',
      2: 'Medium',
      3: 'High',
      4: 'Very High',
    },
    ElapsedTime: {
      0: '<= 1 day',
      1: '<= 1 week',
      2: '<= 2 weeks',
      4: '<= 1 month',
      7: '<= 2 months',
      10: '<= 3 months',
      13: '<= 4 months',
      15: '<= 5 months',
      17: '<= 6 months',
      19: '> 6 months',
    },
    ComponentKnowledge: {
      0: 'Public',
      3: 'Restricted',
      7: 'Sensitive',
      11: 'Critical',
    },
    OpportunityWindow: {
      0: 'Unnecessary / unlimited access',
      1: 'Easy',
      4: 'Moderate',
      11: 'Difficult',
      100: 'None',
    },
    Equipment: {
      0: 'Standard',
      4: 'Specialized',
      7: 'Bespoke',
      9: 'Multiple bespoke',
    },
    SpecialistExpertise: {
      0: 'Layman',
      3: 'Proficient',
      6: 'Expert',
      8: 'Multiple experts',
    },
    AttackFeasibility: [
      { value: 'Very low', lower: 0, upper: 0.0625, color: '#00B050' },
      { value: 'Low', lower: 0.0625, upper: 0.125, color: '#92D050' },
      { value: 'Medium', lower: 0.125, upper: 0.25, color: '#E1E100' },
      { value: 'High', lower: 0.25, upper: 0.5, color: '#E1C000' },
      { value: 'Very High', lower: 0.5, upper: 1.5, color: '#E10000' },
    ],
    RiskColumnText: 'Likelihood of incident scenario',
    RiskRowText: 'Business Impact',
    ColorArray: [
      [
        LikelihoodColors[0],
        LikelihoodColors[1],
        LikelihoodColors[2],
        LikelihoodColors[3],
        LikelihoodColors[4],
      ],
      [
        LikelihoodColors[1],
        LikelihoodColors[2],
        LikelihoodColors[3],
        LikelihoodColors[4],
        LikelihoodColors[5],
      ],
      [
        LikelihoodColors[2],
        LikelihoodColors[3],
        LikelihoodColors[4],
        LikelihoodColors[5],
        LikelihoodColors[6],
      ],
      [
        LikelihoodColors[3],
        LikelihoodColors[4],
        LikelihoodColors[5],
        LikelihoodColors[6],
        LikelihoodColors[7],
      ],
      [
        LikelihoodColors[4],
        LikelihoodColors[5],
        LikelihoodColors[6],
        LikelihoodColors[7],
        LikelihoodColors[8],
      ],
    ],
    ColorArrayMapping: [
      { color: LikelihoodColors[0], legend: '0' },
      { color: LikelihoodColors[1], legend: '1' },
      { color: LikelihoodColors[2], legend: '2' },
      { color: LikelihoodColors[3], legend: '3' },
      { color: LikelihoodColors[4], legend: '4' },
      { color: LikelihoodColors[5], legend: '5' },
      { color: LikelihoodColors[6], legend: '6' },
      { color: LikelihoodColors[7], legend: '7' },
      { color: LikelihoodColors[8], legend: '8' },
    ],
  },
};

export const ImpactCategory = {
  safety: { name: 'Safety', value: 'safety' },
  financial: { name: 'Financial', value: 'financial' },
  operational: { name: 'Operational', value: 'operational' },
  privacy: { name: 'Privacy', value: 'privacy' },
};

export const SecurityGoal = {
  confidentiality: { name: 'Confidentiality', value: 'confidentiality' },
  integrity: { name: 'Integrity', value: 'integrity' },
  availability: { name: 'Availability', value: 'availability' },
  authorization: { name: 'Authorization', value: 'authorization' },
  authenticity: { name: 'Authenticity', value: 'authenticity' },
  storage_limitation: {
    name: 'Storage Limitation',
    value: 'storage_limitation',
  },
  transparency: { name: 'Transparency', value: 'transparency' },
  purpose_limitation: {
    name: 'Purpose Limitation',
    value: 'purpose_limitation',
  },
  data_minimization: { name: 'Data Minimization', value: 'data_minimization' },
  accuracy: { name: 'Accuracy', value: 'accuracy' },
};

export const SearchType = {
  Id: 'Id',
  Name: 'Name',
  ReviewStatus: 'Review Status',
  Likelihood: 'Likelihood',
  Risk: 'Risk',
  Impact: 'Impact',
  SecurityGoal: 'Security Property',
  ImpactCategory: 'Impact Category',
  Exclamation: 'Red Exclamation Mark',
};

export const ReviewState = {
  Reviewed: 'Reviewed',
  Unreviewed: 'Unreviewed',
};

export const CopyMoveType = {
  Copy: 'copy',
  Move: 'move',
};

export const CommentStatus = {
  New: 'new',
  Edited: 'edited',
  Deleted: 'deleted',
};

export const LikelihoodMethod = {
  Automatically: 'Calculated Automatically',
  Category: 'Category Based',
};

export const LikelihoodEstimation = {
  'Calculated Automatically': 'automatic',
  Manually: 'manual',
  'Category Based': 'category',
};

export const CatalogLocation = {
  All: 'all',
  Global: 'global',
  Project: 'project',
};

export const ControlState = {
  implemented: 'proposed_state',
  proposed: 'implemented_state',
};

export const RiskType = {
  noControls: 'no_controls',
  implemented: 'implemented_controls',
  proposed: 'proposed_controls',
  allControls: 'all_controls',
};

export const ControlLikelihoodType = {
  no_controls: 'no_control_likelihood',
  implemented_controls: 'implemented_control_likelihood',
  proposed_controls: 'proposed_control_likelihood',
  all_controls: 'all_control_likelihood',
};

export const CatalogType = {
  Control: 'control',
  ReferenceTree: 'reftree',
};

export const ProjectSpecifc = {
  Project_Specific: 'project_specific',
};

export const ExportType = {
  ANALYSIS_TREE: { value: 'ANALYSIS_TREE', label: 'Analysis Tree' },
  // ENTITIES: { value: 'ENTITIES', label: 'Entities' }, //TODO: re-enable once backend implementation is done. Frontend is finished.
  //SECURITY_CONCEPT: { value: 'security_concept', label: 'Security Concept' },
};

export const LikelihoodType = {
  NO_CONTROLS: { value: 'NO_CONTROLS', label: 'No Control' },
  IMPLEMENTED_CONTROLS: {
    value: 'IMPLEMENTED_CONTROLS',
    label: 'Implemented Control',
  },
  PROPOSED_CONTROLS: { value: 'PROPOSED_CONTROLS', label: 'Proposed Control' },
  ALL_CONTROLS: { value: 'ALL_CONTROLS', label: 'All Control' },
};
export const OwnerType = {
  RefTree: 'reftree',
  Project: 'project',
};

//Reference tree modification type
//Used for queuing actions after accepting warning
export const RefTreeModType = {
  Addition: 'Addition', //Adding to a ref tree node
  Cut: 'Cut',
  Pasting: 'Paste',
  Deletion: 'Delete', //This is only for deleting nodes that are part of a ref tree
  Editing: 'Edit',
  Transfer: 'Transfer',
};

export const RefTreeActionType = {
  Edit: 'edit',
  NewSubtree: 'newSubtree',
  NewReftree: 'newReftree',
};

export const LogType = {
  Admin: 'Admin',
  Project: 'Project',
  Catalog: 'Catalog',
};

export const NewsAction = {
  APPLY: 'apply',
  IGNORE: 'ignore',
};

export const JSONtheme = {
  base00: '#000000',
  base01: '#ffffff',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

export const RiskViewType = {
  All: 'All',
  Highest: 'Highest',
};

export const RiskDecision = {
  avoiding: 'Avoiding',
  reducing: 'Reducing',
  sharing: 'Sharing',
  retaining: 'Retaining'
}
