import axios from '../../Axios/axios';
import settings from '../reducers/settings';
import { INITIALIZE_SETTINGS, INSERT_SETTINGS, GET_SETTINGS } from './types';

export const initializeSettings = () => {
  return function (dispatch) {
    axios.settings.post().then((response) => {
      dispatch({
        type: INITIALIZE_SETTINGS,
        payload: response.data.msg,
      });
    });
  };
};

export const insertSettings = (payload) => {
  return function (dispatch) {
    axios.settings.put(payload).then((response) => {
      dispatch({
        type: INSERT_SETTINGS,
        payload: response.data.msg,
      });
    });
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    try {
      const response = await axios.settings.get(); 
      const { data } = response;

      const env_var = {
        isat_uri: data.ISAT_URI,
        mail_server: data.MAIL_SERVER,
        mail_port: data.MAIL_PORT,
        mail_use_tls: data.MAIL_USE_TLS,
        mail_username: data.MAIL_USERNAME,
        mail_password: data.MAIL_PASSWORD,
        mail_default_sender: data.MAIL_DEFAULT_SENDER,
        ldap_server: data.LDAP_SERVER,
        ldap_base: data.LDAP_BASE,
        ldap_bind_username: data.LDAP_BIND_USERNAME,
        ldap_bind_password: data.LDAP_BIND_PASSWORD,
        ldap_user_filter: data.LDAP_USER_FILTER,
        ldap_name_attribute: data.LDAP_NAME_ATTRIBUTE,
      };
      
      const db_var = {
        isat_uri: data.isat_uri,
        mail_server: data.mail_server,
        mail_port: data.mail_port,
        mail_use_tls: data.mail_use_tls,
        mail_username: data.mail_username,
        mail_password: data.mail_password,
        mail_default_sender: data.mail_default_sender,
        ldap_server: data.ldap_server,
        ldap_base: data.ldap_base,
        ldap_bind_username: data.ldap_bind_username,
        ldap_bind_password: data.ldap_bind_password,
        ldap_user_filter: data.ldap_user_filter,
        ldap_name_attribute: data.ldap_name_attribute,
      };
      
      const env = Object.values(env_var).some(value => value !== "");

      dispatch({
        type: GET_SETTINGS,
        payload: { settings : env ? env_var : db_var , env: env  },
      });
    } catch (error) {
      console.error('Error fetching settings:', error);
      
    }
  };
};
