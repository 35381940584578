import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';

const Item = ({
  id,
  title,
  body,
  answerList,
  link,
  isSearching = false,
  isOpenBySearch = false,
}) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  const [isOpen, setIsOpen] = useState(true);
  const [isToggled, setIsToggled] = useState(true);

  var _isSearching = isSearching;
  const toggle = () => {
    setIsOpen(!isOpen);
    setIsToggled(true);
    _isSearching = false;
  };

  return (
    <Card>
      <CardHeader style={{ cursor: 'pointer' }} id={'searchToggler' + id}>
        <Typography
          variant='h5'
          gutterBottom
          dangerouslySetInnerHTML={createMarkup(title)}
          onClick={toggle}
        />
      </CardHeader>
      <Collapse
        isOpen={isToggled ? isOpen : _isSearching ? isOpenBySearch : false}
        toggler={'#searchToggler' + id}
      >
        <CardBody>
          <ul>
            <li key={0}>
              <Row>
                <Col xs={'auto'}>
                  <Typography dangerouslySetInnerHTML={createMarkup(body)} />
                </Col>
                <Col className={'float-left'}>{link}</Col>
              </Row>
            </li>
            {answerList?.map((bullet, i) => {
              return (
                <Row>
                  <Col xs={'auto'}>
                    <li key={i + 1}>
                      <Typography
                        dangerouslySetInnerHTML={createMarkup(bullet.answer)}
                      />
                      {bullet?.children?.length > 0 && (
                        <ul style={{ paddingLeft: 20 }}>
                          {bullet?.children.map((child, index) => {
                            return (
                              <li key={index}>
                                <Row>
                                  <Col xs={'auto'}>
                                    <Typography
                                      dangerouslySetInnerHTML={createMarkup(
                                        child.answer
                                      )}
                                    />
                                  </Col>
                                  <Col className={'float-left'}>
                                    {child?.link}
                                  </Col>
                                </Row>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  </Col>
                  <Col className={'float-left'}>{bullet?.link}</Col>
                </Row>
              );
            })}
          </ul>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default Item;
