// Asset list main page
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setProject } from '../../../redux/actions/projects';
import ThreatScenarioTable from './ThreatScenarioTable';
//view for threat scenario
const ThreatScenarios = ({
  threats,
  vulnerabilities,
  loading,
  project,
  setProject,
  profile,
}) => {
  const [data, setData] = useState([]);
  // quick fix on threat scenario not showing after creating in tree
  useEffect(() => {
    var url = window.location.pathname;
    var splitUrl = url.split('/');
    var projectId = splitUrl[1];
    if (projectId !== undefined) {
      const projectTemplate = { _id: projectId };
      setProject(projectTemplate);
    }
  }, [])

  useEffect(() => {
    let data = [];
    for (const threat of threats) {
      data = data.concat(
        vulnerabilities.filter((vul) =>
          threat?.children.some(
            (child) => parseInt(child) === parseInt(vul._id)
          )
        )
      );
    }
    setData(data);
  }, [threats, vulnerabilities]);

  useEffect(() => {
    if (profile !== undefined) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);
  return (
    <div style={{ backfaceVisibility: 'visible', background: 'white' }}>
      <ThreatScenarioTable vulnerabilities={data} />{' '}
    </div>
  );
};

const mapStateToProps = (state) => ({
  threats: state.threats.threats,
  vulnerabilities: state.vulnerabilities.vulnerabilities,
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, { setProject })(ThreatScenarios);
