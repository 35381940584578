import { default as React, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import {
  ProjectManagement,
  SafetyManagement,
  SoftwareEngineering,
  SystemsEngineering,
} from '../../../constants/URLConstants';

/**
 * Project List Page.
 *
 * Main page for the list of projects,
 * as well as importing, exporting, and adding of projects.
 *
 * @param getProjects: an action from redux that retrieves the list of projects
 * @param setProject: selects the project to be edited in the attack tree page
 * @param deleteProject: an action from redux that allows the user to delete the project permanently
 * @param projects: serves as a state for each project
 * @param project: serves as a state for selected project
 *
 */
const RelatedSupportPanel = ({ toggle, openPanel }) => {
  //Declares and displays the project list
  useEffect(() => {}, []);

  return (
    <Container fluid='md'>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={toggle}
        className='bold'
      >
        {' '}
        {openPanel ? (
          <i className='fa fa-caret-down' />
        ) : (
          <i className='fa fa-caret-right' />
        )}{' '}
        {'  '}Support for related topics
      </CardHeader>
      <Collapse isOpen={openPanel}>
        <Card>
          <CardBody>
            <ListGroup>
              <ListGroupItem
                tag='button'
                onClick={() => {
                  window.open(SystemsEngineering);
                }}
                action
              >
                <ListGroupItemHeading>Systems Engineering</ListGroupItemHeading>
                <ListGroupItemText>
                  Requirements Management (for requirements determination after
                  TARA)
                  <br />
                  Test Management (for creating test cases and even performing
                  tests of implemented controls)
                  <br />
                  System Design (for creating architecture; Templates,
                  Guidelines)
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem
                tag='button'
                onClick={() => {
                  window.open(SoftwareEngineering);
                }}
                action
              >
                <ListGroupItemHeading>
                  Software Engineering
                </ListGroupItemHeading>
                <ListGroupItemText>
                  INVOLUTION (for creating safe and secure software even faster)
                  <br />
                  Architecture for Legacy Code (for creating software
                  architecture of previous software)
                  <br />
                  Continuous Integration (for creating safe and secure software
                  faster)
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem
                tag='button'
                onClick={() => {
                  window.open(SafetyManagement);
                }}
                action
              >
                <ListGroupItemHeading>Safety Management</ListGroupItemHeading>
                <ListGroupItemText>
                  Functional Safety Management (for creating HARA)
                  <br />
                  System Safety Development (for supporting to create HARA)
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem
                tag='button'
                onClick={() => {
                  window.open(ProjectManagement);
                }}
                action
              >
                <ListGroupItemHeading>Project Management</ListGroupItemHeading>
                <ListGroupItemText>
                  Project Management (for creating further project plan after
                  (already during) TARA process)
                  <br />
                  Resource management (for accelerating development process)
                  <br />
                  Agile Product Development (for software development)
                </ListGroupItemText>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(RelatedSupportPanel));
