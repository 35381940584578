import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Label, Row } from 'reactstrap';
import { btnEdit } from '../../../constants/Style';
import { updateProject } from '../../../redux/actions/projects';
import {
  cancelUnsavedChanges,
  unsavedChanges,
} from '../../../redux/actions/save';
import store from '../../../redux/store';
import TypeSelect from '../project-components/TypeSelect';
/**
 * Project Type Panel
 *
 * Panel for displaying and editing the project type
 *
 * @param data: parameter for the specific project in the project details page
 * @param updateProject: redux action to update a project
 * @param profile: fetches profile of the current user in the system
 */
const ProjectTypePanel = ({
  data,
  updateProject,
  profile,
  save,
  unsavedChanges,
}) => {
  const [editMode, setEditMode] = useState(false);
  const changeMode = () => {
    if (editMode) {
      setFormData({
        ...formData,
        project_type: data?.project_type,
      });
    }
    setEditMode(!editMode);
  };
  const [formData, setFormData] = useState({
    project_type: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      project_type: data?.project_type,
    });
  }, [data]);
  const [fieldId, setFieldId] = useState(null);

  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (!fieldId) {
      let id = unsavedChanges();
      setFieldId(id);
    }
  };

  const { dispatch } = store;
  const onSubmit = async () => {
    updateProject(data._id, formData);
    dispatch(cancelUnsavedChanges(fieldId));
    setFieldId(null);
  };
  useEffect(() => {
    if (save && editMode) {
      updateProject(data._id, formData);
      changeMode();
      setFieldId(null);
    }
  }, [save]);

  return (
    <div>
      {editMode ? (
        <div>
          <Row>
            <div className='project-info-edit'>
              <div className='bold'>Type</div>
              <TypeSelect data={formData.project_type} onChange={onChange} />
            </div>
            <br />
            <br />
            <div style={{ marginLeft: '-180px', marginTop: '95px' }}>
              <td className='no-border'>
                <Button
                  className='btn-add'
                  onClick={() => {
                    onSubmit();
                    changeMode();
                  }}
                >
                  Save
                </Button>
              </td>
              <td className='no-border'>
                <Button
                  className='btn-danger'
                  onClick={() => {
                    changeMode();
                    dispatch(cancelUnsavedChanges(fieldId));
                    setFieldId(null);
                  }}
                >
                  Cancel
                </Button>
              </td>
            </div>
          </Row>
        </div>
      ) : (
        <div className='project-details-label'>
          {data?.baseline === undefined ? (
            data?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('owner') ||
            data?.participants
              ?.find(
                (member) => parseInt(member.user_id) === parseInt(profile?.id)
              )
              ?.role.includes('editor') ? (
              <Button
                className='float-right'
                onClick={() => changeMode()}
                style={btnEdit}
              >
                <i className='fa fa-edit' />
              </Button>
            ) : null
          ) : null}
          <div className='bold'>Type</div>
          <Label>
            <h4 className='project-detail-td'>{data?.project_type}</h4>
          </Label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

export default connect(mapStateToProps, { updateProject, unsavedChanges })(
  ProjectTypePanel
);
