import React, { Fragment } from 'react';
import Row from 'reactstrap/lib/Row';
import { RiskType } from '../../../../constants/Enums';
import {
  GenerateNodeId,
  maxFraction,
} from '../../../../constants/StringConstants';
import { getRisk } from '../../../helpers/AnalysisTreeHelper';
import { capitalizeStart } from '../../../helpers/genericHelper';
import RiskTooltips from './RiskTooltips';

/**
 * Risk tooltip
 *
 * A tooltip component for risks
 *
 *  @param riskObject the risk object of the associated threat (scenario)
 *  @param identifier used to identify the unique id for the tooltip
 *
 */
const RiskDisplay = (nodeData, impactViews, category, riskType) => {
  return (
    //Checks if category impact matches selected impact views
    impactViews.includes(nodeData.impact?.[category]?.toString()) && (
      <Fragment>
        <Row>
          <div
            className='node-table-data'
            id={`RiskTooltip-${GenerateNodeId(nodeData._id)}${
              RiskType.noControls
            }`}
          >
            {capitalizeStart(category)}{' '}
            {riskType === RiskType.allControls && 'all'}
            {riskType === RiskType.proposed && 'proposed'}
            {riskType === RiskType.implemented && 'implemented'}
            {riskType === RiskType.noControls && 'no'} control risk:{' '}
            {maxFraction(
              getRisk(nodeData, impactViews, RiskType.noControls, category)
            )}
          </div>
          <RiskTooltips
            riskObject={nodeData.risk}
            identifier={`${GenerateNodeId(nodeData._id)}${RiskType.noControls}`}
          />
        </Row>
      </Fragment>
    )
  );
};

export default RiskDisplay;
