import {
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  OwnerType,
  ReviewState,
  SearchType,
} from '../../constants/Enums';
import { setEntityUsage } from './genericHelper';

/**
 * Control node.
 *
 * Exchanges the integer id values of children for the actual children object, and repeats for every child's children.
 *
 *  @param nodes A group of nodes, initially assets, that reiterates with its children
 *  @param entityArray Hashmap of all the elements of the attack tree, retrieved from the backend
 *  @returns Returns a filled tree of objects
 *
 */
export const setChildren = (
  nodes,
  entityArray,
  countIndex = false,
  entitiesList = undefined,
  collapseThreatChildren = false
) => {
  let updatedNodes = [];
  //Ids will be replaced by the object entity in the array
  //Checks if array contains object
  if (typeof nodes[0] === 'number') {
    nodes.map((node) => {
      //If countIndex is enabled, adds index counts for entities(and instances of ref tree nodes)
      if (countIndex) {
        if (entityArray[node]?.index === undefined) {
          entityArray[node].index = 1;
        } else {
          entityArray[node].index++;
        }
      }
      let listEntity = undefined;
      listEntity = entitiesList?.find(
        (entity) => entity?._id?.toString() === node.toString()
      );

      if (entityArray[node] !== undefined || listEntity !== undefined) {
        updatedNodes.push(
          Object.assign(
            {},
            {
              ...(entityArray[node] ?? listEntity),
              _collapsed:
                entityArray[node]?.entity_type === EntityType.threat
                  ? true
                  : false,
            }
          )
        );
      } else if (
        Array.isArray(entityArray) &&
        entityArray.find((entity) => entity._id === node) !== undefined
      ) {
        updatedNodes.push(
          Object.assign(
            {},
            {
              ...entityArray.find((entity) => entity._id === node),
              _collapsed:
                entityArray[node]?.entity_type === EntityType.threat
                  ? true
                  : false,
            }
          )
        );
      }
      return node;
    });
  } else {
    updatedNodes = nodes;
  }

  updatedNodes.map((child) => {
    if (Array.isArray(child?.children) && child?.children.length) {
      child.children = setChildren(
        child.children,
        entityArray,
        countIndex,
        entitiesList
      );
    }
    return child;
  });
  return updatedNodes;
};

//Exchanges the actual object values of children in an entity to ID values.
//Used when moving data from attack tree to store.
export const unsetChildren = (entity) => {
  const childrenIds = [];
  if (entity.children !== undefined) {
    entity.children.map((child) => {
      childrenIds.push(child._id);
      return child;
    });
    entity.children = childrenIds;
  }

  return entity;
};

//Returns tree, updates values of node but retains children
//All properties except for children can be changed.
export const updateEntityValueInTree = (analysisTree, entity, entityType) => {
  if (analysisTree?.children === undefined) {
    return analysisTree;
  }
  if (analysisTree?.children?.length > 0) {
    analysisTree.children.map((child, index) => {
      if (child._id === entity?._id) {
        entity.children = child?.children;
        analysisTree.children[index] = { ...child, ...entity };
      }

      updateEntityValueInTree(child, entity, entityType);
      return child;
    });
  }

  return analysisTree;
};

export const deleteEntityInTree = (
  analysistree,
  entity,
  parent = undefined
) => {
  if (analysistree.children === undefined) {
    return analysistree;
  }
  analysistree.children.map((child, index) => {
    //If id and child matches ids, remove and return analysistree
    if (
      child._id === entity._id &&
      (parent === undefined || parent === analysistree._id)
    ) {
      analysistree.children.splice(index, 1);
      return analysistree;
    }
    deleteEntityInTree(child, entity, parent);
    return child;
  });

  return analysistree;
};

//Returns tree, updates children values of node
//Primarily used by Cut/Copy/Pasts attack tree functions
export const updateNodeChildrenInTree = (analysistree, entity) => {
  if (analysistree._id === entity._id) {
    analysistree.children = [...analysistree.children, ...entity.children];
    return analysistree;
  }

  if (analysistree.children === undefined) {
    return analysistree;
  }

  analysistree.children.map((child) => {
    return updateNodeChildrenInTree(child, entity);
  });

  return analysistree;
};

//Returns tree, inserts new entity added based on parent
export const addEntityInTree = (analysistree, entity, parent) => {
  //Checks current node if it is the parent of the entity
  if (analysistree?._id === parent._id) {
    if (analysistree?.children !== undefined) {
      analysistree.children.push(entity);
    } else {
      analysistree.children = [entity];
    }
    return undefined;
  }
  //Checks if nodes have any children
  if (analysistree?.children === undefined) {
    return undefined;
  }

  //Goes through children recursively to find parent
  analysistree.children.map((child) => {
    addEntityInTree(child, entity, parent);
    return child;
  });
  return undefined;
};

//Filters a node, using search object containing criterias of payloads and search types
export const filterCheck = (nodeData, searchObject) => {
  let filtered = false;
  for (const search of searchObject) {
    switch (search.searchType) {
      case SearchType.Name:
        filtered = nodeData?.name
          ?.toLowerCase()
          .includes(search.payload.toLowerCase());
        break;
      case SearchType.Id:
        filtered = nodeData?._id === parseInt(search.payload);
        break;
      case SearchType.ImpactCategory:
        filtered = nodeData?.impact_category
          ?.toLowerCase()
          .includes(search.payload.toLowerCase());
        break;
      case SearchType.ReviewStatus:
        const reviewed = nodeData?.review_state;
        if (reviewed) {
          filtered = search.payload === ReviewState.Reviewed;
        } else {
          filtered = search.payload === ReviewState.Unreviewed;
        }
        break;
      case SearchType.SecurityGoal:
        filtered = nodeData?.security_goal
          ?.toLowerCase()
          .includes(search.payload.toLowerCase());
        break;
      case SearchType.Risk:
        filtered = riskCheck(nodeData, search.payload);
        break;
      case SearchType.Impact:
        const upperImpact = nodeData?.impact_level < search.payload?.upper;
        const lowerImpact = nodeData?.impact_level > search.payload?.lower;
        filtered = checkFilteredBounds(
          search.payload,
          upperImpact,
          lowerImpact
        );
        break;
      case SearchType.Likelihood:
        filtered = likelihoodCheck(nodeData, search.payload);
        break;
      case SearchType.Exclamation:
        if (nodeData.entity_type === EntityType.threat) {
          filtered =
            nodeData?.impact === undefined ||
            nodeData?.security_goal === undefined;
        } else if (nodeData.entity_type === EntityType.vulnerability) {
          if (
            nodeData?.likelihood_estimation_method ===
              LikelihoodEstimation[LikelihoodMethod.Automatically] &&
            nodeData.children?.find(
              (child) => child.entity_type === EntityType.control
            ) !== undefined
          ) {
            filtered = true;
          }
        }
        break;
      default:
        break;
    }
    if (filtered) {
      break;
    }
  }
  return filtered;
};

const riskCheck = (nodeData, payload) => {
  const noControl = isWithinBounds(payload, nodeData?.no_control_risk);
  const implementedControl = isWithinBounds(
    payload,
    nodeData?.implemented_control_risk
  );
  const proposedControl = isWithinBounds(
    payload,
    nodeData?.proposed_control_risk
  );

  const allControl = isWithinBounds(payload, nodeData?.all_control_risk);

  return noControl || implementedControl || proposedControl || allControl;
};

const likelihoodCheck = (nodeData, payload) => {
  if (nodeData.entity_type === EntityType.vulnerability) {
    return isWithinBounds(payload, nodeData?.likelihood);
  } else if (
    nodeData.entity_type === EntityType.threat ||
    nodeData.entity_type === EntityType.vulnerability
  ) {
    const noControl = isWithinBounds(payload, nodeData?.no_control_likelihood);
    const implementedControl = isWithinBounds(
      payload,
      nodeData?.implemented_control_likelihood
    );
    const proposedControl = isWithinBounds(
      payload,
      nodeData?.proposed_control_likelihood
    );
    const allControl = isWithinBounds(
      payload,
      nodeData?.all_control_likelihood
    );
    return noControl || implementedControl || proposedControl || allControl;
  }
  return undefined;
};
export const isWithinBounds = (payload, value) =>
  payload?.upper !== undefined && payload?.lower !== undefined
    ? payload.upper > value && payload.lower < value
    : payload?.upper === undefined
    ? payload.lower < value
    : payload?.lower === undefined && payload.upper > value;

const checkFilteredBounds = (payload, upper, lower) => {
  if (payload?.upper !== undefined && payload?.lower !== undefined) {
    return upper && lower;
  } else if (payload?.upper === undefined) {
    return lower;
  } else if (payload?.lower === undefined) {
    return upper;
  }
  return undefined;
};

//Expands an entity tree from its starting points to all its children
export const expandTree = (
  treeData,
  showReferenceTrees = undefined,
  fullyExpand = false
) => {
  if (fullyExpand && treeData._collapsed) {
    return;
  }
  treeData._collapsed = false;

  if (
    showReferenceTrees !== undefined &&
    treeData?.owner?.owner_type === OwnerType.RefTree
  ) {
    if (showReferenceTrees) {
      if (treeData?._children?.length > 0) {
        treeData._children.map((child) => {
          expandTree(child);
          return child;
        });
      }
    } else {
      treeData._collapsed = true;
    }
  } else if (treeData?._children?.length > 0) {
    treeData._children.map((child) => {
      expandTree(child, showReferenceTrees, fullyExpand);
      return child;
    });
  }
};

export const collapseTree = (treeData) => {
  treeData._collapsed = true;
  if (treeData?._children?.length > 0) {
    treeData._children.map((child) => {
      collapseTree(child);
      return child;
    });
  }
};

//Goes through tree, and collects IDs of all nodes that match search criterias
export const getFilteredIdsFromTree = (searchObject, treeData, matchedIds) => {
  if (treeData?._id !== undefined) {
    if (filterCheck(treeData, searchObject)) {
      matchedIds.push(treeData._id);
    }
  }
  treeData._collapsed = false;
  if (treeData?._children?.length > 0) {
    treeData._children.map((child) => {
      getFilteredIdsFromTree(searchObject, child, matchedIds);
      return child;
    });
  }
};

//Goes through tree, applies collapsed values based on matching search IDs, and if children should be collapsed
export const getFilteredTree = (
  treeData,
  matchedIds,
  showChildren,
  showReferenceTrees
) => {
  let matchFound = false;

  if (matchedIds.includes(treeData._id)) {
    matchFound = true;
  }

  if (matchFound && treeData?._children?.length > 0 && showChildren) {
    treeData._children.map((child) => {
      expandTree(child, showReferenceTrees);
      return child;
    });
    return true;
  } else if (treeData?._children?.length > 0) {
    treeData._children.map((child) => {
      const childMatched = getFilteredTree(child, matchedIds, showChildren);

      if (childMatched) {
        matchFound = true;
      }
      return child;
    });
  }

  treeData._collapsed = !matchFound;
  return matchFound;
};

export const SetTreeLayout = (treeData, treeLayout) => {
  //Checks first node, project nodes don't have IDs
  if (treeData === undefined) {
    return;
  }
  if (treeData?._id !== undefined && treeData?._collapsed !== undefined) {
    treeData._collapsed =
      treeLayout?._collapsed !== undefined ? treeLayout?._collapsed : false;
  }
  if (treeData?.children?.length > 0) {
    treeData.children.map((child, index) => {
      if (treeLayout?.children !== undefined) {
        SetTreeLayout(child, treeLayout.children[index]);
      }
      return child;
    });
  }
};

//Gets node coordinates of a node based provided Id, updates coordinatesObject when found
export const GetNodeCoordinates = (
  Node,
  searchId,
  coordinatesObject,
  usage = undefined
) => {
  if (Node._children === undefined) {
    return;
  }
  Node._children.map((child) => {
    if (parseInt(child._id) === parseInt(searchId)) {
      if (usage === undefined || usage === child.index) {
        coordinatesObject.x = child.x;
        coordinatesObject.y = child.y;
        return child;
      }
    }
    GetNodeCoordinates(child, searchId, coordinatesObject, usage);
    return child;
  });
};

//Clears node params for data updates(reduxs store/api calls)
export const clearNodeParams = (nodeData) => {
  delete nodeData['_children'];
  delete nodeData['_collapsed'];
  delete nodeData['id'];
  delete nodeData['x'];
  delete nodeData['y'];
  delete nodeData['parent'];
  delete nodeData['depth'];
};

export const clearTreeParams = (Node) => {
  clearNodeParams(Node);
  if (Node?.children?.length > 0) {
    Node.children.map((child) => {
      clearTreeParams(child);
      return child;
    });
  }
};

export const RemoveNodeFromTree = (subtreeId, analysisTree) => {
  if (analysisTree?.children?.length > 0) {
    analysisTree.children.map((child, index) => {
      if (child._id === subtreeId) {
        analysisTree.children.splice(index, 1);
      }
      RemoveNodeFromTree(subtreeId, child);
      return child;
    });
  }
};

export const InsertNodeToTree = (analysisTree, subtree, parentId) => {
  if (analysisTree?.children?.length > 0) {
    analysisTree.children.map((child) => {
      if (child._id === parentId) {
        if (child?.children === undefined) {
          child.children = [];
        }
        child.children.push(subtree);
      }
      InsertNodeToTree(child, subtree, parentId);
      return child;
    });
  }
};

export const ChangeTreeRoot = (analysistree, rootId) => {
  if (parseInt(analysistree?._id) === parseInt(rootId)) {
    return analysistree;
  } else {
    let match = null;
    if (analysistree?.children?.length > 0) {
      for (const child of analysistree.children) {
        match = ChangeTreeRoot(child, rootId);
        if (match !== null) {
          break;
        }
      }
    }

    return match;
  }
};

//Gets node indexes from top of tree to starting child id provided
//returns indexes of node in an array, from top to bottom
//enabling refTreeOnly searches only until last reftree owned node
//and also returns the project entity id, and reftree root id(used for modification actions)
export const GetNodeIndexes = (child, refTreeOnly = false, indexes = []) => {
  if (child?.parent?.owner?.owner_type === OwnerType.Project && refTreeOnly) {
    return {
      projectEntityId: child.parent._id,
      refId: child._id,
      indexes: indexes,
    };
  }
  if (child?.parent === undefined) {
    return indexes;
  }

  const index = child.parent.children.findIndex(
    (parentChild) => parentChild._id === child._id || parentChild === child._id
  );
  indexes = [index, ...indexes];

  if (child?.parent !== undefined) {
    return GetNodeIndexes(child.parent, refTreeOnly, indexes);
  }

  return indexes;
};

export const GetNodeWithIndexes = (root, indexes, depth = 0) => {
  if (indexes.length === 0) {
    return root;
  }
  if (indexes.length === depth + 1) {
    return root.children[indexes[depth]];
  }
  return GetNodeWithIndexes(root.children[indexes[depth]], indexes, depth + 1);
};

export const getRisk = (nodeData, impactViews, riskType, category) => {
  if (impactViews.includes(nodeData.impact[category].toString())) {
    return nodeData.risk?.[category]?.[riskType];
  } else {
    return undefined;
  }
};

export const idMapping = (
  nodeStartID,
  mappedIDs,
  analysisTree,
  replace = false
) => {
  if (analysisTree?._id === nodeStartID) {
    replace = true;
  }

  if (analysisTree?.children?.length > 0) {
    analysisTree.children.map((child, index) => {
      if (
        Object.keys(mappedIDs).includes(child._id.toString()) &&
        replace === true
      ) {
        child._id = mappedIDs[child._id];
      }

      idMapping(nodeStartID, mappedIDs, child, replace);
      return child;
    });
  }
};

export const UpdateReferenceTreeFromRoot = (
  analysisTree,
  rootId,
  reftreeId,
  replace = false
) => {
  if (analysisTree?.children.find((child) => child._id === rootId)) {
    if (analysisTree?.reftree_placement_review_state === undefined) {
      analysisTree.reftree_placement_review_state = [
        { review_state: false, starting_node_id: rootId },
      ];
    } else {
      analysisTree.reftree_placement_review_state.push({
        review_state: false,
        starting_node_id: rootId,
      });
    }
  }

  if (analysisTree?._id === rootId) {
    replace = true;
    analysisTree.owner.owner_type = 'reftree';
    //New usage, as these are used for newly created reftrees
    analysisTree.owner.usage = setEntityUsage('R', 1);
    analysisTree.owner.id = reftreeId;
  }

  if (analysisTree?.children?.length > 0) {
    analysisTree.children.map((child) => {
      if (replace && child?.owner?.owner_type !== 'reftree') {
        child.owner.owner_type = 'reftree';
        //New usage, as these are used for newly created reftrees
        child.owner.usage = setEntityUsage('R', 1);
        child.owner.id = reftreeId;
      }
      UpdateReferenceTreeFromRoot(child, rootId, reftreeId, replace);
      return child;
    });
  }
};
