import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap';
import {
  GenerateNodeId,
  maxFraction,
} from '../../../constants/StringConstants';
import { setControlCheckbox } from '../../../redux/actions/analysistree';

/**
 * Control node.
 *
 * A type of node for the attack tree, represents controls.
 *
 *  @param nodeData the node of the control
 *  @param toggleReview redux action to toggle review
 *
 */
const ControlNode = ({
  nodeData,
  projectRole,
  setControlCheckbox,
  readOnly,
}) => {
  const nodeName = nodeData?.name;
  const likelihood = maxFraction(nodeData?.likelihood);

  return (
    <Fragment>
      <Row>
        <div className='node-id'>{nodeData?._id}</div>
        <div style={{ width: '5px' }}></div>
        <div
          className='node-name'
          id={`controlDescription${
            (GenerateNodeId(nodeData?._id), nodeData?.index)
          }`}
        >
          {nodeName}
        </div>
      </Row>

      <Row>
        <div pill>
          <div className='node-type'>Control</div>
          {nodeData?.descriptong && (
            <UncontrolledTooltip
              placement='right'
              target={`controlDescription${
                (GenerateNodeId(nodeData?._id), nodeData?.index)
              }`}
              disabled={nodeData?.description === undefined}
              className='display-linebreak'
            >
              {nodeData?.description}
            </UncontrolledTooltip>
          )}
        </div>
      </Row>
      <Row>
        <div
          className='node-table-data'
          id={`controlLikelihoodTooltip${
            (GenerateNodeId(nodeData?._id), nodeData?.index)
          }`}
        >
          Likelihood: {likelihood}
        </div>
        <UncontrolledTooltip
          placement='right'
          target={`controlLikelihoodTooltip${
            (GenerateNodeId(nodeData?._id), nodeData?.index)
          }`}
          disabled={nodeData?.likelihood === undefined}
        >
          {nodeData?.likelihood}
        </UncontrolledTooltip>
      </Row>
      <Row>
        <FormGroup check>
          <div check size='sm' className='node-table-data'>
            <Input
              disabled={readOnly}
              type='checkbox'
              checked={nodeData?.proposed_state}
              style={{ top: '-4px' }}
              onClick={() =>
                setControlCheckbox(nodeData, {
                  proposed_state: !nodeData?.proposed_state,
                })
              }
            />
            Proposed
          </div>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup check>
          <div check size='sm' className='node-table-data'>
            <Input
              disabled={readOnly}
              type='checkbox'
              checked={nodeData?.implemented_state}
              style={{ top: '-4px' }}
              onClick={() =>
                setControlCheckbox(nodeData, {
                  implemented_state: !nodeData?.implemented_state,
                })
              }
            />
            Implemented
          </div>
        </FormGroup>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  searchString: state.analysistree.searchCriteria,
  projectRole: state.profile.projectRole,
});

export default connect(mapStateToProps, { setControlCheckbox })(ControlNode);
