import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { UserPermissions } from '../../../constants/Enums';
import { selectedColor } from '../../../constants/StringConstants';
import { getProfiles } from '../../../redux/actions/profile';
import { addMember } from '../../../redux/actions/projects';
import DraggableModal from '../../entities/EntityModals/DraggableModal';
import { hasPermission } from '../../helpers/genericHelper';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import AutoCompleteInput from '../project-components/AutoCompleteInput';
import RoleSelect from '../project-components/RoleSelect';

/**
 * Add Member Modal
 *
 * Modal for adding new members on a project in the project details page
 *
 * @param modal: sets the body of the modal
 * @param toggle: triggers/toggles the modal to close/open
 * @param addMember: redux action to add new members on the project
 * @param project: redux state to retrieve specific project
 */

const AddMember = ({
  modal,
  toggle,
  addMember,
  project,
  profiles,
  getProfiles,
  currentMembers,
}) => {
  const [selectedRows] = useState([]);
  useEffect(() => {
    getProfiles();
  }, [selectedRows]);

  const [member, setMember] = useState({
    user_id: undefined,
    role: '',
  });

  //Event handling for inputs
  const onChange = (event) => {
    setMember({
      ...member,
      [event.target.name]: event.target.value,
    });
  };

  //method for adding new project
  const onSubmit = async () => {
    addMember(project?._id, member);
  };

  const selection = (row) => {
    setMember({
      ...member,
      user_id: row._id,
    });
  };

  const columns = React.useMemo(() => [
    {
      name: 'User Name',
      selector: 'name',
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Button
            id={`selectButton${row._id}`}
            onClick={() => {
              selection(row);
            }}
          >
            <FontAwesomeIcon
              icon={row._id === member?.user_id ? faCheckSquare : faSquare}
            />
          </Button>
          <UncontrolledTooltip target={`selectButton${row._id}`}>
            Select
          </UncontrolledTooltip>
        </>
      ),
    },
  ]);

  const conditionalRowStyles = [
    {
      when: (row) => row._id === member?.user_id,
      style: {
        backgroundColor: selectedColor,
      },
    },
  ];

  var profile = profiles.map((profile) => profile.name);
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);

  const searchItems =
    profiles &&
    profiles?.filter(
      (member) =>
        member.name &&
        member.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <AutoCompleteInput
        options={profile}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search ISAT User'
      />
    );
  }, [searchText, resetPaginationToggle]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader style={{ zIndex: 1 }}>
          <div className='modal-header'>Add New Member</div>
        </ModalHeader>
        <div className='modal-line' style={{ zIndex: 1 }} />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <DataTable
                style={{ marginTop: -50, zIndex: 0 }}
                columns={columns}
                data={searchItems?.filter(
                  (profile) =>
                    !currentMembers.some(
                      (member) => member?.user_id === profile._id
                    )
                )}
                // obj1.filter(i => !obj2.includes(i.id))
                conditionalRowStyles={conditionalRowStyles}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={CustomDataTableStyles}
              />
              <Label>Role: </Label>
              <RoleSelect
                onChange={onChange}
                isCreator={hasPermission(
                  profiles.find((profile) => profile._id === member?.user_id)
                    ?.permissions,
                  UserPermissions.Creator
                )}
              />
            </FormGroup>
            <FormGroup>
              <Button
                className='float-right btn-danger'
                onClick={() => {
                  toggle();
                  setSearchText('');
                }}
              >
                Cancel
              </Button>
              {member?.user?._id !== '' && member?.role !== '' ? (
                <Button
                  className='float-right btn-add'
                  onClick={() => {
                    onSubmit();
                    toggle();
                    setSearchText('');
                  }}
                >
                  Add Member
                </Button>
              ) : (
                <Button type='submit' className='float-right btn-add' disabled>
                  Add Member
                </Button>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  profiles: state.profile.profiles,
});

export default connect(mapStateToProps, { addMember, getProfiles })(AddMember);
