import React from 'react';
import { Container } from 'reactstrap';

const IntendedWorkflow = () => {
  return (
    <Container>
      <ol style={{ paddingLeft: 20 }}>
        <li>
          Create a project -{'>'} see the chapter “Add project” in the manual
          for further information
        </li>
        <li>
          Build your Analysis Tree -{'>'} see the chapter “Manage entities” in
          the manual for further information on how to create the entity nodes
          and add the different information
          <ol type='A' style={{ paddingLeft: 20 }}>
            <li>
              Define your Assets -{'>'} see the chapter “Manage Assets” for
              further information
            </li>
            <li>
              Define your Damage Scenarios or Threats -{'>'} see the chapter
              “Manage Damage Scenarios / Threats” in the manual for further
              information
              <ol type='a' style={{ paddingLeft: 20 }}>
                <li>Define your impact categories and Security Property</li>
                <li>Rate your impact</li>
              </ol>
            </li>
            <li>
              Define your Threat Scenarios, if applicable -{'>'} see the chapter
              “Manage Threat Scenarios” in the manual for further information
            </li>
            <li>
              Define your attack paths via Vulnerabilities -{'>'} see the
              chapter “Manage Vulnerabilities” in the manual for further
              information
              <ol type='a' style={{ paddingLeft: 20 }}>
                <li>
                  Rate the likelihood of the lowest level vulnerability{' '}
                  <ol type='I' style={{ paddingLeft: 20 }}>
                    <li>Category-based</li>
                    <li>Manually</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Define your Controls -{'>'} see the chapter “Manage Controls” in
              the manual for further information
              <ol type='a' style={{ paddingLeft: 20 }}>
                <li>Rate the likelihood of the Control</li>
                <li>Set the “implemented” or “planned” status</li>
              </ol>
            </li>
            <li>
              Create reference trees for (sub) attack paths which are present
              more than once within the project -{'>'} see the chapter \“Manage
              reference trees\” in the manual for further information
            </li>
          </ol>
        </li>
        <li>
          Risk determination -{'>'} you don’t have to do anything, the tool is
          doing all the work, if you want to know what it’s doing see chapter
          “Likelihood calculation for entities that are not "lowest level
          vulnerabilities" or Controls” in the manual for further information
        </li>
        <li>
          Have a review of your Analysis Tree -{'>'} see the chapter “Review of
          project description and scope” in the manual and the “Mark as
          reviewed” chapters of the entities for further information
        </li>
        <li>
          Create an export of your analysis -{'>'} see the chapter “Export” in
          the manual for further information
        </li>
      </ol>
    </Container>
  );
};

export default IntendedWorkflow;
