import React from 'react';

export const TARAInformation = [
  // {
  //   header: 'Asset Specification',
  //   information: '',
  //   FAQ: [],
  // },
  {
    header: 'Damage scenarios identification & impact rating',
    information: '',
    FAQ: [
      {
        id: 1,
        question:
          'How do I find out who is a stakeholder for my TARA and should give me input?',
        answer:
          'There are typical stakeholders with whom you will get in contact. We have summarized them in a list that you can find here:',
        link: (
          <a
            href={require('./INV_Stakeholder_Analysis_Template.xlsx')}
            download
          >
            <i class='fas fa-file-download'></i>
            Stakeholder List
          </a>
        ),
        answerList: [
          {
            answer:
              'If it is not clear who is a stakeholder, we recommend to do a stakeholder analysis. You can find a guideline that can help you to do the stakeholder analysis here:',
            link: (
              <a href={require('./INV_Stakeholder_Analysis_ABC.pdf')} download>
                <i class='fas fa-file-download'></i>
                Stakeholder analysis
              </a>
            ),
          },
        ],
      },
      {
        id: 2,
        question:
          'Oh my, what is “personal data” and how do I evaluate related damage scenarios?',
        answer:
          'Often at a first glance it is not obvious which data could be classified as personal data. This is especially the case when you do your analysis for a component of a technical system (e.g. an ECU) and you have to take into account the higher-level system context (e.g. the vehicle).',
        answerList: [
          {
            answer:
              'Data that is handled by these components must be systematically analyzed and classified. Only this will show possible impacts of data leaks or data modification or loss of that data',
          },
          {
            answer:
              'When rating the impact, the user-perspective is chosen. The impact regarding the personal data of an end-user out the perspective the user will experience',
          },
          {
            answer:
              'This will allow me to draw conclusions how a violation might affect me as a company financially or reputation-wise (which is often the even higher damage financially)',
          },
          {
            answer:
              'A short overview how to do such a data privacy assessment that shows the steps you need to do you can find here:',
            link: (
              <a href={require('./INV_DataPrivacy_Guideline.pdf')} download>
                <i class='fas fa-file-download'></i>
                Link
              </a>
            ),
          },
        ],
      },
    ],
  },
  // {
  //   header: 'Threat scenario',
  //   information: '',
  //   FAQ: [],
  // },
  // {
  //   header: 'Attack path analysis',
  //   information: '',
  //   FAQ: [],
  // },
  // {
  //   header: 'Attack feasibility rating',
  //   information: '',
  //   FAQ: [],
  // },

  // {
  //   header: 'Risk value determination',
  //   information: '',
  //   FAQ: [],
  // },
  // {
  //   header: 'Risk treatment decision',
  //   information: '',
  //   FAQ: [],
  // },
  {
    header: 'Cyber-security Goals',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'How can I test, whether I meet my security goals?',
        answer:
          'Testing security requirements and testing in general can be very costly. Usually, 50% of development cost go into testing. There is a lot of saving potential if organized cleverly.',
        answerList: [
          {
            answer: 'Usually, there will result two types of tests',
            children: [
              {
                answer:
                  'Requirements-based tests (system test, SW test, HIL, SIL (…) based on requirements) – also usually referred to as verification',
              },
              {
                answer:
                  'Goal-based tests that test for higher-level ideas which cannot be tested by single requirements (Stress tests, Fuzzing, Vulnerability-Scanning, Penetration testing) – usually referred to as validation.',
              },
            ],
          },
          {
            answer:
              'It is important to understand that it is not sufficient to test all requirements that can be attributed to a goal and then say “the goal is met, and the system is secure"',
          },
          {
            answer:
              'An overall testing logic should be present that delivers an argumentation why the chosen test methods and test instances (e.g. a HIL rack) are adequate and sufficient',
          },
          {
            answer:
              'In addition to the pre-planned testing, Penetration testing is performed to find extra loopholes that an attacker might use to get access to your system. This is an art and makes use of the cleverness of the tester. This gives extra assurance that even with malicious intent the system withstands an attack',
          },
          {
            answer:
              'Yet there is no clear limit how much you should test. You can always test more. In complex systems, it is mathematically impossible to test all combinations that are possible since they are so numerous (i.e. it would take 824 years to test all combinations with a typical test run-time)',
          },
          {
            answer:
              'For that reason, the testing logic is so important to get right. If done correctly, it is the basis for a good test strategy that can save a lot of money',
          },
          {
            answer:
              'Testing is always a trade-off between the invested effort and the expected outcome. Ask yourself: How much of the identified risks can I realistically avoid by testing the system? How much damage will it prevent? So how much can I invest in testing?',
          },
          {
            answer:
              'Here is a link to an overview of common test strategies which you might use',
            link: (
              <a href={require('./INV_Overview_Test-Strategies.pdf')} download>
                <i class='fas fa-file-download'></i>
                Link
              </a>
            ),
          },
          {
            answer:
              'INVENSITY is experienced in creating good test strategies and calculate them economically. We deliver an answer, where it makes sense to stop with testing and save that money. Contact us here:',
            link: (
              <a
                href={
                  'https://www.invensity.com/consulting/systems-engineering'
                }
              >
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
    ],
  },
  {
    header: 'Cyber-security concept',
    information: '',
    FAQ: [
      {
        id: 1,
        question: 'How do I create good security requirements out of the TARA?',
        answer:
          'There are typical stakeholders with whom you will get in contact. We have summarized them in a list that you can find here',
        answerList: [
          {
            answer:
              'If it is not clear who is a stakeholder, we recommend to do a stakeholder analysis. You can find a guideline that can help you to do the stakeholder analysis here ',
            link: (
              <a href={require('./INV_Stakeholder_Analysis_ABC.pdf')} download>
                <i class='fas fa-file-download'></i>
                Stakeholder analysis
              </a>
            ),
          },
          {
            answer:
              'To do this consistently, we recommend to introduce certain levels (you could also call them abstraction layers) for your requirements.',
          },
          {
            answer:
              'The requirements level should reflect your system architecture',
          },
          {
            answer:
              'Lower complexity systems typically have 3 layers, higher complexity systems up to 5. Do not introduce too many layers since this creates high efforts in traceability and handling (the tree structure gets exponentially larger!)',
          },
          {
            answer:
              'Whenever possible, try to stop before you make design decisions and leave this step for the system architect. I.e. do not decide if a security control will be implemented in hardware or software, if possible.            ',
          },
          {
            answer:
              'Here you find a short guideline how to write good requirements',
            link: (
              <a href={require('./INV_Requirements_Guideline.pdf')} download>
                <i class='fas fa-file-download'></i>
                Guideline
              </a>
            ),
          },
          {
            answer:
              'We can also support you in writing requirements. ISAT supports an Export. If you want to outsource the complete requirements generation out of your TARA, this is possible. Please contact us here:',
            link: (
              <a href={'https://www.invensity.com/consulting/cyber-security/'}>
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 2,
        question:
          'What is a security concept? Or – how do I get from a risk treatment decision to a meaningful security solution?',
        answer:
          'A security concept is a proposition how to implement the recommended measures into your system under the given restrictions',
        answerList: [
          {
            answer:
              'Security solutions need to be integrated into the overall systems design',
          },
          {
            answer:
              'This sounds trivial but usually involves a lot of persons and many alignments with technical and non-technical disciplines and departments',
          },
          {
            answer:
              'In a first step you need to understand what is planned / pre-defined for the product (e.g. selected hardware) and what requirements are given by other disciplines (e.g. functional safety)',
          },
          {
            answer:
              'The second step is to assess the framework conditions and identify possible risk mitigation measures. This means talking with a lot of stakeholders to find out what requirements or restrictions they name. Not all possible measures will prove feasible. You might learn that a hardware might not have the capability for the proposed solution.',
          },
          {
            answer:
              'Third, a set of measures will be selected out of the possibilities. Criteria for selection are',
            children: [
              {
                answer: 'Costs and efforts',
              },
              {
                answer:
                  'Impact on development (e.g timing – will the solution be ready when needed)',
              },
              {
                answer:
                  'Impact on overall residual risk (you want to select a set of measures that reduces the risk under an acceptable threshold)',
              },
            ],
          },
          {
            answer:
              'Out of this the concept will be derived. It shows the best combination of solutions and gives an argumentation why this selection is good',
          },
          {
            answer:
              'If you need support creating a security concept, contact our experts:',
            link: (
              <a href={'https://www.invensity.com/consulting/cyber-security/'}>
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 3,
        question:
          'I am missing software architecture for my system as an input for TARA. What can I do?',
        answer:
          'Incomplete software architecture is a common issue. If your system evolved over many years and generations, you have old code that you want to keep for some reason, called Legacy code. You might have used third party libraries to save time for which you have no architecture. Last, but not least time pressure is a big contributor that let people directly start with coding without creating an architecture first or documenting their coding in an architectural form.',
        link: (
          <a href={require('./INV_SW_Arch.pdf')} download>
            <i class='fas fa-file-download'></i>
            Document
          </a>
        ),
        answerList: [
          {
            answer:
              'The first thing you can do is an automated code analysis. That is a work around which is proven to reduce the effects the missing architecture.',
            children: [
              {
                answer: 'Be it through managing open-source software,  ',
              },
              {
                answer: 'Reconstruct architecture from code',
              },
              {
                answer:
                  'Provide timely insights and suggestion on how to improve the code and its design.',
              },
            ],
          },
          {
            answer:
              'If you want further information on a methodology, how to create an architecture from legacy code, INVENSITY has developed the product ACO (Architecture and Code Optimization).',
            link: (
              <a
                href={
                  'https://www.invensity.com/consulting/software-engineering/'
                }
              >
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 4,
        question:
          'How do I realize and integrate the security concept in my software? ',
        answer:
          'A security concepts has a direct influence on software architecture. New functionalities or interfaces to be added, removed, or changed need to be considered. Therefore, a dedicated software architect is one of the most important stakeholders of the security analysis, conception, and implementation.',
        link: (
          <a href={require('./INV_SW_Arch.pdf')} download>
            <i class='fas fa-file-download'></i>
            Document
          </a>
        ),
        answerList: [
          {
            answer:
              'As security implementations or external security components, which need to be integrated are possible parts to be reused in multiple projects, a wide exchange between all software architects is proposed.',
          },
          {
            answer: 'Additional Info:',
            link: (
              <a
                href={
                  'https://www.invensity.com/consulting/software-engineering/'
                }
              >
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 5,
        question:
          'What alignment do cybersecurity and software risk management need? ',
        answer:
          'Technical Debt is a known risk driver in software development. Be it low code quality, known bugs or missing implementations. The Technical Debt implications for compliance, operations, development, safety, and security. Central management of all risks allows to set the right priorities and to make the good business decisions.',
        answerList: [
          {
            answer:
              'To effectively align cybersecurity and software risk management you could:',
            children: [
              {
                answer:
                  'Track risks in your ticket tool. Most ticket tools either provide ‘risk’ categories or can be extended to do so. Additional tagging topic clusters such as ‘cybersecurity’ and ‘technical debt’ will make the information available and visible.',
              },
              {
                answer:
                  'Schedule regular sync meetings. Regular meetings with risk managers, security manager, project managers and architects are a best practice.',
              },
            ],
          },
          {
            answer: 'Additional Info:',
            link: (
              <a
                href={
                  'https://www.invensity.com/consulting/software-engineering/'
                }
              >
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 6,
        question:
          'I am using external software parts (e.g. OSS) - how do i ensure security?',
        answer:
          'This should be part of Vulnerability Management. To identify vulnerabilities open-source libraries can be scanned for vulnerabilities. Vulnerability management should take care of tracking these vulnerabilities through projects and deliveries.',
        answerList: [
          {
            answer:
              'Identified vulnerabilities can lead to internal measures but are often resolved by the external developers. In this cases an update to the latest version of the external part is a good solution. However, this leads to the risk of incompatibility, which need to be tracked in risk management.',
          },
          {
            answer:
              'Hint: If you are using encryption software – even if it is only as external component, take care of export control.',
          },
          {
            answer:
              'Hint: If you use external parts, take also care if these parts can be reused.',
          },
          {
            answer: 'Additional Info:',
            link: (
              <a
                href={
                  'https://www.invensity.com/consulting/software-engineering/'
                }
              >
                <i class='fas fa-external-link-alt'></i>
                Link
              </a>
            ),
          },
        ],
      },
      {
        id: 7,
        question:
          'How can I set up or improve the reuse of security related functionality?',
        answer:
          'Reuse of software improves your quality and time-to-market of upcoming products and projects. Therefore, it makes sense to clarify the reuse of any written or included software component. An explicit planning and managing of reference architecture, platform concept or block library is necessary for a long-term success. This may have an initial effort but will pay off quickly.',
        answerList: [
          {
            answer:
              'To set this up, you need explicit and aligned software architects.',
            link: (
              <a href={require('./INV_SW_Arch.pdf')} download>
                <i class='fas fa-file-download'></i>
                Document
              </a>
            ),
          },
        ],
      },
    ],
  },
  // {
  //   header: 'Next steps - what to do with the outputs',
  //   information: '',
  //   FAQ: [],
  // },
];
