import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import { CatalogLocation, OwnerType } from '../../../constants/Enums';
import {
  addReferenceTree,
  addReferenceTreeToNode,
} from '../../../redux/actions/analysistree';
import { addEntity } from '../../../redux/actions/entities';
import CustomDataTableStyles from '../../layout/DataTableStyles';
import { InputTemplate } from './InputTemplate';

/**
 * Similar Control Modal body
 *
 * A modal body for similar control creation
 *
 *  @param toggle toggle for modal display
 *  @param parent parent supplied by the parent component for update/creation
 *  @param addReferenceTree redux action to add reference tree
 *  @param addReferenceTreeToNode redux action to add reference tree to node
 *  @param userReftrees user's accessible reftrees
 *  @param defaultControlCatalogs project catalogs
 *  @param newEntity create new entity from parent
 *  @param sameNameEntities similar entities
 *  @param selectedSimilarEntity selected similar entity
 *  @param selectSimilarEntity action to select similar entity
 *  @param entityName entity name from initial creation
 *  @param sameNameCatalog catalog of selected similar entity
 *  @param setSameNameCatalog set catalog of selected similar entity
 *  @param userControlCatalogs accessible catalogs of the user
 *
 */

const SimilarControlModalBody = ({
  toggle,
  parent,
  addReferenceTree,
  addReferenceTreeToNode,
  userReftrees,
  defaultControlCatalogs,
  newEntity,
  sameNameEntities,
  selectedSimilarEntity,
  selectSimilarEntity,
  entityName,
  sameNameCatalog,
  setSameNameCatalog,
  userControlCatalogs,
}) => {
  const [projectCatalog, setProjectCatalog] = useState(undefined);

  const columns = React.useMemo(() => [
    {
      name: 'Name',
      selector: (row) => row[0].name,
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'catalog_type',
      sortable: true,
      width: '80px',
      cell: (row) => (
        <>
          {userControlCatalogs.find((catalog) =>
            catalog?.children?.includes(row[0].owner.id)
          )?.catalog_type === CatalogLocation.Global ? (
            <i className='btn-icon fa fa-globe' />
          ) : (
            <i className='btn-icon fa fa-tasks' />
          )}
        </>
      ),
    },
    {
      cell: (row) => (
        <Fragment>
          <Button
            id={`deleteUserButton${row._id}`}
            className='btn-secondary'
            onClick={() => {
              selectSimilarEntity(row[0]);
            }}
          >
            <FontAwesomeIcon
              icon={row[0] === selectedSimilarEntity ? faCheckSquare : faSquare}
            />
          </Button>
        </Fragment>
      ),
    },
  ]);

  useEffect(() => {
    if (
      selectedSimilarEntity?.owner?.owner_type === OwnerType.Project ||
      selectedSimilarEntity === undefined
    ) {
      setSameNameCatalog(undefined);
      return;
    }
    const reftreeId = selectedSimilarEntity.owner.id;

    const catalog = userControlCatalogs.find((catalog) =>
      catalog?.children.includes(reftreeId)
    );
    setSameNameCatalog(catalog);
  }, [selectedSimilarEntity]);

  const conditionalRowStyles = [
    {
      when: (row) => row[0] === selectedSimilarEntity,
      style: {
        backgroundColor: 'var(--highlight-color)',
      },
    },
  ];

  const existingControl = async () => {
    if (selectedSimilarEntity.owner.owner_type === 'project') {
      const newReftree = await addReferenceTree(
        {
          name: selectedSimilarEntity?.name,
          description: selectedSimilarEntity?.description,
          catalog: projectCatalog._id,
        },
        selectedSimilarEntity
      );
      addReferenceTreeToNode(parent, newReftree);
      toggle();
    } else {
      const reftree = userReftrees.find(
        (reftree) =>
          parseInt(reftree._id) === parseInt(selectedSimilarEntity.owner.id)
      );
      addReferenceTreeToNode(parent, reftree, undefined, projectCatalog);
      toggle();
    }
  };

  const selectProjectCatalog = (e) => {
    const catalogId = parseInt(e.target.value);

    const projectCatalog = defaultControlCatalogs.find(
      (cat) => cat._id === catalogId
    );
    setProjectCatalog(projectCatalog);
  };

  return (
    <Fragment>
      A control with the following similar name <b>{entityName} </b> already
      exists in the project. Do you want to proceed? Alternatively, you can
      select an existing one from the list below.
      <br />
      If an existing control from a global catalog is selected, the user would
      need to provide a project catalog for it to be assigned on before being
      used.
      <br />
      <DataTable
        columns={columns}
        data={sameNameEntities}
        pagination
        customStyles={CustomDataTableStyles}
        defaultSortAsc='name'
        conditionalRowStyles={conditionalRowStyles}
      />
      {((sameNameCatalog?.project_id === undefined &&
        sameNameCatalog !== undefined) ||
        selectedSimilarEntity?.owner?.owner_type === OwnerType.Project) && (
        <Fragment>
          <FormGroup>
            <InputTemplate
              label='Project Catalog Assignment'
              type='select'
              value={projectCatalog?._id ?? ''}
              onChange={selectProjectCatalog}
              invalid={projectCatalog === undefined}
              formFeedback='Please select a default catalog to use existing control'
              selectDefault
            >
              {defaultControlCatalogs?.map((catalogItem) => {
                return (
                  <option value={catalogItem._id}>{catalogItem.name}</option>
                );
              })}
            </InputTemplate>
          </FormGroup>
        </Fragment>
      )}
      <FormGroup>
        <Button
          className='float-right btn-add'
          onClick={() => {
            newEntity();
          }}
        >
          Ok
        </Button>
        <Button
          className='float-right btn-add'
          onClick={() => existingControl()}
          disabled={
            //Disabled if there's no similar entity
            selectedSimilarEntity === undefined
              ? true
              : //Global control catalog and project catalog is undefined
              selectedSimilarEntity.owner.owner_type === OwnerType.RefTree &&
                sameNameCatalog?.project_id === undefined &&
                projectCatalog === undefined
              ? true
              : //Regular Control and project catalog is undefined
                selectedSimilarEntity.owner.owner_type === OwnerType.Project &&
                projectCatalog === undefined
          }
        >
          Use Existing Control
        </Button>
        <Button className='float-right btn-danger' onClick={toggle}>
          Cancel
        </Button>
      </FormGroup>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userReftrees: state.profile.userReftrees,
  defaultControlCatalogs: state.project.control_catalogs,
  userControlCatalogs: state.profile.userControlCatalogs,
});

export default connect(mapStateToProps, {
  addEntity,
  addReferenceTree,
  addReferenceTreeToNode,
})(SimilarControlModalBody);
